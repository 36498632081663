export const linear_gradient_disabled =
    'linear-gradient(45deg,hsla(0,0%,100%,.15) 25%,transparent 0,transparent 50%,hsla(0,0%,100%,.15) 0,hsla(0,0%,100%,.15) 75%,transparent 0,transparent)';

const BatteryIcon = ({
    value,
    color,
    isDisabled,
}: {
    value: number;
    color: string;
    isDisabled: boolean;
}): JSX.Element => {
    return (
        <div className="display-block position-relative">
            <div
                className="display-flex flex-column padding-2 border-style-solid border-width-1"
                style={{ width: '64px', height: '26px', borderRadius: '6px', color: '#a7afbb' }}
            >
                {isDisabled ? null : (
                    <div className="display-flex justify-content-center z-index-2">
                        <span
                            data-testid={'battery-value'}
                            className="bg-darker justify-content-center display-flex position-absolute text-color-white align-items-center text-size-12"
                            style={{
                                borderRadius: '8px',
                                width: '36px',
                                top: '7px',
                                height: '12px',
                            }}
                        >
                            {value}%
                        </span>
                    </div>
                )}
                <div className="position-relative width-100pct height-100pct">
                    {!isDisabled ? (
                        <div
                            className={`${color} z-index-1 position-absolute height-100pct`}
                            data-testid="battery-progress"
                            style={{
                                width: `${value}%`,
                                borderRadius: '4px',
                            }}
                        />
                    ) : null}
                    <div
                        className="position-absolute width-100pct height-100pct"
                        data-testid="battery-bg"
                        style={{
                            borderRadius: '4px',
                            backgroundColor: isDisabled ? '#d0d8de' : '#E5EBF0',
                            backgroundImage: isDisabled ? linear_gradient_disabled : '',
                        }}
                    />
                </div>
            </div>
            <div
                className="position-absolute"
                style={{
                    background: '#A7AFBB',
                    width: '4px',
                    height: '8px',
                    borderRadius: '0px 2px 2px 0px',
                    left: '64px',
                    top: '9px',
                }}
            />
        </div>
    );
};

export default BatteryIcon;
