import useMutationMarkAsSeenApi from '../../../queries/useMutationMarkAsSeenApi';
import { AccessToken } from '../../../../../../configuration';
import { useIntl } from 'react-intl';

const MarkAsSeen = ({
    eventId,
    accessToken,
    markAll,
    isFromWidget,
    severityLevel,
    lastEventId,
    disabled,
    animateEvent,
}: {
    accessToken: AccessToken;
    eventId?: string;
    markAll?: boolean;
    isFromWidget?: boolean;
    severityLevel?: string[];
    lastEventId?: number;
    disabled?: boolean;
    animateEvent?: Function;
}): JSX.Element => {
    const intl = useIntl();
    const mutation = useMutationMarkAsSeenApi(accessToken, eventId, markAll, isFromWidget, severityLevel, lastEventId);

    const handleMarkAsSeen = () => {
        if (!disabled) {
            mutation.mutate({});
            if (animateEvent) {
                animateEvent(eventId);
            }
        }
    };

    return (
        <div
            className={
                disabled
                    ? 'display-flex justify-content-start text-color-gray'
                    : 'display-flex justify-content-start text-color-primary cursor-pointer'
            }
            onClick={() => handleMarkAsSeen()}
        >
            <span className="rioglyph rioglyph-ok-circle margin-right-4" style={{ marginTop: '3px' }} />
            {markAll ? (
                <span className="text-size-14">
                    {intl.formatMessage({ id: 'e4c.overview.events.widget.modal.markAllAsSeen' })}
                </span>
            ) : (
                <span className="text-size-14">
                    {intl.formatMessage({ id: 'e4c.overview.events.widget.modal.markAsSeen' })}
                </span>
            )}
        </div>
    );
};

export default MarkAsSeen;
