import AbstractElectricVehiclesSorter from './AbstractElectricVehiclesSorter';
import SortDirection from '../../common/utilities/sort/SortDirection';
import orderBy from 'lodash/orderBy';
import ChargingStatus from '../models/enums/ChargingStatus';
import { SORT_KEY_CHARGING_STATUS } from './keys';
import { VehicleChargingData } from '../models/VehicleChargingData';

class ChargingStatusSorter extends AbstractElectricVehiclesSorter {
    constructor() {
        super(SORT_KEY_CHARGING_STATUS);
    }
    sort = (array: VehicleChargingData[], direction: SortDirection): VehicleChargingData[] =>
        orderBy<VehicleChargingData>(array, this.mapChargingStatusToIndex, direction);

    mapChargingStatusToIndex = (ev: VehicleChargingData): number => {
        switch (ev.measurements.chargingState.value) {
            case ChargingStatus.FAILURE:
                return 2;
            case ChargingStatus.CHARGED:
                return 3;
            case ChargingStatus.CHARGING:
                return 4;
            case ChargingStatus.CHARGING_LONGPRESS:
                return 5;
            case ChargingStatus.CONNECTED:
                return 6;
            default:
                return 1;
        }
    };
}

export default ChargingStatusSorter;
