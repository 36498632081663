import { ReactElement } from 'react';
import { TooltipCellWrapper } from '../TooltipCellWrapper';

interface BatteryTemperatureProps {
    maxBatteryTemperature?: number;
    minBatteryTemperature?: number;
}

const renderTemperature = (colorClass: string, temperature?: number) => {
    return (
        temperature && (
            <div className="display-flex">
                <span className="rioglyph rioglyph-warning-sign text-color-warning margin-right-5" />
                <div className={`text-bold ${colorClass} ellipsis-1`}>
                    <TooltipCellWrapper content={`${temperature}° C`} />
                </div>
            </div>
        )
    );
};

const BatteryTemperatureCell = ({
    maxBatteryTemperature,
    minBatteryTemperature,
}: BatteryTemperatureProps): ReactElement => {
    const noTemperatureInfo = !minBatteryTemperature && !maxBatteryTemperature;

    if (noTemperatureInfo) {
        return (
            <>
                <span className="rioglyph rioglyph-ok margin-right-5" />
                <span>OK</span>
            </>
        );
    } else {
        return (
            <>
                {renderTemperature('text-color-danger', maxBatteryTemperature)}
                {renderTemperature('text-color-info', minBatteryTemperature)}
            </>
        );
    }
};

export default BatteryTemperatureCell;
