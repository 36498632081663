import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

const renderChargingUntilDateAndTime = (
    date: string,
    isDateOutdated: boolean,
    time: string,
    isTimeOutdated: boolean
): JSX.Element => {
    return (
        <>
            <FormattedMessage id={'e4c.vehicles.table.chargingStatus.chargingUntil'} />{' '}
            <span className={classNames({ 'text-decoration-line-through': isDateOutdated })}>{date}</span>
            <span className={classNames({ 'text-decoration-line-through': isTimeOutdated })}>{` ${time}`}</span>
        </>
    );
};

const renderChargingUntilDelayedDateAndTime = (
    hasDelayedDate: boolean,
    dateAndTime: string | null
): JSX.Element | null => {
    return hasDelayedDate ? <span className={'text-danger'}>{` ${dateAndTime}`}</span> : null;
};

export interface ChargingUntilProps {
    date: string;
    isDateOutdated: boolean;
    time: string;
    isTimeOutdated: boolean;
    hasDelayedDate: boolean;
    dateAndTime: string | null;
}

const ChargingUntil = (props: ChargingUntilProps): JSX.Element => {
    const { date, isDateOutdated, time, isTimeOutdated, hasDelayedDate, dateAndTime } = props;
    return (
        <span className={'padding-left-5 display-block ellipsis-1'} data-testid={'cs-charging-until'}>
            {renderChargingUntilDateAndTime(date, isDateOutdated, time, isTimeOutdated)}
            {renderChargingUntilDelayedDateAndTime(hasDelayedDate, dateAndTime)}
        </span>
    );
};

export default ChargingUntil;
