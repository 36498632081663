import { HistoryTableColumnDetails } from '../../types';
import { DateTimeCol } from '../components/columns/DateTimeCol';
import { Status } from '../components/columns/Status';
import { Duration } from '../components/columns/Duration';
import RemainingCapacity from '../components/columns/RemainingCapacity';
import StateOfCharge from '../components/columns/StateOfCharge';
import EnergyConsumed from '../components/columns/EnergyConsumed';
import BatteryCurrent from '../components/columns/BatteryCurrent';
import EnergyAdded from '../components/columns/EnergyAdded';
import VehicleName from '../components/columns/VehicleName';
import '../HistoryTable.css';
import { buildCollpsedItems, getStateOfChargingWarning } from '../HistoryTableHelper';
import CollapsedHeader from './CollapsedHeader';
import CollapsedHeaderRow from '../components/collapsed/CollapsedHeaderRow';
import { PaginationOptions } from '../../../../common/components/pagination/Pagination';
import ChargingProcessList from '../../../models/ChargingProcessList';
import BatteryVoltage from '../components/columns/BatteryVoltage';
import ChargingProcess from '../../../models/ChargingProcess';
import BatteryTemperature from '../components/columns/BatteryTemperature';

export const getBatteryColumnConfig = (): { [key: string]: HistoryTableColumnDetails } => {
    return {
        date: {
            label: 'e4c.history.date',
            sortable: true,
            width: 100,
            hasRightSeparator: true,
        },
        vehicle: {
            label: 'e4c.history.vehicle',
            sortable: false,
        },
        status: {
            label: 'e4c.history.status',
            sortable: false,
        },
        remainingCapacity: {
            label: 'e4c.history.remainingCapacity',
            sortable: false,
            tooltip: 'e4c.history.remainingCapacity.tooltip',
        },
        stateOfCharge: {
            label: 'e4c.history.stateOfCharge',
            tooltip: 'e4c.history.stateOfCharge.tooltip',
            sortable: false,
            hasRightSeparator: true,
        },
        duration: {
            label: 'e4c.history.duration',
            sortable: false,
        },
        energyConsumed: {
            label: 'e4c.history.energyConsumed',
            sortable: false,
        },
        energyAdded: {
            label: 'e4c.history.energyAdded',
            sortable: false,
        },
        current: {
            label: 'e4c.history.current',
            sortable: false,
        },
        voltage: {
            label: 'e4c.history.voltage',
            sortable: false,
        },
        batteryTemperature: {
            label: 'e4c.history.batteryTemperature',
            sortable: false,
        },
        chevron: {
            label: 'e4c.history.chevron',
            sortable: false,
            width: 50,
        },
    };
};

export const renderBatteryColumnValue = (column: string, chargingProcess: ChargingProcess): JSX.Element => {
    switch (column) {
        case 'date':
            return <DateTimeCol date={chargingProcess.startDateTime} />;
        case 'status':
            return <Status processType={chargingProcess.type} />;
        case 'vehicle':
            return <VehicleName name={chargingProcess.assetName} />;
        case 'duration':
            return <Duration duration={chargingProcess.duration} />;
        case 'remainingCapacity':
            return (
                <RemainingCapacity
                    warningColor={getStateOfChargingWarning(chargingProcess.stateOfCharge)}
                    remainingRange={chargingProcess.remainingRangeKmAtStartTime}
                    energyLevel={chargingProcess.energyLevelKwhAtStartTime}
                />
            );
        case 'stateOfCharge':
            return (
                <StateOfCharge
                    warningColor={getStateOfChargingWarning(chargingProcess.stateOfCharge)}
                    stateOfCharge={chargingProcess.stateOfCharge}
                />
            );
        case 'energyConsumed':
            return (
                <EnergyConsumed
                    totalEnergyConsumptionKwh={chargingProcess.totalEnergyConsumptionKwh}
                    processType={chargingProcess.type}
                />
            );
        case 'energyAdded':
            return <EnergyAdded energyAdded={chargingProcess.energyAddedKwh} processType={chargingProcess.type} />;
        case 'current':
            return <BatteryCurrent currents={chargingProcess.batteryCurrents} />;
        case 'voltage':
            return <BatteryVoltage voltages={chargingProcess.batteryVoltage} />;
        case 'batteryTemperature':
            return (
                <BatteryTemperature
                    maxBatteryTemperatureWarningC={chargingProcess.maxBatteryTemperatureWarningC}
                    minBatteryTemperatureWarningC={chargingProcess.minBatteryTemperatureWarningC}
                />
            );
        case 'chevron':
            return <></>;
        default:
            throw Error('Column from ColumnConfig not handled in renderColumnValue - please add it');
    }
};

export const renderRows = (
    sortedRows: ChargingProcessList,
    tableColumns: string[],
    keysOpened: string[],
    locale: string,
    tableColumnsWithRightSeparator: string[],
    renderColumnValue: (column: string, chargingProcess: ChargingProcess, locale: string) => JSX.Element,
    setKeysOpened: (key: string) => void,
    pagination: PaginationOptions
): JSX.Element[] => {
    return Object.entries(sortedRows.filterByPaginationAndGroupByStartDate(pagination)).map(
        ([date, chargingProcessList], index) => {
            const collapsedItems = buildCollpsedItems(keysOpened, date);
            return (
                <>
                    <tr className={'compactRow'} key={index} data-testid="collapsed-header-history">
                        <CollapsedHeader
                            maximumVoltage={chargingProcessList.getMaxVoltage()}
                            maximumCurrent={chargingProcessList.getMaxCurrent()}
                            collapsedType={collapsedItems.collapsedType}
                            date={date}
                            quantityOfVehicles={chargingProcessList.getNumberOfVehicles()}
                            setKeysOpened={setKeysOpened}
                            sumEnergyAdded={chargingProcessList.getSumEnergyAdded()}
                            sumEnergyConsumed={chargingProcessList.getSumEnergyConsumption()}
                            totalOfBatteryTemperatureWarnings={chargingProcessList.getTotalOfBatteryTemperatureWarnings()}
                        />
                    </tr>
                    {collapsedItems.rowType === 'extendedRow'
                        ? chargingProcessList.processList.map((cycle: ChargingProcess, idx: number) => {
                              return (
                                  <CollapsedHeaderRow
                                      collapsedItems={collapsedItems.rowType}
                                      cycle={cycle}
                                      key={idx}
                                      locale={locale}
                                      renderColumnValue={renderColumnValue}
                                      tableColumns={tableColumns}
                                      tableColumnsWithRightSeparator={tableColumnsWithRightSeparator}
                                  />
                              );
                          })
                        : null}
                </>
            );
        }
    );
};
