interface WithUnitProps {
    unit: string;
    className?: string;
    children?: JSX.Element;
    dataTestId: string;
}

const WithUnit: React.FC<WithUnitProps> = ({ children, unit, dataTestId }) => {
    return (
        <span data-testid={dataTestId}>
            {children} {unit}
        </span>
    );
};
export default WithUnit;
