import { configureStorage } from './storage';

export const extractAccessTokenFromWindowLocation = window => {
    if (!window || !window.location || !window.location.href || typeof window.location.href !== 'string') {
        return;
    }

    let token;

    const replacer = (substring, arg) => {
        token = arg;
        return substring;
    };

    window.location.href.replace(/access_token=([^&]+)/u, replacer);

    return token;
};

export const configureAccessToken = (window, storage) => {
    const urlToken = extractAccessTokenFromWindowLocation(window);

    if (urlToken) {
        storage.saveAccessToken(urlToken);
    }

    return storage;
};

export const accessToken = configureAccessToken(window, configureStorage());
