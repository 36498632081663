import VehicleDetailsFooter from './VehicleDetailsFooter';
import CloseFooter from './CloseFooter/CloseFooter';

class EtgeVehicleDetailsFooter extends VehicleDetailsFooter {
    private readonly _onClose: () => void;

    constructor(onClose: () => void) {
        super();
        this._onClose = onClose;
    }

    renderFooter(): JSX.Element {
        return <CloseFooter onCloseClick={this._onClose} />;
    }
}

export default EtgeVehicleDetailsFooter;
