type CollapsedVoltageProps = {
    maximumVoltage: number | null;
};

const CollapsedVoltage = (props: CollapsedVoltageProps): JSX.Element => {
    const { maximumVoltage } = props;
    return (
        <span data-testid="battery-voltage" className="ellipsis-1 display-block">
            <span className={'rioglyph rioglyph-flash text-size-20 text-color-gray'} />
            {maximumVoltage != null ? <span>{`${maximumVoltage.toFixed(0)} V`}</span> : <span>-</span>}
        </span>
    );
};

export default CollapsedVoltage;
