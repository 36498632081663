import { NO_DATA_INFO } from '../../../types';

type EnergyConsumedProps = {
    totalEnergyConsumptionKwh: number | null;
    processType: string;
};

const EnergyConsumed = (props: EnergyConsumedProps): JSX.Element => {
    const { totalEnergyConsumptionKwh, processType } = props;

    const hasValue = totalEnergyConsumptionKwh !== null && !isNaN(totalEnergyConsumptionKwh);

    const displayValue = (): string => {
        if (hasValue && processType === 'DISCHARGING') {
            return `${totalEnergyConsumptionKwh.toFixed(0)} kWh`;
        }
        return '-';
    };
    return (
        <span>
            {hasValue || processType === 'CHARGING' ? (
                <span>{displayValue()}</span>
            ) : (
                <span className={'text-color-lighter'}>{NO_DATA_INFO}</span>
            )}
        </span>
    );
};

export default EnergyConsumed;
