import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Timer, VehicleDetails, Weekday } from '../../components/details/vehicleDetails/types';
import { ChargingModeEnum } from '../../types';
import { State } from '../../../../../types';
import { ErrorCodeEnum } from '../../../common/enums/ErrorCode';

export const VEHICLE_DETAILS_LOADING_STARTED = 'VEHICLE_DETAILS_LOADING_STARTED';
export const VEHICLE_DETAILS_LOADING_SUCCESSFUL = 'VEHICLE_DETAILS_LOADING_SUCCESSFUL';
export const VEHICLE_DETAILS_LOADING_FAILED = 'VEHICLE_DETAILS_LOADING_FAILED';
export const VEHICLE_CHARGING_MODE_CHANGED = 'VEHICLE_CHARGING_MODE_CHANGED';
export const VEHICLE_TIMER_CONFIGURATION_TIMER_ADDED = 'VEHICLE_TIMER_CONFIGURATION_TIMER_ADDED';
export const VEHICLE_TIMER_CONFIGURATION_TIMER_REMOVED = 'VEHICLE_TIMER_CONFIGURATION_TIMER_REMOVED';
export const IMMEDIATE_CONFIGURATION_SENDING_STARTED = 'IMMEDIATE_CONFIGURATION_SENDING_STARTED';
export const IMMEDIATE_CONFIGURATION_SENDING_SUCCESSFUL = 'IMMEDIATE_CONFIGURATION_SENDING_SUCCESSFUL';
export const IMMEDIATE_CONFIGURATION_SENDING_FAILED = 'IMMEDIATE_CONFIGURATION_SENDING_FAILED';
export const TIMER_CONFIGURATION_SENDING_STARTED = 'TIMER_CONFIGURATION_SENDING_STARTED';
export const TIMER_CONFIGURATION_SENDING_SUCCESSFUL = 'TIMER_CONFIGURATION_SENDING_SUCCESSFUL';
export const TIMER_CONFIGURATION_SENDING_FAILED = 'TIMER_CONFIGURATION_SENDING_FAILED';
export const DAY_TO_TIMER_ADDED = 'DAY_TO_TIMER_ADDED';
export const DAY_FROM_TIMER_REMOVED = 'DAY_FROM_TIMER_REMOVED';
export const TIMER_DEPARTURE_TIME_CHANGED = 'TIMER_DEPARTURE_TIME_CHANGED';
export const TIMER_ENABLED_CHANGED = 'TIMER_ENABLED_CHANGED';
export const CONFIGURATION_CHANGE_CONFIRMED = 'CONFIGURATION_CHANGE_CONFIRMED';
export const SHOW_CONFIGURATION_CHANGE_CONFIRM_DIALOG = 'SHOW_CONFIGURATION_CHANGE_CONFIRM_DIALOG';
export const SHOW_SWITCH_TO_IMMEDIATE_CHARGING_DIALOG = 'SHOW_SWITCH_TO_IMMEDIATE_CHARGING_DIALOG';
export const SWITCH_TO_IMMEDIATE_CHARGING_CONFIRMED = 'SWITCH_TO_IMMEDIATE_CHARGING_CONFIRMED';
export const CLIMATE_MODE_CHANGED = 'CLIMATE_MODE_CHANGED';
export const TARGET_STATE_OF_CHARGE_CHANGED = 'TARGET_STATE_OF_CHARGE_CHANGED';
export const UNSAVED_CHANGES_CONFIRM_DIALOG_SHOWED = 'UNSAVED_CHANGES_CONFIRM_DIALOG_SHOWED';
export const TOGGLE_SENDING_CHARGING_CONFIG_SUCCEEDED = 'TOGGLE_SENDING_CHARGING_CONFIG_SUCCEEDED';
export const READY_TO_DRIVE_DURATION_CHANGED = 'READY_TO_DRIVE_DURATION_CHANGED';
export const CLEAN_STATE = 'VEHICLE_CHARGING_DETAILS_CLEAN_STATE';

export interface VehicleDetailsLoadingStartedAction {
    type: typeof VEHICLE_DETAILS_LOADING_STARTED;
}

export interface VehicleDetailsLoadingSuccessfulAction {
    type: typeof VEHICLE_DETAILS_LOADING_SUCCESSFUL;
    payload: VehicleDetails;
}

export interface VehicleDetailsLoadingFailedAction {
    type: typeof VEHICLE_DETAILS_LOADING_FAILED;
    payload: ErrorCodeEnum | null;
}

export interface VehicleChargingModeChangedAction {
    type: typeof VEHICLE_CHARGING_MODE_CHANGED;
    payload: ChargingModeEnum;
}

export interface VehicleTimerConfigurationTimerAddedAction {
    type: typeof VEHICLE_TIMER_CONFIGURATION_TIMER_ADDED;
    payload: Timer;
}

export interface VehicleTimerConfigurationTimerRemovedAction {
    type: typeof VEHICLE_TIMER_CONFIGURATION_TIMER_REMOVED;
    payload: number;
}

export interface ImmediateConfigurationSendingStartedAction {
    type: typeof IMMEDIATE_CONFIGURATION_SENDING_STARTED;
}

export interface ImmediateConfigurationSendingSuccessfulAction {
    type: typeof IMMEDIATE_CONFIGURATION_SENDING_SUCCESSFUL;
}

export interface ImmediateConfigurationSendingFailedAction {
    type: typeof IMMEDIATE_CONFIGURATION_SENDING_FAILED;
}

export interface TimerConfigurationSendingStartedAction {
    type: typeof TIMER_CONFIGURATION_SENDING_STARTED;
}

export interface TimerConfigurationSendingSuccessfulAction {
    type: typeof TIMER_CONFIGURATION_SENDING_SUCCESSFUL;
}

export interface TimerConfigurationSendingFailedAction {
    type: typeof TIMER_CONFIGURATION_SENDING_FAILED;
}

export interface DayToTimerAddedAction {
    type: typeof DAY_TO_TIMER_ADDED;
    payload: {
        day: Weekday;
        timerNo: number;
    };
}

export interface DayFromTimerRemovedAction {
    type: typeof DAY_FROM_TIMER_REMOVED;
    payload: {
        day: Weekday;
        timerNo: number;
    };
}

export interface TimerDepartureTimeChangedAction {
    type: typeof TIMER_DEPARTURE_TIME_CHANGED;
    payload: {
        departureTime: string;
        timerNo: number;
    };
}

export interface TimerEnabledChangedAction {
    type: typeof TIMER_ENABLED_CHANGED;
    payload: number;
}

export interface ConfigurationChangeConfirmedAction {
    type: typeof CONFIGURATION_CHANGE_CONFIRMED;
}

export interface ShowConfigurationChangeConfirmDialogAction {
    type: typeof SHOW_CONFIGURATION_CHANGE_CONFIRM_DIALOG;
    payload: boolean;
}

export interface ShowSwitchToImmediateChargingDialogAction {
    type: typeof SHOW_SWITCH_TO_IMMEDIATE_CHARGING_DIALOG;
    payload: boolean;
}

export interface SwitchToImmediateChargingConfirmedAction {
    type: typeof SWITCH_TO_IMMEDIATE_CHARGING_CONFIRMED;
    payload: boolean;
}

export interface ClimateModeChangedAction {
    type: typeof CLIMATE_MODE_CHANGED;
    payload: boolean;
}

export interface TargetStateOfChargeChangedAction {
    type: typeof TARGET_STATE_OF_CHARGE_CHANGED;
    payload: number;
}

export interface ToggleSendingChargingConfigSucceededAction {
    type: typeof TOGGLE_SENDING_CHARGING_CONFIG_SUCCEEDED;
    payload: boolean;
}

export interface ReadyToDriveDurationChangedAction {
    type: typeof READY_TO_DRIVE_DURATION_CHANGED;
    payload: number;
}

export interface CleanStateAction {
    type: typeof CLEAN_STATE;
}

export type VehicleDetailsActions =
    | VehicleDetailsLoadingStartedAction
    | VehicleDetailsLoadingSuccessfulAction
    | VehicleDetailsLoadingFailedAction
    | VehicleChargingModeChangedAction
    | VehicleTimerConfigurationTimerAddedAction
    | VehicleTimerConfigurationTimerRemovedAction
    | ImmediateConfigurationSendingStartedAction
    | ImmediateConfigurationSendingSuccessfulAction
    | ImmediateConfigurationSendingFailedAction
    | TimerConfigurationSendingStartedAction
    | TimerConfigurationSendingSuccessfulAction
    | TimerConfigurationSendingFailedAction
    | DayToTimerAddedAction
    | DayFromTimerRemovedAction
    | TimerDepartureTimeChangedAction
    | TimerEnabledChangedAction
    | ConfigurationChangeConfirmedAction
    | ShowConfigurationChangeConfirmDialogAction
    | ClimateModeChangedAction
    | TargetStateOfChargeChangedAction
    | ShowSwitchToImmediateChargingDialogAction
    | SwitchToImmediateChargingConfirmedAction
    | ToggleSendingChargingConfigSucceededAction
    | ReadyToDriveDurationChangedAction
    | CleanStateAction;

export type VehicleDetailsThunkAction<R> = ThunkAction<R, State, undefined, VehicleDetailsActions>;
export type VehicleDetailsThunkDispatch = ThunkDispatch<State, undefined, VehicleDetailsActions>;
