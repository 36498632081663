import { PropsWithChildren, useState } from 'react';
import { AssetTreeContext } from './contexts';

const AssetTreeProvider = ({ children }: PropsWithChildren) => {
    const [selectedAssetIds, setSelectedAssetIds] = useState<string[]>([]);
    const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>([]);

    return (
        <AssetTreeContext.Provider
            value={{
                selectedAssetIds,
                selectedGroupIds,
                onSelectAssets: setSelectedAssetIds,
                onSelectGroups: setSelectedGroupIds,
            }}
        >
            {children}
        </AssetTreeContext.Provider>
    );
};

export default AssetTreeProvider;
