/* eslint-disable no-nested-ternary */
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { BarChartPanelProps, KpiEnum, MultiselectOptionsType } from '../types';
import SpinnerInfoBox from '@rio-cloud/rio-uikit/Spinner';
import SelectionDropdown from '../kpiCharts/KpiPanelHeader/SelectionDropdown';
import BarChart from './BarChart';
import KpiSelectionDropDown from '../kpiCharts/KpiPanelHeader/KpiSelectionDropDown';
import VehiclesSelectionDropDown from '../kpiCharts/KpiPanelHeader/VehiclesSelectionDropDown';
import moment, { Moment } from 'moment';
import {
    initKpi,
    initTimeframe,
    initVehicleIds,
    saveKpiOnStorage,
    saveTimeframeOnStorage,
    saveVehicleIdsOnStorage,
    Timeframe,
} from './BarChartPanelStorageHelper';
import KpiPanelFooter from '../kpiCharts/KpiPanelFooter/KpiPanelFooter';
import { createGoogleAnalyticsEvent, gtmUtil, TrackingCategory } from '../../utils/googleTagManager';
import {
    getProcessedSelection,
    processCorrectGAEventKpi,
    processCorrectGAEventTimeframe,
    processCorrectGAEventVehicles,
} from './helpers';
import ErrorState from '../../common/components/states/ErrorState';

const BarChartPanel = ({
    defaultEndValue,
    defaultStartValue,
    fetchingVehiclesStatistics,
    initiallySelectedKpi,
    localStoragePrefix,
    vehicles,
    vehiclesCount,
    vehiclesStatistics,
}: BarChartPanelProps): JSX.Element => {
    const intl = useIntl();
    const [showSelectionDropdown, setShowSelectionDropdown] = useState<boolean>(false);

    const [selectedVehicleIds, setSelectedVehicleIds] = useState<string[] | MultiselectOptionsType[]>([
        MultiselectOptionsType.ALL_OPTIONS,
    ]);

    const [selectedKpi, setSelectedKpi] = useState<KpiEnum>(initiallySelectedKpi);
    const [currentStartDate, setCurrentStartDate] = useState<Moment>(defaultStartValue);
    const [currentEndDate, setCurrentEndDate] = useState<Moment>(defaultEndValue);

    const linkAssetIds = selectedVehicleIds.includes(MultiselectOptionsType.ALL_OPTIONS)
        ? vehicles.map(value => value.assetId)
        : selectedVehicleIds;

    const handleSelectedTimeFrame = useCallback(
        (timeFrame: { startDate: string; endDate: string }): void => {
            setCurrentStartDate(moment(timeFrame.startDate));
            setCurrentEndDate(moment(timeFrame.endDate));
            saveTimeframeOnStorage(localStoragePrefix, timeFrame);
        },
        [localStoragePrefix]
    );

    useEffect(() => {
        initKpi(localStoragePrefix, initiallySelectedKpi, (kpi: KpiEnum) => {
            setSelectedKpi(kpi);
            saveKpiOnStorage(localStoragePrefix, kpi);
        });

        initTimeframe(
            localStoragePrefix,
            { startDate: defaultStartValue.toString(), endDate: defaultEndValue.toString() },
            (timeframe: Timeframe) => handleSelectedTimeFrame(timeframe)
        );

        initVehicleIds(localStoragePrefix, [MultiselectOptionsType.ALL_OPTIONS], (vehicleIds: string[]) =>
            setSelectedVehicleIds(vehicleIds)
        );
    }, [defaultEndValue, defaultStartValue, handleSelectedTimeFrame, initiallySelectedKpi, localStoragePrefix]);

    const hideSelectionDropdown = (): void => {
        saveVehicleIdsOnStorage(localStoragePrefix, selectedVehicleIds);
        setShowSelectionDropdown(false);
    };

    const handleSelectedVehicleIds = (response: string[]): void => {
        if (!response) {
            return;
        }
        setSelectedVehicleIds(getProcessedSelection(selectedVehicleIds, response, vehiclesCount));
        const trackingAction = processCorrectGAEventVehicles(localStoragePrefix);
        gtmUtil.pushGaEvent(createGoogleAnalyticsEvent(TrackingCategory.OVERVIEW_TAB, trackingAction));
    };

    const handleSelectedKpi = (kpi: KpiEnum): void => {
        const trackingAction = processCorrectGAEventKpi(kpi, localStoragePrefix);
        gtmUtil.pushGaEvent(createGoogleAnalyticsEvent(TrackingCategory.OVERVIEW_TAB, trackingAction));
        setSelectedKpi(kpi);
        saveKpiOnStorage(localStoragePrefix, kpi);
    };

    const changeDatesFromDropDown = (startValue: Moment, endValue: Moment): void => {
        const trackingAction = processCorrectGAEventTimeframe(localStoragePrefix);
        gtmUtil.pushGaEvent(
            createGoogleAnalyticsEvent(TrackingCategory.OVERVIEW_TAB, trackingAction, `${startValue} - ${endValue}`)
        );
        setCurrentStartDate(startValue);
        setCurrentEndDate(endValue);
        saveTimeframeOnStorage(localStoragePrefix, {
            startDate: startValue.toString(),
            endDate: endValue.toString(),
        });
    };
    return (
        <div data-testid="bar-chart-panel" className="panel panel-default padding-15 padding-right-25">
            {showSelectionDropdown && (
                <SelectionDropdown
                    currentEndDate={currentEndDate}
                    currentStartDate={currentStartDate}
                    changeDatesFromDropDown={changeDatesFromDropDown}
                    handleChange={handleSelectedVehicleIds}
                    onOutsideClick={hideSelectionDropdown}
                    selectedVehicleIds={selectedVehicleIds}
                    vehicles={vehicles}
                />
            )}
            <div className="display-block margin-bottom-25 height-30">
                <KpiSelectionDropDown handleSelectedKpi={handleSelectedKpi} selectedKpi={selectedKpi} />
                <VehiclesSelectionDropDown
                    currentEndDate={currentEndDate.format('DD/MM/YYYY')}
                    currentStartDate={currentStartDate.format('DD/MM/YYYY')}
                    setShowSelectionDropdown={setShowSelectionDropdown}
                    selectedVehicleIds={selectedVehicleIds}
                    vehiclesCount={vehiclesCount}
                />
            </div>
            {fetchingVehiclesStatistics ? (
                <SpinnerInfoBox text={intl.formatMessage({ id: 'e4c.spinner.loading' })} />
            ) : vehiclesStatistics.length === 0 ? (
                <div className={'padding-top-15 padding-bottom-15'}>
                    <ErrorState
                        stateProps={{
                            type: 'notfound',
                            message: <FormattedMessage id={'e4c.overview.notFoundState.chart.message'} />,
                        }}
                    />
                </div>
            ) : (
                <>
                    <BarChart
                        currentEndDate={currentEndDate}
                        currentStartDate={currentStartDate}
                        initiallySelectedKpi={initiallySelectedKpi}
                        selectedVehicleIds={selectedVehicleIds}
                        selectedKpi={selectedKpi}
                        vehiclesStatistics={vehiclesStatistics}
                    />
                    <KpiPanelFooter
                        selectedVehicleIds={linkAssetIds}
                        startDate={currentStartDate}
                        endDate={currentEndDate}
                        localStoragePrefix={localStoragePrefix}
                    />
                </>
            )}
        </div>
    );
};

export default BarChartPanel;
