import Select from '@rio-cloud/rio-uikit/Select';
import { FormattedMessage } from 'react-intl';
import { KpiEnum, KpiSelectionDropDownPros } from '../../types';

const kpiOptions = [
    {
        id: KpiEnum.AV_DRIVING_CONSUMPTION,
        label: <FormattedMessage id="e4c.overview.averageConsumption" />,
    },
    {
        id: KpiEnum.AV_RECUPERATION,
        label: <FormattedMessage id="e4c.overview.averageRecuperation" />,
    },
    {
        id: KpiEnum.AV_MILEAGE,
        label: <FormattedMessage id="e4c.overview.averageMileage" />,
    },
];

const KpiSelectionDropDown = (props: KpiSelectionDropDownPros): JSX.Element => {
    const { handleSelectedKpi, selectedKpi } = props;

    return (
        <div className="form-group position-absolute left-15 padding-0">
            <Select
                className={'kpiSelectionDropdown'}
                options={kpiOptions}
                btnClassName={'text-color-highlight text-bold border-none text-size-18'}
                value={[selectedKpi]}
                onChange={(kpi?: { id: KpiEnum }): void => handleSelectedKpi(kpi?.id as KpiEnum)}
            />
        </div>
    );
};

export default KpiSelectionDropDown;
