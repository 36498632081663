import { ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import UserManualPage from '../components/UserManualPage';
import UserManualSection from '../components/UserManualSection';
import detailFleetEventsPanelModalImage from '../../../../../assets/images/detail-fleet-events-panel-modal.jpg';

const FleetEventsItem = (): ReactElement => {
    const intl = useIntl();
    const formattedMessageValues = {
        b: (chunks: any) => <b>{chunks}</b>,
        ul: (chunks: any) => <ul className={'margin-left-25'}>{chunks}</ul>,
        li: (chunks: any) => <li>{chunks}</li>,
    };
    return (
        <UserManualPage
            title={<FormattedMessage id="e4c.userManual.details.fleetEvents" />}
            dataTestId="fleet-events-item"
        >
            <img
                alt={intl.formatMessage({ id: 'e4c.userManual.altText.fleetEvents' })}
                src={detailFleetEventsPanelModalImage}
                width="959"
                height="322"
            />
            <UserManualSection title={<FormattedMessage id="e4c.userManual.details.fleetEvents.sectionTitle1" />}>
                <FormattedMessage
                    id="e4c.userManual.details.fleetEvents.sectionDescription1"
                    values={formattedMessageValues}
                />
                <div className={'margin-left-10 margin-top-10'}>
                    <div>
                        <span className="event-icon rioglyph rioglyph-error-sign text-color-danger float-left" />
                        <FormattedMessage
                            id="e4c.userManual.details.fleetEvents.eventType1"
                            values={formattedMessageValues}
                        />
                    </div>
                    <div>
                        <span className="event-icon rioglyph rioglyph-warning-sign text-color-warning float-left" />
                        <FormattedMessage
                            id="e4c.userManual.details.fleetEvents.eventType2"
                            values={formattedMessageValues}
                        />
                    </div>
                    <div>
                        <span className="event-icon rioglyph rioglyph-ok-sign text-color-success float-left" />
                        <FormattedMessage
                            id="e4c.userManual.details.fleetEvents.eventType3"
                            values={formattedMessageValues}
                        />
                    </div>
                </div>
            </UserManualSection>
            <UserManualSection title={<FormattedMessage id="e4c.userManual.details.fleetEvents.sectionTitle2" />}>
                <FormattedMessage
                    id="e4c.userManual.details.fleetEvents.sectionDescription2"
                    values={formattedMessageValues}
                />
            </UserManualSection>
        </UserManualPage>
    );
};

export default FleetEventsItem;
