type BatteryTemperatureProps = {
    maxBatteryTemperatureWarningC: number | null;
    minBatteryTemperatureWarningC: number | null;
};

const BatteryTemperature = (props: BatteryTemperatureProps): JSX.Element => {
    return props.minBatteryTemperatureWarningC == null && props.maxBatteryTemperatureWarningC === null ? (
        <div data-testid="battery-temperatures">
            <span className={'rioglyph rioglyph-ok margin-right-5'} />
            <span>OK</span>
        </div>
    ) : (
        <div data-testid="battery-temperatures">
            {props.maxBatteryTemperatureWarningC && (
                <div className="ellipsis-1">
                    <span className={'rioglyph rioglyph-warning-sign text-color-warning margin-right-5'} />
                    <span className={'text-color-danger text-bold'}>{props.maxBatteryTemperatureWarningC}° C</span>
                </div>
            )}
            {props.minBatteryTemperatureWarningC && (
                <div className="ellipsis-1">
                    <span className={'rioglyph rioglyph-warning-sign text-color-warning margin-right-5'} />
                    <span className={'text-color-info text-bold'}>{props.minBatteryTemperatureWarningC}° C</span>
                </div>
            )}
        </div>
    );
};

export default BatteryTemperature;
