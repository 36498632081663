import { ChargeCycleActivity, HistoryTableColumnDetails } from '../../types';
import { Status } from '../components/columns/Status';
import { Duration } from '../components/columns/Duration';
import { DateTimeCol } from '../components/columns/DateTimeCol';
import EnergyConsumed from '../components/columns/EnergyConsumed';
import EnergyAdded from '../components/columns/EnergyAdded';
import VehicleName from '../components/columns/VehicleName';
import '../HistoryTable.css';
import { buildCollpsedItems } from '../HistoryTableHelper';
import CollapsedHeader from './CollapsedHeader';
import CollapsedHeaderRow from '../components/collapsed/CollapsedHeaderRow';
import { PaginationOptions } from '../../../../common/components/pagination/Pagination';
import ChargingProcessList from '../../../models/ChargingProcessList';
import { Consumption } from '../components/columns/Consumption';
import Temperature from '../components/columns/Temperature';
import ChargingProcess from '../../../models/ChargingProcess';
import Mileage from '../components/columns/Mileage';

export const getConsumptionColumnConfig = (): { [key: string]: HistoryTableColumnDetails } => {
    return {
        date: {
            label: 'e4c.history.date',
            sortable: true,
            width: 100,
            hasRightSeparator: true,
        },
        vehicle: {
            label: 'e4c.history.vehicle',
            sortable: false,
        },
        status: {
            label: 'e4c.history.status',
            sortable: false,
        },
        duration: {
            label: 'e4c.history.duration',
            sortable: false,
        },
        energyConsumed: {
            label: 'e4c.history.energyConsumed',
            sortable: false,
        },
        consumption: {
            label: 'e4c.history.aggregateUsage',
            sortable: false,
            tooltip: 'e4c.history.aggregateUsage.tooltip',
        },
        temperature: {
            label: 'e4c.history.outsideTemperature',
            sortable: false,
        },
        mileage: {
            label: 'e4c.history.mileage',
            sortable: false,
        },
        energyAdded: {
            label: 'e4c.history.energyAdded',
            sortable: false,
        },
        chevron: {
            label: 'e4c.history.chevron',
            sortable: false,
            width: 50,
        },
    };
};
export const renderConsumptionColumnValue = (
    column: string,
    chargingProcess: Pick<ChargeCycleActivity, any>
): JSX.Element => {
    switch (column) {
        case 'date':
            return <DateTimeCol date={chargingProcess.startDateTime} />;
        case 'vehicle':
            return <VehicleName name={chargingProcess.assetName} />;
        case 'status':
            return <Status processType={chargingProcess.type} />;
        case 'duration':
            return <Duration duration={chargingProcess.duration} />;
        case 'energyConsumed':
            return (
                <EnergyConsumed
                    processType={chargingProcess.type}
                    totalEnergyConsumptionKwh={chargingProcess.totalEnergyConsumptionKwh}
                />
            );
        case 'consumption':
            return (
                <Consumption
                    processType={chargingProcess.type}
                    powertrainEnergyConsumptionKwh={chargingProcess.powertrainEnergyConsumptionKwh}
                    powertrainEnergyConsumptionPercentage={chargingProcess.powertrainEnergyConsumptionPercentage}
                    auxEnergyConsumptionKwh={chargingProcess.auxEnergyConsumptionKwh}
                    auxEnergyConsumptionPercentage={chargingProcess.auxEnergyConsumptionPercentage}
                    amsEnergyConsumptionKwh={chargingProcess.amsEnergyConsumptionKwh}
                    amsEnergyConsumptionPercentage={chargingProcess.amsEnergyConsumptionPercentage}
                />
            );
        case 'temperature':
            return <Temperature temperatures={chargingProcess.temperatures} />;
        case 'mileage':
            return <Mileage processType={chargingProcess.type} mileage={chargingProcess.mileage} />;
        case 'energyAdded':
            return <EnergyAdded energyAdded={chargingProcess.energyAddedKwh} processType={chargingProcess.type} />;
        case 'chevron':
            return <></>;
        default:
            throw Error('Column from ColumnConfig not handled in renderColumnValue - please add it');
    }
};

export const renderRows = (
    sortedRows: ChargingProcessList,
    tableColumns: string[],
    keysOpened: string[],
    locale: string,
    tableColumnsWithRightSeparator: string[],
    renderColumnValue: (column: string, chargingProcess: ChargingProcess, locale: string) => JSX.Element,
    setKeysOpened: (key: string) => void,
    pagination: PaginationOptions
): JSX.Element[] => {
    return Object.entries(sortedRows.filterByPaginationAndGroupByStartDate(pagination)).map(
        ([date, chargingProcessList], index) => {
            const collapsedItems = buildCollpsedItems(keysOpened, date);
            return (
                <>
                    <tr className={'compactRow'} key={index}>
                        <CollapsedHeader
                            sumEnergyAdded={chargingProcessList.getSumEnergyAdded()}
                            sumEnergyConsumed={chargingProcessList.getSumEnergyConsumption()}
                            date={date}
                            isOpened={collapsedItems.collapsedType}
                            temperature={chargingProcessList.getMaximumTemperature()}
                            quantityOfVehicles={chargingProcessList.getNumberOfVehicles()}
                            setKeysOpened={setKeysOpened}
                            sumMileages={chargingProcessList.getSumMileages()}
                            sumAggregatedValues={chargingProcessList.getSumAggregatedValues()}
                        />
                    </tr>
                    {collapsedItems.rowType === 'extendedRow'
                        ? chargingProcessList.processList.map((cycle: any, idx: number) => {
                              return (
                                  <CollapsedHeaderRow
                                      collapsedItems={collapsedItems.rowType}
                                      cycle={cycle}
                                      key={idx}
                                      locale={locale}
                                      renderColumnValue={renderColumnValue}
                                      tableColumns={tableColumns}
                                      tableColumnsWithRightSeparator={tableColumnsWithRightSeparator}
                                  />
                              );
                          })
                        : null}
                </>
            );
        }
    );
};
