import { ReactElement, useState } from 'react';
import { useIntl } from 'react-intl';
import HorizontalCard from '../../../../../../common/components/HorizontalCard';
import ConfigItemActions from './ConfigItemActions';

interface ContentProps {
    title: string;
    subTitle: string;
}

export interface ConfigItemProps {
    eventName: string;
    eventDescription: string;
    eventEnabled: boolean;
    updatable: boolean;
    onChangeFunction?: Function;
    eventMessage?: string;
}

const Content = ({ title, subTitle }: ContentProps): ReactElement => {
    return (
        <div data-testid="item-menu-content" className="display-flex flex-column">
            <p className={'text-size-12 margin-0 text-medium '}>{title}</p>
            <p className={'text-size-12 text-normal margin-0'}>{subTitle}</p>
        </div>
    );
};

const ConfigItem = ({
    eventName,
    eventDescription,
    eventEnabled,
    updatable,
    eventMessage,
    onChangeFunction,
}: ConfigItemProps): ReactElement => {
    const intl = useIntl();

    const [enabled, setEnabled] = useState(eventEnabled);

    const handleChange = (): void => {
        setEnabled(!enabled);
        onChangeFunction?.();
    };

    const tooltipMessage = eventMessage ? intl.formatMessage({ id: eventMessage }) : undefined;

    const right = (
        <ConfigItemActions
            checked={enabled}
            disabled={!updatable}
            handleChange={handleChange}
            message={tooltipMessage}
        />
    );

    return (
        <HorizontalCard right={right}>
            <Content
                title={intl.formatMessage({ id: eventName })}
                subTitle={intl.formatMessage({ id: eventDescription })}
            />
        </HorizontalCard>
    );
};

export default ConfigItem;
