import { ReactElement, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import overviewVehicleChargingTableImage from '../../../../../assets/images/overview-vehicle-charging-table.png';
import {
    createGoogleAnalyticsEvent,
    gtmUtil,
    TrackingAction,
    TrackingCategory,
} from '../../../../../utils/googleTagManager';
import UserManualPage from '../components/UserManualPage';
import UserManualSection from '../components/UserManualSection';

const VehicleChargingItem = (): ReactElement => {
    const intl = useIntl();
    useEffect(() => {
        gtmUtil.pushGaEvent(createGoogleAnalyticsEvent(TrackingCategory.GENERAL, TrackingAction.USER_MANUAL_WELCOME));
    }, []);

    return (
        <>
            <UserManualPage
                title={<FormattedMessage id={'e4c.userManual.overview.vehicleCharging.title'} />}
                footer={<FormattedMessage id={'e4c.userManual.overview.etgeObs'} />}
                dataTestId="vehicle-charging-item"
            >
                <img
                    alt={intl.formatMessage({ id: 'e4c.userManual.altText.welcome' })}
                    src={overviewVehicleChargingTableImage}
                />
                <UserManualSection title={<FormattedMessage id={'e4c.userManual.overview.vehicleCharging.subTitle'} />}>
                    <FormattedMessage id={'e4c.userManual.overview.vehicleCharging.description'} />
                </UserManualSection>
            </UserManualPage>
        </>
    );
};

export default VehicleChargingItem;
