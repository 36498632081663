/* eslint-disable no-nested-ternary */
// re-think tenary if statement
import { Component } from 'react';
import './VehicleDetails.css';
import { ChargingModeEnum } from '../../../types';
import { ChargingConfigurationProperties, Timer, Weekday } from './types';
import { convertDepartureTime, departureTimes } from './vehicleDetailsHelper';
import TargetStateOfCharge from './TargetStateOfCharge/TargetStateOfCharge';
import ClimateMode from './ClimateMode/ClimateMode';
import TimerChargingMode from './TimerChargingMode/TimerChargingMode';
import ImmediatelyChargingMode from './ImmediatelyChargingMode/ImmediatelyChargingMode';
import { currentTimezone } from '../../../../utils/timezone';

export default class ChargingConfiguration extends Component<ChargingConfigurationProperties, {}> {
    static addTimer(props: ChargingConfigurationProperties): void {
        const newTimer: Timer = {
            weekdays: [],
            departure_time: convertDepartureTime('00:00', currentTimezone),
            enabled: true,
            zone_id: currentTimezone,
        };

        props.onAddTimer(newTimer);
    }

    static removeTimer(timerNo: number, props: ChargingConfigurationProperties): void {
        props.onRemoveTimer(timerNo);
    }

    onChargingModeChange(): void {
        this.props.toggleUnsavedVehicleChanges(true);
        const newChargingMode =
            this.props.chargingMode === ChargingModeEnum.IMMEDIATE
                ? ChargingModeEnum.TIMER
                : ChargingModeEnum.IMMEDIATE;
        this.props.onChargingModeChange(newChargingMode);
    }

    onClimateModeChange(): void {
        this.props.toggleUnsavedVehicleChanges(true);
        this.props.onClimateModeChange(!this.props.climateMode);
    }

    onTargetStateOfChargeChange(targetStateOfCharge: number): void {
        this.props.toggleUnsavedVehicleChanges(true);
        this.props.onTargetStateOfChargeChange(targetStateOfCharge);
    }

    isTimerModeActive(): boolean {
        return this.props.chargingMode === ChargingModeEnum.TIMER;
    }

    renderImmediatelyChargingMode(): JSX.Element {
        return (
            <ImmediatelyChargingMode
                isReadOnly={this.props.isReadOnlyUser}
                isChecked={!this.isTimerModeActive()}
                onChargingModeChange={(): void => this.onChargingModeChange()}
            />
        );
    }

    renderTimerChargingMode(): JSX.Element {
        const toggleWeekDay = (timerId: number, weekDay: Weekday): void => {
            if (this.props.timerConfiguration) {
                this.props.toggleUnsavedVehicleChanges(true);
                const wd = this.props.timerConfiguration.timers[timerId].weekdays;
                if (wd.includes(weekDay)) {
                    this.props.onRemoveDayFromTimer(weekDay, timerId);
                } else {
                    this.props.onAddDayToTimer(weekDay, timerId);
                }
            }
        };

        const addTimer = (): void => {
            this.props.toggleUnsavedVehicleChanges(true);
            ChargingConfiguration.addTimer(this.props);
        };

        return (
            <TimerChargingMode
                isActive={this.isTimerModeActive()}
                isReadOnly={this.props.isReadOnlyUser}
                timerConfiguration={this.props.timerConfiguration}
                locale={this.props.locale}
                departureTimesOptions={departureTimes}
                chargingConfigurations={this.props.chargingConfigurations}
                onChargingModeChange={(): void => this.onChargingModeChange()}
                onAddTimer={addTimer}
                onRemoveTimer={this.props.onRemoveTimer}
                onToggleWeekDay={toggleWeekDay}
                onDepartureTimeChange={this.props.onDepartureTimeChange}
                onTimerEnabledChange={this.props.onTimerEnabledChange}
                onReadyToDriveDurationChange={this.props.onReadyToDriveDurationChange}
                toggleUnsavedVehicleChanges={this.props.toggleUnsavedVehicleChanges}
            />
        );
    }

    renderBothChargingModes(): JSX.Element {
        return (
            <div>
                {this.renderImmediatelyChargingMode()}
                {this.renderTimerChargingMode()}
            </div>
        );
    }

    render(): JSX.Element {
        return (
            <div id="charging-details-tool-bar" className="padding-20">
                {this.props.isReadOnlyUser
                    ? this.isTimerModeActive()
                        ? this.renderTimerChargingMode()
                        : this.renderImmediatelyChargingMode()
                    : this.renderBothChargingModes()}
                <ClimateMode
                    isChecked={this.props.climateMode}
                    isEnabled={!this.props.isReadOnlyUser}
                    onChange={(): void => this.onClimateModeChange()}
                />
                <TargetStateOfCharge
                    isEnabled={!this.props.isReadOnlyUser}
                    value={this.props.targetStateOfCharge}
                    onChange={(value): void => this.onTargetStateOfChargeChange(value)}
                />
            </div>
        );
    }
}
