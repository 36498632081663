import { useIntl } from 'react-intl';

interface DisconnectedAtProps {
    dateAndTime: string;
}

const DisconnectedAt = (props: DisconnectedAtProps): JSX.Element => {
    const { dateAndTime } = props;
    const intl = useIntl();

    return (
        <span className={'padding-left-5 display-block ellipsis-1'} data-testid={'cs-disconnected-time'}>
            {intl.formatMessage({ id: 'e4c.vehicles.table.chargingStatus.disconnectedAt' })}
            {` ${dateAndTime}`}
        </span>
    );
};

export default DisconnectedAt;
