import SkeletonLoader from '../../../common/components/Skeleton/SkeletonLoader';

const NUMBER_OF_ROWS = 10;

const SkeletonVehicleBodyTable = (): JSX.Element => {
    return (
        <tbody data-testid={'vehicle-table-body-skeleton'}>
            {[...Array(NUMBER_OF_ROWS)].map((_, idx) => (
                <tr className="cursor-pointer" data-testid={'vehicle-table-row-skeleton'} key={`${idx}-skeleton-vc`}>
                    <td className="height-60">
                        <SkeletonLoader>
                            <rect x="0" y="12" rx="0" ry="0" width="18" height="16" />
                            <rect x="23" y="12" rx="0" ry="0" width="110" height="16" />
                            <rect x="145" y="10" rx="0" ry="0" width="18" height="18" />
                        </SkeletonLoader>
                    </td>
                    <td className="height-60">
                        <SkeletonLoader>
                            <rect x="0" y="10" rx="0" ry="0" width="20" height="20" />
                            <rect x="27" y="12" rx="0" ry="0" width="80" height="16" />
                        </SkeletonLoader>
                    </td>
                    <td className="height-60">
                        <SkeletonLoader>
                            <rect x="0" y="7" rx="0" ry="0" width="130" height="35" />
                        </SkeletonLoader>
                    </td>
                    <td className="height-60">
                        <SkeletonLoader>
                            <rect x="0" y="6" rx="0" ry="0" width="70" height="10" />
                            <rect x="0" y="20" rx="0" ry="0" width="70" height="10" />
                        </SkeletonLoader>
                    </td>
                    <td className="height-60">
                        <SkeletonLoader>
                            <rect x="0" y="5" rx="0" ry="0" width="18" height="12" />
                            <rect x="0" y="20" rx="0" ry="0" width="18" height="12" />
                            <rect x="23" y="6" rx="0" ry="0" width="70" height="10" />
                            <rect x="23" y="20" rx="0" ry="0" width="70" height="10" />
                        </SkeletonLoader>
                    </td>
                    <td className="height-60">
                        <SkeletonLoader>
                            <rect x="0" y="10" rx="0" ry="0" width="20" height="20" />
                            <rect x="27" y="12" rx="0" ry="0" width="80" height="16" />
                        </SkeletonLoader>
                    </td>
                    <td className="height-60">
                        <SkeletonLoader>
                            <rect x="0" y="6" rx="0" ry="0" width="50" height="10" />
                            <rect x="0" y="20" rx="0" ry="0" width="50" height="10" />
                        </SkeletonLoader>
                    </td>
                </tr>
            ))}
        </tbody>
    );
};

export default SkeletonVehicleBodyTable;
