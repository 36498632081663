import { useIntl } from 'react-intl';
import { useTimezone } from '../../../../common/providers/timezone';

interface TimeTableCellProps {
    startTime: Date;
    endTime: Date;
    duration: number;
    labelId: string;
    textColor: string;
}

export const TimeTableCell = ({ startTime, endTime, duration, labelId, textColor }: TimeTableCellProps) => {
    const intl = useIntl();
    const timezone = useTimezone();

    const formatTime = (time: Date) => {
        return time.toLocaleTimeString(intl.locale, {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
            timeZone: timezone,
        });
    };

    const formatDuration = (durationNumber: number) => {
        const hours = Math.floor(durationNumber / 60);
        const minutes = durationNumber % 60;
        return `${hours}h ${minutes}min`;
    };

    return (
        <td className="padding-15">
            <div className={`${textColor} text-medium`}>{intl.formatMessage({ id: labelId })}</div>
            <div className="margin-top-2 text-color-black">
                <span className="padding-right-2">
                    {formatTime(startTime)} - {formatTime(endTime)}
                </span>
                <span className="padding-right-2">|</span>
                <span className="rioglyph rioglyph-time-alt text-size-16 padding-right-2" />
                <span>{formatDuration(duration)}</span>
            </div>
        </td>
    );
};
