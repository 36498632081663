import { type TableViewTogglesViewType } from '@rio-cloud/rio-uikit/TableViewToggles';
import { AccessToken } from '../../../../configuration';
import {
    VEHICLES_LOADING_FAILED,
    VEHICLES_LOADING_STARTED,
    VEHICLES_LOADING_SUCCESSFUL,
    VEHICLES_TABLE_CHARGING_STATUS_FILTER_CHANGED,
    VEHICLES_TABLE_ROW_SELECTED,
    VEHICLES_TABLE_SEARCH_VALUE_CHANGED,
    VEHICLES_TABLE_SHOW_SETTINGS_TOGGLED,
    VEHICLES_TABLE_SORT_CHANGED,
    VEHICLES_TABLE_VIEW_TYPE_CHANGED,
    VehiclesTableChargingStatusFilterChangedAction,
    VehiclesTableRowSelectedAction,
    VehiclesTableSearchValueChangedAction,
    VehiclesTableShowSettingsToggledAction,
    VehiclesTableSortChanged,
    VehiclesTableViewTypeChangedAction,
    VehiclesThunkDispatch,
    VehicleThunkAction,
} from './types';
import { config } from '../../../../config';
import { SortDirection, Vehicle } from '../../vehicleCharging/types';
import { mapAssetList } from './AssetListMapper';
import AbstractElectricVehiclesSorter from '../../vehicleCharging/sorters/AbstractElectricVehiclesSorter';
import ChargingStatus from '../../vehicleCharging/models/enums/ChargingStatus';
import { createGoogleAnalyticsEvent, gtmUtil, TrackingAction, TrackingCategory } from '../../utils/googleTagManager';
import { ErrorCodeEnum } from '../../common/enums/ErrorCode';

export const fetchElectricVehicles = (accessToken: AccessToken): VehicleThunkAction<void> => {
    return async (dispatch: VehiclesThunkDispatch): Promise<void> => {
        dispatch({
            type: VEHICLES_LOADING_STARTED,
        });
        const vehiclesEndpoint = `${config.backend.OTA_CHARGING_STATE_SERVICE}/assets`;
        const response = await fetch(vehiclesEndpoint, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (response.status === 200) {
            const json = await response.json();
            const vehicles: Vehicle[] = mapAssetList(json.items);
            dispatch({
                type: VEHICLES_LOADING_SUCCESSFUL,
                payload: vehicles,
            });
        } else if (response.status === 403) {
            dispatch({
                type: VEHICLES_LOADING_FAILED,
                payload: ErrorCodeEnum.FORBIDDEN,
            });
        } else {
            dispatch({
                type: VEHICLES_LOADING_FAILED,
                payload: ErrorCodeEnum.OTHER,
            });
        }
    };
};

export const vehiclesTableViewTypeChanged = (
    viewType: TableViewTogglesViewType
): VehiclesTableViewTypeChangedAction => {
    return {
        type: VEHICLES_TABLE_VIEW_TYPE_CHANGED,
        payload: viewType,
    };
};

export const vehiclesTableRowSelected = (vehicleId: string | null): VehiclesTableRowSelectedAction => {
    if (vehicleId) {
        gtmUtil.pushGaEvent(
            createGoogleAnalyticsEvent(TrackingCategory.VEHICLES_TAB, TrackingAction.OPEN_VEHICLE_DETAILS)
        );
    }
    return {
        type: VEHICLES_TABLE_ROW_SELECTED,
        payload: vehicleId,
    };
};

export const vehiclesTableSortChanged = (
    sorter: AbstractElectricVehiclesSorter,
    sortDir: SortDirection
): VehiclesTableSortChanged => {
    return {
        type: VEHICLES_TABLE_SORT_CHANGED,
        sortDir,
        sorter,
    };
};

export const vehiclesTableSearchValueChanged = (searchValue: string): VehiclesTableSearchValueChangedAction => {
    return {
        type: VEHICLES_TABLE_SEARCH_VALUE_CHANGED,
        payload: searchValue,
    };
};

export const vehiclesTableShowSettingsToggled = (): VehiclesTableShowSettingsToggledAction => {
    return {
        type: VEHICLES_TABLE_SHOW_SETTINGS_TOGGLED,
    };
};

export const chargingStatusFilterChanged = (
    chargingStatusValues: (ChargingStatus | undefined)[]
): VehiclesTableChargingStatusFilterChangedAction => {
    return {
        type: VEHICLES_TABLE_CHARGING_STATUS_FILTER_CHANGED,
        payload: chargingStatusValues,
    };
};
