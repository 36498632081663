import { ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ReleaseNotes from '@rio-cloud/rio-uikit/ReleaseNotes';
import UserManualPage from '../components/UserManualPage';
import { whatsNewData } from './whatsNewData';
import { WhatsNewContent, WhatsNewData } from './types';
import { FeatureTogglesT, useFeatureToggle } from '../../../../hooks/useFeatureToggle';
import { FeatureToggles } from '../../../../../../../configuration/featureToggle/featureToggles';

const WhatsNewItem = (): ReactElement => {
    const intl = useIntl();
    const formattedMessageValues = {
        b: (chunks: any) => <b>{chunks}</b>,
        u: (chunks: any) => <u>{chunks}</u>,
        ul: (chunks: any) => <ul className="margin-left-25">{chunks}</ul>,
        ol: (chunks: any) => <ol className="margin-left-25">{chunks}</ol>,
        li: (chunks: any) => <li>{chunks}</li>,
    };

    const buildContent = (content: WhatsNewContent) => {
        return (
            <div key={content.title.value}>
                <div className={content.title.className ?? 'margin-bottom-10 margin-top-0 text-size-h2'}>
                    <FormattedMessage id={content.title.value} />
                </div>
                <div className={content.description.className}>
                    <FormattedMessage id={content.description.value} values={formattedMessageValues} />
                </div>
                {content.footer && (
                    <div className={content.footer.className ?? 'margin-top-20 text-size-12'}>
                        <FormattedMessage id={content.footer.value} />
                    </div>
                )}
                {content.image && (
                    <img
                        className={
                            content.image.className ??
                            'margin-top-20 width-100pct border-style-solid border-width-1 border-color-lighter margin-bottom-10'
                        }
                        src={content.image.value}
                    />
                )}
            </div>
        );
    };

    const { value: isNewHistoryEnabled } = useFeatureToggle(FeatureToggles.NEW_HISTORY);
    const { value: isRedesignDownloadEnabled } = useFeatureToggle(FeatureToggles.REDESIGN_DOWNLOAD);
    const disabledWhatsNewItems = [
        ...(isNewHistoryEnabled ? [] : [FeatureToggles.NEW_HISTORY]),
        ...(isRedesignDownloadEnabled ? [] : [FeatureToggles.REDESIGN_DOWNLOAD]),
    ] as FeatureTogglesT[];
    const whatsNewDataFiltered = whatsNewData.filter(
        ({ toggle }) => !toggle || !disabledWhatsNewItems.includes(toggle)
    );

    const releaseNotesData = whatsNewDataFiltered.reduce((releaseNotes, item): WhatsNewData => {
        return {
            ...releaseNotes,
            [intl.formatMessage({ id: item.version })]: {
                date: item.date,
                content: buildContent(item.content),
            },
        };
    }, {});

    return (
        <UserManualPage title={<FormattedMessage id={'e4c.userManual.whatsNew'} />} dataTestId="whats-new-item">
            <ReleaseNotes releaseNotes={releaseNotesData} data-testid="release-notes" />
        </UserManualPage>
    );
};

export default WhatsNewItem;
