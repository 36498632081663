import { VehicleDetailsState } from './types';
import {
    CLEAN_STATE,
    CLIMATE_MODE_CHANGED,
    CONFIGURATION_CHANGE_CONFIRMED,
    DAY_FROM_TIMER_REMOVED,
    DAY_TO_TIMER_ADDED,
    IMMEDIATE_CONFIGURATION_SENDING_FAILED,
    IMMEDIATE_CONFIGURATION_SENDING_STARTED,
    IMMEDIATE_CONFIGURATION_SENDING_SUCCESSFUL,
    READY_TO_DRIVE_DURATION_CHANGED,
    SHOW_CONFIGURATION_CHANGE_CONFIRM_DIALOG,
    SHOW_SWITCH_TO_IMMEDIATE_CHARGING_DIALOG,
    SWITCH_TO_IMMEDIATE_CHARGING_CONFIRMED,
    TARGET_STATE_OF_CHARGE_CHANGED,
    TIMER_CONFIGURATION_SENDING_FAILED,
    TIMER_CONFIGURATION_SENDING_STARTED,
    TIMER_CONFIGURATION_SENDING_SUCCESSFUL,
    TIMER_DEPARTURE_TIME_CHANGED,
    TIMER_ENABLED_CHANGED,
    TOGGLE_SENDING_CHARGING_CONFIG_SUCCEEDED,
    VEHICLE_CHARGING_MODE_CHANGED,
    VEHICLE_DETAILS_LOADING_FAILED,
    VEHICLE_DETAILS_LOADING_STARTED,
    VEHICLE_DETAILS_LOADING_SUCCESSFUL,
    VEHICLE_TIMER_CONFIGURATION_TIMER_ADDED,
    VEHICLE_TIMER_CONFIGURATION_TIMER_REMOVED,
    VehicleDetailsActions,
} from '../../actions/details/types';
import { Timer, TimerConfiguration, Weekday } from '../../components/details/vehicleDetails/types';
import { DEFAULT_READY_TO_DRIVE_DURATION_VALUE } from '../../components/details/vehicleDetails/ReadyToDriveDuration';
import { currentTimezone } from '../../../utils/timezone';

const initialState: VehicleDetailsState = {
    timerConfiguration: undefined,
    fetchingDetails: false,
    selectedVehicle: null,
    sendingChargingConfig: false,
    sendingChargingConfigSucceeded: false,
    sendingChargingConfigFailed: false,
    showConfigurationChangeConfirmDialog: false,
    isConfigurationChangeDialogConfirmed: false,
    selectedVehicleBeforeChanges: null,
    showUnsavedChangesConfirmDialog: false,
    showSwitchToImmediateChargingDialog: false,
    isSwitchToImmediateChargingConfirmed: false,
    timerConfigurationBeforeChanges: undefined,
    userAction: null,
    chargingConfigurations: [],
    errorCode: null,
};

const reducer = (state: VehicleDetailsState = initialState, action: VehicleDetailsActions): VehicleDetailsState => {
    let timerConfiguration: TimerConfiguration | undefined;
    let updatedTimerNo = 0;
    switch (action.type) {
        case VEHICLE_DETAILS_LOADING_STARTED:
            return {
                ...state,
                fetchingDetails: true,
                timerConfiguration: undefined,
                timerConfigurationBeforeChanges: undefined,
                selectedVehicle: null,
                selectedVehicleBeforeChanges: null,
                sendingChargingConfigFailed: false,
                showUnsavedChangesConfirmDialog: false,
                userAction: null,
                chargingConfigurations: [],
                errorCode: null,
            };
        case VEHICLE_DETAILS_LOADING_SUCCESSFUL:
            return {
                ...state,
                fetchingDetails: false,
                timerConfiguration: action.payload.timerConfiguration,
                timerConfigurationBeforeChanges: action.payload.timerConfigurationBeforeChanges,
                selectedVehicle: action.payload.vehicle,
                selectedVehicleBeforeChanges: action.payload.selectedVehicleBeforeChanges,
                sendingChargingConfigFailed: false,
                userAction: action.payload.userAction,
                chargingConfigurations: action.payload.chargingConfigurations,
                errorCode: null,
            };
        case VEHICLE_DETAILS_LOADING_FAILED:
            return {
                ...state,
                fetchingDetails: false,
                timerConfiguration: undefined,
                sendingChargingConfigFailed: false,
                chargingConfigurations: [],
                errorCode: action.payload,
            };
        case VEHICLE_CHARGING_MODE_CHANGED:
            if (state.selectedVehicle) {
                return {
                    ...state,
                    fetchingDetails: false,
                    selectedVehicle: {
                        ...state.selectedVehicle,
                        chargingConfiguration: {
                            ...state.selectedVehicle.chargingConfiguration,
                            chargingMode: action.payload,
                        },
                    },
                    sendingChargingConfigFailed: false,
                };
            } else {
                return {
                    ...state,
                };
            }
        case VEHICLE_TIMER_CONFIGURATION_TIMER_ADDED: {
            // TODO: set climate mode accordingly
            const newTimerConfiguration: TimerConfiguration = {
                climate_mode: true,
                target_state_of_charge: 100,
                // use the zone_id of the current TimerConfiguration and, if not available, the client's time zone
                zone_id: (timerConfiguration && timerConfiguration.zone_id) || currentTimezone,
                timers: [],
                ready_to_drive_duration:
                    timerConfiguration?.ready_to_drive_duration ?? DEFAULT_READY_TO_DRIVE_DURATION_VALUE,
            };
            if (state.timerConfiguration) {
                timerConfiguration = { ...state.timerConfiguration };
            } else {
                timerConfiguration = newTimerConfiguration;
            }
            const oldTimersArray = [...timerConfiguration.timers];
            const timersArray: Timer[] = [...oldTimersArray, action.payload];
            const currTimerConfiguration = { ...timerConfiguration };
            currTimerConfiguration.timers = timersArray;
            timerConfiguration = currTimerConfiguration;
            return {
                ...state,
                timerConfiguration,
                sendingChargingConfigFailed: false,
            };
        }
        case VEHICLE_TIMER_CONFIGURATION_TIMER_REMOVED: {
            if (state.timerConfiguration) {
                timerConfiguration = { ...state.timerConfiguration };
                const timers = [...timerConfiguration.timers];
                const currTimerConfig = { ...timerConfiguration };
                timers.splice(action.payload, 1);
                currTimerConfig.timers = timers;
                timerConfiguration = currTimerConfig;
                return {
                    ...state,
                    timerConfiguration,
                };
            } else {
                return state;
            }
        }
        case IMMEDIATE_CONFIGURATION_SENDING_STARTED:
            return {
                ...state,
                sendingChargingConfig: true,
                sendingChargingConfigFailed: false,
            };
        case IMMEDIATE_CONFIGURATION_SENDING_SUCCESSFUL:
            return {
                ...state,
                sendingChargingConfig: false,
                sendingChargingConfigSucceeded: true,
                sendingChargingConfigFailed: false,
                selectedVehicleBeforeChanges: state.selectedVehicle,
            };
        case IMMEDIATE_CONFIGURATION_SENDING_FAILED:
            return {
                ...state,
                sendingChargingConfig: false,
                sendingChargingConfigFailed: true,
            };
        case TIMER_CONFIGURATION_SENDING_STARTED:
            return {
                ...state,
                sendingChargingConfig: true,
                sendingChargingConfigFailed: false,
            };
        case TIMER_CONFIGURATION_SENDING_SUCCESSFUL:
            return {
                ...state,
                sendingChargingConfig: false,
                sendingChargingConfigSucceeded: true,
                sendingChargingConfigFailed: false,
                selectedVehicleBeforeChanges: state.selectedVehicle,
                timerConfigurationBeforeChanges: state.timerConfiguration,
            };
        case TIMER_CONFIGURATION_SENDING_FAILED:
            return {
                ...state,
                sendingChargingConfig: false,
                sendingChargingConfigFailed: true,
            };
        case DAY_TO_TIMER_ADDED:
            updatedTimerNo = action.payload.timerNo;
            if (state.timerConfiguration) {
                timerConfiguration = { ...state.timerConfiguration };
                const timers = [...timerConfiguration.timers];
                const updatedTimer: Timer = { ...timers[updatedTimerNo] };
                const weekdaysArray: Weekday[] = [...updatedTimer.weekdays, action.payload.day];
                updatedTimer.weekdays = weekdaysArray;
                timers[updatedTimerNo] = updatedTimer;
                const currentTimerConfig = { ...timerConfiguration };
                currentTimerConfig.timers = timers;
                timerConfiguration = currentTimerConfig;
                return {
                    ...state,
                    timerConfiguration,
                    sendingChargingConfigFailed: false,
                };
            } else {
                return state;
            }

        case DAY_FROM_TIMER_REMOVED:
            updatedTimerNo = action.payload.timerNo;
            if (state.timerConfiguration) {
                timerConfiguration = { ...state.timerConfiguration };
                const timersArr = [...timerConfiguration.timers];
                const updTimer: Timer = { ...timersArr[updatedTimerNo] };
                const index = updTimer.weekdays.indexOf(action.payload.day);
                const weekdaysArr: Weekday[] = [...updTimer.weekdays];
                if (index !== -1) {
                    weekdaysArr.splice(index, 1);
                }
                updTimer.weekdays = weekdaysArr;
                timersArr[updatedTimerNo] = updTimer;
                const currentTimerConfiguration = { ...timerConfiguration };
                currentTimerConfiguration.timers = timersArr;
                timerConfiguration = currentTimerConfiguration;
                return {
                    ...state,
                    timerConfiguration,
                    sendingChargingConfigFailed: false,
                };
            } else {
                return state;
            }
        case TIMER_DEPARTURE_TIME_CHANGED:
            updatedTimerNo = action.payload.timerNo;
            if (state.timerConfiguration) {
                timerConfiguration = { ...state.timerConfiguration };
                const tArray = [...timerConfiguration.timers];
                const uTimer: Timer = { ...tArray[updatedTimerNo] };
                uTimer.departure_time = action.payload.departureTime;
                tArray[updatedTimerNo] = uTimer;
                const currTimerConfig = { ...timerConfiguration };
                currTimerConfig.timers = tArray;
                timerConfiguration = currTimerConfig;
                return {
                    ...state,
                    timerConfiguration,
                    sendingChargingConfigFailed: false,
                };
            } else {
                return state;
            }
        case TIMER_ENABLED_CHANGED: {
            updatedTimerNo = action.payload;
            if (state.timerConfiguration) {
                timerConfiguration = { ...state.timerConfiguration };
                const timersArr = [...timerConfiguration.timers];
                const updTimer: Timer = { ...timersArr[updatedTimerNo] };
                updTimer.enabled = !timerConfiguration.timers[updatedTimerNo].enabled;
                timersArr[updatedTimerNo] = updTimer;
                const currentTimerConfiguration = { ...timerConfiguration };
                currentTimerConfiguration.timers = timersArr;
                timerConfiguration = currentTimerConfiguration;
                return {
                    ...state,
                    timerConfiguration,
                    sendingChargingConfigFailed: false,
                };
            } else {
                return state;
            }
        }
        case SHOW_CONFIGURATION_CHANGE_CONFIRM_DIALOG:
            return {
                ...state,
                showConfigurationChangeConfirmDialog: action.payload,
                isConfigurationChangeDialogConfirmed: false,
            };
        case CONFIGURATION_CHANGE_CONFIRMED:
            return {
                ...state,
                isConfigurationChangeDialogConfirmed: true,
                showConfigurationChangeConfirmDialog: false,
            };
        case SHOW_SWITCH_TO_IMMEDIATE_CHARGING_DIALOG:
            return {
                ...state,
                showSwitchToImmediateChargingDialog: action.payload,
            };
        case SWITCH_TO_IMMEDIATE_CHARGING_CONFIRMED:
            return {
                ...state,
                isSwitchToImmediateChargingConfirmed: action.payload,
                showSwitchToImmediateChargingDialog: false,
            };
        case CLIMATE_MODE_CHANGED:
            if (state.selectedVehicle) {
                if (state.timerConfiguration) {
                    return {
                        ...state,
                        selectedVehicle: {
                            ...state.selectedVehicle,
                            chargingConfiguration: {
                                ...state.selectedVehicle.chargingConfiguration,
                                climateMode: action.payload,
                            },
                        },
                        timerConfiguration: {
                            ...state.timerConfiguration,
                            climate_mode: action.payload,
                        },
                    };
                } else {
                    return {
                        ...state,
                        selectedVehicle: {
                            ...state.selectedVehicle,
                            chargingConfiguration: {
                                ...state.selectedVehicle.chargingConfiguration,
                                climateMode: action.payload,
                            },
                        },
                    };
                }
            } else {
                return state;
            }
        case TARGET_STATE_OF_CHARGE_CHANGED:
            if (state.selectedVehicle) {
                if (state.timerConfiguration) {
                    return {
                        ...state,
                        selectedVehicle: {
                            ...state.selectedVehicle,
                            chargingConfiguration: {
                                ...state.selectedVehicle.chargingConfiguration,
                                targetStateOfCharge: action.payload,
                            },
                        },
                        timerConfiguration: {
                            ...state.timerConfiguration,
                            target_state_of_charge: action.payload,
                        },
                    };
                } else {
                    return {
                        ...state,
                        selectedVehicle: {
                            ...state.selectedVehicle,
                            chargingConfiguration: {
                                ...state.selectedVehicle.chargingConfiguration,
                                targetStateOfCharge: action.payload,
                            },
                        },
                    };
                }
            } else {
                return state;
            }
        case TOGGLE_SENDING_CHARGING_CONFIG_SUCCEEDED:
            return {
                ...state,
                sendingChargingConfigSucceeded: action.payload,
            };
        case READY_TO_DRIVE_DURATION_CHANGED:
            if (state.timerConfiguration) {
                return {
                    ...state,
                    timerConfiguration: {
                        ...state.timerConfiguration,
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        ready_to_drive_duration: action.payload,
                    },
                };
            } else {
                return state;
            }
        case CLEAN_STATE:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
