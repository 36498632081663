import { ReactElement, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import detailStateOfChargePanelModalImage from '../../../../../assets/images/detail-state-of-charge-panel-modal.jpg';
import {
    createGoogleAnalyticsEvent,
    gtmUtil,
    TrackingAction,
    TrackingCategory,
} from '../../../../../utils/googleTagManager';
import UserManualPage from '../components/UserManualPage';

const StateOfChargeItem = (): ReactElement => {
    const intl = useIntl();
    useEffect(() => {
        gtmUtil.pushGaEvent(
            createGoogleAnalyticsEvent(TrackingCategory.GENERAL, TrackingAction.USER_MANUAL_STATE_CHARGE)
        );
    }, []);

    return (
        <UserManualPage
            title={<FormattedMessage id={'e4c.userManual.details.stateOfCharge.title'} />}
            dataTestId="state-charge-item"
        >
            <span className="margin-bottom-20">
                <FormattedMessage id={'e4c.userManual.details.stateOfCharge.subTitle'} />
            </span>
            <div className="margin-bottom-20">
                <img
                    alt={intl.formatMessage({ id: 'e4c.userManual.altText.chargeAndRemainingCapacity' })}
                    src={detailStateOfChargePanelModalImage}
                    width="605"
                    height="452"
                />
            </div>
            <div className="counter color-onboarding size-small filled">
                <div>
                    <p className="padding-top-5 margin-botton-0 margin-top-0">
                        <span className="text-bold">
                            <FormattedMessage id={'e4c.userManual.details.stateOfCharge.charge'} />
                        </span>
                        <FormattedMessage id={'e4c.userManual.details.stateOfCharge.chargeDescription'} />
                    </p>
                </div>
                <div>
                    <p className="margin-botton-0 margin-top-0">
                        <span className="text-bold">
                            <FormattedMessage id={'e4c.userManual.details.stateOfCharge.remainingCapacity'} />
                        </span>{' '}
                        <FormattedMessage id={'e4c.userManual.details.stateOfCharge.remainingCapacityDescription'} />
                    </p>
                </div>
            </div>
        </UserManualPage>
    );
};

export default StateOfChargeItem;
