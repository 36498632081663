import { MainPropertiesFromDispatch, MainPropertiesFromState } from './types';
import { ThunkDispatch } from 'redux-thunk';
import { AccessToken, getAccessToken } from '../../../../configuration';
import { State } from '../../../../types';
import { withRouter } from 'react-router';
import Main from '../../components/main/Main';
import { connect } from 'react-redux';
import {
    ElectricVehiclesThunkAction,
    fetchElectricVehiclesFromMonitoringService,
    fetchElectricVehiclesFromStateService,
} from '../../vehicleCharging/actions';
import Api from '../../../../api/types';

const mapDispatchToProps = (dispatch: ThunkDispatch<any, undefined, any>): MainPropertiesFromDispatch => {
    return {
        fetchElectricVehiclesFromMonitoringService: (
            accessToken: AccessToken
        ): ElectricVehiclesThunkAction<void, Api> => dispatch(fetchElectricVehiclesFromMonitoringService(accessToken)),
        fetchElectricVehiclesFromStateService: (accessToken: AccessToken): ElectricVehiclesThunkAction<void, Api> =>
            dispatch(fetchElectricVehiclesFromStateService(accessToken)),
    };
};

const mapStateToProps = (state: State): MainPropertiesFromState => {
    return {
        accessToken: getAccessToken(state),
    };
};

const MainContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));

export default MainContainer;
