import { SeverityEventLevel } from '../../../../models/SeverityEventLevelEnum';
import { EventStatus } from '../../../../models/EventStatusEnum';

const getSeverityIcon = (severityLevel: SeverityEventLevel): string => {
    switch (severityLevel) {
        case SeverityEventLevel.CRITICAL:
            return 'rioglyph-error-sign text-color-danger';
        case SeverityEventLevel.WARNING:
            return 'rioglyph-warning-sign text-color-warning';
        case SeverityEventLevel.SUCCESS:
            return 'rioglyph-ok-sign text-color-success';
        case SeverityEventLevel.INFO:
            return 'rioglyph-info-sign text-color-info';
    }
};

const getColorByStatus = (status?: EventStatus) => (status === EventStatus.SEEN ? 'text-color-gray' : '');

export interface SeverityIconProps {
    severityLevel: SeverityEventLevel;
    status?: EventStatus;
}

const SeverityIcon: React.FC<SeverityIconProps> = ({ severityLevel, status }: SeverityIconProps) => (
    <span
        className={`event-icon rioglyph ${getSeverityIcon(severityLevel)} ${
            status ? getColorByStatus(status) : ''
        } float-left`}
    />
);

export default SeverityIcon;
