import { KpiEnum, VehicleStatistics } from '../../types';
import StatisticsBuilder from './StatisticsBuilder';
import AverageMileageStatisticsBuilder from './AverageMileageStatisticsBuilder';
import AverageDrivingConsumptionStatisticsBuilder from './AverageDrivingConsumptionStatisticsBuilder';
import AverageRecuperationStatisticsBuilder from './AverageRecuperationStatisticsBuilder';

class StatisticsBuilderFactory {
    private readonly _endDate: Date;
    private readonly _startDate: Date;
    private readonly _vehicleStatistics: VehicleStatistics[];

    constructor(endDate: Date, startDate: Date, vehicleStatistics: VehicleStatistics[]) {
        this._endDate = endDate;
        this._startDate = startDate;
        this._vehicleStatistics = vehicleStatistics;
    }

    getBuilder(kpi: KpiEnum): StatisticsBuilder {
        switch (kpi) {
            case KpiEnum.AV_DRIVING_CONSUMPTION:
                return new AverageDrivingConsumptionStatisticsBuilder(
                    this._endDate,
                    this._startDate,
                    this._vehicleStatistics
                );
            case KpiEnum.AV_MILEAGE:
                return new AverageMileageStatisticsBuilder(this._endDate, this._startDate, this._vehicleStatistics);
            case KpiEnum.AV_RECUPERATION:
                return new AverageRecuperationStatisticsBuilder(
                    this._endDate,
                    this._startDate,
                    this._vehicleStatistics
                );
        }
    }
}

export default StatisticsBuilderFactory;
