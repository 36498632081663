import ChevronDownIcon from '../components/collapsed/ChevronDownIcon';
import CollapsedEnergyAdded from '../components/collapsed/CollapsedEnergyAdded';
import CollapsedEnergyConsumed from '../components/collapsed/CollapsedEnergyConsumed';
import CollapsedVehicleQuantity from '../components/collapsed/CollapsedVehicleQuantity';
import CollapsedDateCol from '../components/collapsed/CollapsedDateCol';
import { CollapsedHeaderProps } from './types';
import CollapsedTemperature from '../components/collapsed/CollapsedTemperature';
import CollapsedConsumption from '../components/collapsed/CollapsedConsumption';
import CollapsedMileage from '../components/collapsed/CollapsedMileage';

const CollapsedHeader = (props: CollapsedHeaderProps): JSX.Element => {
    const {
        sumEnergyAdded,
        sumEnergyConsumed,
        date,
        isOpened,
        quantityOfVehicles,
        temperature,
        setKeysOpened,
        sumMileages,
        sumAggregatedValues,
    } = props;
    return (
        <>
            <td>
                <CollapsedDateCol date={date} />
            </td>
            <td>
                <CollapsedVehicleQuantity quantityOfVehicles={quantityOfVehicles} />
            </td>
            <td> </td>
            <td> </td>
            <td>
                <CollapsedEnergyConsumed sumEnergyConsumed={sumEnergyConsumed} />
            </td>
            <td>
                <CollapsedConsumption sumAggregatedValues={sumAggregatedValues} />
            </td>
            <td>
                <CollapsedTemperature temperature={temperature} />
            </td>
            <td>
                <CollapsedMileage sumMileages={sumMileages} />
            </td>
            <td>
                <CollapsedEnergyAdded sumEnergyAdded={sumEnergyAdded} />
            </td>
            <td>
                <ChevronDownIcon collapsedType={isOpened} setKeysOpened={setKeysOpened} date={date} />
            </td>
        </>
    );
};

export default CollapsedHeader;
