import { useRef } from 'react';
import { useIntl } from 'react-intl';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import TooltipRef from '../../../common/components/Tooltip/Tooltip';

interface VehiclesTableSettingsButtonProps {
    onClick: () => void;
}

const VehiclesTableSettingsButton = (props: VehiclesTableSettingsButtonProps): JSX.Element => {
    const intl = useIntl();
    const ref = useRef<HTMLDivElement>(null);
    return (
        <div className="table-toolbar-column" data-testid={'vt-settings'}>
            <TooltipRef
                ref={ref}
                dataTestId="tooltip-service-info"
                tooltipContent={intl.formatMessage({ id: 'e4c.vehicles.table.table.settings' })}
                placement={Tooltip.BOTTOM}
            >
                <button
                    className="btn btn-default btn-icon-only"
                    data-testid="button-close-settings"
                    onClick={props.onClick}
                >
                    <span className="rioglyph rioglyph-cog" />
                </button>
            </TooltipRef>
        </div>
    );
};

export default VehiclesTableSettingsButton;
