/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { isEmpty, isEqual, size } from 'lodash';
import Tree, { SelectionChangeResponse } from '@rio-cloud/rio-uikit/Tree';
import TreeOption from '@rio-cloud/rio-uikit/TreeOption';
import AssetTree from '@rio-cloud/rio-uikit/AssetTree';
import TreeCategory from '@rio-cloud/rio-uikit/TreeCategory';
import { useGetAssets, useGetGroups } from '../../queries/useAssetTree';
import { State } from '../../../../../types';
import { getAccessToken } from '../../../../../configuration';
import {
    filterAssetGroupIds,
    filterAssetsByExistentData,
    filterSelectedAssetsFromSelectedGroups,
    findAssetsFromSelectedGroups,
} from './utils';
import { AssetTreeContext } from '../../../../../providers/contexts';
import { createGoogleAnalyticsEvent, gtmUtil, TrackingAction, TrackingCategory } from '../../../utils/googleTagManager';
import { useVehicleChargingAPI } from '../../../vehicleCharging/queries/useVehicleChargingAPI';

const CATEGORY_ASSETS = 'assets';
const SELECTED_GROUPS_QUERY = 'groupIds';
const SELECTED_ASSETS_QUERY = 'assetIds';

const AssetTreeSidebar = () => {
    const intl = useIntl();
    const history = useHistory();
    const accessToken = useSelector((state: State) => getAccessToken(state));
    const { assets: unfilteredAssets, isError: assetsError } = useGetAssets(accessToken);
    const { groups, isError: groupsError } = useGetGroups(accessToken);
    const { vehicleChargingData, isError: vehicleChargingError } = useVehicleChargingAPI(false, accessToken);

    const { selectedAssetIds, selectedGroupIds, onSelectAssets, onSelectGroups } = useContext(AssetTreeContext);

    const [isTreeOpen, setIsTreeOpen] = useState(true);
    const [expandedAssetGroups, setExpandedAssetGroups] = useState<string[]>([]);
    const [showEmptyGroups, setShowEmptyGroups] = useState(
        JSON.parse(localStorage.getItem('showEmptyGroups') || 'false')
    );
    const [showAssetGroups, setShowAssetGroups] = useState(
        JSON.parse(localStorage.getItem('showAssetGroups') || 'true')
    );

    const assets = filterAssetsByExistentData(unfilteredAssets, vehicleChargingData);

    // Updates the selected groups and assets based on the current URL query parameters
    useEffect(() => {
        const query = getQuery();
        const groupIds = query.get(SELECTED_GROUPS_QUERY)?.split(',') || [];
        const assetIds = query.get(SELECTED_ASSETS_QUERY)?.split(',') || [];

        if (groupIds.length > 0) {
            onSelectGroups(groupIds);
            const filteredAssetsIds = assets
                .filter(asset => filterAssetGroupIds(asset.groupIds, groupIds))
                .map(asset => asset.id);
            onSelectAssets(filteredAssetsIds);
        }

        if (assetIds.length > 0) {
            onSelectAssets(assetIds);
        }
    }, [history]);

    // Updates the selected assets to include all assets associated with the currently selected groups
    useEffect(() => {
        if (selectedGroupIds.length > 0 && assets.length > 0) {
            const assetIdsFromGroups = [
                ...selectedAssetIds,
                ...findAssetsFromSelectedGroups(selectedGroupIds, assets),
            ].filter((id, index, self) => self.indexOf(id) === index);
            if (!isEqual(assetIdsFromGroups, selectedAssetIds)) {
                onSelectAssets(assetIdsFromGroups);
            }
        }
    }, [selectedGroupIds, assets]);

    // Updates the URL query parameters whenever an asset or a group is selected
    useEffect(() => {
        const query = getQuery();

        // Manage assetIds
        const filteredAssetIds = filterSelectedAssetsFromSelectedGroups(selectedAssetIds, selectedGroupIds, assets);
        query.delete(SELECTED_ASSETS_QUERY);
        if (filteredAssetIds && filteredAssetIds.length > 0) {
            query.append(SELECTED_ASSETS_QUERY, filteredAssetIds.join(','));
        }

        // Manage groupIds
        query.delete(SELECTED_GROUPS_QUERY);
        if (selectedGroupIds && selectedGroupIds.length > 0) {
            query.append(SELECTED_GROUPS_QUERY, selectedGroupIds.join(','));
        }

        const previousQuery = getQuery().toString();
        const newQuery = query.toString();

        // Only push if the query has changed
        if (previousQuery !== newQuery) {
            history.push({ search: newQuery });
        }
    }, [selectedAssetIds, selectedGroupIds, assets]);

    const handleToggle = (stateSetter: Function, localStorageKey: string) => {
        stateSetter((prevState: boolean) => {
            const newValue = !prevState;
            localStorage.setItem(localStorageKey, JSON.stringify(newValue));
            gtmUtil.pushGaEvent(
                createGoogleAnalyticsEvent(
                    TrackingCategory.GENERAL,
                    TrackingAction.ASSET_TREE_OPTION_CHANGE,
                    `${localStorageKey}: ${newValue}`
                )
            );
            return newValue;
        });
    };

    const getQuery = (): URLSearchParams => new URLSearchParams(history.location.search);

    const handleSelectAssets = ({
        items: updatedSelectedAssetIds,
        groups: updatedSelectedGroupIds,
    }: SelectionChangeResponse): void => {
        onSelectGroups(
            size(updatedSelectedAssetIds) === 1 && isEmpty(updatedSelectedGroupIds) ? [] : updatedSelectedGroupIds
        );
        onSelectAssets(updatedSelectedAssetIds);
    };

    const handleToggleEmptyGroups = () => handleToggle(setShowEmptyGroups, 'showEmptyGroups');
    const handleToggleAssetGroups = () => handleToggle(setShowAssetGroups, 'showAssetGroups');

    if (assetsError || groupsError || vehicleChargingError) {
        return null;
    }

    return (
        <AssetTree
            resizable
            minWidth={350}
            maxWidth={470}
            currentCategoryId={CATEGORY_ASSETS}
            isOpen={isTreeOpen}
            onToggleTree={() => setIsTreeOpen(!isTreeOpen)}
            useOffscreen
            data-testid="asset-tree"
        >
            <TreeCategory
                key={CATEGORY_ASSETS}
                id={CATEGORY_ASSETS}
                icon="rioglyph-truck"
                hasSelection={!isEmpty(selectedAssetIds) || !isEmpty(selectedGroupIds)}
                label={intl.formatMessage({ id: 'e4c.assetTree.menuIcon.tooltip' })}
            >
                <Tree
                    groups={showAssetGroups ? groups : []}
                    items={assets}
                    expandedGroups={expandedAssetGroups}
                    onExpandGroupsChange={setExpandedAssetGroups}
                    onSelectionChange={handleSelectAssets}
                    selectedItems={selectedAssetIds}
                    selectedGroups={selectedGroupIds}
                    searchPlaceholder={intl.formatMessage({ id: 'e4c.assetTree.search.placeholder' })}
                    showEmptyGroups={showEmptyGroups}
                    treeOptions={[
                        <TreeOption
                            key="emptyGroupsToggle"
                            label={intl.formatMessage({ id: 'e4c.assetTree.options.emptyGroups.label' })}
                            isChecked={showEmptyGroups}
                            onChange={handleToggleEmptyGroups}
                            data-testid="empty-groups-toggle"
                        />,
                        <TreeOption
                            key="assetGroupsToggle"
                            label={intl.formatMessage({ id: 'e4c.assetTree.options.assetGroups.label' })}
                            isChecked={showAssetGroups}
                            onChange={handleToggleAssetGroups}
                            data-testid="asset-groups-toggle"
                        />,
                    ]}
                />
            </TreeCategory>
        </AssetTree>
    );
};

export default AssetTreeSidebar;
