import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AccessToken } from '../../../configuration';
import Api from '../../../api/types';
import { isSuccessful } from '../../../api/utils';
import { State } from '../../../types';

export const ELECTRIC_VEHICLES_MONITORING_LOADING_STARTED = 'ELECTRIC_VEHICLES_MONITORING_LOADING_STARTED';
export const ELECTRIC_VEHICLES_MONITORING_LOADING_SUCCESSFUL = 'ELECTRIC_VEHICLES_MONITORING_LOADING_SUCCESSFUL';
export const ELECTRIC_VEHICLES_MONITORING_LOADING_FAILED = 'ELECTRIC_VEHICLES_MONITORING_LOADING_FAILED';

export const ELECTRIC_VEHICLES_STATE_LOADING_STARTED = 'ELECTRIC_VEHICLES_STATE_LOADING_STARTED';
export const ELECTRIC_VEHICLES_STATE_LOADING_SUCCESSFUL = 'ELECTRIC_VEHICLES_STATE_LOADING_SUCCESSFUL';
export const ELECTRIC_VEHICLES_STATE_LOADING_FAILED = 'ELECTRIC_VEHICLES_STATE_LOADING_FAILED';

interface ElectricVehiclesMonitoringLoadingStartedAction {
    type: typeof ELECTRIC_VEHICLES_MONITORING_LOADING_STARTED;
}

export interface ElectricVehiclesMonitoringLoadingSuccessfulAction {
    type: typeof ELECTRIC_VEHICLES_MONITORING_LOADING_SUCCESSFUL;
    assets: Object[];
    account: Object;
}

interface ElectricVehiclesMonitoringLoadingFailedAction {
    type: typeof ELECTRIC_VEHICLES_MONITORING_LOADING_FAILED;
}

interface ElectricVehiclesStateLoadingStartedAction {
    type: typeof ELECTRIC_VEHICLES_STATE_LOADING_STARTED;
}

interface ElectricVehiclesStateLoadingSuccessfulAction {
    type: typeof ELECTRIC_VEHICLES_STATE_LOADING_SUCCESSFUL;
    items: Object[];
}

interface ElectricVehiclesStateLoadingFailedAction {
    type: typeof ELECTRIC_VEHICLES_STATE_LOADING_FAILED;
}

export type ElectricVehiclesActions =
    | ElectricVehiclesMonitoringLoadingStartedAction
    | ElectricVehiclesMonitoringLoadingSuccessfulAction
    | ElectricVehiclesMonitoringLoadingFailedAction
    | ElectricVehiclesStateLoadingStartedAction
    | ElectricVehiclesStateLoadingSuccessfulAction
    | ElectricVehiclesStateLoadingFailedAction;

export type ElectricVehiclesThunkAction<R, T> = ThunkAction<R, State, T, ElectricVehiclesActions>;
type ElectricVehiclesThunkDispatch = ThunkDispatch<State, any, ElectricVehiclesActions>;

/**
 * Action creators
 */
const loadingMonitoringElectricVehiclesStarted = (): ElectricVehiclesMonitoringLoadingStartedAction => {
    return { type: ELECTRIC_VEHICLES_MONITORING_LOADING_STARTED };
};

export const loadingMonitoringElectricVehiclesSucceeded = (
    assets: Object[],
    account: Object
): ElectricVehiclesMonitoringLoadingSuccessfulAction => {
    return {
        type: ELECTRIC_VEHICLES_MONITORING_LOADING_SUCCESSFUL,
        assets,
        account,
    };
};

const loadingMonitoringElectricVehiclesFailed = (): ElectricVehiclesMonitoringLoadingFailedAction => {
    return { type: ELECTRIC_VEHICLES_MONITORING_LOADING_FAILED };
};

const loadingStateElectricVehiclesStarted = (): ElectricVehiclesStateLoadingStartedAction => {
    return { type: ELECTRIC_VEHICLES_STATE_LOADING_STARTED };
};

const loadingStateElectricVehiclesSucceeded = (items: Object[]): ElectricVehiclesStateLoadingSuccessfulAction => {
    return {
        type: ELECTRIC_VEHICLES_STATE_LOADING_SUCCESSFUL,
        items,
    };
};

const loadingStateElectricVehiclesFailed = (): ElectricVehiclesStateLoadingFailedAction => {
    return { type: ELECTRIC_VEHICLES_STATE_LOADING_FAILED };
};

/**
 * Side effects
 */

// Once we get everything from one single service this action can be renamed later to be agnostic of the service.
export const fetchElectricVehiclesFromMonitoringService = (
    accessToken: AccessToken
): ElectricVehiclesThunkAction<void, Api> => {
    return async (dispatch: ElectricVehiclesThunkDispatch, getState: () => State, api: Api): Promise<void> => {
        dispatch(loadingMonitoringElectricVehiclesStarted());

        const response = await api.getMonitoringVehicles(accessToken);

        if (isSuccessful(response)) {
            const json = await response.json();
            dispatch(loadingMonitoringElectricVehiclesSucceeded(json.assets, json.account));
        } else {
            dispatch(loadingMonitoringElectricVehiclesFailed());
        }
    };
};

// Once we get everything from one single service this action can be renamed later to be agnostic of the service.
export const fetchElectricVehiclesFromStateService = (
    accessToken: AccessToken
): ElectricVehiclesThunkAction<void, Api> => {
    return async (dispatch: ElectricVehiclesThunkDispatch, getState: () => State, api: Api): Promise<void> => {
        dispatch(loadingStateElectricVehiclesStarted());

        const response = await api.getStateVehicles(accessToken);

        if (isSuccessful(response)) {
            const json = await response.json();
            dispatch(loadingStateElectricVehiclesSucceeded(json.items));
        } else {
            dispatch(loadingStateElectricVehiclesFailed());
        }
    };
};
