import { ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import UserManualPage from '../components/UserManualPage';
import UserManualSection from '../components/UserManualSection';
import detailDownloadHistoryPanelModalImage from '../../../../../assets/images/detail-download-history-panel-modal.png';
import { config } from '../../../../../../../config';

const DownloadHistoryItem = (): ReactElement => {
    const intl = useIntl();
    const formattedMessageValues = {
        b: (chunks: any) => <b>{chunks}</b>,
        ol: (chunks: any) => <ol className="margin-left-25">{chunks}</ol>,
        ul: (chunks: any) => <ul className="margin-left-25">{chunks}</ul>,
        li: (chunks: any) => <li>{chunks}</li>,
        a: (chunks: any) => (
            <a href={config.minimalDatasetUserManualUrl} rel="noreferrer noopener" target="_blank">
                {chunks}
            </a>
        ),
    };
    return (
        <UserManualPage
            title={<FormattedMessage id="e4c.userManual.details.downloadHistory" />}
            dataTestId="download-history-item"
        >
            <img
                alt={intl.formatMessage({ id: 'e4c.userManual.altText.downloadHistory' })}
                src={detailDownloadHistoryPanelModalImage}
                width="958"
                height="321"
            />
            <UserManualSection title={<FormattedMessage id="e4c.userManual.details.downloadHistory.title" />}>
                <FormattedMessage
                    id="e4c.userManual.details.downloadHistory.description"
                    values={formattedMessageValues}
                />
                <div className="margin-top-10">
                    <FormattedMessage
                        id="e4c.userManual.details.downloadHistory.descriptionBullet"
                        values={formattedMessageValues}
                    />
                </div>
            </UserManualSection>
            <UserManualSection title={<FormattedMessage id="e4c.userManual.details.downloadHistory.title2" />}>
                <FormattedMessage
                    id="e4c.userManual.details.downloadHistory.description2"
                    values={formattedMessageValues}
                />
                <div className="margin-top-10">
                    <FormattedMessage
                        id="e4c.userManual.details.downloadHistory.descriptionBullet2"
                        values={formattedMessageValues}
                    />
                </div>
                <div className="margin-top-10">
                    <FormattedMessage
                        id="e4c.userManual.details.downloadHistory.description3"
                        values={formattedMessageValues}
                    />
                </div>
            </UserManualSection>
            <UserManualSection title={<FormattedMessage id="e4c.userManual.details.downloadHistory.title3" />}>
                <FormattedMessage
                    id="e4c.userManual.details.downloadHistory.description4"
                    values={formattedMessageValues}
                />
                <DownloadHistoryTable />
            </UserManualSection>
            <UserManualSection title={<FormattedMessage id="e4c.userManual.details.downloadHistory.title4" />}>
                <FormattedMessage
                    id="e4c.userManual.details.downloadHistory.description5"
                    values={formattedMessageValues}
                />
            </UserManualSection>
        </UserManualPage>
    );
};

type DownloadHistoryTableMap = {
    id: string;
    noDescription?: boolean;
};

const downloadHistoryTableMap: DownloadHistoryTableMap[] = [
    { id: 'vehicleName', noDescription: true },
    { id: 'vin', noDescription: true },
    { id: 'status' },
    { id: 'startTime' },
    { id: 'endTime' },
    { id: 'duration' },
    { id: 'mileageStart' },
    { id: 'MileageEnd' },
    { id: 'distance' },
    { id: 'startSoc' },
    { id: 'endSoc' },
    { id: 'minimumSoc' },
    { id: 'maximumSoc' },
    { id: 'totalConsumptionUsage' },
    { id: 'consumption' },
    { id: 'totalEnergyCharged' },
    { id: 'energyAddedToBattery' },
    { id: 'recuperation' },
    { id: 'energyConsumptionPowertrain' },
    { id: 'energyConsumptionClimatization' },
    { id: 'energyConsumptionAuxiliaryUnits' },
    { id: 'fuelConsumptionAdditionalHeating' },
    { id: 'insideTemperatureAverage', noDescription: true },
    { id: 'outsideTemperatureAverage', noDescription: true },
    { id: 'outsideTemperatureMin', noDescription: true },
    { id: 'outsideTemperatureMax', noDescription: true },
    { id: 'batteryTemperatureAverage', noDescription: true },
];

const DownloadHistoryTable = () => {
    return (
        <table className="table table-head-filled margin-top-10">
            <thead>
                <tr>
                    <th>
                        <FormattedMessage id="e4c.userManual.details.downloadHistory.table.column.name" />
                    </th>
                    <th>
                        <FormattedMessage id="e4c.userManual.details.downloadHistory.table.column.description" />
                    </th>
                </tr>
            </thead>
            <tbody>
                {downloadHistoryTableMap.map(row => {
                    const name = `history.export.header.${row.id}`;
                    const description = `history.export.description.${row.id}`;
                    return (
                        <tr key={row.id}>
                            <td className="white-space-nowrap">
                                <FormattedMessage id={name} />
                            </td>
                            <td>{row.noDescription ? '-' : <FormattedMessage id={description} />}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default DownloadHistoryItem;
