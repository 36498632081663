import {
    aggregatedAverageMileageDaily,
    aggregatedAverageMileageMonthly,
    getMileageSummaryValue,
} from './AverageMileageStatisticsHelper';
import StatisticsBuilder from './StatisticsBuilder';
import { AggregationPeriodType, AvgMileageChartData, StatisticsData } from './types';

class AverageMileageStatisticsBuilder extends StatisticsBuilder {
    build(): StatisticsData {
        let data: AvgMileageChartData[];
        if (this.isDailyAggregated()) {
            data = aggregatedAverageMileageDaily(this._vehicleStatistics, this._startDate, this._endDate);
            return {
                aggregationPeriodType: AggregationPeriodType.DAILY,
                data,
                summaryValue: getMileageSummaryValue(data),
            };
        } else {
            data = aggregatedAverageMileageMonthly(this._vehicleStatistics, this._startDate, this._endDate);
            return {
                aggregationPeriodType: AggregationPeriodType.MONTHLY,
                data,
                summaryValue: getMileageSummaryValue(data),
            };
        }
    }
}

export default AverageMileageStatisticsBuilder;
