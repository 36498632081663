import { useMutation, useQuery } from 'react-query';
import { fetchEventsSettings } from '../../../../api/events';
import { AccessToken } from '../../../../configuration';
import EventSettingsResponse from '../overviewWidget/contentWidget/events/settings/Models/EventSettingsResponse';
import { requestEventSetting } from '../overviewWidget/contentWidget/events/settings/Models/EventSettingsRequest';
import { ContactDetail, SettingsDetails } from '../overviewWidget/contentWidget/events/settings/Models/IModel';

export const useGetEventSettings = (accessToken: AccessToken) => {
    const { isLoading, isError, data } = useQuery(['events-settings'], () =>
        fetchEventsSettings({ accessToken }).then(res => res.json())
    );

    const eventSettingsData = data ? new EventSettingsResponse(data) : null;

    return { isLoading, isError, eventSettingsData };
};

export const usePatchEventSettings = (
    accessToken: AccessToken,
    severity: string,
    settings: SettingsDetails[],
    contactsInfo: ContactDetail[] | null
) => {
    const body = requestEventSetting(severity, settings, contactsInfo);
    return useMutation(() =>
        fetchEventsSettings({
            accessToken,
            body: JSON.stringify(body),
            contentType: 'application/merge-patch+json',
            method: 'PATCH',
        })
    );
};
