import React from 'react';
import './BatteryStatusColumn.css';
import BatteryIcon from '../../../../../common/components/batteryIcon/BatteryIcon';
import { findBatteryStatusInterval } from '../../../../../common/helpers/tables';

interface BatteryStatusProps {
    isDisabled: boolean;
    value: number;
}

export const BatteryStatusColumn = ({ isDisabled, value }: BatteryStatusProps): JSX.Element => {
    let color = '';

    if (!isDisabled) {
        color = findBatteryStatusInterval(value)?.backgroundColor || '';
    }

    return <BatteryIcon isDisabled={isDisabled} color={color} value={value} />;
};

export const MemoBatteryStatusColumn = React.memo(BatteryStatusColumn);
