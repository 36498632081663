import EmptyEvents from './EmptyEvents';
import './Events.css';
import { Events } from '../../../models/Event';
import ContentLoader from '@rio-cloud/rio-uikit/ContentLoader';
import EventsBySeverityCard from './eventCard/EventsBySeverityCard';
import ErrorState from '../../../../common/components/states/ErrorState';
import React from 'react';

const EventsCard: React.FC<{ event: Events }> = ({ event }) => (
    <>
        <div className="display-flex justify-content-end">
            <div className="badge badge-muted z-index-1" style={{ top: '19px' }}>
                {event.count}
            </div>
        </div>
        <EventsBySeverityCard event={event} />
    </>
);

const EventsBySeverity = ({
    loading,
    error,
    events,
}: {
    loading: boolean;
    error: boolean;
    events: Events[] | undefined;
}): JSX.Element => {
    if (loading) {
        return (
            <div className="display-flex flex-1-1">
                <ContentLoader className="height-60" />
            </div>
        );
    }

    if (error) {
        return <ErrorState stateProps={{ type: 'general', withoutBorder: true }} />;
    }

    if (!events?.length) {
        return <EmptyEvents />;
    }

    return (
        <div className="display-flex flex-column flex-1-1" style={{ gap: '8px' }}>
            {events.map((event: Events) => (
                <EventsCard key={event.severityLevel} event={event} />
            ))}
        </div>
    );
};

export default EventsBySeverity;
