import moment from 'moment';
import { round } from 'lodash';

export const drivenDistanceThreshold = 5; // use a distance threshold for meaningful average values

export const overallAverageFromAveragePerDay = (averagePerDay: number[]): number => {
    let overallAverage = 0;
    const nonZeroEntries = averagePerDay.filter(value => value > 0);
    if (nonZeroEntries.length > 0) {
        overallAverage = nonZeroEntries.reduce((x, y) => x + y) / nonZeroEntries.length;
    }
    return round(overallAverage, 2);
};

export const isCurrentMonthAndYear = (monthDate: moment.Moment, currentDate: moment.Moment): boolean => {
    return currentDate.month() === monthDate.month() && currentDate.year() === monthDate.year();
};

export const arrayOfDatesInRange = (start: Date, end: Date): string[] => {
    const dates = [];
    const currentDate = moment(start);
    const lastDate = moment(end);
    for (let date = currentDate; date.isSameOrBefore(lastDate); date = date.add(1, 'day')) {
        dates.push(date.format('YYYY-MM-DD'));
    }
    return dates;
};

export const arrayOfMonthsInRange = (start: Date, end: Date): string[] => {
    const dates = [];
    const dateStart = moment(start);
    const dateEnd = moment(end);
    while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
        dates.push(dateStart.format('YYYY-MM'));
        dateStart.add(1, 'month');
    }
    return dates;
};

export const divideIfPositive = (valueToCheck: number, numerator: number, denominator: number): number => {
    return valueToCheck > 0 ? round(numerator / denominator, 2) : 0;
};
