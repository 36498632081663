import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { mapWeekdayToIntl } from '../../../../../../common/enums/intl/weekdays';
import { ChargingConfigurationStatusEnum } from '../../../../../types';
import { convertDateToDayMonthYear } from '../../vehicleDetailsHelper';
import { State } from '../../../../../../../../types';
import { getLocale } from '../../../../../../../../configuration';
import { getNextOccurrences } from './getNextOccurrences';
import { Weekday } from '../vehicleChargingConfigurationTypes';

interface NextOccurrencesProps {
    weekdays: Weekday[];
    departureTime: string;
    repeatTimer: boolean;
    showStatus?: boolean;
    timersSentToVehicle?: {
        status: ChargingConfigurationStatusEnum;
        departureTime: Date;
    }[];
}

const NextOccurrences = ({
    weekdays,
    departureTime,
    repeatTimer,
    showStatus,
    timersSentToVehicle,
}: NextOccurrencesProps) => {
    const language = useSelector((state: State) => getLocale(state));

    const departureTimes = getNextOccurrences(weekdays, departureTime, repeatTimer, timersSentToVehicle, showStatus);

    const getStatusElement = (status: ChargingConfigurationStatusEnum) => {
        switch (status) {
            case ChargingConfigurationStatusEnum.REJECTED:
                return (
                    <div className="label label-danger label-condensed">
                        <FormattedMessage id="e4c.vehicles.details.preview.status.rejected" />
                    </div>
                );
            case ChargingConfigurationStatusEnum.ACCEPTED:
                return (
                    <div className="label label-success label-condensed">
                        <FormattedMessage id="e4c.vehicles.details.preview.status.accepted" />
                    </div>
                );
            case ChargingConfigurationStatusEnum.SENDING:
                return (
                    <div className="label label-warning label-condensed">
                        <FormattedMessage id="e4c.vehicles.details.preview.status.pending" />
                    </div>
                );
            default:
                return (
                    <div className="label label-default label-condensed">
                        <FormattedMessage id="e4c.vehicles.details.preview.status.planned" />
                    </div>
                );
        }
    };

    return (
        <table className="table table-sm preview table-layout-fixed margin-top-15" data-testid="next-occurrences-table">
            <thead>
                <tr>
                    <th scope="col" colSpan={3}>
                        <label className="text-size-14">
                            <FormattedMessage id="e4c.vehicle.details.nextOccurrences" />
                        </label>
                    </th>
                    {showStatus && (
                        <th scope="col">
                            <label className="text-size-14">
                                <FormattedMessage id="e4c.vehicle.details.status" />
                            </label>
                        </th>
                    )}
                </tr>
            </thead>
            <tbody>
                {departureTimes.map((departure, index) => (
                    <tr key={index}>
                        <td>
                            <span className="rioglyph rioglyph-calendar text-size-20 padding-right-5" />
                            <FormattedMessage id={mapWeekdayToIntl(departure.day)} />
                        </td>
                        <td>{convertDateToDayMonthYear(departure.date, language)}</td>
                        <td>
                            <span className="rioglyph rioglyph-time-alt text-size-20 padding-right-5" />
                            {departure.time}
                        </td>
                        {departure.status && <td>{getStatusElement(departure.status)}</td>}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default NextOccurrences;
