import _ from 'lodash';
import { DurationProps } from '../../../types';

export const Duration = (props: DurationProps): JSX.Element => {
    let formattedDuration = '';
    if (props.duration !== null) {
        const hours = Math.floor(props.duration / 60);
        const minutes = props.duration - hours * 60;
        const paddedNumber = (input: number): string => {
            return _.padStart(input.toString(), 2, '0');
        };
        formattedDuration = `${paddedNumber(hours)}:${paddedNumber(minutes)} h`;
    }
    return <span className="ellipsis-1 display-block">{formattedDuration}</span>;
};
