import { AccessToken } from '../../../../configuration';
import { closeEventSource, setUpEventSource } from '../../../../api/events';

const WIDGET_EVENT_SOURCE = 'WIDGET_EVENT_SOURCE';

export type EventListeners = {
    [key in EventSubscriptionType]: (event: any) => void;
};

enum EventSubscriptionType {
    UPDATE_EVENTS = 'UPDATE_EVENTS',
}

const subscribeToVehicleEventUpdate = (accessToken: AccessToken, updateEvents: Function): void => {
    const eventListeners: EventListeners = {
        [EventSubscriptionType.UPDATE_EVENTS]: event => {
            updateEvents(event.lastEventId);
        },
    };
    setUpEventSource(accessToken, WIDGET_EVENT_SOURCE, eventListeners);
};

export const unsubscribeToVehicleEventUpdate = (): void => {
    closeEventSource(WIDGET_EVENT_SOURCE);
};

export default subscribeToVehicleEventUpdate;
