import { AssetType, ChargingStatusEnum, Vehicle } from '../../vehicleCharging/types';

export const mapAsset = (assetJson: any): Vehicle => {
    const readyToDriveStatus =
        assetJson.charging_status === ChargingStatusEnum.DISCONNECTED ? '' : assetJson.ready_to_drive_status;

    return {
        vehicleId: assetJson.asset_id,
        name: assetJson.name,
        vin: assetJson.vin,
        chargingStatus: assetJson.charging_status,
        chargingUntil: assetJson.charging_until,
        delayedChargingUntil: assetJson.delayed_charging_until,
        disconnectedAt: assetJson.disconnected_at,
        stateOfCharge: assetJson.state_of_charge,
        chargingConfiguration: {
            chargingMode: assetJson.charging_configuration ? assetJson.charging_configuration.charging_mode : null,
            status: assetJson.charging_configuration ? assetJson.charging_configuration.status : null,
            departureDate: assetJson.charging_configuration ? assetJson.charging_configuration.departure_date : null,
            climateMode: assetJson.charging_configuration ? assetJson.charging_configuration.climate_mode : null,
            targetStateOfCharge: assetJson.charging_configuration
                ? assetJson.charging_configuration.target_state_of_charge
                : null,
        },
        readyToDriveStatus,
        readyToDriveUntil: assetJson.ready_to_drive_until,
        timestamp: assetJson.last_updated,
        remainingRange: Math.floor(assetJson.remaining_range), // in km
        hasMonitoringService: false,
        type: AssetType.BUS,
    };
};
