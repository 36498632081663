import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { whatsNewData } from './whatsNewData';
import { getUsername } from '../../../../../../../configuration';
import { MANUAL_WHATS_NEW_SEARCH, USER_MANUAL } from '../../../../constants/paths/paths';
import { addQueryParam } from '../../../../helpers/queryParametersHelper';
import { FeatureToggles } from '../../../../../../../configuration/featureToggle/featureToggles';
import { useFeatureToggle } from '../../../../hooks/useFeatureToggle';

const WhatsNewChecker = () => {
    const history = useHistory();
    const username = useSelector(state => getUsername(state));

    const toggle = FeatureToggles.NEW_HISTORY;
    const { value: isEnabled } = useFeatureToggle(toggle);

    useEffect(() => {
        if (username && isEnabled !== undefined) {
            const whatsNewDataFiltered = isEnabled ? whatsNewData : whatsNewData.filter(item => item.toggle !== toggle);
            const version = whatsNewDataFiltered[0].version;
            const hasSeen = localStorage.getItem(`release_${version}_${username}`);
            if (hasSeen !== 'true') {
                history.push({
                    search: addQueryParam(history.location.search, USER_MANUAL, MANUAL_WHATS_NEW_SEARCH),
                });
                localStorage.setItem(`release_${version}_${username}`, 'true');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, username, isEnabled]);

    return null;
};

export default WhatsNewChecker;
