import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ContactDetail } from '../Models/IModel';
import ContactForm from './ContactForm';
import isEqual from 'lodash/fp/isEqual';

interface ContactSectionProps {
    initialContacts: ContactDetail[];
    changedContacts: ContactDetail[];
    handleSaveContact: Function;
    handleDiscardContact: Function;
    handlePendingChanges: Function;
}

export interface ContactInfoSection extends ContactDetail {
    contactError?: boolean;
    languageError?: boolean;
}

const removeErrors = (contacts: ContactDetail[]) =>
    contacts
        .map(value => ({
            type: value.type,
            contact: value.contact,
            language: value.language,
        }))
        .filter(Boolean);

const ContactSection = ({
    initialContacts,
    changedContacts,
    handleSaveContact,
    handleDiscardContact,
    handlePendingChanges,
}: ContactSectionProps) => {
    const intl = useIntl();
    const [addContact, setAddContact] = useState(false);
    const [contacts, setContacts] = useState<ContactInfoSection[]>(initialContacts);
    const [showButtons, setShowButtons] = useState(false);
    useEffect(() => {
        setContacts(changedContacts);
    }, [changedContacts]);

    useEffect(() => {
        const hasChanges = !isEqual(initialContacts, contacts);
        setShowButtons(hasChanges || addContact);
        handlePendingChanges(hasChanges || addContact);
    }, [contacts, addContact, handlePendingChanges, initialContacts]);

    const handleChange = (type: 'contact' | 'language', index: number, value: string) => {
        const contactUpdate = [...contacts];
        const contactToChange = contactUpdate[index];
        if (contactToChange) {
            contactToChange[type] = value;
            setContacts(contactUpdate);
        }
    };

    const handleError = (type: 'contactError' | 'languageError', contact: string, value: boolean) => {
        const contactUpdate = [...contacts];
        const contactInError = contactUpdate.find(item => item.contact === contact);
        if (contactInError) {
            contactInError[type] = value;
            setContacts(contactUpdate);
        }
    };

    const handleDelete = (contact: string) => {
        setContacts(contacts.filter(item => item.contact !== contact));
        setAddContact(false);
    };

    const handleAddContact = () => {
        const initialContact = {
            type: 'SMS',
            contact: '',
            language: '',
        };
        const newContacts = [...contacts];
        newContacts.push(initialContact);
        setContacts(newContacts);
        setAddContact(true);
    };

    const handleOnDiscard = () => {
        handleDiscardContact(contacts);
        setAddContact(false);
    };
    const handleOnSubmit = () => {
        const contactsWithError = contacts.find(contact => {
            const contactInvalid = contact.contact === '' || Boolean(contact.contactError);
            handleError('contactError', contact.contact, contactInvalid);
            const languageInvalid = contact.language === '' || Boolean(contact.languageError);
            handleError('languageError', contact.contact, languageInvalid);
            return contactInvalid || languageInvalid;
        });
        if (!contactsWithError) {
            const contactsWithoutErrorMessage = removeErrors(contacts);
            handleSaveContact(contactsWithoutErrorMessage);
        }
    };

    return (
        <>
            <p className="text-normal text-size-12 margin-0 margin-top-15 margin-bottom-10">
                {intl.formatMessage({ id: 'e4c.overview.events.widget.settings.critical.sms.description' })}
            </p>
            <ContactForm
                contacts={contacts}
                handleError={handleError}
                handleChange={handleChange}
                handleDelete={handleDelete}
                handleAddContact={handleAddContact}
                onSubmit={handleOnSubmit}
                onDiscard={handleOnDiscard}
                showButtons={showButtons}
            />
        </>
    );
};

export default ContactSection;
