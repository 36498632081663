import React, { FC } from 'react';
import ChargingConnectorPlug from './ChargingConnectorPlug';
import { formatDisconnectedAt, getChargingUntil, getStatusColorAndMessage } from './ChargingStatusColumnHelper';
import ConnectorIcon from './ConnectorIcon';
import ChargingStatusMessage from './ChargingStatusMessage';
import DisconnectedAt from './DisconnectedAt';
import ChargingUntil, { ChargingUntilProps } from './ChargingUntil';
import ChargingStatus from '../../../../models/enums/ChargingStatus';
import { useIntl } from 'react-intl';

export interface ChargingStatusColumnProps {
    chargingState?: ChargingStatus;
    chargingUntil?: string | null;
    delayedChargingUntil?: string | null;
    disconnectedAt?: string | null;
    lastUpdated: string;
}

export const ChargingDetails: FC<{
    isPluggedOn: boolean;
    showChargingUntil: boolean;
    locale: string;
    disconnectedAt?: string | null;
    chargingUntilProps?: ChargingUntilProps;
}> = ({ isPluggedOn, showChargingUntil, locale, disconnectedAt, chargingUntilProps }) => {
    if (isPluggedOn) {
        return showChargingUntil && chargingUntilProps ? <ChargingUntil {...chargingUntilProps} /> : null;
    }

    const isDisconnectedAt = !!disconnectedAt;
    return isDisconnectedAt ? <DisconnectedAt dateAndTime={formatDisconnectedAt(disconnectedAt, locale)} /> : null;
};

export const ChargingStatusColumn = ({
    chargingState,
    chargingUntil,
    delayedChargingUntil,
    disconnectedAt,
    lastUpdated,
}: ChargingStatusColumnProps): JSX.Element => {
    const isPluggedOn = chargingState !== ChargingStatus.DISCONNECTED;
    const pluggedClass = isPluggedOn ? ChargingConnectorPlug.PLUGGED_ON : ChargingConnectorPlug.PLUGGED_OFF;
    const { locale } = useIntl();
    const statusColorAndMessage = getStatusColorAndMessage(chargingState);

    const { showChargingUntil, chargingUntilProps } = getChargingUntil(
        lastUpdated,
        locale,
        chargingUntil,
        delayedChargingUntil,
        isPluggedOn,
        chargingState
    );

    return (
        <div className={'display-flex align-items-center'}>
            <ConnectorIcon color={statusColorAndMessage.color} plug={pluggedClass} />
            <div className={'display-block ellipsis-1'}>
                <ChargingStatusMessage messageId={statusColorAndMessage.text} />
                <ChargingDetails
                    showChargingUntil={showChargingUntil}
                    isPluggedOn={isPluggedOn}
                    chargingUntilProps={chargingUntilProps}
                    locale={locale}
                    disconnectedAt={disconnectedAt}
                />
            </div>
        </div>
    );
};

export const MemoChargingStatusColumn = React.memo(ChargingStatusColumn);
