import { ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import UserManualPage from '../components/UserManualPage';
import userManualHistoryViewImage from '../../../../../assets/images/detail-history-view-panel-modal.png';
import UserManualSection from '../components/UserManualSection';

const NewHistoryItem = (): ReactElement => {
    const intl = useIntl();
    const formattedMessageValues = {
        b: (chunks: any) => <b>{chunks}</b>,
        ul: (chunks: any) => <ul style={{ listStyleType: 'none' }}>{chunks}</ul>,
        li: (chunks: any) => <li className={'margin-top-10'}>{chunks}</li>,
    };
    return (
        <UserManualPage
            title={<FormattedMessage id="e4c.userManual.details.newHistory" />}
            footer={<FormattedMessage id="e4c.userManual.details.global.onlyAvailable" />}
            dataTestId="new-history-item"
        >
            <img
                alt={intl.formatMessage({ id: 'e4c.userManual.altText.newHistory' })}
                src={userManualHistoryViewImage}
                width="959"
                height="322"
            />
            <UserManualSection title={<FormattedMessage id="e4c.userManual.details.newHistory.sectionTitle" />}>
                <FormattedMessage id="e4c.userManual.details.newHistory.description" values={formattedMessageValues} />
            </UserManualSection>
            <UserManualSection title={<FormattedMessage id="e4c.userManual.details.newHistory.sectionTitle2" />}>
                <FormattedMessage id="e4c.userManual.details.newHistory.description2" values={formattedMessageValues} />
                <div className={'margin-top-10'}>
                    <FormattedMessage
                        id="e4c.userManual.details.newHistory.description3"
                        values={formattedMessageValues}
                    />
                </div>
            </UserManualSection>
        </UserManualPage>
    );
};
export default NewHistoryItem;
