import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Switch from '@rio-cloud/rio-uikit/Switch';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import { PLACEMENT } from '@rio-cloud/rio-uikit/values/Placement';
import HistoryDownloadButton from '../../../common/features/HistoryDownload/HistoryDownloadButton';
import { useFeatureToggle } from '../../../common/hooks/useFeatureToggle';
import { NEW_HISTORY_PATH } from '../../../common/constants/paths/paths';
import { HISTORY_PATH } from '../../../../../configuration/setup/path';
import { FeatureToggles } from '../../../../../configuration/featureToggle/featureToggles';
import { getUsername } from '../../../../../configuration';
import { removeCookie } from '../../../common/utilities/cookies';

export const RightContent = () => {
    const history = useHistory();
    const { value: isNewHistoryFeatureToggleEnabled } = useFeatureToggle(FeatureToggles.NEW_HISTORY);
    const { value: isEarlyFleet } = useFeatureToggle(FeatureToggles.EARLY_FLEET);
    const { value: isRedesignDownload } = useFeatureToggle(FeatureToggles.REDESIGN_DOWNLOAD);
    const username = useSelector(state => getUsername(state));
    const isNewView = history.location.pathname.includes(NEW_HISTORY_PATH);
    const showDownloadButton = !(isRedesignDownload && isNewView);

    const changeView = () => {
        history.push({
            pathname: `${isNewView ? HISTORY_PATH : NEW_HISTORY_PATH}`,
            search: history.location.search,
        });
        localStorage.setItem(`newHistoryViewSelected_${username}`, String(!isNewView));
        if (isNewView) {
            removeCookie(`historyDownloadAlert-${username}`);
        }
    };

    return (
        <div data-testid="toolbar-right-content" className="table-toolbar-group-right">
            {isNewHistoryFeatureToggleEnabled && !isEarlyFleet && (
                <div data-testid="new-history-switch" className="table-toolbar-column justify-content-center">
                    <Switch
                        onChange={() => changeView()}
                        checked={isNewView}
                        labelPosition={Switch.LABEL_POSITION_LEFT}
                    >
                        <OverlayTrigger
                            placement={PLACEMENT.BOTTOM_END}
                            overlay={
                                <Tooltip
                                    id="tooltip"
                                    className="white-space-pre-line"
                                    textAlignment="left"
                                    allowOnTouch
                                >
                                    <FormattedMessage id="e4c.history.newHistoryView.tooltip" />
                                </Tooltip>
                            }
                        >
                            <span className="rioglyph rioglyph-info-sign text-size-18" />
                        </OverlayTrigger>

                        <span className="padding-right-5">
                            <FormattedMessage id="e4c.history.newHistoryView" />
                        </span>
                    </Switch>
                </div>
            )}
            {showDownloadButton && (
                <div className="table-toolbar-column">
                    <HistoryDownloadButton />
                </div>
            )}
        </div>
    );
};
