import { State } from '../../../../types';
import ElectricVehicle from '../models/ElectricVehicle';
import ElectricVehicleList from '../models/ElectricVehicleList';
import FleetType from '../models/enums/FleetType';

export const getElectricVehiclesFromServices = (state: State): ElectricVehicle[] => {
    return state.electricVehicles.electricVehicles.filter(ev => ev.isValid);
};

export const getElectricVehiclesListFromServices = (state: State): ElectricVehicleList => {
    return new ElectricVehicleList(state.electricVehicles.electricVehicles.filter(ev => ev.isValid));
};

export const getElectricVehicleFromServices = (state: State): ElectricVehicle | undefined => {
    return state.electricVehicles.electricVehicles.find(ev => ev.assetId === state.vehicles.selectedRow);
};

export const getIsFetchingMonitoringElectricVehicles = (state: State): boolean => {
    return state.electricVehicles.isFetchingMonitoringElectricVehicles;
};

export const getIsFetchingStateElectricVehicles = (state: State): boolean => {
    return state.electricVehicles.isFetchingStateElectricVehicles;
};

export const getFleetType = (state: State): FleetType => {
    return state.electricVehicles.fleetType;
};
