export const E_MANAGER = 'MAN eManager';

export interface BatteryStatusInterval {
    min: number;
    max: number;
    backgroundColor: string;
    color: string;
    text: string;
}

export const DEFAULT_COLOR_CLASSES: BatteryStatusInterval[] = [
    { min: 21, max: 100, backgroundColor: 'bg-success', text: '', color: '' },
    { min: 11, max: 20, backgroundColor: 'bg-warning', text: 'text-warning text-bold', color: 'warning' },
    { min: 0, max: 10, backgroundColor: 'bg-danger', text: 'text-danger text-bold', color: 'danger' },
];

export const CHARTS = {
    left: 'leftChart-',
    right: 'rightChart-',
};
