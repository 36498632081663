import { FormattedMessage, FormattedNumber } from 'react-intl';
import List from './List';
import WithUnit from './WithUnit';
import NoDataElement from './NoDataElement';

type Currents = {
    avg: number;
    max: number;
} | null;

const BatteryCurrent = ({ currents }: { currents: Currents }): JSX.Element => {
    if (!currents) {
        return <NoDataElement />;
    }

    const listValues = Object.entries(currents).map(([key, value]) => ({
        label: <FormattedMessage id={`e4c.history.${key}`} />,
        value: (
            <WithUnit unit="A" dataTestId={key}>
                <FormattedNumber value={value} maximumFractionDigits={0} />
            </WithUnit>
        ),
    }));

    return <List data-testid="battery-currents" values={listValues} />;
};

export default BatteryCurrent;
