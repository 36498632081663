import { Moment } from 'moment';
import Button from '@rio-cloud/rio-uikit/Button';
import { HistoryContentEnum } from '../types';
import { FormattedMessage } from 'react-intl';
import { createGoogleAnalyticsEvent, gtmUtil, TrackingAction, TrackingCategory } from '../../../utils/googleTagManager';
import DateRangePicker from '../../../common/components/DateRangePicker/DateRangePicker';

interface LeftContentProps {
    changeSelectedRangeDate: (startValue: any, endValue: any) => void;
    defaultEndValue: Moment | undefined;
    defaultStartValue: Moment | undefined;
    handleSelectedContentChange: (selection: HistoryContentEnum) => void;
    selectedContent: HistoryContentEnum;
}

type ButtonsConfig = {
    label: string;
    icon: string;
};

const buttonsConfig: Record<HistoryContentEnum, ButtonsConfig> = {
    [HistoryContentEnum.BATTERY_HISTORY]: {
        label: 'e4c.history.content.batteryHistory',
        icon: 'rioglyph-battery-level-low',
    },
    [HistoryContentEnum.CONSUMPTION_HISTORY]: {
        label: 'e4c.history.content.consumptionHistory',
        icon: 'rioglyph-speed',
    },
};

const processTrackingActionHistoryTab = (tab: HistoryContentEnum) =>
    tab === HistoryContentEnum.BATTERY_HISTORY
        ? TrackingAction.SWITCH_TO_BATTERY_HISTORY
        : TrackingAction.SWITCH_TO_CONSUMPTION_HISTORY;

export const LeftContent = ({
    changeSelectedRangeDate,
    defaultEndValue,
    defaultStartValue,
    handleSelectedContentChange,
    selectedContent,
}: LeftContentProps) => {
    const handleClickTab = (tab: HistoryContentEnum) => {
        gtmUtil.pushGaEvent(
            createGoogleAnalyticsEvent(TrackingCategory.HISTORY_TAB, processTrackingActionHistoryTab(selectedContent))
        );
        handleSelectedContentChange(tab);
    };

    return (
        <div data-testid="toolbar-left-content" className="table-toolbar-group-left">
            <div className="table-toolbar-column">
                <div className="btn-group">
                    {Object.entries(buttonsConfig).map(([key, value]) => (
                        <Button
                            key={key}
                            active={selectedContent === (key as HistoryContentEnum)}
                            onClick={() => handleClickTab(key as HistoryContentEnum)}
                        >
                            <span className={`rioglyph ${value.icon}`} />
                            <FormattedMessage id={value.label} />
                        </Button>
                    ))}
                </div>
            </div>
            <div className="min-width-350 margin-left-10">
                <DateRangePicker
                    defaultStartValue={defaultStartValue}
                    defaultEndValue={defaultEndValue}
                    changeSelectedRangeDate={changeSelectedRangeDate}
                />
            </div>
        </div>
    );
};
