import { HistoryContentEnum, HistoryContentProps } from '../types';
import { NothingSelectedState } from '../NothingSelectedState';
import {
    getBatteryColumnConfig,
    renderBatteryColumnValue,
    renderRows as renderBatteryRows,
} from './batteryHistoryTable/BatteryHistoryTableColumnHelper';
import {
    getConsumptionColumnConfig,
    renderConsumptionColumnValue,
    renderRows as renderConsumptionRows,
} from './consumptionHistoryTable/ConsumptionHistoryTableHelper';
import HistoryTable from './HistoryTable';
import ErrorState from '../../../common/components/states/ErrorState';
import { FormattedMessage } from 'react-intl';

export const HistoryContent = ({
    assets,
    historySortBy,
    historySortDir,
    chargingProcesses,
    handleHistoryTableSortChange,
    selectedAssetIds,
    selectedHistoryContent,
}: HistoryContentProps): JSX.Element => {
    if (selectedAssetIds.length === 0) {
        return <NothingSelectedState verticalAlign={'80'} />;
    } else {
        if (chargingProcesses.size() === 0) {
            return (
                <ErrorState
                    stateProps={{
                        type: 'notfound',
                        message: <FormattedMessage id={'e4c.history.notFoundState.message'} />,
                    }}
                    verticalAlign={'80'}
                />
            );
        }
        switch (selectedHistoryContent) {
            case HistoryContentEnum.BATTERY_HISTORY:
                return (
                    <HistoryTable
                        assets={assets}
                        columnsDetails={getBatteryColumnConfig()}
                        handleTableSortChange={handleHistoryTableSortChange}
                        renderColumnValue={renderBatteryColumnValue}
                        renderRows={renderBatteryRows}
                        rows={chargingProcesses.getChargingProcessBy(selectedAssetIds)}
                        sortBy={historySortBy}
                        sortDir={historySortDir}
                    />
                );
            case HistoryContentEnum.CONSUMPTION_HISTORY:
                return (
                    <HistoryTable
                        assets={assets}
                        columnsDetails={getConsumptionColumnConfig()}
                        handleTableSortChange={handleHistoryTableSortChange}
                        renderColumnValue={renderConsumptionColumnValue}
                        renderRows={renderConsumptionRows}
                        rows={chargingProcesses.getChargingProcessBy(selectedAssetIds)}
                        sortBy={historySortBy}
                        sortDir={historySortDir}
                    />
                );
        }
    }
};
