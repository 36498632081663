import { NO_DATA_INFO } from '../../../types';
import { roundValue } from '../utils';

type StateOfChargeProps = {
    warningColor?: string;
    stateOfCharge: number | null;
};

const StateOfCharge = ({ warningColor, stateOfCharge }: StateOfChargeProps): JSX.Element => {
    const noDataElement = <div className={'text-color-lighter'}>{NO_DATA_INFO}</div>;
    const isNotValidStateOfCharge = stateOfCharge === null || stateOfCharge === undefined;
    const displayValue = (): string => (stateOfCharge || stateOfCharge === 0 ? `${roundValue(stateOfCharge)}%` : '');

    return <span className={warningColor}>{isNotValidStateOfCharge ? noDataElement : displayValue()}</span>;
};

export default StateOfCharge;
