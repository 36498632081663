import { useContext } from 'react';
import { useIntl } from 'react-intl';
import TimerSetupForm from './TimerSetupForm/TimerSetupForm';
import { VehicleDetailsContext } from '../../../../../../../../providers/contexts';
import TimerListView from './TimerListView';
import CreateTimerButtons from './CreateTimerButtons';

const TimerCharging = ({ assetId }: { assetId: string }) => {
    const intl = useIntl();
    const { isReadOnly, showSetupForm, unsavedTimers } = useContext(VehicleDetailsContext);

    const hasNoTimers = !unsavedTimers?.length;

    return (
        <>
            {hasNoTimers && (
                <>
                    <div className="display-flex">
                        <span className="rioglyph rioglyph-time text-color-light text-size-h1" />
                        <div className="display-flex flex-column margin-left-20">
                            <span className="text-medium">
                                {intl.formatMessage({ id: 'e4c.vehicle.details.timerCharging.noTimers.title' })}
                            </span>
                            {!isReadOnly && (
                                <span className="text-color-dark">
                                    {intl.formatMessage({
                                        id: showSetupForm
                                            ? 'e4c.vehicle.details.timerCharging.noTimers.description.form'
                                            : 'e4c.vehicle.details.timerCharging.noTimers.description',
                                    })}
                                </span>
                            )}
                        </div>
                    </div>
                    {!isReadOnly && !showSetupForm && <CreateTimerButtons />}
                </>
            )}
            {showSetupForm && <TimerSetupForm assetId={assetId} />}
            {!hasNoTimers && !showSetupForm && <TimerListView />}
        </>
    );
};

export default TimerCharging;
