import { ThunkDispatch } from 'redux-thunk';
import { VehicleDetailsState } from '../../reducers/details/types';
import { VehicleDetailsPropertiesFromDispatch, VehicleDetailsPropertiesFromState } from './types';
import { AccessToken, getAccessToken, getLocale } from '../../../../../configuration';
import {
    cleanState,
    fetchVehicleDetails,
    onAddDayToTimer,
    onClimateModeChange,
    onConfirmConfigurationChange,
    onConfirmSwitchToImmediateCharging,
    onDepartureTimeChange,
    onReadyToDriveDurationChange,
    onRemoveDayFromTimer,
    onShowConfigurationChangeConfirmDialog,
    onShowSwitchToImmediateChargingDialog,
    onTargetStateOfChargeChange,
    onTimerEnabledChange,
    sendImmediateConfiguration,
    sendTimerConfiguration,
    toggleSendingChargingConfigSucceeded,
    vehicleChargingModeChanged,
    vehicleTimerConfigurationTimerAdded,
    vehicleTimerConfigurationTimerRemoved,
} from '../../actions/details/VehicleDetails.actions';
import { State } from '../../../../../types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
    getChargingConfigurations,
    getFetchingDetails,
    getIsConfigurationChangeDialogConfirmed,
    getIsSwitchToImmediateChargingConfirmed,
    getSelectedVehicle,
    getSelectedVehicleBeforeChanges,
    getSendingChargingConfigFailed,
    getSendingChargingConfigSucceeded,
    getShowConfigurationChangeConfirmDialog,
    getShowSwitchToImmediateChargingDialog,
    getShowUnsavedChangesConfirmDialog,
    getTimerConfiguration,
    getTimerConfigurationBeforeChanges,
    getUserAction,
    getVehicleDetailsErrorCode,
} from '../../selectors/details/VehicleDetails.selectors';
import VehicleDetails from '../../components/details/vehicleDetails/VehicleDetails';
import { ChargingModeEnum } from '../../types';
import { Timer, TimerConfiguration, Weekday } from '../../components/details/vehicleDetails/types';
import { injectIntl } from 'react-intl';
import { fetchElectricVehicles } from '../../../actions/vehicles/Vehicles.actions';
import { getElectricVehicleFromServices } from '../../selectors/selectors';
import ElectricVehicle from '../../models/ElectricVehicle';
import {
    CleanStateAction,
    ClimateModeChangedAction,
    ConfigurationChangeConfirmedAction,
    DayFromTimerRemovedAction,
    DayToTimerAddedAction,
    ReadyToDriveDurationChangedAction,
    ShowConfigurationChangeConfirmDialogAction,
    ShowSwitchToImmediateChargingDialogAction,
    SwitchToImmediateChargingConfirmedAction,
    TargetStateOfChargeChangedAction,
    TimerDepartureTimeChangedAction,
    TimerEnabledChangedAction,
    ToggleSendingChargingConfigSucceededAction,
    VehicleChargingModeChangedAction,
    VehicleDetailsThunkAction,
    VehicleTimerConfigurationTimerAddedAction,
    VehicleTimerConfigurationTimerRemovedAction,
} from '../../actions/details/types';
import { VehicleThunkAction } from '../../../actions/vehicles/types';

const mapDispatchToProps = (
    dispatch: ThunkDispatch<VehicleDetailsState, undefined, any>
): VehicleDetailsPropertiesFromDispatch => {
    return {
        fetchVehicleDetails: (
            accessToken: AccessToken,
            assetId: string,
            vehicle: ElectricVehicle
        ): VehicleDetailsThunkAction<void> => dispatch(fetchVehicleDetails(accessToken, assetId, vehicle)),
        onChargingModeChange: (chargingMode: ChargingModeEnum): VehicleChargingModeChangedAction =>
            dispatch(vehicleChargingModeChanged(chargingMode)),
        onImmediateChargingSent: (
            accessToken: AccessToken,
            assetId: string,
            climateMode: boolean,
            targetStateOfCharge: number
        ): VehicleDetailsThunkAction<void> =>
            dispatch(sendImmediateConfiguration(accessToken, assetId, climateMode, targetStateOfCharge)),
        onAddTimer: (timer: Timer): VehicleTimerConfigurationTimerAddedAction =>
            dispatch(vehicleTimerConfigurationTimerAdded(timer)),
        onRemoveTimer: (timerNo: number): VehicleTimerConfigurationTimerRemovedAction =>
            dispatch(vehicleTimerConfigurationTimerRemoved(timerNo)),
        onTimerChargingSent: (
            accessToken: AccessToken,
            assetId: string,
            timerConfiguration: TimerConfiguration
        ): VehicleDetailsThunkAction<void> =>
            dispatch(sendTimerConfiguration(accessToken, assetId, timerConfiguration)),
        onAddDayToTimer: (day: Weekday, timerNo: number): DayToTimerAddedAction =>
            dispatch(onAddDayToTimer(day, timerNo)),
        onRemoveDayFromTimer: (day: Weekday, timerNo: number): DayFromTimerRemovedAction =>
            dispatch(onRemoveDayFromTimer(day, timerNo)),
        onDepartureTimeChange: (departureTime: string, timerNo: number): TimerDepartureTimeChangedAction =>
            dispatch(onDepartureTimeChange(departureTime, timerNo)),
        onTimerEnabledChange: (timerNo: number): TimerEnabledChangedAction => dispatch(onTimerEnabledChange(timerNo)),
        onConfirmConfigurationChange: (): ConfigurationChangeConfirmedAction =>
            dispatch(onConfirmConfigurationChange()),
        onShowDepartureTimeConfirmDialog: (show: boolean): ShowConfigurationChangeConfirmDialogAction =>
            dispatch(onShowConfigurationChangeConfirmDialog(show)),
        onShowSwitchToImmediateChargingDialog: (show: boolean): ShowSwitchToImmediateChargingDialogAction =>
            dispatch(onShowSwitchToImmediateChargingDialog(show)),
        onConfirmSwitchToImmediateCharging: (confirmed: boolean): SwitchToImmediateChargingConfirmedAction =>
            dispatch(onConfirmSwitchToImmediateCharging(confirmed)),
        onClimateModeChange: (climateMode: boolean): ClimateModeChangedAction =>
            dispatch(onClimateModeChange(climateMode)),
        onTargetStateOfChargeChange: (targetStateOfCharge: number): TargetStateOfChargeChangedAction =>
            dispatch(onTargetStateOfChargeChange(targetStateOfCharge)),
        toggleSendingChargingConfigSucceeded: (
            sendingChargingConfigSucceeded: boolean
        ): ToggleSendingChargingConfigSucceededAction =>
            dispatch(toggleSendingChargingConfigSucceeded(sendingChargingConfigSucceeded)),
        fetchElectricVehicles: (accessToken: AccessToken): VehicleThunkAction<void> =>
            dispatch(fetchElectricVehicles(accessToken)),
        onReadyToDriveDurationChange: (readyToDriveDuration: number): ReadyToDriveDurationChangedAction =>
            dispatch(onReadyToDriveDurationChange(readyToDriveDuration)),
        cleanState: (): CleanStateAction => dispatch(cleanState()),
    };
};

const mapStateToProps = (state: State): VehicleDetailsPropertiesFromState => {
    return {
        accessToken: getAccessToken(state),
        chargingConfigurations: getChargingConfigurations(state),
        errorCode: getVehicleDetailsErrorCode(state),
        fetchingDetails: getFetchingDetails(state),
        isConfigurationChangeDialogConfirmed: getIsConfigurationChangeDialogConfirmed(state),
        isSwitchToImmediateChargingConfirmed: getIsSwitchToImmediateChargingConfirmed(state),
        locale: getLocale(state),
        selectedVehicle: getSelectedVehicle(state),
        selectedVehicleBeforeChanges: getSelectedVehicleBeforeChanges(state),
        sendingChargingConfigFailed: getSendingChargingConfigFailed(state),
        sendingChargingConfigSucceeded: getSendingChargingConfigSucceeded(state),
        showDepartureTimeConfirmDialog: getShowConfigurationChangeConfirmDialog(state),
        showSwitchToImmediateChargingDialog: getShowSwitchToImmediateChargingDialog(state),
        showUnsavedChangesConfirmDialog: getShowUnsavedChangesConfirmDialog(state),
        timerConfiguration: getTimerConfiguration(state),
        timerConfigurationBeforeChanges: getTimerConfigurationBeforeChanges(state),
        userAction: getUserAction(state),
        vehicle: getElectricVehicleFromServices(state),
    };
};

const VehicleDetailsContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(VehicleDetails)));

export default VehicleDetailsContainer;
