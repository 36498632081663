import { FormattedMessage } from 'react-intl';
import { CustomTooltip } from '../../../components/shared/CustomTooltip';
import { HistoryTableHeaderItemProps } from '../types';
import { getTranslationDataField } from './SortableHistoryTableHeaderItem';

const HistoryTableHeaderItem = (props: HistoryTableHeaderItemProps): JSX.Element => {
    const { column, label, tooltip, width } = props;
    const optionalWidthClassName: string = width ? `width-${width}-md` : '';
    const optionalTooltip: JSX.Element | null = tooltip ? (
        <CustomTooltip messageId={tooltip} iconClassName={'text-color-darkest cursor-pointer'} />
    ) : null;
    return (
        <>
            {column === 'chevron' ? (
                <th className={'table-action'} key={column} />
            ) : (
                <FormattedMessage key={column} id={label}>
                    {(translation): JSX.Element => (
                        <th
                            key={column}
                            className={`user-select-none ${optionalWidthClassName}`}
                            data-field={getTranslationDataField(translation)}
                            data-sortby={column}
                            data-track-ga-event-label={column}
                            data-track-ga-event-category={'performance, table'}
                        >
                            <div className="display-flex">
                                <span className={'ellipsis-1 display-block'}>{translation}</span>
                                {optionalTooltip}
                            </div>
                        </th>
                    )}
                </FormattedMessage>
            )}
        </>
    );
};

export default HistoryTableHeaderItem;
