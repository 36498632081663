import { FormattedMessage, useIntl } from 'react-intl';
import detailVehicleStatusTableModal from '../../../../../assets/images/images-details-vehicle-status/detail-vehicle-status-table-modal.png';
import noDataImage from '../../../../../assets/images/images-details-vehicle-status/NODATA.jpeg';
import notAvailableImage from '../../../../../assets/images/images-details-vehicle-status/notavailable.png';
import unknownImage from '../../../../../assets/images/images-details-vehicle-status/unknown.jpeg';
import notReadyImage from '../../../../../assets/images/images-details-vehicle-status/notready.jpeg';
import readyToDriveImage from '../../../../../assets/images/images-details-vehicle-status/readytodrive.jpeg';
import { useEffect } from 'react';
import {
    createGoogleAnalyticsEvent,
    gtmUtil,
    TrackingAction,
    TrackingCategory,
} from '../../../../../utils/googleTagManager';
import UserManualPage from '../components/UserManualPage';
import UserManualOptions from '../components/UserManualOptions';

const VehicleStatusItem = (): JSX.Element => {
    const intl = useIntl();
    useEffect(() => {
        gtmUtil.pushGaEvent(
            createGoogleAnalyticsEvent(TrackingCategory.GENERAL, TrackingAction.USER_MANUAL_VEHICLE_STATUS)
        );
    }, []);

    const manualOptions = [
        {
            image: (
                <img
                    alt={intl.formatMessage({ id: 'e4c.userManual.altText.vehicleStatus.noData' })}
                    src={noDataImage}
                    width="125"
                    height="50"
                />
            ),
            boldText: <FormattedMessage id={'e4c.userManual.details.vehicleStatus.noData'} />,
            description: <FormattedMessage id={'e4c.userManual.details.vehicleStatus.noDataDescription'} />,
        },
        {
            image: (
                <img
                    alt={intl.formatMessage({ id: 'e4c.userManual.altText.vehicleStatus.notAvailable' })}
                    src={notAvailableImage}
                    width="125"
                    height="50"
                />
            ),
            boldText: <FormattedMessage id={'e4c.userManual.details.vehicleStatus.notAvailable'} />,
            description: <FormattedMessage id={'e4c.userManual.details.vehicleStatus.notAvailableDescription'} />,
        },
        {
            image: (
                <img
                    alt={intl.formatMessage({ id: 'e4c.userManual.altText.vehicleStatus.unknown' })}
                    src={unknownImage}
                    width="125"
                    height="50"
                />
            ),
            boldText: <FormattedMessage id={'e4c.userManual.details.vehicleStatus.unknown'} />,
            description: <FormattedMessage id={'e4c.userManual.details.vehicleStatus.unknownDescription'} />,
        },
        {
            image: (
                <img
                    alt={intl.formatMessage({ id: 'e4c.userManual.altText.vehicleStatus.notReady' })}
                    src={notReadyImage}
                    width="125"
                    height="50"
                />
            ),
            boldText: <FormattedMessage id={'e4c.userManual.details.vehicleStatus.notReady'} />,
            description: <FormattedMessage id={'e4c.userManual.details.vehicleStatus.notReadyDescription'} />,
        },
        {
            image: (
                <img
                    alt={intl.formatMessage({ id: 'e4c.userManual.altText.vehicleStatus.readyToDrive' })}
                    src={readyToDriveImage}
                    width="125"
                    height="50"
                />
            ),
            boldText: <FormattedMessage id={'e4c.userManual.details.vehicleStatus.readyToDrive'} />,
            description: <FormattedMessage id={'e4c.userManual.details.vehicleStatus.readyToDriveDescription'} />,
        },
    ];

    return (
        <UserManualPage
            title={<FormattedMessage id={'e4c.userManual.details.vehicleStatus.title'} />}
            footer={<FormattedMessage id={'e4c.userManual.overview.etgeObs'} />}
            dataTestId="vehicle-status-item"
        >
            <img
                alt={intl.formatMessage({ id: 'e4c.userManual.altText.vehicleStatus' })}
                src={detailVehicleStatusTableModal}
                width="651"
                height="261"
            />
            <>
                {manualOptions.map((option, index) => (
                    <UserManualOptions
                        key={index}
                        image={option.image}
                        boldText={option.boldText}
                        description={option.description}
                    />
                ))}
            </>
        </UserManualPage>
    );
};

export default VehicleStatusItem;
