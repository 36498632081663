import AbstractElectricVehiclesSorter from './AbstractElectricVehiclesSorter';
import SortDirection from '../../common/utilities/sort/SortDirection';
import orderBy from 'lodash/orderBy';
import { SORT_KEY_LAST_UPDATED } from './keys';
import { VehicleChargingData } from '../models/VehicleChargingData';

class LastUpdatedSorter extends AbstractElectricVehiclesSorter {
    constructor() {
        super(SORT_KEY_LAST_UPDATED);
    }

    sort = (array: VehicleChargingData[], direction: SortDirection): VehicleChargingData[] =>
        orderBy<VehicleChargingData>(array, e => e.measurementsLastUpdated, direction);
}

export default LastUpdatedSorter;
