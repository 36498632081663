import { ChargeCycleActivityType } from '../types';
import moment from 'moment';

type Statistics = { avg: number; max: number; min: number };

const floorNullableValue = (value: number | null) => {
    return value ? Math.floor(value) : null;
};

class ChargingProcess {
    private _assetId: string;
    private _assetName: string;
    private _duration: number | null;
    private _endTime: string | null;
    private _energyAddedKwh: number | null;
    private _energyLevelKwhAtStartTime: number | null;
    private _remainingRangeKmAtStartTime: number | null;
    private _startTime: string;
    private _startDate: string;
    private _startDateTime: string;
    private _stateOfCharge: number | null;
    private _totalEnergyConsumptionKwh: number | null;
    readonly powertrainEnergyConsumptionKwh: number | null;
    readonly powertrainEnergyConsumptionPercentage: number | null;
    private _type: ChargeCycleActivityType;
    readonly auxEnergyConsumptionKwh: number | null;
    readonly auxEnergyConsumptionPercentage: number | null;
    readonly amsEnergyConsumptionKwh: number | null;
    readonly amsEnergyConsumptionPercentage: number | null;
    readonly temperatures: Statistics | null = null;
    readonly batteryVoltage: Statistics | null = null;
    readonly batteryCurrents: Omit<Statistics, 'min'> | null = null;
    readonly maxBatteryTemperatureWarningC: number | null;
    readonly minBatteryTemperatureWarningC: number | null;
    readonly mileage: number | null = null;
    constructor(
        asset_id: string,
        average_battery_current_amps: number | null,
        average_battery_voltage_volts: number | null,
        duration: number | null,
        end_time: string | null,
        energy_added_kwh: number | null,
        energy_level_kwh_at_start_time: number | null,
        max_battery_current_amps: number | null,
        max_battery_voltage_volts: number | null,
        min_battery_voltage_volts: number | null,
        remaining_range_km_at_start_time: number | null,
        start_time: string,
        state_of_charge_percentage_at_start_time: number | null,
        type: ChargeCycleActivityType,
        powertrain_energy_consumption_kwh: number | null,
        powertrain_energy_consumption_percentage: number | null,
        aux_energy_consumption_kwh: number | null,
        aux_energy_consumption_percentage: number | null,
        ams_energy_consumption_kwh: number | null,
        ams_energy_consumption_percentage: number | null,
        average_ambient_temperature_celsius: number | null,
        minimum_ambient_temperature_celsius: number | null,
        maximum_ambient_temperature_celsius: number | null,
        max_battery_temperature_warning_c: number | null,
        min_battery_temperature_warning_c: number | null,
        total_energy_consumption_kwh: number | null,
        driven_distance_km: number | null
    ) {
        this._assetId = asset_id;
        this._assetName = '';
        this._duration = duration;
        this._endTime = end_time;
        this._energyAddedKwh = energy_added_kwh;
        this._energyLevelKwhAtStartTime = energy_level_kwh_at_start_time;
        this._remainingRangeKmAtStartTime = remaining_range_km_at_start_time;
        this._startTime = start_time;
        this._startDate = moment(new Date(start_time)).format('DD/MM/YYYY');
        this._startDateTime = moment(new Date(start_time)).format('YYYY-DD-MM HH:mm:ss');
        this._stateOfCharge = state_of_charge_percentage_at_start_time;
        this._totalEnergyConsumptionKwh = total_energy_consumption_kwh;
        this._type = type;
        this.powertrainEnergyConsumptionKwh = powertrain_energy_consumption_kwh;
        this.powertrainEnergyConsumptionPercentage = powertrain_energy_consumption_percentage;
        this.auxEnergyConsumptionKwh = aux_energy_consumption_kwh;
        this.auxEnergyConsumptionPercentage = aux_energy_consumption_percentage;
        this.amsEnergyConsumptionKwh = ams_energy_consumption_kwh;
        this.amsEnergyConsumptionPercentage = ams_energy_consumption_percentage;
        if (
            average_ambient_temperature_celsius &&
            minimum_ambient_temperature_celsius &&
            maximum_ambient_temperature_celsius
        ) {
            this.temperatures = {
                avg: average_ambient_temperature_celsius,
                min: minimum_ambient_temperature_celsius,
                max: maximum_ambient_temperature_celsius,
            };
        }
        if (average_battery_voltage_volts && max_battery_voltage_volts && min_battery_voltage_volts) {
            this.batteryVoltage = {
                avg: average_battery_voltage_volts,
                min: min_battery_voltage_volts,
                max: max_battery_voltage_volts,
            };
        }
        if (average_battery_current_amps && max_battery_current_amps) {
            this.batteryCurrents = {
                avg: average_battery_current_amps,
                max: max_battery_current_amps,
            };
        }
        this.maxBatteryTemperatureWarningC = max_battery_temperature_warning_c;
        this.minBatteryTemperatureWarningC = min_battery_temperature_warning_c;

        this.mileage = driven_distance_km;
    }

    public get assetId(): string {
        return this._assetId;
    }

    public set assetId(value: string) {
        this._assetId = value;
    }

    public get assetName(): string {
        return this._assetName;
    }

    public set assetName(value: string) {
        this._assetName = value;
    }

    public get energyAddedKwh(): number | null {
        return floorNullableValue(this._energyAddedKwh);
    }

    public get type(): ChargeCycleActivityType {
        return this._type;
    }

    public set type(value: ChargeCycleActivityType) {
        this._type = value;
    }

    public get startTime(): string {
        return this._startTime;
    }

    public set startTime(value: string) {
        this._startTime = value;
    }

    public get startDate(): string {
        return this._startDate;
    }

    public set startDate(value: string) {
        this._startDate = value;
    }

    public get startDateTime(): string {
        return this._startDateTime;
    }

    public set startDateTime(value: string) {
        this._startDateTime = value;
    }

    public get endTime(): string | null {
        return this._endTime;
    }

    public set endTime(value: string | null) {
        this._endTime = value;
    }

    public get duration(): number | null {
        return this._duration;
    }

    public set duration(value: number | null) {
        this._duration = value;
    }

    public get remainingRangeKmAtStartTime(): number | null {
        return this._remainingRangeKmAtStartTime;
    }

    public get stateOfCharge(): number | null {
        return this._stateOfCharge;
    }

    public set stateOfCharge(value: number | null) {
        this._stateOfCharge = value;
    }

    public get energyLevelKwhAtStartTime(): number | null {
        return this._energyLevelKwhAtStartTime;
    }

    public get totalEnergyConsumptionKwh(): number | null {
        return floorNullableValue(this._totalEnergyConsumptionKwh);
    }

    public set totalEnergyConsumptionKwh(value: number | null) {
        this._totalEnergyConsumptionKwh = value;
    }
}

export default ChargingProcess;
