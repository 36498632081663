import { Weekday } from '../../../vehicleCharging/components/details/vehicleDetails/ChargingMode/vehicleChargingConfigurationTypes';

export const validWeekDays: Weekday[] = [
    Weekday.MONDAY,
    Weekday.TUESDAY,
    Weekday.WEDNESDAY,
    Weekday.THURSDAY,
    Weekday.FRIDAY,
    Weekday.SATURDAY,
    Weekday.SUNDAY,
];

const ShortWeekdayMap: Record<Weekday, string> = {
    [Weekday.MONDAY]: 'e4c.vehicles.details.timerSettings.monday',
    [Weekday.TUESDAY]: 'e4c.vehicles.details.timerSettings.tuesday',
    [Weekday.WEDNESDAY]: 'e4c.vehicles.details.timerSettings.wednesday',
    [Weekday.THURSDAY]: 'e4c.vehicles.details.timerSettings.thursday',
    [Weekday.FRIDAY]: 'e4c.vehicles.details.timerSettings.friday',
    [Weekday.SATURDAY]: 'e4c.vehicles.details.timerSettings.saturday',
    [Weekday.SUNDAY]: 'e4c.vehicles.details.timerSettings.sunday',
};

export const mapShortWeekdayToIntl = (weekday: Weekday): string => ShortWeekdayMap[weekday];

const WeekdayMap: Record<Weekday, string> = {
    [Weekday.MONDAY]: 'e4c.global.weekday.monday',
    [Weekday.TUESDAY]: 'e4c.global.weekday.tuesday',
    [Weekday.WEDNESDAY]: 'e4c.global.weekday.wednesday',
    [Weekday.THURSDAY]: 'e4c.global.weekday.thursday',
    [Weekday.FRIDAY]: 'e4c.global.weekday.friday',
    [Weekday.SATURDAY]: 'e4c.global.weekday.saturday',
    [Weekday.SUNDAY]: 'e4c.global.weekday.sunday',
};

export const mapWeekdayToIntl = (weekday: Weekday): string => WeekdayMap[weekday];
