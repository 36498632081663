import { SortDirection } from '../../../vehicleCharging/types';
import { HistoryTableColumnDetails, ColumnLabels } from '../types';
import SortableHistoryTableHeaderItem from './SortableHistoryTableHeaderItem';
import HistoryTableHeaderItem from './HistoryTableHeaderItem';

const renderHistoryTableHeader = (
    columns: string[],
    labels: ColumnLabels | { [x: string]: string | undefined },
    sortables: ColumnLabels | { [x: string]: boolean | undefined },
    widths: ColumnLabels | { [x: string]: number | undefined },
    tooltips: { [key: string]: HistoryTableColumnDetails },
    sortDir: SortDirection,
    sortBy: string,
    handleSortChange: (e: React.MouseEvent) => void
): JSX.Element => {
    return (
        <>
            <colgroup>
                {columns.map(column => {
                    const className = column === 'chevron' ? 'table-action' : '';
                    const style = column === 'vehicle' ? 'min-width-150 width-150' : '';
                    return <col key={column} className={`${className}${style}`} />;
                })}
            </colgroup>
            <thead>
                <tr>
                    {columns.map((column: string | keyof ColumnLabels) => {
                        const keyValue: string | keyof ColumnLabels = column;
                        const label = labels[keyValue];
                        const width = widths[column];
                        const tooltip = tooltips[column].tooltip;
                        const sortable = sortables[column];
                        if (sortable) {
                            return (
                                <SortableHistoryTableHeaderItem
                                    key={keyValue}
                                    column={column}
                                    handleSortChange={handleSortChange}
                                    label={label}
                                    sortBy={sortBy}
                                    sortDir={sortDir}
                                    tooltip={tooltip}
                                    width={width}
                                />
                            );
                        } else {
                            return (
                                <HistoryTableHeaderItem
                                    key={keyValue}
                                    column={column}
                                    label={label}
                                    tooltip={tooltip}
                                    width={width}
                                />
                            );
                        }
                    })}
                </tr>
            </thead>
        </>
    );
};

export default renderHistoryTableHeader;
