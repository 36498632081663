import { areWeTestingWithVitest } from './RuntimeHelpers';
import * as jstz from 'jstimezonedetect';

export const determineTimezone = (): string => {
    const tz = jstz.determine();
    return Intl.DateTimeFormat().resolvedOptions().timeZone
        ? Intl.DateTimeFormat().resolvedOptions().timeZone
        : tz.name();
};

export const currentTimezone = areWeTestingWithVitest() ? 'Europe/Berlin' : determineTimezone();
