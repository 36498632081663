import { VehicleStatistics } from '../../types';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { State } from '../../../../../types';

export const VEHICLES_STATISTICS_LOADING_STARTED = 'VEHICLES_STATISTICS_LOADING_STARTED';
export const VEHICLES_STATISTICS_LOADING_SUCCESSFUL = 'VEHICLES_STATISTICS_LOADING_SUCCESSFUL';
export const VEHICLES_STATISTICS_LOADING_FAILED = 'VEHICLES_STATISTICS_LOADING_FAILED';

export interface VehiclesStatisticsLoadingStartedAction {
    type: typeof VEHICLES_STATISTICS_LOADING_STARTED;
}

export interface VehiclesStatisticsLoadingSuccessfulAction {
    type: typeof VEHICLES_STATISTICS_LOADING_SUCCESSFUL;
    payload: VehicleStatistics[];
}

export interface VehiclesStatisticsLoadingFailedAction {
    type: typeof VEHICLES_STATISTICS_LOADING_FAILED;
}

export type VehiclesStatisticsActions =
    | VehiclesStatisticsLoadingStartedAction
    | VehiclesStatisticsLoadingSuccessfulAction
    | VehiclesStatisticsLoadingFailedAction;

export type VehiclesStatisticsThunkAction<R> = ThunkAction<R, State, undefined, VehiclesStatisticsActions>;
export type VehiclesStatisticsThunkDispatch = ThunkDispatch<State, undefined, VehiclesStatisticsActions>;
