import _ from 'lodash';
import moment from 'moment';
import { VehicleStatistics } from '../../types';
import {
    arrayOfDatesInRange,
    arrayOfMonthsInRange,
    divideIfPositive,
    drivenDistanceThreshold,
    isCurrentMonthAndYear,
    overallAverageFromAveragePerDay,
} from './StatisticsHelpers';
import { AvgRecuperationChartData } from './types';

const buildAggregatedRecuperationObject = (
    maxNumberOfVehicles: number,
    month: string,
    numberOfDays: number,
    totalAverageRecuperation: number
): AvgRecuperationChartData => {
    return {
        date: month,
        averageRecuperation: divideIfPositive(totalAverageRecuperation, totalAverageRecuperation, numberOfDays),
        vehiclesQuantity: maxNumberOfVehicles,
    };
};

const averageRecuperationDaily = (vehiclesStatistics: VehicleStatistics[], date: string) => {
    return vehiclesStatistics.reduce(
        (acc, vehicle) => {
            const vehicleData = vehicle.history.find(data => data.date === date);
            if (
                vehicleData &&
                vehicleData.recuperatedEnergy &&
                vehicleData.recuperatedEnergy > 0 &&
                vehicleData.drivenDistance &&
                vehicleData.drivenDistance > drivenDistanceThreshold
            ) {
                acc.totalRecuperatedEnergy += vehicleData.recuperatedEnergy;
                acc.totalDrivenDistance += vehicleData.drivenDistance;
                acc.numberOfVehicles++;
            }

            return acc;
        },
        {
            totalDrivenDistance: 0,
            totalRecuperatedEnergy: 0,
            numberOfVehicles: 0,
        }
    );
};

export const aggregatedAverageRecuperationDaily = (
    vehiclesStatistics: VehicleStatistics[],
    startDate: Date,
    endDate: Date
): AvgRecuperationChartData[] => {
    const datesInRange = arrayOfDatesInRange(startDate, endDate);
    return datesInRange.reduce((acc: AvgRecuperationChartData[], date) => {
        const { totalDrivenDistance, totalRecuperatedEnergy, numberOfVehicles } = averageRecuperationDaily(
            vehiclesStatistics,
            date
        );

        acc.push({
            date,
            averageRecuperation: divideIfPositive(totalDrivenDistance, totalRecuperatedEnergy, totalDrivenDistance),
            vehiclesQuantity: numberOfVehicles,
        });

        return acc;
    }, []);
};

export const aggregatedAverageRecuperationMonthly = (
    vehiclesStatistics: VehicleStatistics[],
    startDate: Date,
    endDate: Date
): AvgRecuperationChartData[] => {
    const aggregatedAverage: AvgRecuperationChartData[] = [];
    const valuesCalculatedPerDayFromStartDateToEndDate = aggregatedAverageRecuperationDaily(
        vehiclesStatistics,
        startDate,
        endDate
    );
    const months = arrayOfMonthsInRange(startDate, endDate);
    let totalAverageRecuperationPerMonth = 0;
    let totalAverageRecuperation = 0;
    let numberOfDays = 0;
    let numberOfDaysInMonth = 0;
    let maxNumberOfVehiclesInMonth = 0;
    months.forEach(month => {
        const monthDate = moment(month);
        valuesCalculatedPerDayFromStartDateToEndDate
            .filter(object => isCurrentMonthAndYear(monthDate, moment(object.date)))
            .forEach(value => {
                if (value.averageRecuperation > 0) {
                    totalAverageRecuperation += value.averageRecuperation;
                    totalAverageRecuperationPerMonth += value.averageRecuperation;
                    numberOfDaysInMonth++;
                    numberOfDays++;
                }
                if (value.vehiclesQuantity > maxNumberOfVehiclesInMonth) {
                    maxNumberOfVehiclesInMonth = value.vehiclesQuantity;
                }
            });
        aggregatedAverage.push(
            buildAggregatedRecuperationObject(
                maxNumberOfVehiclesInMonth,
                month,
                numberOfDaysInMonth,
                totalAverageRecuperationPerMonth
            )
        );
        totalAverageRecuperationPerMonth = 0;
        numberOfDaysInMonth = 0;
        maxNumberOfVehiclesInMonth = 0;
    });
    aggregatedAverage.push(
        buildAggregatedRecuperationObject(maxNumberOfVehiclesInMonth, 'total', numberOfDays, totalAverageRecuperation)
    );
    return aggregatedAverage;
};

export const getAverageRecuperationSummaryValue = (data: AvgRecuperationChartData[]): number => {
    const totalFromRecuperationMonths = data.find(rec => rec.date === 'total')?.averageRecuperation;
    let overallRecuperationAverage = 0;
    if (totalFromRecuperationMonths) {
        _.remove(data, rec => rec.date === 'total');
        overallRecuperationAverage = totalFromRecuperationMonths;
    } else {
        overallRecuperationAverage = overallAverageFromAveragePerDay(data.map(item => item.averageRecuperation));
    }
    return overallRecuperationAverage;
};
