import KpiBarChart from './KpiBarChart';
import { StatisticsData } from '../statistics/types';
import { FormattedMessage } from 'react-intl';
import BarTooltip from './BarTooltip';
import isEmpty from 'lodash/fp/isEmpty';

interface AverageMileageBarChartProps {
    statistics: StatisticsData;
}

const AverageMileageBarChart = (props: AverageMileageBarChartProps): JSX.Element => (
    <div className="padding-bottom-20 height-250">
        <KpiBarChart
            aggregationPeriodType={props.statistics.aggregationPeriodType}
            data={props.statistics.data}
            barSettings={[
                {
                    dataKey: 'averageMileage',
                    color: 'color-highlight',
                },
            ]}
            onAxisLeftFormat={(value): string => Number(value).toFixed(0)}
            tooltip={({ active, payload }): JSX.Element => {
                if (!active || isEmpty(payload)) {
                    return <></>;
                }
                const data = payload[0].payload;
                return (
                    <BarTooltip
                        aggregationPeriodType={props.statistics.aggregationPeriodType}
                        date={data.date}
                        vehiclesQuantity={data.vehiclesQuantity}
                        value={data.averageMileage}
                        units={
                            <>
                                km/
                                <FormattedMessage id={'e4c.overview.charts.unit.vehicle'} />
                            </>
                        }
                    />
                );
            }}
        />
    </div>
);

export default AverageMileageBarChart;
