interface KpiChartProps {
    chartClass: string;
    innerChart: JSX.Element;
    summaryUnit: JSX.Element | string;
    summaryValue: number;
    testId: string;
}

/**
 * Probably chartClass property can be removed and replaced by id, since its used only for testing purposes
 */
const KpiChart = (props: KpiChartProps): JSX.Element => (
    <div className={props.chartClass}>
        <div className="display-block margin-top-25 margin-bottom-15">
            <span className="text-size-30 padding-right-5" data-testid={props.testId}>
                {props.summaryValue}
            </span>
            <span className="text-size-14">{props.summaryUnit}</span>
        </div>
        <div className={'height-250 width-100pct'}>{props.innerChart}</div>
    </div>
);

export default KpiChart;
