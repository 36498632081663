import Select, { SelectOption } from '@rio-cloud/rio-uikit/Select';
import { FormattedMessage } from 'react-intl';
import { CustomTooltip } from '../../../../components/shared/CustomTooltip';

export enum ReadyToDriveDurationRange {
    THIRTY_MINUTES = 30,
    SIXTY_MINUTES = 60, // default
    NINETY_MINUTES = 90,
    HUNDRED_TWENTY_MINUTES = 120,
    HUNDRED_EIGHTY_MINUTES = 180,
}

export const DEFAULT_READY_TO_DRIVE_DURATION_VALUE = 60;

export const readyToDriveDurationOptions = [
    {
        id: ReadyToDriveDurationRange.THIRTY_MINUTES.toString(),
        label: <FormattedMessage id="e4c.vehicles.details.preview.thirtyMinutes" />,
    },
    {
        id: ReadyToDriveDurationRange.SIXTY_MINUTES.toString(),
        label: <FormattedMessage id="e4c.vehicles.details.preview.sixtyMinutes" />,
    },
    {
        id: ReadyToDriveDurationRange.NINETY_MINUTES.toString(),
        label: <FormattedMessage id="e4c.vehicles.details.preview.ninetyMinutes" />,
    },
    {
        id: ReadyToDriveDurationRange.HUNDRED_TWENTY_MINUTES.toString(),
        label: <FormattedMessage id="e4c.vehicles.details.preview.hundredTwentyMinutes" />,
    },
    {
        id: ReadyToDriveDurationRange.HUNDRED_EIGHTY_MINUTES.toString(),
        label: <FormattedMessage id="e4c.vehicles.details.preview.hundredEightyMinutes" />,
    },
];

export interface ReadyToDriveDurationProps {
    selectedDurationRange: number;
    onSelectReadyToDriveDurationRange: (timeRange: number) => void;
    isEnabled: boolean;
    toggleUnsavedVehicleChanges: (unsavedChanges: boolean) => void;
}

const ReadyToDriveDuration = (props: ReadyToDriveDurationProps): JSX.Element => {
    const { selectedDurationRange, isEnabled } = props;

    const onReadyToDriveDurationChange = (targetReadyToDriveDuration: number): void => {
        props.toggleUnsavedVehicleChanges(true);
        props.onSelectReadyToDriveDurationRange(targetReadyToDriveDuration);
    };

    return (
        <div className="ready-to-drive-time-container margin-top-10">
            <span className="align-sub">
                <span className="rioglyph rioglyph-ok-sign text-size-16" />
                <span className="text-color-highlight text-bold padding-left-5">
                    <FormattedMessage id="e4c.vehicles.details.readyToDriveTime" />
                </span>
                <CustomTooltip
                    messageId={'e4c.vehicles.details.readyToDriveTime.tooltip'}
                    iconClassName={'text-color-info'}
                />
            </span>
            <div className="pull-right">
                <Select
                    id={'readyToDriveTimeDropdown'}
                    value={[selectedDurationRange.toString()]}
                    onChange={(readyToDriveDurationOption?: SelectOption): void =>
                        onReadyToDriveDurationChange(Number(readyToDriveDurationOption?.id))
                    }
                    className="form-control padding-0 border-none display-inline-block width-172"
                    options={readyToDriveDurationOptions}
                    disabled={!isEnabled}
                />
            </div>
        </div>
    );
};

export default ReadyToDriveDuration;
