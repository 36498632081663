import { FormattedMessage } from 'react-intl';
import { KpiEnum, VehicleStatistics, MultiselectOptionsType } from '../types';
import KpiChart from '../kpiCharts/KpiChart';
import { useEffect, useState } from 'react';
import { Moment } from 'moment';
import { StatisticsData } from '../kpiCharts/statistics/types';
import AverageMileageBarChart from '../kpiCharts/barCharts/AverageMileageBarChart';
import AverageRecuperationBarChart from '../kpiCharts/barCharts/AverageRecuperationBarChart';
import AverageConsumptionBarChart from '../kpiCharts/barCharts/AverageConsumptionBarChart';
import StatisticsBuilderFactory from '../kpiCharts/statistics/StatisticsBuilderFactory';

interface BarChartProps {
    currentEndDate: Moment;
    currentStartDate: Moment;
    initiallySelectedKpi: KpiEnum;
    vehiclesStatistics: VehicleStatistics[];
    selectedVehicleIds: string[] | MultiselectOptionsType;
    selectedKpi: KpiEnum;
}

const BarChart = (props: BarChartProps): JSX.Element => {
    const { currentEndDate, currentStartDate, selectedVehicleIds, selectedKpi, vehiclesStatistics } = props;

    const [selectedStatistics, setSelectedStatistics] = useState<VehicleStatistics[]>([]);

    useEffect(() => {
        if (selectedVehicleIds.includes(MultiselectOptionsType.ALL_OPTIONS)) {
            setSelectedStatistics(vehiclesStatistics);
        } else {
            setSelectedStatistics(vehiclesStatistics.filter(vehicle => selectedVehicleIds.includes(vehicle.vehicleId)));
        }
    }, [selectedVehicleIds, vehiclesStatistics]);

    const getStatisticsData = (): StatisticsData => {
        return new StatisticsBuilderFactory(currentStartDate.toDate(), currentEndDate.toDate(), selectedStatistics)
            .getBuilder(selectedKpi)
            .build();
    };

    let statistics;

    switch (selectedKpi) {
        case KpiEnum.AV_MILEAGE:
            statistics = getStatisticsData();
            return (
                <KpiChart
                    chartClass={'avgMileageChart'}
                    innerChart={<AverageMileageBarChart statistics={statistics} />}
                    summaryUnit={
                        <>
                            km/
                            <FormattedMessage id={'e4c.overview.charts.unit.vehicle'} />
                        </>
                    }
                    summaryValue={statistics.summaryValue}
                    testId={''}
                />
            );

        case KpiEnum.AV_RECUPERATION:
            statistics = getStatisticsData();
            return (
                <KpiChart
                    chartClass={'avgRecuperationChart'}
                    innerChart={<AverageRecuperationBarChart statistics={statistics} />}
                    summaryUnit={'kWh/km'}
                    summaryValue={statistics.summaryValue}
                    testId={'overall'}
                />
            );

        default:
            statistics = getStatisticsData();
            return (
                <KpiChart
                    chartClass={'avgDrivingConsumptionChart'}
                    innerChart={<AverageConsumptionBarChart statistics={statistics} />}
                    summaryUnit={'kWh/km'}
                    summaryValue={statistics.summaryValue}
                    testId={'overall'}
                />
            );
    }
};

export default BarChart;
