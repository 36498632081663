import { MonitoringServiceResponse } from '../models/api/monitoringServiceResponse';
import { StateServiceResponse } from '../models/api/stateServiceResponse';
import { VehicleChargingData } from '../models/VehicleChargingData';
import { mapEnumVehicleModel, mapEnumVehicleType, roundStateOfCharge, roundTemperature } from './rulesVehicleCharging';

const mappingVehicleChargingFromServer = (
    monitorData?: MonitoringServiceResponse,
    stateData?: StateServiceResponse
): VehicleChargingData[] => {
    if (!monitorData || !stateData) {
        return [];
    }

    return monitorData.assets?.map(monitor => {
        const stateToBeMapped = stateData.items.find(state => state.asset_id === monitor.asset_id);
        return {
            assetId: monitor.asset_id,
            productVersion: monitor.product_version,
            hasMinimalDatenset: monitor.has_minimaldatenset,
            assetInfo: {
                modifiedAt: monitor.asset_info?.modified_at,
                name: monitor.asset_info?.name ? monitor.asset_info?.name : stateToBeMapped?.name || '',
                type: mapEnumVehicleType(monitor.asset_info?.type),
                vehicleModel: mapEnumVehicleModel(monitor.asset_info?.vehicle_model),
                vin: monitor.asset_info?.vin ? monitor.asset_info?.vin : stateToBeMapped?.vin || '',
            },
            measurements: {
                chargingState: {
                    measuredAt: monitor.measurements.charging_state?.measured_at,
                    value: monitor.measurements.charging_state?.value ?? stateToBeMapped?.charging_status,
                },
                drivingState: {
                    measuredAt: monitor.measurements.driving_state?.measured_at,
                    value: monitor.measurements.driving_state?.value,
                },
                energyLevel: {
                    measuredAt: monitor.measurements.energy_level?.measured_at,
                    value: monitor.measurements.energy_level?.value,
                },
                remainingRange: {
                    measuredAt: monitor.measurements.remaining_range?.measured_at,
                    value: monitor.measurements?.remaining_range?.value ?? stateToBeMapped?.remaining_range,
                },
                stateOfCharge: {
                    measuredAt: monitor.measurements.state_of_charge?.measured_at,
                    value: roundStateOfCharge(
                        monitor.measurements?.state_of_charge?.value ?? stateToBeMapped?.state_of_charge
                    ),
                },
                ambientTemperature: {
                    measuredAt: monitor.measurements.ambient_temperature?.measured_at,
                    value: roundTemperature(monitor.measurements?.ambient_temperature?.value),
                },
                insideTemperature: {
                    measuredAt: monitor.measurements.inside_temperature?.measured_at,
                    value: roundTemperature(monitor.measurements.inside_temperature?.value),
                },
            },
            measurementsLastUpdated: monitor.measurements_last_updated,
            chargingConfiguration: {
                chargingMode: stateToBeMapped?.charging_configuration.charging_mode,
                climateMode: stateToBeMapped?.charging_configuration.climate_mode,
                departureDate: stateToBeMapped?.charging_configuration.departure_date,
                status: stateToBeMapped?.charging_configuration.status,
                targetStateOfCharge: stateToBeMapped?.charging_configuration.target_state_of_charge,
            },
            readyToDriveStatus: stateToBeMapped?.ready_to_drive_status,
            readyToDriveUntil: stateToBeMapped?.ready_to_drive_until,
            chargingUntil: stateToBeMapped?.charging_until,
            delayedChargingUntil: stateToBeMapped?.delayed_charging_until,
            disconnectedAt: stateToBeMapped?.disconnected_at,
        };
    });
};

export default mappingVehicleChargingFromServer;
