import VehicleType from '../../common/vehicles/VehicleType';
import VehicleModel from './enums/VehicleModel';

class AssetInfo {
    private readonly _modifiedAt: string;
    private readonly _name: string;
    private readonly _type: VehicleType;
    private readonly _vin: string;
    private readonly _vehicleModel: VehicleModel;

    constructor(modifiedAt: string, vin: string, name: string, type: VehicleType, vehicleModel: VehicleModel) {
        this._modifiedAt = modifiedAt;
        this._vin = vin;
        this._name = name;
        this._type = type;
        this._vehicleModel = vehicleModel;
    }

    public get modifiedAt(): string {
        return this._modifiedAt;
    }

    public get vin(): string {
        return this._vin;
    }

    public get name(): string {
        return this._name;
    }

    public get type(): VehicleType {
        return this._type;
    }

    public get vehicleModel(): VehicleModel {
        return this._vehicleModel;
    }
}

export default AssetInfo;
