import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { mapShortWeekdayToIntl, validWeekDays } from '../../../../../../../common/enums/intl/weekdays';
import { Weekday } from '../../vehicleChargingConfigurationTypes';

interface WeekdayButtonsProps {
    weekdays: Weekday[];
    setWeekdays: (weekdays: Weekday[]) => void;
    isRepeatTimer: boolean;
}

export const WeekdayButtons = ({ weekdays, setWeekdays, isRepeatTimer }: WeekdayButtonsProps) => {
    const handleWeekdayClick = (weekday: Weekday) =>
        isRepeatTimer
            ? setWeekdays(weekdays.includes(weekday) ? weekdays.filter(w => w !== weekday) : [...weekdays, weekday])
            : setWeekdays([weekday]);

    return (
        <div className="btn-group width-70pct">
            {validWeekDays.map(weekday => (
                <button
                    type="button"
                    className={classNames('btn btn-default', { active: weekdays.includes(weekday) })}
                    key={weekday}
                    onClick={() => handleWeekdayClick(weekday)}
                >
                    <FormattedMessage id={mapShortWeekdayToIntl(weekday)} />
                </button>
            ))}
        </div>
    );
};
