import { FormattedMessage, FormattedNumber, IntlShape, useIntl } from 'react-intl';
import TooltipWrapperList from '../../../../../common/components/Tooltip/TooltipWrapperList';
import { roundValue } from '../utils';
import NoDataElement from './NoDataElement';
import WithUnit from './WithUnit';

type Voltages = {
    avg: number;
    min: number;
    max: number;
} | null;

const roundNumber = (n?: number) => {
    return n ? `${roundValue(n)} V` : '';
};

const buildTooltip = (intl: IntlShape, voltages: Voltages): string => {
    return `${intl.formatMessage({ id: 'e4c.history.avg' })}: ${roundNumber(voltages?.avg)}
    ${intl.formatMessage({ id: 'e4c.history.min' })}: ${roundNumber(voltages?.min)}
    ${intl.formatMessage({ id: 'e4c.history.max' })}: ${roundNumber(voltages?.max)}`;
};

const BatteryVoltage = (props: { voltages: Voltages }): JSX.Element => {
    const { voltages } = props;
    const intl = useIntl();

    const tooltipContent = buildTooltip(intl, voltages);

    if (!voltages) {
        return <NoDataElement />;
    }

    const voltagesValues = Object.entries(voltages).map(([key, value]) => ({
        label: <FormattedMessage id={`e4c.history.${key}`} />,
        value: (
            <WithUnit unit="V" dataTestId={key}>
                <FormattedNumber value={value} maximumFractionDigits={0} />
            </WithUnit>
        ),
    }));

    return <TooltipWrapperList dataTestId="battery-voltages" tooltipContent={tooltipContent} values={voltagesValues} />;
};

export default BatteryVoltage;
