import { useState } from 'react';
import VehiclesBodyTable from './VehiclesBodyTable';
import VehiclesHeaderTable from './VehiclesHeaderTable';
import { tableClassNames } from '../../helpers/table/VehiclesTableCssHelper';
import Pagination, { PaginationOptions } from '../../../common/components/pagination/Pagination';
import { useSelector } from 'react-redux';
import { State } from '../../../../../types';
import SkeletonVehicleBodyTable from '../../../vehicleCharging/components/Skeleton/SkeletonVehicleBodyTable';
import { getSortDir, getSorter, getVehiclesTableViewType } from '../../../selectors/vehicles/Vehicles.selectors';
import { VehicleChargingData } from '../../models/VehicleChargingData';
import ErrorState from '../../../common/components/states/ErrorState';
import { FormattedMessage } from 'react-intl';

const PAGINATION_OPTIONS = [20, 40, 60];
const PAGINATION_DEFAULT_PAGE_SIZE = PAGINATION_OPTIONS[0];
const PAGINATION_DEFAULT_PAGE = 1;

export interface VehiclesTableProperties {
    vehicles: VehicleChargingData[];
    isLoading: boolean;
    columnOrder: string[];
    hiddenColumns: string[];
}

const VehiclesTable = ({ columnOrder, hiddenColumns, vehicles, isLoading }: VehiclesTableProperties): JSX.Element => {
    const tableViewType = useSelector((state: State) => getVehiclesTableViewType(state));
    const sortDir = useSelector((state: State) => getSortDir(state));
    const sorter = useSelector((state: State) => getSorter(state));

    const [pagination, setPagination] = useState<PaginationOptions>({
        pageSize: PAGINATION_DEFAULT_PAGE_SIZE,
        pageNumber: 1,
    });

    if (!isLoading && !vehicles.length) {
        return (
            <ErrorState
                stateProps={{
                    type: 'notfound',
                    message: <FormattedMessage id={'e4c.vehicles.notFoundState.table.message'} />,
                }}
            />
        );
    }

    return (
        <div id="vehiclesTable">
            <div className="table-responsive">
                <table className={tableClassNames(tableViewType)}>
                    <VehiclesHeaderTable
                        columnOrder={columnOrder}
                        hiddenColumns={hiddenColumns}
                        sorter={sorter}
                        sortDir={sortDir}
                    />
                    {isLoading ? (
                        <SkeletonVehicleBodyTable />
                    ) : (
                        <VehiclesBodyTable
                            columnOrder={columnOrder}
                            hiddenColumns={hiddenColumns}
                            pagination={pagination}
                            sorter={sorter}
                            sortDir={sortDir}
                            tableViewType={tableViewType}
                            vehicles={vehicles}
                        />
                    )}
                </table>
            </div>
            {!isLoading && (
                <Pagination
                    defaultPage={PAGINATION_DEFAULT_PAGE}
                    defaultSize={PAGINATION_DEFAULT_PAGE_SIZE}
                    numberOfItems={vehicles.length}
                    onPaginationChanges={setPagination}
                    sizeOptions={PAGINATION_OPTIONS}
                />
            )}
        </div>
    );
};

export default VehiclesTable;
