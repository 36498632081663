import { useState } from 'react';
import { useIntl } from 'react-intl';
import moment, { Moment } from 'moment';
import DatePicker from '@rio-cloud/rio-uikit/DatePicker';
import './localeImports';
import { TimeFrameSelector } from './TimeFrameSelector';

interface DatePickerProps {
    defaultStartValue: Moment | undefined;
    defaultEndValue: Moment | undefined;
    changeSelectedRangeDate: (startValue: any, endValue: any) => void;
}

const DateRangePicker = ({ defaultStartValue, defaultEndValue, changeSelectedRangeDate }: DatePickerProps) => {
    const { locale, formatMessage } = useIntl();
    const [customDatesSelected, setCustomDatesSelected] = useState(false);

    const handleDateChange = (
        startValue: Moment | string | undefined,
        endValue: Moment | string | undefined,
        isCustom: boolean = true
    ) => {
        changeSelectedRangeDate(startValue, endValue);
        setCustomDatesSelected(isCustom);
    };

    const isValidDate = (date: Moment) =>
        date.isSameOrBefore(moment()) && date.isSameOrAfter(moment().subtract(12, 'months'));
    const isValidStartDate = (date: Moment) => date.isSameOrBefore(defaultEndValue) && isValidDate(date);
    const isValidEndDate = (date: Moment) => date.isSameOrAfter(defaultStartValue) && isValidDate(date);

    const getStartDatePicker = () => {
        const handleDate = (value: Moment | string | undefined) => handleDateChange(value, defaultEndValue);
        return getDatePicker(defaultStartValue, isValidStartDate, handleDate);
    };

    const getEndDatePicker = () => {
        const handleDate = (value: Moment | string | undefined) => handleDateChange(defaultStartValue, value);
        return getDatePicker(defaultEndValue, isValidEndDate, handleDate);
    };

    const getDatePicker = (
        date: Moment | undefined,
        validateDate: (date: Moment) => boolean,
        handleDate: (date: Moment | string | undefined) => void
    ) => {
        return (
            <DatePicker
                value={date}
                onChange={(value: any) => handleDate(value)}
                inputProps={{
                    placeholder: formatMessage({ id: 'e4c.global.dateRange.selectDate' }),
                    onKeyDown: event => event.preventDefault(),
                }}
                locale={locale}
                dateFormat="DD.MM.YYYY"
                timeFormat="HH:mm"
                isValidDate={validateDate}
            />
        );
    };

    return (
        <div className="display-flex gap-10 margin-bottom-10">
            <TimeFrameSelector
                changeDates={(start: Moment, end: Moment) => handleDateChange(start, end, false)}
                customDatesSelected={customDatesSelected}
            />
            <div className="flex-1-1-0" data-testid="date-range-picker-start">
                {getStartDatePicker()}
            </div>
            <div className="flex-1-1-0" data-testid="date-range-picker-end">
                {getEndDatePicker()}
            </div>
        </div>
    );
};

export default DateRangePicker;
