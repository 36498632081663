import { ReactElement } from 'react';
import { TooltipCellWrapper } from '../TooltipCellWrapper';
import NoData from '../NoData';

export const EnergyAndRangeCell = ({
    value,
    unit,
    textColor,
}: {
    value?: number;
    unit: string;
    textColor: string;
}): ReactElement => {
    return value !== undefined ? (
        <div className={`text-medium ${textColor}`}>
            <TooltipCellWrapper content={`${Math.round(value)} ${unit}`} />
        </div>
    ) : (
        <NoData hasTooltip={false} />
    );
};
