import {
    ELECTRIC_VEHICLES_MONITORING_LOADING_FAILED,
    ELECTRIC_VEHICLES_MONITORING_LOADING_STARTED,
    ELECTRIC_VEHICLES_MONITORING_LOADING_SUCCESSFUL,
    ELECTRIC_VEHICLES_STATE_LOADING_FAILED,
    ELECTRIC_VEHICLES_STATE_LOADING_STARTED,
    ELECTRIC_VEHICLES_STATE_LOADING_SUCCESSFUL,
    ElectricVehiclesActions,
} from './actions';
import { mapMonitoringJsonToElectricVehicleArray } from './mappers/monitoringMappers';
import { mapStateJsonToElectricVehicleArray } from './mappers/stateMappers';
import initialState, { ElectricVehiclesState } from './store';
import { mergeVehicles, mergeWithMonitoringData, mergeWithStateData } from './mergers';
import FleetType from './models/enums/FleetType';
import VehicleType from '../common/vehicles/VehicleType';

const reducer = (
    state: ElectricVehiclesState = initialState,
    action: ElectricVehiclesActions
): ElectricVehiclesState => {
    switch (action.type) {
        case ELECTRIC_VEHICLES_MONITORING_LOADING_STARTED:
            return {
                ...state,
                isFetchingMonitoringElectricVehicles: true,
            };

        case ELECTRIC_VEHICLES_MONITORING_LOADING_SUCCESSFUL: {
            const monitoringElectricVehicles = mapMonitoringJsonToElectricVehicleArray(action.assets);
            const hasMinimalDatenset = monitoringElectricVehicles.filter(v => v.hasMinimalDatenset === true);
            const fleetType = getFleetType(action.account);
            return {
                ...state,
                isFetchingMonitoringElectricVehicles: false,
                hasMinimalDatenset: hasMinimalDatenset.length > 0,
                electricVehicles: mergeVehicles(
                    state.electricVehicles,
                    monitoringElectricVehicles,
                    mergeWithMonitoringData
                ),
                fleetType,
            };
        }
        case ELECTRIC_VEHICLES_MONITORING_LOADING_FAILED:
            return {
                ...state,
                isFetchingMonitoringElectricVehicles: false,
            };

        case ELECTRIC_VEHICLES_STATE_LOADING_STARTED:
            return {
                ...state,
                isFetchingStateElectricVehicles: true,
            };

        case ELECTRIC_VEHICLES_STATE_LOADING_SUCCESSFUL: {
            const stateElectricVehicles = mapStateJsonToElectricVehicleArray(action.items);
            return {
                ...state,
                isFetchingStateElectricVehicles: false,
                electricVehicles: mergeVehicles(state.electricVehicles, stateElectricVehicles, mergeWithStateData),
            };
        }
        case ELECTRIC_VEHICLES_STATE_LOADING_FAILED:
            return {
                ...state,
                isFetchingStateElectricVehicles: false,
            };

        default:
            return state;
    }
};

const getFleetType = (account: any): FleetType => {
    const assetTypes: string[] = account.asset_types;
    if (assetTypes !== undefined && assetTypes.length === 1) {
        if (assetTypes.includes(VehicleType.VAN.toString())) {
            return FleetType.ETGE_ONLY;
        } else if (assetTypes.includes(VehicleType.BUS.toString())) {
            return FleetType.E4C_ONLY;
        }
    }
    return FleetType.MIXED;
};

export default reducer;
