import { VehiclesHistoryState, VehiclesHistoryActions } from './types';
import { HistoryContentEnum } from './components/types';
import { LOCATION_CHANGE } from 'connected-react-router';
import initialState from './store';
import {
    VEHICLE_CHARGING_PROCESSES_LOADING_STARTED,
    VEHICLE_CHARGING_PROCESSES_LOADING_SUCCESSFUL,
    VEHICLE_CHARGING_PROCESSES_LOADING_FAILED,
    HISTORY_ASSETS_SELECTED,
    HISTORY_TABLE_SORT_CHANGED,
    SELECTED_HISTORY_CONTENT_CHANGED,
} from './actions';

const reducer = (state: VehiclesHistoryState = initialState, action: VehiclesHistoryActions): VehiclesHistoryState => {
    switch (action.type) {
        case LOCATION_CHANGE:
            return {
                ...state,
                selectedHistoryContent: HistoryContentEnum.BATTERY_HISTORY,
            };
        case VEHICLE_CHARGING_PROCESSES_LOADING_STARTED:
            return {
                ...state,
                chargingProcesses: [],
                fetchingChargingProcesses: true,
                chargingProcessesErrorCode: null,
            };
        case VEHICLE_CHARGING_PROCESSES_LOADING_SUCCESSFUL:
            return {
                ...state,
                chargingProcesses: action.payload,
                fetchingChargingProcesses: false,
                chargingProcessesErrorCode: null,
            };
        case VEHICLE_CHARGING_PROCESSES_LOADING_FAILED:
            return {
                ...state,
                chargingProcesses: [],
                fetchingChargingProcesses: false,
                chargingProcessesErrorCode: action.payload,
            };
        case HISTORY_ASSETS_SELECTED:
            return {
                ...state,
                selectedHistoryAssetIds: action.payload,
            };
        case HISTORY_TABLE_SORT_CHANGED:
            return {
                ...state,
                historySortDir: action.payload.sortDir,
                historySortBy: action.payload.sortBy,
            };
        case SELECTED_HISTORY_CONTENT_CHANGED:
            return {
                ...state,
                selectedHistoryContent: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;
