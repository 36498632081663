import { Moment } from 'moment';
import { useInfiniteQuery, useQuery } from 'react-query';
import { AccessToken } from '../../../../../configuration';
import { fetchChargeCycles, fetchChargeStats } from '../../../../../api/history';
import mappingChargeStatsFromServer from '../helpers/mappingChargeStatsFromServer';
import { AssetsChargeStatsResponseApi } from '../chargeStatsResponse';
import { mappingChargeCyclesFromServer } from '../helpers/mappingChargeCyclesFromServer';

const getDigitsStringFromNumber = (value: number, size: number) => {
    return value.toString().padStart(size, '0');
};

/**
 * Get date in Date Time format with timezone offset,
 * yyyy-MM-dd'T'HH:mm:ss.SSSXXX — for example, "2000-10-31T01:30:00.000-05:00".
 * @param date to transform
 */
export const getDateWithTimezoneOffset = (date: Date) => {
    const year = date.getFullYear();
    const month = getDigitsStringFromNumber(date.getMonth() + 1, 2);
    const day = getDigitsStringFromNumber(date.getDate(), 2);
    const hours = getDigitsStringFromNumber(date.getHours(), 2);
    const minutes = getDigitsStringFromNumber(date.getMinutes(), 2);
    const seconds = getDigitsStringFromNumber(date.getSeconds(), 2);
    const milliseconds = getDigitsStringFromNumber(date.getMilliseconds(), 3);
    const timezoneOffset = new Date().getTimezoneOffset() * -1;
    const timezoneOffsetHours = timezoneOffset / 60;
    const timezoneOffsetAbsHours = getDigitsStringFromNumber(Math.floor(Math.abs(timezoneOffsetHours)), 2);
    const timezoneOffsetRelative = timezoneOffset < 0 ? '-' : encodeURIComponent('+');
    const timezoneOffsetMinutes = getDigitsStringFromNumber(Math.abs(timezoneOffset % 60), 2);
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${timezoneOffsetRelative}${timezoneOffsetAbsHours}:${timezoneOffsetMinutes}`;
};

export const useGetChargeStats = (
    accessToken: AccessToken,
    assetIds: string[],
    startDate?: Moment,
    endDate?: Moment,
    fetchDisabled?: boolean
) => {
    const enabled = !fetchDisabled && startDate !== undefined && endDate !== undefined;
    const startDateWithTimezoneOffset = startDate ? getDateWithTimezoneOffset(startDate.toDate()) : '';
    const endDateWithTimezoneOffset = endDate ? getDateWithTimezoneOffset(endDate.toDate()) : '';
    const { isLoading, isError, isSuccess, data, hasNextPage, fetchNextPage } = useInfiniteQuery(
        ['history-charge-stats', startDateWithTimezoneOffset, endDateWithTimezoneOffset, assetIds],
        ({ pageParam }) =>
            fetchChargeStats(
                accessToken,
                startDateWithTimezoneOffset,
                endDateWithTimezoneOffset,
                assetIds,
                pageParam
            ).then(res => res.json()),
        {
            getNextPageParam: lastPage => lastPage?.cursor?.next,
            enabled,
        }
    );

    const result = data ? mappingChargeStatsFromServer(data?.pages as AssetsChargeStatsResponseApi[]) : [];
    const hasPages = !!hasNextPage || !!(data?.pages && data.pages.length > 1);

    return { isLoading, isError, isSuccess, result, hasNextPage: !!hasNextPage, fetchNextPage, hasPages };
};

export const useGetChargeCycles = (accessToken: AccessToken, assetId: string, startDate: string, endDate: string) => {
    const { isLoading, isError, isSuccess, data } = useQuery(
        ['history-charge-cycles', assetId, startDate, endDate],
        () => fetchChargeCycles(accessToken, assetId, startDate, endDate).then(res => res.json())
    );

    const result = data ? mappingChargeCyclesFromServer(data) : [];

    return { isLoading, isError, isSuccess, result };
};
