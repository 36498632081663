import { Vehicle, ChargingModeEnum, ChargingStatusEnum, ChargingConfigurationStatusEnum } from '../../../types';
import { AccessToken } from '../../../../../../configuration';
import {
    VehicleDetailsPropertiesFromState,
    VehicleDetailsPropertiesFromDispatch,
} from '../../../containers/details/types';
import { RouteComponentProps } from 'react-router';
import { WrappedComponentProps } from 'react-intl';
import ElectricVehicle from '../../../models/ElectricVehicle';
import { VehicleChargingData } from '../../../models/VehicleChargingData';

export interface VehicleDetails {
    vehicle: Vehicle | null;
    selectedVehicleBeforeChanges: Vehicle | null;
    timerConfiguration: TimerConfiguration | undefined;
    timerConfigurationBeforeChanges: TimerConfiguration | undefined;
    chargingConfigurations: ChargingConfiguration[];
    userAction: UserAction | null;
}

export interface VehicleDetailsOwnProperties {
    onClose: () => void;
    toggleConfirmDialog: (opened: boolean) => void;
    toggleUnsavedVehicleChanges: (unsavedChanges: boolean) => void;
    vehicleObjectFromTable: VehicleChargingData;
}

export type VehicleDetailsProperties = VehicleDetailsPropertiesFromState &
    VehicleDetailsPropertiesFromDispatch &
    RouteComponentProps &
    VehicleDetailsOwnProperties;

export interface VehicleDetailsProps extends VehicleDetailsProperties, RouteComponentProps, WrappedComponentProps {}

// API objects from JSON response START:
/* eslint-disable camelcase */

export interface UserAction {
    post_immediate_configurations: ActionLink | null;
    post_timer_configurations: ActionLink | null;
}

export interface ChargingConfiguration {
    asset_id: string;
    status: ChargingConfigurationStatusEnum;
    charging_mode: ChargingModeEnum;
    departure_time: string;
    departure_date: string;
    climate_mode: boolean;
}

export interface ActionLink {
    href: string;
}

export interface TimerConfiguration {
    climate_mode: boolean;
    target_state_of_charge: number;
    zone_id: string;
    timers: Timer[];
    ready_to_drive_duration: number;
}

export interface Timer {
    weekdays: Weekday[];
    departure_time: string;
    enabled: boolean;
    zone_id: string;
}

export enum Weekday {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY',
}

/* eslint-enable camelcase */
// API objects from JSON response END

export interface ChargingConfigurationProperties {
    timerConfiguration: TimerConfiguration | undefined;
    chargingConfigurations: ChargingConfiguration[];
    accessToken: AccessToken;
    chargingMode: ChargingModeEnum | null;
    climateMode: boolean;
    targetStateOfCharge: number;
    locale: string;
    isReadOnlyUser: boolean;
    onChargingModeChange: (chargingMode: ChargingModeEnum) => void;
    onAddTimer: (timer: Timer) => void;
    onRemoveTimer: (timerNo: number) => void;
    onAddDayToTimer: (day: Weekday, timerNo: number) => void;
    onRemoveDayFromTimer: (day: Weekday, timerNo: number) => void;
    onDepartureTimeChange: (departureTime: string, timerNo: number) => void;
    onTimerEnabledChange: (timerNo: number) => void;
    onClimateModeChange: (climateMode: boolean) => void;
    onTargetStateOfChargeChange: (targetStateOfCharge: number) => void;
    onReadyToDriveDurationChange: (readyToDriveDuration: number) => void;
    toggleUnsavedVehicleChanges: (unsavedChanges: boolean) => void;
}

export interface FooterProperties {
    accessToken: AccessToken;
    assetId: string;
    chargingMode: ChargingModeEnum;
    chargingStatus: ChargingStatusEnum;
    onImmediateChargingSave: (
        accessToken: AccessToken,
        assetId: string,
        climateMode: boolean,
        targetStateOfCharge: number
    ) => void;
    timerConfiguration: TimerConfiguration | undefined;
    onTimerChargingSave: (accessToken: AccessToken, assetId: string, timerConfiguration: TimerConfiguration) => void;
    onConfirmConfigurationChange: () => void;
    showConfigurationChangeConfirmDialog: boolean;
    onShowConfigurationChangeConfirmDialog: (show: boolean) => void;
    isConfigurationChangeDialogConfirmed: boolean;
    showSwitchToImmediateChargingDialog: boolean;
    isSwitchToImmediateChargingConfirmed: boolean;
    onConfirmSwitchToImmediateCharging: (confirmed: boolean) => void;
    onShowSwitchToImmediateChargingDialog: (show: boolean) => void;
    onChargingModeChange: (chargingMode: ChargingModeEnum) => void;
    climateMode: boolean;
    targetStateOfCharge: number;
    showUnsavedChangesConfirmDialog: boolean;
    onSidebarClose: () => void;
    toggleUnsavedVehicleChanges: (unsavedChanges: boolean) => void;
    saveButtonDisabled: boolean;
    toggleSendingChargingConfigSucceeded: (sendingChargingConfigSucceeded: boolean) => void;
    updateVehiclesTable: (accessToken: AccessToken) => void;
    sendingChargingConfigSucceeded: boolean;
    updateVehicleDetails: (accessToken: AccessToken, assetId: string, vehicle: ElectricVehicle) => void;
    vehicle: ElectricVehicle;
}

export interface DepartureTimesPreviewProps {
    timers: Timer[];
    locale: string;
    chargingConfigurations: ChargingConfiguration[];
}

export interface DepartureTime {
    day: Weekday;
    date: Date;
    time: string;
    status: ChargingConfigurationStatusEnum;
}

export class PercentageSelectOption {
    id: string;
    value: number;
    label: string;
    selected: boolean;

    constructor(id: string, value: number, selected: boolean) {
        this.id = id;
        this.value = value;
        this.label = `${value}%`;
        this.selected = selected;
    }
}
