import { LogLevel } from 'configcat-react';
import { ConfigState } from './types';

const getBoolEnvValue = (envValue: string): boolean => {
    return typeof import.meta.env[envValue] !== 'undefined' && import.meta.env[envValue] === 'true';
};

export const config: ConfigState = {
    title: import.meta.env.VITE_TITLE,
    backend: {
        MENU_SERVICE: import.meta.env.VITE_MENU_SERVICE,
        OTA_CHARGING_STATE_SERVICE: import.meta.env.VITE_OTA_CHARGING_STATE_BASE_URL,
        ASSET_ADMINISTRATION_SERVICE: import.meta.env.VITE_ASSET_ADMINISTRATION_SERVICE,
        TAG_SERVICE: import.meta.env.VITE_TAG_SERVICE,
        OTA_CHARGING_MONITORING_SERVICE: import.meta.env.VITE_OTA_CHARGING_MONITORING_BASE_URL,
        FEEDBACK_SERVICE: import.meta.env.VITE_FEEDBACK_SERVICE_URL,
        NOTIFICATIONS_SERVICE_URL: import.meta.env.VITE_NOTIFICATIONS_SERVICE_URL,
        DOWNLOAD_HISTORY_SERVICE: import.meta.env.VITE_DOWNLOAD_HISTORY_SERVICE,
        EXPORTS_HISTORY_SERVICE: import.meta.env.VITE_EXPORTS_HISTORY_SERVICE,
        EXPORTS_MINIMAL_DATASET_SERVICE: import.meta.env.VITE_EXPORTS_MINIMAL_DATASET_SERVICE,
    },
    homeRoute: import.meta.env.VITE_HOME_ROUTE,
    id: import.meta.env.VITE_ID,
    login: {
        authority: import.meta.env.VITE_LOGIN_AUTHORITY,
        // TODO: Request and supply your App's `client_id` as
        //       well as the needed OAuth scopes here
        clientId: 'd9fcd1f0-f5a1-4894-a37a-14b0e1f381f2',
        oauthScope: [
            'openid',
            'profile',
            'email',
            'ota-charging.read',
            'ota-charging.write',
            'asset-administration.read',
            'tags.read',
        ],
        mockAuthorization: getBoolEnvValue('VITE_LOGIN_MOCK_AUTHORIZATION'),
        mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
        preventRedirect: getBoolEnvValue('VITE_LOGIN_PREVENT_REDIRECT'),
        redirectUri: `${window.location.origin}/redirect.html`,
        silentRedirectUri: `${window.location.origin}/redirect.html`,
    },
    enableMockServer: getBoolEnvValue('VITE_ENABLE_MOCK_SERVER'),
    banner: {
        enable: getBoolEnvValue('VITE_BANNER_ENABLE'),
        message: import.meta.env.VITE_BANNER_MESSAGE,
    },
    feedbackQuestionnaire: import.meta.env.VITE_FEEDBACK_QUESTIONNAIRE,
    upsellUrl: import.meta.env.VITE_UPSELLING_RIO_URL,
    eManagerMLearnMoreUrl: import.meta.env.VITE_EMANAGER_M_LEARN_MORE_URL,
    minimalDatasetUserManualUrl: import.meta.env.VITE_MINIMAL_DATASET_USER_MANUAL_URL,
    configCat: {
        apiKey: import.meta.env.VITE_CONFIG_CAT_API_KEY ?? '',
        logLevel: import.meta.env.VITE_CONFIG_CAT_LOG_LEVEL === 'INFO' ? LogLevel.Info : LogLevel.Off,
        cacheTimeInSeconds: 600,
    },
};
