import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import * as _ from 'lodash';
import { State } from '../../../../../types';
import { useDispatch, useSelector } from 'react-redux';
import { vehiclesTableRowSelected } from '../../../actions/vehicles/Vehicles.actions';
import { getVehiclesSelectedRow } from '../../../selectors/vehicles/Vehicles.selectors';
import { VehicleChargingData } from '../../../vehicleCharging/models/VehicleChargingData';
import { getColumnConfig, renderColumnValue } from './columns/VehiclesColumn';

export interface VehicleRowTableProperties {
    columnOrder: string[];
    hiddenColumns: string[];
    sortedRows: VehicleChargingData[];
}

const VehiclesRowsTable = (props: VehicleRowTableProperties): JSX.Element => {
    const { columnOrder, hiddenColumns, sortedRows } = props;
    const { locale } = useIntl();
    const dispatch = useDispatch();
    const selectedRow = useSelector((state: State) => getVehiclesSelectedRow(state));
    const onVehicleSelect = (assetId: string) => dispatch(vehiclesTableRowSelected(assetId));

    const columnsDetails = getColumnConfig();
    let columns = _.keysIn(columnsDetails);
    const columnLabels = _.mapValues(columnsDetails, column => column.label);
    columns = columnOrder.filter((name: any) => !hiddenColumns.includes(name));

    const getRowClassNames = (vehicleId: string): string => {
        return classNames(selectedRow === vehicleId && 'active', 'cursor-pointer');
    };
    return (
        <>
            {sortedRows.map((row: VehicleChargingData) => (
                <tr
                    data-testid={'vehicle-table-row'}
                    key={row.assetId}
                    onClick={() => onVehicleSelect(row.assetId)}
                    className={getRowClassNames(row.assetId)}
                >
                    {columns.map((col, index) => (
                        <FormattedMessage key={index} id={columnLabels[col]}>
                            {(label): JSX.Element => (
                                <td key={col} data-field={label} className="padding-10">
                                    {renderColumnValue(col, row, locale)}
                                </td>
                            )}
                        </FormattedMessage>
                    ))}
                </tr>
            ))}
        </>
    );
};

export default VehiclesRowsTable;
