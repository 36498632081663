import SkeletonLoader from '../../common/components/Skeleton/SkeletonLoader';

export const SkeletonOverviewContentTeaser = (): JSX.Element => {
    return (
        <div className="teaser-content" data-testid="overview-content-skeleton">
            <p className="teaser-content-subtitle" style={{ height: '20px' }}>
                <SkeletonLoader>
                    <rect x="0" y="0" rx="0" ry="0" width="500" height="16" />
                </SkeletonLoader>
            </p>
            <section style={{ height: '65px', marginTop: '20px' }}>
                <SkeletonLoader>
                    <rect x="0" y="0" rx="0" ry="0" width="125" height="16" />
                    <rect x="0" y="26" rx="0" ry="0" width="125" height="16" />
                </SkeletonLoader>
            </section>
            <div className="width-200" style={{ height: '30px', marginBottom: '10px' }}>
                <SkeletonLoader>
                    <rect x="0" y="0" rx="0" ry="0" width="200" height="30" />
                </SkeletonLoader>
            </div>
        </div>
    );
};

export const SkeletonOverviewWelcomeTeaser = (): JSX.Element => {
    return (
        <div style={{ height: '44px', width: '270px', marginBottom: '20px' }}>
            <SkeletonLoader>
                <rect x="0" y="0" rx="0" ry="0" width="270" height="44" />
            </SkeletonLoader>
        </div>
    );
};
