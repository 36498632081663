import package_json from '../../../package.json';

import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

const version = () => {
    return `${package_json.version}-${import.meta.env.VITE_GIT_SHORT_HASH}`;
};

export const datadogInit: () => void = async () => {
    if ((window as any).Cypress) {
        return;
    }
    const config = await (await fetch('/env-config.json')).json();
    const hostConfig = config[window.location.hostname];
    if (!hostConfig?.datadog.enabled) {
        return;
    }
    console.info('Datadog RUM and logging is enabled');
    datadogRum.init({
        applicationId: '061c550e-eb93-46e2-81dd-4dc4fef8099b',
        clientToken: 'pubc38a7a1a3c9a4dfbee4fafea90730130',
        site: 'datadoghq.eu',
        service: 'electric-vehicle-web',
        env: hostConfig.datadog.env,
        version: version(),
        sessionSampleRate: hostConfig.datadog.sampleRate,
        trackUserInteractions: true,
        allowedTracingUrls: hostConfig.datadog.allowedTracingOrigins,
    });
    datadogRum.setGlobalContextProperty('team', 'man-electric-bus');

    datadogLogs.init({
        clientToken: 'pubc38a7a1a3c9a4dfbee4fafea90730130',
        site: 'datadoghq.eu',
        forwardErrorsToLogs: true,
        sessionSampleRate: hostConfig.datadog.sampleRate,
        version: version(),
        service: 'electric-vehicle-web',
        env: hostConfig.datadog.env,
    });
    datadogLogs.setGlobalContextProperty('team', 'man-electric-bus');
    datadogLogs.setGlobalContextProperty('team_identifier', 'man-team-electric-bus');
    datadogLogs.setGlobalContextProperty('account_id', '550229738824');
};

console.info('Starting logging for version', version());
