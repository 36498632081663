interface WidgetProps {
    title: string;
    children: React.ReactElement;
    link?: React.ReactNode;
    options?: React.ReactNode;
    className?: string;
}

const Widget: React.FC<WidgetProps> = ({ title, children, link, options, className }) => {
    const widgetStyle = 'padding-15 flex-column panel panel-default display-flex justify-content-between';
    return (
        <div className={className ? `${widgetStyle} ${className}` : widgetStyle}>
            <div className="display-flex padding-bottom-15 justify-content-between align-items-center">
                <h4 className="text-size-18 text-bold">{title}</h4>
                {options}
            </div>
            {children}
            {link && <div className="display-flex padding-top-25 justify-content-end">{link}</div>}
        </div>
    );
};

export default Widget;
