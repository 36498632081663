import React from 'react';

import ProgrammedChargingMode from './ProgrammedChargingMode';
import SendingConfiguration from './SendingConfiguration';
import TimerChargingMode from './TimerChargingMode';
import ImmediateChargingMode from './ImmediateChargingMode';
import ChargingConfigurationStatus from '../../../../models/enums/ChargingConfigurationStatus';
import ChargingMode from '../../../../models/enums/ChargingMode';
import VehicleModel from '../../../../models/enums/VehicleModel';
import { VehicleChargingData } from '../../../../models/VehicleChargingData';

interface ChargingModeColumnProps {
    assetInfo: VehicleChargingData['assetInfo'];
    chargingConfiguration?: VehicleChargingData['chargingConfiguration'];
}

export const ChargingModeColumn = ({ assetInfo, chargingConfiguration }: ChargingModeColumnProps): JSX.Element => {
    if (assetInfo.vehicleModel === VehicleModel.ETGE) {
        return <ProgrammedChargingMode />;
    }

    if (chargingConfiguration?.status === ChargingConfigurationStatus.SENDING) {
        return <SendingConfiguration />;
    }

    if (chargingConfiguration?.chargingMode === ChargingMode.TIMER) {
        return (
            <TimerChargingMode
                departureDate={chargingConfiguration.departureDate}
                isClimateControlEnabled={!!chargingConfiguration.climateMode}
            />
        );
    }

    return <ImmediateChargingMode isClimateControlEnabled={!!chargingConfiguration?.climateMode} />;
};

export const MemoChargingModeColumn = React.memo(ChargingModeColumn);
