import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { useIntl } from 'react-intl';
import { getModifiedAt } from '../../../../helpers/rulesVehicleCharging';
import ChargingStatus from '../../../../models/enums/ChargingStatus';
import { VehicleChargingData } from '../../../../models/VehicleChargingData';
import ChargingConnectorPlug from '../../../table/columns/ChargingStatusColumn/ChargingConnectorPlug';
import { ChargingDetails } from '../../../table/columns/ChargingStatusColumn/ChargingStatusColumn';
import {
    getChargingUntil,
    getStatusColorAndMessage,
} from '../../../table/columns/ChargingStatusColumn/ChargingStatusColumnHelper';
import ConnectorIcon from '../../../table/columns/ChargingStatusColumn/ConnectorIcon';

const Connector = ({
    chargingState,
    isPluggedOn,
}: {
    chargingState: ChargingStatus;
    isPluggedOn: boolean;
}): JSX.Element => {
    const pluggedClass = isPluggedOn ? ChargingConnectorPlug.PLUGGED_ON : ChargingConnectorPlug.PLUGGED_OFF;

    return (
        <div className="ellipsis-1">
            <ConnectorIcon color={getStatusColorAndMessage(chargingState).color} plug={pluggedClass} />
        </div>
    );
};

const AssetDetailsChargingState = ({ vehicleData }: { vehicleData: VehicleChargingData }): JSX.Element => {
    const intl = useIntl();
    const chargingState = vehicleData.measurements.chargingState.value || ChargingStatus.UNKNOWN;
    const isPluggedOn = chargingState !== ChargingStatus.DISCONNECTED;

    const { showChargingUntil, chargingUntilProps } = getChargingUntil(
        getModifiedAt(vehicleData.assetInfo),
        intl.locale,
        vehicleData.chargingUntil,
        vehicleData.delayedChargingUntil,
        isPluggedOn,
        chargingState
    );

    return (
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip width="auto" className="top">
                    <>
                        {intl.formatMessage({ id: getStatusColorAndMessage(chargingState).text })}
                        <ChargingDetails
                            showChargingUntil={showChargingUntil}
                            isPluggedOn={isPluggedOn}
                            chargingUntilProps={chargingUntilProps}
                            locale={intl.locale}
                            disconnectedAt={vehicleData.disconnectedAt}
                        />
                    </>
                </Tooltip>
            }
        >
            <div data-testid={'connector-details-with-tooltip'}>
                <Connector chargingState={chargingState} isPluggedOn={isPluggedOn} />
            </div>
        </OverlayTrigger>
    );
};

export default AssetDetailsChargingState;
