import React from 'react';
import Switch from '@rio-cloud/rio-uikit/Switch';

interface ChargingTimerEnableSwitchProperties {
    id: number;
    onToggle: (id: number) => void;
    isChecked: boolean;
    isEnabled: boolean;
    toggleUnsavedVehicleChanges: (unsavedChanges: boolean) => void;
}

export default class ChargingTimerEnableSwitch extends React.Component<ChargingTimerEnableSwitchProperties> {
    onTimerEnabledChange(targetTimerEnabledChange: number): void {
        this.props.toggleUnsavedVehicleChanges(true);
        this.props.onToggle(targetTimerEnabledChange);
    }

    render(): JSX.Element {
        return (
            <div
                className="display-inline-block border-style-solid border-top-0 border-right-0 border-bottom-0
                border-color-light padding-left-10 align-middle"
            >
                <Switch
                    keyName={`timerEnabled_${this.props.id}`}
                    onChange={(): void => this.onTimerEnabledChange(this.props.id)}
                    checked={this.props.isChecked}
                    disabled={!this.props.isEnabled}
                />
            </div>
        );
    }
}
