import { useIntl } from 'react-intl';
import ClearableInput from '@rio-cloud/rio-uikit/ClearableInput';
import { commonPhoneNumberValidation } from '../../../../../../../validation/userInputValidation';

export interface ContactInputProps {
    contact?: string;
    handleChange: Function;
    setError: Function;
    error?: boolean;
}

const ContactInput = ({ contact, handleChange, error, setError }: ContactInputProps): JSX.Element => {
    const intl = useIntl();
    const handleContactChange = (inputValue: string) => {
        handleChange(inputValue);
    };

    const informationLabel = intl.formatMessage({ id: 'e4c.overview.events.widget.settings.critical.sms.phoneNumber' });

    const errorMessage = intl.formatMessage({
        id: 'e4c.overview.events.widget.settings.critical.sms.validation.format',
    });

    const contactValidation = () => {
        const isPhoneNumberValid = commonPhoneNumberValidation(contact);
        setError(!isPhoneNumberValid);
    };

    return (
        <div
            className={`flex-1-1 padding-right-15 form-group ${error ? 'has-error has-feedback' : ''}`}
            data-testid="contact-number-input"
        >
            <div>
                <label>{informationLabel}</label>
                <span className="text-color-dark">*</span>
            </div>
            <ClearableInput
                value={contact}
                onChange={handleContactChange}
                onBlur={contactValidation}
                onClear={() => setError(false)}
                placeholder={informationLabel}
            />
            {error && (
                <span data-testid="contact-input-error" className="help-block">
                    <span>{errorMessage}</span>
                </span>
            )}
        </div>
    );
};

export default ContactInput;
