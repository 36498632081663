import { ReactElement } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { UserManualNavItemProps } from '../types';
import { USER_MANUAL } from '../../../constants/paths/paths';
import { ProductLabel } from '../../../../components/shared/ProductLabel';
import { ProductVersion } from '../../../vehicles/productVersion';
import { addQueryParam, removeQueryParam } from '../../../helpers/queryParametersHelper';

const UserManualNavItem = ({ dataKey, isNewItem, isMbooking }: UserManualNavItemProps): ReactElement => {
    const intl = useIntl();
    const { pathname, search } = useLocation();
    const searchCleaned = removeQueryParam(search, USER_MANUAL);
    const newSearch = addQueryParam(searchCleaned, USER_MANUAL, dataKey);

    return (
        <Link
            to={{ pathname, search: newSearch }}
            className={search.endsWith(dataKey) ? 'active' : ''}
            data-key={dataKey}
        >
            <FormattedMessage id={`e4c.userManual.${dataKey}`} />
            {isMbooking && (
                <div className="display-inline-block margin-left-5">
                    <ProductLabel
                        productVersion={ProductVersion.EMANAGER_M}
                        tooltipText={intl.formatMessage({ id: 'e4c.userManual.details.featureM.tooltip' })}
                    />
                </div>
            )}
            {isNewItem && (
                <div className="label label-primary label-filled label-condensed margin-left-5">
                    <FormattedMessage id="e4c.global.new" />
                </div>
            )}
        </Link>
    );
};

export default UserManualNavItem;
