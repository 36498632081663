import ElectricVehicle from '../models/ElectricVehicle';
import AssetInfo from '../models/AssetInfo';
import Measurement from '../models/Measurement';
import MeasurementType from '../models/enums/MeasurementType';
import ChargingState from '../models/ChargingState';
import ChargingStatus from '../models/enums/ChargingStatus';
import { ProductVersion } from '../../common/vehicles/productVersion';
import { mapEnumVehicleModel, mapEnumVehicleType } from '../helpers/rulesVehicleCharging';

export const mapMonitoringJsonToElectricVehicleArray = (assets: Object[]): ElectricVehicle[] => {
    return assets.map((asset: any) => {
        const measurements = mapMonitoringJsonToMeasurementArray(asset.measurements);
        return new ElectricVehicle(
            asset.asset_id,
            mapMonitoringJsonToProductVersion(asset.product_version),
            asset.has_minimaldatenset,
            mapMonitoringJsonToAssetInfo(asset),
            measurements,
            asset.measurements_last_updated,
            mapMonitoringJsonToChargingState(measurements)
        );
    });
};

const mapMonitoringJsonToProductVersion = (item: any): ProductVersion => {
    switch (item) {
        case 'EMANAGER_S':
            return ProductVersion.EMANAGER_S;
        case 'EMANAGER_M':
            return ProductVersion.EMANAGER_M;
        default:
            return ProductVersion.NOT_BOOKED;
    }
};

const mapMonitoringJsonToAssetInfo = (item: any): AssetInfo => {
    return new AssetInfo(
        item.asset_info.modified_at,
        item.asset_info.vin,
        item.asset_info.name,
        mapEnumVehicleType(item.asset_info.type),
        mapEnumVehicleModel(item.asset_info.vehicle_model)
    );
};

export const mapMonitoringJsonToMeasurementArray = (items: any): Measurement[] => {
    const measurements: Measurement[] = [];

    if (items.state_of_charge) {
        measurements.push(
            new Measurement(
                MeasurementType.STATE_OF_CHARGE,
                items.state_of_charge.measured_at,
                Math.round(items.state_of_charge.value)
            )
        );
    }

    if (items.remaining_range) {
        measurements.push(
            new Measurement(
                MeasurementType.REMAINING_RANGE,
                items.remaining_range.measured_at,
                Math.floor(items.remaining_range.value)
            )
        );
    }

    if (items.energy_level) {
        measurements.push(
            new Measurement(
                MeasurementType.ENERGY_LEVEL,
                items.energy_level.measured_at,
                Math.floor(items.energy_level.value)
            )
        );
    }

    if (items.driving_state) {
        measurements.push(
            new Measurement(MeasurementType.DRIVING_STATE, items.driving_state.measured_at, items.driving_state.value)
        );
    }

    if (items.charging_state) {
        measurements.push(
            new Measurement(
                MeasurementType.CHARGING_STATE,
                items.charging_state.measured_at,
                items.charging_state.value
            )
        );
    }

    return measurements;
};

export const mapMonitoringJsonToChargingState = (measurements: Measurement[]): ChargingState | undefined => {
    const measurement = measurements.find(m => m.type === MeasurementType.CHARGING_STATE);
    return new ChargingState(
        measurement ? measurement.value : ChargingStatus.UNKNOWN,
        undefined,
        undefined,
        undefined,
        undefined
    );
};
