import VehicleDetailsConfiguration from './VehicleDetailsConfiguration';
import EtgeVehicleDetailsConfiguration from './EtgeVehicleDetailsConfiguration';
import E4CVehicleDetailsConfiguration from './E4CVehicleDetailsConfiguration';
import { Vehicle } from '../../../types';
import ElectricVehicle from '../../../models/ElectricVehicle';
import VehicleModel from '../../../models/enums/VehicleModel';
import { VehicleDetailsProps } from '../vehicleDetails/types';

class VehicleDetailsConfigurationFactory {
    private readonly _vehicle: ElectricVehicle;
    private readonly _selectedVehicle: Vehicle;
    private readonly _vehicleDetailsProps: VehicleDetailsProps;
    private readonly _isReadOnlyUser: boolean;
    private readonly _toggleUnsavedVehicleChanges: (unsavedChanges: boolean) => void;

    constructor(
        toggleUnsavedVehicleChanges: (unsavedChanges: boolean) => void,
        vehicle: ElectricVehicle,
        selectedVehicle: Vehicle,
        vehicleDetailsProps: VehicleDetailsProps,
        isReadOnlyUser: boolean
    ) {
        this._vehicle = vehicle;
        this._selectedVehicle = selectedVehicle;
        this._vehicleDetailsProps = vehicleDetailsProps;
        this._isReadOnlyUser = isReadOnlyUser;
        this._toggleUnsavedVehicleChanges = toggleUnsavedVehicleChanges;
    }

    private vehicleModel(): VehicleModel {
        return this._vehicle.assetInfo.vehicleModel;
    }

    createConfiguration(): VehicleDetailsConfiguration | null {
        if (this.vehicleModel() === VehicleModel.ETGE) {
            return new EtgeVehicleDetailsConfiguration();
        } else if (this.vehicleModel() === VehicleModel.E4C) {
            return new E4CVehicleDetailsConfiguration(
                this._toggleUnsavedVehicleChanges,
                this._selectedVehicle,
                this._vehicleDetailsProps,
                this._isReadOnlyUser
            );
        } else {
            return new EtgeVehicleDetailsConfiguration();
        }
    }
}

export default VehicleDetailsConfigurationFactory;
