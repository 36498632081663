import { ReactElement } from 'react';
import moment from 'moment';
import { AggregationPeriodType } from '../../statistics/types';
import { ElectricVehicleIcon } from '../../../../common/components/ElectricVehicleIcon/ElectricVehicleIcon';
import VehicleType from '../../../../common/vehicles/VehicleType';

interface OnHoverBarChartHeaderProperties {
    aggregationPeriodType: AggregationPeriodType;
    date: string;
    vehiclesQuantity: number;
}

const OnHoverBarChartHeader = (props: OnHoverBarChartHeaderProperties): ReactElement => {
    const { aggregationPeriodType, date, vehiclesQuantity } = props;
    const checkDate = (dateToCheck: string): string => {
        if (aggregationPeriodType === AggregationPeriodType.MONTHLY) {
            return moment(new Date(dateToCheck)).format('MMM');
        } else {
            return moment(new Date(dateToCheck)).format('DD.MM.YYYY');
        }
    };
    return (
        <div
            className="display-flex padding-bottom-5 border-color-light border-width-1 border-style-solid border-top-0 border-left-0 border-right-0"
            data-testid="on-hover-bar-chart-header"
        >
            <span className="text-color-darkest padding-right-15">{checkDate(date)}</span>
            <div>
                <ElectricVehicleIcon vehicleType={VehicleType.BUS} size="text-size-16" extraClasses="padding-right-2" />
                <span className="text-color-darkest bold">{vehiclesQuantity}</span>
            </div>
        </div>
    );
};

export default OnHoverBarChartHeader;
