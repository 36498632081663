import { PaginationOptions } from '../../../common/components/pagination/Pagination';
import _ from 'lodash';
import ChargingStatus from '../../models/enums/ChargingStatus';
import { VehicleChargingData } from '../../models/VehicleChargingData';

export const applyPagination = (
    pagOpt: PaginationOptions,
    connectedVehicles: VehicleChargingData[],
    disconnectedVehicles: VehicleChargingData[]
): void => {
    const all: VehicleChargingData[] = [...connectedVehicles, ...disconnectedVehicles];
    const final = [];
    const offset = pagOpt.pageSize * (pagOpt.pageNumber - 1);

    final.push(..._.drop(all, offset).slice(0, pagOpt.pageSize));

    _.remove(connectedVehicles);
    _.remove(disconnectedVehicles);

    final.forEach(vehicle =>
        (vehicle.measurements.chargingState.value === ChargingStatus.DISCONNECTED
            ? disconnectedVehicles
            : connectedVehicles
        ).push(vehicle)
    );
};

export const filterConnectionStatus = (
    rows: VehicleChargingData[],
    connectedVehiclesRows: VehicleChargingData[],
    disconnectedVehiclesRows: VehicleChargingData[]
): VehicleChargingData[] => {
    rows.forEach(row =>
        (row.measurements.chargingState.value === ChargingStatus.DISCONNECTED
            ? disconnectedVehiclesRows
            : connectedVehiclesRows
        ).push(row)
    );
    return rows;
};

const filterElectricVehiclesByNameOrVin = (searchValue: string, vehicle: VehicleChargingData): boolean => {
    return (
        vehicle.assetInfo.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        vehicle.assetInfo.vin.toString().toLowerCase().includes(searchValue.toLowerCase())
    );
};

export const filterRowsBySearchAndTreeAsset = (
    electricVehicles: VehicleChargingData[],
    selectedVehicleIds: string[],
    searchValue: string
): VehicleChargingData[] => {
    let filteredVehicles: VehicleChargingData[] = electricVehicles;

    if (selectedVehicleIds.length) {
        filteredVehicles = filteredVehicles.filter(vehicle => selectedVehicleIds.includes(vehicle.assetId));
    }

    if (searchValue) {
        filteredVehicles = filteredVehicles.filter(v => filterElectricVehiclesByNameOrVin(searchValue, v));
    }

    return filteredVehicles;
};

export const filterRowsByChargingStatus = (
    electricVehicles: VehicleChargingData[],
    filterByChargingStatusWidget: (ChargingStatus | undefined)[]
): VehicleChargingData[] => {
    if (filterByChargingStatusWidget.length) {
        return electricVehicles.filter(v =>
            filterByChargingStatusWidget.includes(v.measurements.chargingState.value as ChargingStatus)
        );
    }
    return electricVehicles;
};
