import { ReactElement } from 'react';
import { ProductVersion } from '../../../vehicles/productVersion';
import VehicleType from '../../../vehicles/VehicleType';
import TooltipWrapper from '../../Tooltip/TooltipWrapper';
import { ProductLabel } from '../../../../components/shared/ProductLabel';
import { ElectricVehicleIcon } from '../../ElectricVehicleIcon/ElectricVehicleIcon';

export interface VehicleIdentificationProps {
    name: string;
    productVersion: ProductVersion;
    vehicleType?: VehicleType;
}

const VehicleIdentification = ({ name, productVersion, vehicleType }: VehicleIdentificationProps): ReactElement => {
    const vehicleIcon = vehicleType && <ElectricVehicleIcon vehicleType={vehicleType} />;

    return (
        <div className="display-flex align-items-center">
            {vehicleIcon}
            <div className="display-flex padding-left-5 ellipsis-1 margin-right-10 height-40 align-items-center">
                <TooltipWrapper tooltipContent={name} dataTestId="vehicle-name">
                    <>{name}</>
                </TooltipWrapper>
            </div>
            <ProductLabel productVersion={productVersion} dataTestId="vehicle-product-version" />
        </div>
    );
};

export default VehicleIdentification;
