const CollapsedConsumption = ({ sumAggregatedValues }: { sumAggregatedValues: number }) => {
    return (
        <>
            <span className={'rioglyph rioglyph-stats text-size-20 text-color-gray margin-right-10'} />
            <span data-testid="sum-aggregated">{`${sumAggregatedValues} kWh` ?? '-'}</span>
        </>
    );
};

export default CollapsedConsumption;
