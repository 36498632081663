import moment from 'moment-timezone';
import { useIntl } from 'react-intl';
import TooltipWrapper from '../../../../../common/components/Tooltip/TooltipWrapper';
import { useTimezone } from '../../../../../common/providers/timezone';
import ChargingModeColumnIntl from './ChargingModeColumnIntl';
import ContentCharging from './ContentCharging';

interface TimerChargingModeProps {
    departureDate?: string | null;
    isClimateControlEnabled: boolean;
}

const TimerChargingMode = (props: TimerChargingModeProps): JSX.Element => {
    const { departureDate, isClimateControlEnabled } = props;
    const intl = useIntl();
    const timezone = useTimezone();

    const climateModeId = isClimateControlEnabled
        ? ChargingModeColumnIntl.CLIMATE_CONTROL_ENABLED
        : ChargingModeColumnIntl.CLIMATE_CONTROL_DISABLED;

    let formattedDepartureDate;
    let formattedDepartureTime;
    const hasDepartureDate = !!departureDate;

    if (hasDepartureDate) {
        const date = new Date(departureDate);
        formattedDepartureDate = date.toLocaleDateString(intl.locale, { day: '2-digit', month: '2-digit' });
        formattedDepartureTime = moment(date).tz(timezone).format('HH:mm');
    }

    const formattedDate = `${formattedDepartureDate} ${formattedDepartureTime}`;
    const climateMode = intl.formatMessage({ id: climateModeId });
    const tooltipContent = `${formattedDate}\n${climateMode}`;

    return (
        <div className="display-flex flex-column">
            <TooltipWrapper dataTestId="tcm-datetime" tooltipContent={tooltipContent}>
                <ContentCharging
                    value={formattedDate}
                    climateMode={climateMode}
                    isClimateControlEnabled={isClimateControlEnabled}
                />
            </TooltipWrapper>
        </div>
    );
};

export default TimerChargingMode;
