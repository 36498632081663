import { getFleetTypeFromLocalStorage } from './localStorage';
import ElectricVehicle from './models/ElectricVehicle';
import FleetType from './models/enums/FleetType';

export interface ElectricVehiclesState {
    electricVehicles: ElectricVehicle[];
    fleetType: FleetType;
    hasMinimalDatenset: boolean;
    isFetchingMonitoringElectricVehicles: boolean;
    isFetchingStateElectricVehicles: boolean;
}

const initialState: ElectricVehiclesState = {
    electricVehicles: [],
    fleetType: getFleetTypeFromLocalStorage(),
    hasMinimalDatenset: false,
    isFetchingMonitoringElectricVehicles: false,
    isFetchingStateElectricVehicles: false,
};

export default initialState;
