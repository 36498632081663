import { useMemo } from 'react';
import ExpanderList, { ExpanderListItem } from '@rio-cloud/rio-uikit/ExpanderList';
import { VehicleStatsHeader } from './VehicleStatsHeader';
import { ProductVersion } from '../../../common/vehicles/productVersion';
import { AssetsChargeStats } from '../types';
import CycleDataTable from '../CycleDataTable/CycleDataTable';

const generateExpanderListItems = (assetsChargeStats: AssetsChargeStats[]): ExpanderListItem[] => {
    return assetsChargeStats.map((chargeStats: AssetsChargeStats) => {
        const { assetId, assetName, vehicleType, productVersion, stats, startDate, endDate, isOpen } = chargeStats;

        return {
            header: (
                <VehicleStatsHeader
                    name={assetName}
                    productVersion={productVersion}
                    assetId={assetId}
                    vehicleType={vehicleType}
                    stats={stats}
                />
            ),
            body:
                productVersion === ProductVersion.EMANAGER_S ? null : (
                    <CycleDataTable assetId={assetId} startDate={startDate} endDate={endDate} />
                ),
            className: 'margin-bottom-5 border-none',
            bodyClassName: 'padding-0',
            open: isOpen,
        };
    });
};

export const VehicleExpanderList = ({ assetsChargeStats }: { assetsChargeStats: AssetsChargeStats[] }) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const items = useMemo(() => generateExpanderListItems(assetsChargeStats), [assetsChargeStats.length]);
    return <ExpanderList className="border-none margin-bottom-20" items={items} rounded={false} />;
};
