import StatisticsBuilder from './StatisticsBuilder';
import { AggregationPeriodType, StatisticsData } from './types';
import {
    aggregatedAverageConsumptionDaily,
    aggregatedAverageConsumptionMonthly,
    getAverageConsumptionSummaryValue,
} from './AverageDrivingConsumptionStatisticsHelper';

class AverageDrivingConsumptionStatisticsBuilder extends StatisticsBuilder {
    build(): StatisticsData {
        let data;
        if (this.isDailyAggregated()) {
            data = aggregatedAverageConsumptionDaily(this._vehicleStatistics, this._startDate, this._endDate);
            return {
                aggregationPeriodType: AggregationPeriodType.DAILY,
                data,
                summaryValue: getAverageConsumptionSummaryValue(data),
            };
        } else {
            data = aggregatedAverageConsumptionMonthly(this._vehicleStatistics, this._startDate, this._endDate);
            return {
                aggregationPeriodType: AggregationPeriodType.MONTHLY,
                data,
                summaryValue: getAverageConsumptionSummaryValue(data),
            };
        }
    }
}

export default AverageDrivingConsumptionStatisticsBuilder;
