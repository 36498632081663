type CollapsedEnergyAddedProps = {
    date: string;
    collapsedType: string;
    setKeysOpened: (date: string) => void;
};

const ChevronDownIcon = (props: CollapsedEnergyAddedProps): JSX.Element => {
    const { date, collapsedType } = props;
    return (
        <span
            className={`formatExpander btn btn-link btn-icon-only animate ${collapsedType}`}
            onClick={(): void => props.setKeysOpened(date)}
        >
            <span className={'rioglyph rioglyph-chevron-down'}></span>
        </span>
    );
};

export default ChevronDownIcon;
