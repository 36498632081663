import { VehiclesHistoryState, HistoryContentEnum } from './types';
import { SortDirection } from '../vehicleCharging/types';

const initialState: VehiclesHistoryState = {
    selectedHistoryAssetIds: [],
    chargingProcesses: [],
    fetchingChargingProcesses: false,
    chargingProcessesErrorCode: null,
    historySortBy: 'date',
    historySortDir: SortDirection.DESCENDING,
    selectedHistoryContent: HistoryContentEnum.BATTERY_HISTORY,
};

export default initialState;
