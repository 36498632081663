import { has } from 'lodash';
import { SeverityEventLevel } from '../../../../../models/SeverityEventLevelEnum';
import {
    ContactDetail,
    EventsSettingsResponse,
    EventsSettings,
    EventsSettingsUI,
    SettingsDetails,
    SettingsDetailsResponse,
} from './IModel';

export interface EventsSettingsAPI {
    updatable: boolean;
    events_settings: EventsSettingsResponse;
    contacts_info: ContactDetail[];
}

class EventSettingsResponse {
    private _updatable: boolean;
    private _eventsSettings: EventsSettings;
    private _contactsInfo: ContactDetail[];
    constructor(eventSettingFetchData: EventsSettingsAPI) {
        this._updatable = eventSettingFetchData.updatable;
        this._eventsSettings = this._mapEventsSettings(eventSettingFetchData.events_settings);
        this._contactsInfo = eventSettingFetchData.contacts_info;
    }

    public get updatable(): boolean {
        return this._updatable;
    }

    public set updatable(value: boolean) {
        this._updatable = value;
    }

    public get eventsSettings(): EventsSettings {
        return this._eventsSettings;
    }

    public set eventsSettings(value: EventsSettings) {
        this._eventsSettings = value;
    }

    public get contactsInfo(): ContactDetail[] {
        return this._contactsInfo;
    }

    public set contactsInfo(value: ContactDetail[]) {
        this._contactsInfo = value;
    }

    private _mapEventsSettings = (eventsSettings: EventsSettingsResponse): EventsSettings =>
        Object.entries(eventsSettings).reduce((events, [key, value]): EventsSettings => {
            const level = key as SeverityEventLevel;
            events[level] = this._mapSettingsDetails(value);
            return events;
        }, {} as EventsSettings);

    private _mapSettingsDetails = (settingsDetails: SettingsDetailsResponse[]): SettingsDetails[] =>
        settingsDetails.map(item => {
            const { extra_info, ...rest } = item;
            return { ...rest, extraInfo: extra_info };
        });

    private _mapSettingsNavigation(
        settingInfo: SettingsDetails[],
        updatable: boolean,
        eventSeverityLevel: SeverityEventLevel,
        contactsInfo: ContactDetail[]
    ): EventsSettingsUI {
        return {
            eventSeverityLevel,
            names: settingInfo.map(item => item.name).join(),
            eventSettings: { settings: settingInfo, updatable, contactsInfo },
        };
    }

    public mapSettingsWithSeverity = (): EventsSettingsUI[] | [] =>
        Object.values(SeverityEventLevel).reduce((events, value): EventsSettingsUI[] => {
            const eventsSettings =
                this._eventsSettings && has(this._eventsSettings, value) && this._eventsSettings[value];
            if (eventsSettings && eventsSettings.length) {
                events.push(this._mapSettingsNavigation(eventsSettings, this._updatable, value, this._contactsInfo));
            }
            return events;
        }, [] as EventsSettingsUI[]);
}

export default EventSettingsResponse;
