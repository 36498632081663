import ChargingConnectorColor from './ChargingConnectorColor';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { ChargingUntilProps } from './ChargingUntil';
import ChargingStatus from '../../../../models/enums/ChargingStatus';
import { currentTimezone } from '../../../../../utils/timezone';

export const formatDisconnectedAt = (value: string, locale?: string): string => {
    const parsedDate = new Date(value);
    let formattedDisconnectedAt = momentTz(parsedDate).tz(currentTimezone).format('HH:mm');
    const today = moment(new Date().setHours(0, 0, 0, 0));
    const isToday = today.isAfter(moment(parsedDate));
    if (isToday) {
        const formattedDate = parsedDate.toLocaleDateString(locale, {
            day: '2-digit',
            month: '2-digit',
        });
        formattedDisconnectedAt = `${formattedDate} ${formattedDisconnectedAt}`;
    }
    return formattedDisconnectedAt;
};

export const getStatusColorAndMessage = (
    chargingStatus?: ChargingStatus
): { color: ChargingConnectorColor; text: string } => {
    switch (chargingStatus) {
        case ChargingStatus.CONNECTED:
            return {
                color: ChargingConnectorColor.STATUS_COLOR_WARNING,
                text: 'e4c.vehicles.table.chargingStatus.connected',
            };
        case ChargingStatus.DISCONNECTED:
            return {
                color: ChargingConnectorColor.STATUS_COLOR_GRAYED_OUT,
                text: 'e4c.vehicles.table.chargingStatus.disconnected',
            };
        case ChargingStatus.CHARGING:
            return {
                color: ChargingConnectorColor.STATUS_COLOR_SUCCESS,
                text: 'e4c.vehicles.table.chargingStatus.charging',
            };
        case ChargingStatus.CHARGING_LONGPRESS:
            return {
                color: ChargingConnectorColor.STATUS_COLOR_SUCCESS,
                text: 'e4c.vehicles.table.chargingStatus.chargingLongpress',
            };
        case ChargingStatus.CHARGED:
            return {
                color: ChargingConnectorColor.STATUS_COLOR_SUCCESS,
                text: 'e4c.vehicles.table.chargingStatus.charged',
            };
        case ChargingStatus.FAILURE:
            return {
                color: ChargingConnectorColor.STATUS_COLOR_DANGER,
                text: 'e4c.vehicles.table.chargingStatus.failure',
            };
        default:
            return {
                color: ChargingConnectorColor.STATUS_COLOR_DANGER,
                text: 'e4c.vehicles.table.chargingStatus.noSignal',
            };
    }
};

export const getChargingUntil = (
    lastUpdated: string,
    locale: string,
    chargingUntil: string | null | undefined,
    delayedChargingUntil: string | null | undefined,
    isPluggedOn: boolean,
    chargingState?: ChargingStatus
): { showChargingUntil: boolean; chargingUntilProps?: ChargingUntilProps } => {
    let chargingUntilProps: ChargingUntilProps | undefined;

    const isChargingUntilDisplayed =
        chargingState === ChargingStatus.CHARGING || chargingState === ChargingStatus.CHARGING_LONGPRESS;

    if (isChargingUntilDisplayed) {
        chargingUntilProps = getChargingUntilProperties(locale, lastUpdated, chargingUntil, delayedChargingUntil);
    }

    const showChargingUntil = isPluggedOn && isChargingUntilDisplayed && !!chargingUntilProps;

    return { showChargingUntil, chargingUntilProps };
};

export const getChargingUntilProperties = (
    locale: string,
    lastUpdated: string,
    chargingUntil?: string | null,
    delayedChargingUntil?: string | null
): ChargingUntilProps | undefined => {
    if (chargingUntil) {
        let formattedChargingUntilDate = '';
        let hasDifferentDelayedDate = false;
        const parsedDate = new Date(Date.parse(chargingUntil));
        const lastUpdatedDate = new Date(Date.parse(lastUpdated));
        const formattedChargingUntilTime = momentTz(parsedDate).tz(currentTimezone).format('HH:mm');

        if (parsedDate.toDateString() !== lastUpdatedDate.toDateString()) {
            formattedChargingUntilDate = parsedDate.toLocaleDateString(locale, {
                day: '2-digit',
                month: '2-digit',
            });
        }
        let formattedDelayedChargingUntilData = null;
        if (delayedChargingUntil) {
            const parsedDelayedDate = new Date(Date.parse(delayedChargingUntil));
            const formattedDelayedChargingUntilTime = momentTz(parsedDelayedDate).tz(currentTimezone).format('HH:mm');

            const formattedDelayedChargingUntilDate = parsedDelayedDate.toLocaleDateString(locale, {
                day: '2-digit',
                month: '2-digit',
            });

            hasDifferentDelayedDate = formattedChargingUntilDate !== formattedDelayedChargingUntilDate;
            if (hasDifferentDelayedDate && formattedChargingUntilDate !== '') {
                formattedDelayedChargingUntilData = `${formattedDelayedChargingUntilDate} ${formattedDelayedChargingUntilTime}`;
            } else {
                formattedDelayedChargingUntilData = formattedDelayedChargingUntilTime;
            }
        }

        return {
            date: formattedChargingUntilDate,
            isDateOutdated: hasDifferentDelayedDate,
            time: formattedChargingUntilTime,
            isTimeOutdated: formattedDelayedChargingUntilData !== null,
            hasDelayedDate: formattedDelayedChargingUntilData !== null,
            dateAndTime: formattedDelayedChargingUntilData,
        } as ChargingUntilProps;
    }
    return undefined;
};
