import ReadyToDriveStatus from './enums/ReadyToDriveStatus';
import ChargingStatus from './enums/ChargingStatus';
import { isConnectorPluggedOn } from './ChargingStateRules';

class ChargingState {
    private readonly _chargingStatus: ChargingStatus | undefined;
    private readonly _chargingUntil: string | undefined;
    private readonly _delayedChargingUntil: string | undefined;
    private readonly _readyToDriveStatus: ReadyToDriveStatus | undefined;
    private readonly _readyToDriveUntil: string | undefined;

    constructor(
        chargingStatus: ChargingStatus | undefined,
        chargingUntil: string | undefined,
        delayedChargingUntil: string | undefined,
        readyToDriveStatus: ReadyToDriveStatus | undefined,
        readyToDriveUntil: string | undefined
    ) {
        this._chargingStatus = chargingStatus;
        this._chargingUntil = chargingUntil;
        this._delayedChargingUntil = delayedChargingUntil;
        this._readyToDriveStatus = readyToDriveStatus;
        this._readyToDriveUntil = readyToDriveUntil;
    }

    get chargingStatus(): ChargingStatus | undefined {
        return this._chargingStatus;
    }

    public get chargingUntil(): string | undefined {
        return this._chargingUntil;
    }

    get delayedChargingUntil(): string | undefined {
        return this._delayedChargingUntil;
    }

    get readyToDriveStatus(): ReadyToDriveStatus | undefined {
        return this._readyToDriveStatus;
    }

    get readyToDriveUntil(): string | undefined {
        return this._readyToDriveUntil;
    }

    isConnectorPluggedOn(): boolean {
        return isConnectorPluggedOn(this);
    }
}

export default ChargingState;
