import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CalloutUpsell from './CalloutUpsell';
import { getCookie, setCookie } from '../../common/utilities/cookies';
import { getUserAccount } from '../../../../configuration';
import { config } from '../../../../config';

const HistoryUpsell = ({ hasMBooking }: { hasMBooking: boolean }): ReactElement => {
    const [show, setShow] = useState(!hasMBooking);

    const userAccount = useSelector(state => getUserAccount(state));

    useEffect(() => {
        if (!hasMBooking) {
            const cookie = getCookie(`calloutupsell-${userAccount}`);
            setShow(!cookie);
        }
    }, [hasMBooking, userAccount]);

    const handleClose = () => {
        setCookie({ key: `calloutupsell-${userAccount}`, value: 'true', expireDate: 40 });
        setShow(false);
    };

    return (
        <>
            {show && (
                <CalloutUpsell
                    subtitle={'e4c.history.upsellBanner.descriptionM'}
                    title={'e4c.history.upsellBanner.titleM'}
                    infoUrl={config.eManagerMLearnMoreUrl}
                    handleClose={handleClose}
                />
            )}
        </>
    );
};

export default HistoryUpsell;
