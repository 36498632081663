import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Notification from '@rio-cloud/rio-uikit/Notification';
import Sidebar from '@rio-cloud/rio-uikit/Sidebar';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { VehicleDetailsProps } from './types';
import notVanishingNotificationError from './notVanishingNotificationError';
import { createVehicleDetailsConfiguration, createVehicleDetailsFooter } from './vehicleDetailsHelper';
import { hasUnsavedChanges, isReadOnlyUserValidation } from './VehicleDetailsValidations';
import UpsellStateWrapper from '../../../../common/components/upsell/UpsellStateWrapper';
import VehicleDetailsConfiguration from '../VehicleDetailsConfiguration/VehicleDetailsConfiguration';
import VehiclesDetailsError from '../VehicleDetailsError/VehicleDetailsError';
import VehicleDetailsFooter from '../VehicleDetailsFooter/VehicleDetailsFooter';
import AssetDetails from '../VehicleDetailsInfo/AssetDetailsInfo/AssetDetails';
import { ProductVersion } from '../../../../common/vehicles/productVersion';
import VehicleModel from '../../../models/enums/VehicleModel';
import { cleanState } from '../../../actions/details/VehicleDetails.actions';
import { useDispatch } from 'react-redux';
import { UpcomingFeatureState } from './UpcomingFeatureState';

const VehicleDetails = (props: VehicleDetailsProps): JSX.Element => {
    const {
        accessToken,
        errorCode,
        fetchVehicleDetails,
        fetchingDetails,
        onClose,
        selectedVehicle,
        toggleConfirmDialog,
        toggleUnsavedVehicleChanges,
        toggleSendingChargingConfigSucceeded,
        sendingChargingConfigFailed,
        sendingChargingConfigSucceeded,
        selectedVehicleBeforeChanges,
        vehicle,
        vehicleObjectFromTable,
    } = props;

    const dispatch = useDispatch();
    const isCM4 =
        vehicle?.assetInfo.vehicleModel === VehicleModel.ELSA || vehicle?.assetInfo.vehicleModel === VehicleModel.TRUE;

    useEffect(() => {
        if (isCM4) {
            dispatch(cleanState());
        } else if (vehicle?.assetId) {
            fetchVehicleDetails(accessToken, vehicle.assetId, vehicle);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicle?.assetId, isCM4]);

    const onSidebarClose = (): void => {
        if (hasUnsavedChangesLocal()) {
            toggleConfirmDialog(true);
        } else {
            onClose();
        }
    };

    const hasUnsavedChangesLocal = (): boolean => {
        return hasUnsavedChanges(props);
    };

    if (sendingChargingConfigFailed) {
        notVanishingNotificationError(<FormattedMessage id="e4c.vehicle.details.save.failure" />);
    }

    if (sendingChargingConfigSucceeded) {
        Notification.success(<FormattedMessage id="e4c.vehicle.details.save.success" />, '', 3000);
        toggleSendingChargingConfigSucceeded(false);
    }

    const isReadOnlyUser = isReadOnlyUserValidation(props);

    const title = !errorCode && !fetchingDetails ? <FormattedMessage id="e4c.sidebar.title" /> : '';

    const vehicleDetailsFooter: VehicleDetailsFooter | null = createVehicleDetailsFooter(
        vehicle,
        selectedVehicle,
        props,
        (): void => onSidebarClose(),
        (): boolean => hasUnsavedChanges(props)
    );

    const vehicleDetailsConfiguration: VehicleDetailsConfiguration | null = createVehicleDetailsConfiguration(
        toggleUnsavedVehicleChanges,
        vehicle,
        selectedVehicle,
        props,
        isReadOnlyUser
    );

    return (
        <Sidebar
            className={errorCode ? 'error-sidebar' : ''}
            title={title}
            titleClassName="padding-left-5"
            headerClassName="min-height-0"
            closed={false}
            onClose={(): void => onSidebarClose()}
            position="right"
            resizable={false}
            width={615}
            fly
            footer={
                !errorCode &&
                !isReadOnlyUser &&
                selectedVehicle &&
                selectedVehicleBeforeChanges &&
                vehicleDetailsFooter &&
                vehicleDetailsFooter.renderFooter()
            }
        >
            {fetchingDetails ? (
                <FormattedMessage id="e4c.spinner.loading">
                    {(loadingText): JSX.Element => <Spinner text={loadingText.toString()} />}
                </FormattedMessage>
            ) : (
                <>
                    {vehicle && !errorCode && <AssetDetails vehicleData={vehicleObjectFromTable} />}
                    {isCM4 ? (
                        <>
                            <hr className="margin-0" />
                            <UpcomingFeatureState />
                        </>
                    ) : (
                        <>
                            {selectedVehicle && vehicleDetailsConfiguration?.renderConfiguration()}
                            {errorCode && <VehiclesDetailsError errorCode={errorCode} />}
                            {vehicle?.productVersion === ProductVersion.EMANAGER_S && !errorCode && (
                                <div className="margin-20">
                                    <UpsellStateWrapper
                                        features={[
                                            <FormattedMessage key="a" id="e4v.upselling.drivingStatus" />,
                                            <FormattedMessage key="a" id="e4v.upselling.remainingRangeInKwH" />,
                                        ]}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
        </Sidebar>
    );
};

export default VehicleDetails;
