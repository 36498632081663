import _ from 'lodash';
import { HistoryTableColumnDetails, CollapsedItemsProps, ColumnLabels } from '../types';
import classNames from 'classnames';
import { findBatteryStatusInterval } from '../../../common/helpers/tables';

export const historyTableClassNames = classNames(
    'table',
    'table-layout-fixed',
    'table-column-overflow-hidden',
    'table-bordered',
    'table-sticky',
    'table-head-filled'
);

const getCollapsedClassNameState = (keysOpened: string[], key: string): string => {
    if (keysOpened.includes(key)) {
        return 'open';
    }
    return '';
};

const getRowClassNameType = (keysOpened: string[], key: string): string => {
    if (keysOpened.includes(key)) {
        return 'extendedRow';
    }
    return 'compactRow';
};

export const getKeysFromColumnsDetailsMap = (columnsDetails: {
    [key: string]: HistoryTableColumnDetails;
}): string[] => {
    return _.keysIn(columnsDetails);
};

export const findColumnsWithRightSeparator = (
    columnsDetails: {
        [key: string]: HistoryTableColumnDetails;
    },
    columns: string[]
): string[] => {
    return columns.filter(col => columnsDetails[col].hasRightSeparator);
};

export const buildMapOfLabelsPerColumnDetails = (columnsDetails: {
    [key: string]: HistoryTableColumnDetails;
}): ColumnLabels | { [x: string]: string | undefined } => {
    return _.mapValues(columnsDetails, column => column.label);
};

export const buildMapOfSortablesPerColumnDetails = (columnsDetails: {
    [key: string]: HistoryTableColumnDetails;
}): ColumnLabels | { [x: string]: boolean | undefined } => {
    return _.mapValues(columnsDetails, column => column.sortable);
};

export const buildMapOfWidthsPerColumnDetails = (columnsDetails: {
    [key: string]: HistoryTableColumnDetails;
}): ColumnLabels | { [x: string]: number | undefined } => {
    return _.mapValues(columnsDetails, column => column.width);
};

export const buildCollpsedItems = (keysOpened: string[], date: string): CollapsedItemsProps => {
    return {
        collapsedType: getCollapsedClassNameState(keysOpened, date),
        rowType: getRowClassNameType(keysOpened, date),
    };
};

export const getStateOfChargingWarning = (stateOfCharge: number | null): string => {
    return !!stateOfCharge || stateOfCharge === 0 ? findBatteryStatusInterval(stateOfCharge)?.text || '' : '';
};
