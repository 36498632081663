import ContentLoader from '@rio-cloud/rio-uikit/ContentLoader';

export const VehicleListLoader = () => {
    return (
        <>
            <ContentLoader className="width-200 height-25 margin-bottom-10" data-testid="date-loader" />
            <ContentLoader className="width-100pct height-70" data-testid="vehicle-list-loader" />
        </>
    );
};
