import { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';

export interface SaveChangesProps {
    handleCancel: VoidFunction;
    handleSave: VoidFunction;
    status: ReactElement | null;
}

const SaveChanges = ({ handleCancel, handleSave, status }: SaveChangesProps) => {
    const intl = useIntl();
    const dialogTitle = intl.formatMessage({ id: 'e4c.global.saveChanges' });
    const content = {
        title: intl.formatMessage({
            id: 'e4c.overview.events.widget.settings.confirmation.saveChangesDescription',
        }),
        description: intl.formatMessage({
            id: 'e4c.overview.events.widget.settings.confirmation.saveChangesDescription2',
        }),
    };
    const buttons = {
        cancel: intl.formatMessage({ id: 'e4c.global.cancel' }),
        save: intl.formatMessage({ id: 'e4c.global.saveChanges' }),
    };

    const dialogContent = (
        <>
            <p className="text-size-20 text-medium margin-0">{content.title}</p>
            <p className="text-size-15 margin-top-10">{content.description}</p>
        </>
    );

    if (status) {
        return status;
    }

    return (
        <div data-testid="save-changes-panel">
            <ConfirmationDialog
                show={true}
                title={dialogTitle}
                content={dialogContent}
                onClickConfirm={handleSave}
                onClickCancel={handleCancel}
                confirmButtonText={buttons.save}
                cancelButtonText={buttons.cancel}
                useOverflow
            />
        </div>
    );
};

export default SaveChanges;
