type CollapsedEnergyAddedProps = {
    sumEnergyAdded: number | null;
};

const CollapsedEnergyAdded = (props: CollapsedEnergyAddedProps): JSX.Element => {
    const { sumEnergyAdded } = props;
    const hasValidSumEnergyAdded = sumEnergyAdded && sumEnergyAdded > 0 && !isNaN(sumEnergyAdded);
    return (
        <>
            <span data-testid="energy-added" className="ellipsis-1 display-block">
                <span className={'rioglyph rioglyph-battery-level-full text-size-20 text-color-gray'} />
                <span className={'rioglyph rioglyph-arrow-up text-size-20 text-color-gray'} />
                {hasValidSumEnergyAdded ? <span>{`${sumEnergyAdded?.toFixed(0)} kWh`}</span> : <span>-</span>}
            </span>
        </>
    );
};

export default CollapsedEnergyAdded;
