import { useHistory } from 'react-router';
import { DELETE_EVENT_PATH } from '../../../../common/constants/paths/paths';
import { useIntl } from 'react-intl';

const DeleteEvents = ({
    eventId,
    isNewStatus,
    deleteAll,
    disabled,
}: {
    eventId?: string;
    isNewStatus?: boolean;
    deleteAll?: boolean;
    disabled?: boolean;
}): JSX.Element => {
    const intl = useIntl();
    const history = useHistory();
    return (
        <div
            className={
                disabled
                    ? 'display-flex justify-content-start text-color-gray'
                    : 'display-flex justify-content-start text-color-primary cursor-pointer'
            }
            onClick={() =>
                !disabled &&
                history.push({
                    pathname: DELETE_EVENT_PATH,
                    state: { eventId, deleteAll },
                })
            }
        >
            {isNewStatus ? (
                <span className="rioglyph rioglyph-trash margin-right-4" style={{ marginTop: '3px' }} />
            ) : null}
            {deleteAll ? (
                <>
                    <span className="rioglyph rioglyph-trash margin-right-4" style={{ marginTop: '3px' }} />
                    <span className="text-size-14">{intl.formatMessage({ id: 'e4c.global.deleteAll' })}</span>
                </>
            ) : (
                <span className="text-size-14">{intl.formatMessage({ id: 'e4c.global.delete' })}</span>
            )}
        </div>
    );
};

export default DeleteEvents;
