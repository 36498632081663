import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { type TableViewTogglesViewType } from '@rio-cloud/rio-uikit/TableViewToggles';
import { SortDirection, Vehicle } from '../../vehicleCharging/types';
import AbstractElectricVehiclesSorter from '../../vehicleCharging/sorters/AbstractElectricVehiclesSorter';
import ChargingStatus from '../../vehicleCharging/models/enums/ChargingStatus';
import { State } from '../../../../types';
import { ErrorCodeEnum } from '../../common/enums/ErrorCode';

export const VEHICLES_LOADING_STARTED = 'VEHICLES_LOADING_STARTED';
export const VEHICLES_LOADING_SUCCESSFUL = 'VEHICLES_LOADING_SUCCESSFUL';
export const VEHICLES_LOADING_FAILED = 'VEHICLES_LOADING_FAILED';
export const VEHICLES_TABLE_VIEW_TYPE_CHANGED = 'VEHICLES_TABLE_VIEW_TYPE_CHANGED';
export const VEHICLES_TABLE_ROW_SELECTED = 'VEHICLES_TABLE_ROW_SELECTED';
export const VEHICLES_TABLE_SORT_CHANGED = 'VEHICLES_TABLE_SORT_CHANGED';
export const VEHICLES_TABLE_SEARCH_VALUE_CHANGED = 'VEHICLES_TABLE_SEARCH_VALUE_CHANGED';
export const VEHICLES_TABLE_SHOW_SETTINGS_TOGGLED = 'VEHICLES_TABLE_SHOW_SETTINGS_TOGGLED';
export const VEHICLES_TABLE_CHARGING_STATUS_FILTER_CHANGED = 'VEHICLES_TABLE_CHARGING_STATUS_FILTER_CHANGED';

export interface VehiclesLoadingStartedAction {
    type: typeof VEHICLES_LOADING_STARTED;
}

export interface VehiclesLoadingSuccessfulAction {
    type: typeof VEHICLES_LOADING_SUCCESSFUL;
    payload: Vehicle[];
}

export interface VehiclesLoadingFailedAction {
    type: typeof VEHICLES_LOADING_FAILED;
    payload: ErrorCodeEnum | null;
}

export interface VehiclesTableViewTypeChangedAction {
    type: typeof VEHICLES_TABLE_VIEW_TYPE_CHANGED;
    payload: TableViewTogglesViewType;
}

export interface VehiclesTableRowSelectedAction {
    type: typeof VEHICLES_TABLE_ROW_SELECTED;
    payload: string | null;
}

export interface VehiclesTableSortChanged {
    type: typeof VEHICLES_TABLE_SORT_CHANGED;
    sortDir: SortDirection;
    sorter: AbstractElectricVehiclesSorter;
}

export interface VehiclesTableShowSettingsToggledAction {
    type: typeof VEHICLES_TABLE_SHOW_SETTINGS_TOGGLED;
}

export interface VehiclesTableSearchValueChangedAction {
    type: typeof VEHICLES_TABLE_SEARCH_VALUE_CHANGED;
    payload: string;
}

export interface VehiclesTableChargingStatusFilterChangedAction {
    type: typeof VEHICLES_TABLE_CHARGING_STATUS_FILTER_CHANGED;
    payload: (ChargingStatus | undefined)[];
}

export type VehiclesActions =
    | VehiclesLoadingStartedAction
    | VehiclesLoadingSuccessfulAction
    | VehiclesLoadingFailedAction
    | VehiclesTableViewTypeChangedAction
    | VehiclesTableRowSelectedAction
    | VehiclesTableSortChanged
    | VehiclesTableSearchValueChangedAction
    | VehiclesTableShowSettingsToggledAction
    | VehiclesTableChargingStatusFilterChangedAction;

export type VehicleThunkAction<R> = ThunkAction<R, State, undefined, VehiclesActions>;
export type VehiclesThunkDispatch = ThunkDispatch<State, undefined, VehiclesActions>;
