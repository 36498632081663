import { ReactNode } from 'react';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import ApplicationLayoutBodyBanner from '@rio-cloud/rio-uikit/ApplicationLayoutBodyBanner';
import PageLoader from '../PageLoader';
import ErrorState from '../states/ErrorState';
import { config } from '../../../../../config';

interface Validation {
    isError?: boolean;
    isLoading?: boolean;
}

interface ApplicationLayoutSkeletonProps extends Validation {
    children: ReactNode;
    error?: ReactNode;
    loading?: ReactNode;
    isLoading?: boolean;
    sidebarLeftContent?: SideBarContentProps;
    sidebarRightContent?: SideBarContentProps;
}

interface SideBarContentProps extends Validation {
    content?: ReactNode;
}

interface BodyContentProps extends Validation {
    errorComponent?: ReactNode;
    loadingComponent?: ReactNode;
    children: ReactNode;
}

const SidebarContent = ({ isLoading, isError, content }: SideBarContentProps) => {
    if (isLoading) {
        return <Spinner />;
    }
    if (isError) {
        return <ErrorState stateProps={{ type: 'general' }} />;
    }
    return <>{content}</>;
};

const BodyContent = ({ children, isLoading, isError, errorComponent, loadingComponent }: BodyContentProps) => {
    if (isLoading) {
        return (
            <div className="display-flex justify-content-center align-items-center height-100pct">
                {loadingComponent}
            </div>
        );
    }
    if (isError) {
        return (
            <div className="display-flex justify-content-center align-items-center height-100pct">{errorComponent}</div>
        );
    }
    return <>{children}</>;
};

const ApplicationLayoutSkeleton = ({
    children,
    error,
    isError,
    loading,
    isLoading,
    sidebarLeftContent,
    sidebarRightContent,
}: ApplicationLayoutSkeletonProps) => {
    const errorComponent = error ?? <ErrorState stateProps={{ type: 'general' }} />;
    const loadingComponent = loading ?? <PageLoader />;
    return (
        <>
            {sidebarLeftContent && (
                <ApplicationLayout.Sidebar>
                    <SidebarContent {...sidebarLeftContent} />
                </ApplicationLayout.Sidebar>
            )}
            <ApplicationLayout.Body
                className="responsive"
                banner={
                    config.banner.enable && (
                        <ApplicationLayoutBodyBanner>{config.banner.message}</ApplicationLayoutBodyBanner>
                    )
                }
            >
                <BodyContent
                    isError={isError}
                    isLoading={isLoading}
                    errorComponent={errorComponent}
                    loadingComponent={loadingComponent}
                >
                    {children}
                </BodyContent>
            </ApplicationLayout.Body>
            {sidebarRightContent && (
                <ApplicationLayout.Sidebar className="right">
                    <SidebarContent {...sidebarRightContent} />
                </ApplicationLayout.Sidebar>
            )}
        </>
    );
};

export default ApplicationLayoutSkeleton;
