import VehicleType from '../../../common/vehicles/VehicleType';
import VehicleTypeClass from './VehicleTypeClass';

const mapVehicleTypeToClass = (vehicleType: VehicleType): VehicleTypeClass => {
    switch (vehicleType) {
        case VehicleType.BUS:
            return VehicleTypeClass.BUS;
        case VehicleType.VAN:
            return VehicleTypeClass.VAN;
        case VehicleType.TRUCK:
            return VehicleTypeClass.TRUCK;
        default:
            return VehicleTypeClass.BUS;
    }
};

export default mapVehicleTypeToClass;
