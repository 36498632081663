import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FooterProperties, Timer } from './types';
import { ChargingModeEnum, ChargingStatusEnum } from '../../../types';
import Notification from '@rio-cloud/rio-uikit/Notification';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import notVanishingNotificationWarning from './notVanishingNotificationWarning';
import { days, getTimeHrs, getTimeMins } from './vehicleDetailsHelper';
import { SwitchToImmediateChargingReason } from '../../types';
import SwitchToImmediateChargingDialog from '../VehicleDetailsDialogs/SwitchToImmediateChargingDialog/SwitchToImmediateChargingDialog';
import SaveOrCancelFooter from '../VehicleDetailsFooter/SaveOrCancelFooter/SaveOrCancelFooter';

let timerMap: Map<string, string[]> = new Map();

const DEPARTURE_TIME_WARNING_SPAN = 5;
const messageNextDepartureTimeTooSoon = (
    <FormattedMessage id={'e4c.vehicle.details.configurationChangeConfirmationDialog.nextDepartureTooSoon'} />
);
const messageDepartureTimesTooSoon = (
    <FormattedMessage id={'e4c.vehicle.details.configurationChangeConfirmationDialog.departureTimesTooSoon'} />
);
const messageOngoingChargingAffected = (
    <FormattedMessage id={'e4c.vehicle.details.configurationChangeConfirmationDialog.ongoingChargingAffected'} />
);
const messageSaveChanges = <FormattedMessage id={'e4c.vehicle.details.saveChanges'} />;
let confirmationDialogMessages = Array<any>();

export default class Footer extends React.Component<FooterProperties, {}> {
    onImmediateChargingDialogConfirm(): void {
        this.props.onChargingModeChange(ChargingModeEnum.IMMEDIATE);
        this.closeSwitchToImmediateChargingDialog();
        if (this.props.timerConfiguration) {
            this.props.onTimerChargingSave(this.props.accessToken, this.props.assetId, this.props.timerConfiguration);
        }
    }

    onImmediateChargingDialogCancel(): void {
        this.closeSwitchToImmediateChargingDialog();
    }

    private closeSwitchToImmediateChargingDialog(): void {
        this.props.onShowSwitchToImmediateChargingDialog(false);
    }

    reasonForSwitchToImmediateCharging(): SwitchToImmediateChargingReason {
        if (this.props.timerConfiguration && this.props.timerConfiguration.timers.length === 0) {
            return SwitchToImmediateChargingReason.ALL_DELETED;
        } else {
            return SwitchToImmediateChargingReason.ALL_DISABLED;
        }
    }

    static handleSaveChargerConfig(props: FooterProperties): void {
        props.toggleUnsavedVehicleChanges(false);
        // reset messages for confirmation dialog
        confirmationDialogMessages = [];
        const isOngoingChargingAffected = props.chargingStatus === ChargingStatusEnum.CHARGING;

        if (props.chargingMode === ChargingModeEnum.IMMEDIATE) {
            if (isOngoingChargingAffected) {
                confirmationDialogMessages.push(messageOngoingChargingAffected);
                props.onShowConfigurationChangeConfirmDialog(true);
            } else {
                props.onImmediateChargingSave(
                    props.accessToken,
                    props.assetId,
                    props.climateMode,
                    props.targetStateOfCharge
                );
            }
        } else if (props.chargingMode === ChargingModeEnum.TIMER && props.timerConfiguration) {
            // Ask the user about the switch to immediate charging if all timers have been disabled/removed
            const enabledTimersCount = props.timerConfiguration.timers.filter(timer => timer.enabled).length;
            if (enabledTimersCount === 0) {
                props.onShowSwitchToImmediateChargingDialog(true);
                return;
            }
            const timersWithoutSelectedDays = props.timerConfiguration.timers.filter(
                timer => timer.weekdays.length === 0
            );
            const hasDuplicateTimers = Footer.checkDuplicateTimers(props.timerConfiguration.timers);
            const isDepartureTimeTooSoon = Footer.areDepartureTimesTooSoon();
            const isNextDepartureTimeTooSoon = Footer.isNextDepartureTimeTooSoon();

            if (hasDuplicateTimers) {
                notVanishingNotificationWarning(
                    <FormattedMessage id={'e4c.vehicle.details.warning.identicalTimers'} />
                );
                return;
            }
            if (timersWithoutSelectedDays.length !== 0) {
                Notification.error('Timer has no selected days', 'Invalid timer', 3000);
                return;
            }

            if (isOngoingChargingAffected) {
                confirmationDialogMessages.push(messageOngoingChargingAffected);
            }
            if (isNextDepartureTimeTooSoon) {
                confirmationDialogMessages.push(messageNextDepartureTimeTooSoon);
            }
            if (isDepartureTimeTooSoon) {
                confirmationDialogMessages.push(messageDepartureTimesTooSoon);
            }
            if (isOngoingChargingAffected || isNextDepartureTimeTooSoon || isDepartureTimeTooSoon) {
                props.onShowConfigurationChangeConfirmDialog(true);
            }
            if (
                timersWithoutSelectedDays.length === 0 &&
                !hasDuplicateTimers &&
                !isOngoingChargingAffected &&
                !isNextDepartureTimeTooSoon &&
                !isDepartureTimeTooSoon
            ) {
                props.onTimerChargingSave(props.accessToken, props.assetId, props.timerConfiguration);
            }
        } else {
            Notification.error('Invalid configuration', 'Invalid configuration', 3000);
        }
    }

    static onConfirmConfigurationChange(props: FooterProperties): void {
        props.toggleUnsavedVehicleChanges(false);
        props.onConfirmConfigurationChange();
        if (props.chargingMode === ChargingModeEnum.IMMEDIATE) {
            props.onImmediateChargingSave(
                props.accessToken,
                props.assetId,
                props.climateMode,
                props.targetStateOfCharge
            );
        } else if (props.chargingMode === ChargingModeEnum.TIMER && props.timerConfiguration) {
            props.onTimerChargingSave(props.accessToken, props.assetId, props.timerConfiguration);
        }
    }

    static onCancelConfigurationChange(props: FooterProperties): void {
        props.onShowConfigurationChangeConfirmDialog(false);
    }

    /*  This method creates a HashMap<Weekday, Array of departure times> with sorted departure times array.
    As soon as the first duplicated departure time for one weekday is found the method returns false. */
    static checkDuplicateTimers(timers: Timer[]): boolean {
        timerMap = new Map();
        let duplicateFound = false;
        timers.forEach(timer => {
            if (duplicateFound) {
                return true;
            } else {
                timer.weekdays.forEach(weekday => {
                    if (timerMap.has(weekday) && !duplicateFound) {
                        const tempList = timerMap.get(weekday);
                        if (tempList) {
                            const existingDepartureTime = tempList.find(
                                departureTime => timer.departure_time === departureTime
                            );
                            if (existingDepartureTime) {
                                duplicateFound = true;
                                return;
                            } else {
                                tempList.push(timer.departure_time);
                                tempList.sort();
                            }
                        }
                    } else {
                        const departureList: string[] = [];
                        departureList.push(timer.departure_time);
                        timerMap.set(weekday.toString(), departureList);
                    }
                    return false;
                });
                return false;
            }
        });
        return duplicateFound;
    }

    static isNextDepartureTimeTooSoon(): boolean {
        const now = new Date();
        const currentTimeHrs = now.getHours();
        const currentTimeMins = now.getMinutes();
        const currentDay = new Date().getDay();
        const currentDayName = days[currentDay];
        // check first next due departure time
        const timerOnCurrentDay = timerMap.get(currentDayName);
        if (timerOnCurrentDay) {
            const timerOnCurrentDayDepartureTimeHrs = getTimeHrs(timerOnCurrentDay[0]);
            const timerOnCurrentDayDepartureTimeMins = getTimeMins(timerOnCurrentDay[0]);
            if (
                timerOnCurrentDayDepartureTimeHrs > currentTimeHrs &&
                (timerOnCurrentDayDepartureTimeHrs - currentTimeHrs < DEPARTURE_TIME_WARNING_SPAN ||
                    (timerOnCurrentDayDepartureTimeHrs - currentTimeHrs === DEPARTURE_TIME_WARNING_SPAN &&
                        timerOnCurrentDayDepartureTimeMins < currentTimeMins))
            ) {
                return true;
            }
        } else if (
            currentTimeHrs > 24 - DEPARTURE_TIME_WARNING_SPAN ||
            (currentTimeHrs === 24 - DEPARTURE_TIME_WARNING_SPAN && currentTimeMins > 0)
        ) {
            // if it's later than 24hrs - DEPARTURE_TIME_WARNING_SPAN => check timers on the following day
            const nextDay = currentDay === 6 ? 0 : currentDay + 1;
            const timerOnNextDay = timerMap.get(days[nextDay]);
            if (timerOnNextDay) {
                const timerOnNextDayDepartureTimeHrs = getTimeHrs(timerOnNextDay[0]);
                const timerOnNextDayDepartureTimeMins = getTimeMins(timerOnNextDay[0]);
                if (
                    Footer.isTimerOnNextDayTooSoon(
                        currentTimeHrs,
                        timerOnNextDayDepartureTimeHrs,
                        currentTimeMins,
                        timerOnNextDayDepartureTimeMins
                    )
                ) {
                    return true;
                }
            }
        }
        return false;
    }

    static areDepartureTimesTooSoon(): boolean {
        for (const weekday of timerMap.keys()) {
            const tempList = timerMap.get(weekday);
            // check difference between departure times on current day
            if (tempList && tempList.length > 1) {
                for (let i = 0; i < tempList.length; i++) {
                    let nextDue = '';
                    if (tempList[i + 1]) {
                        const tempDepartureTime = tempList[i];
                        const tempDepartureTimeHrs = getTimeHrs(tempDepartureTime);
                        const tempDepartureTimeMins = getTimeMins(tempDepartureTime);
                        nextDue = tempList[i + 1];
                        const nextDueTimeHrs = getTimeHrs(nextDue);
                        const nextDueTimeMins = getTimeMins(nextDue);
                        if (
                            nextDueTimeHrs - tempDepartureTimeHrs < DEPARTURE_TIME_WARNING_SPAN ||
                            (nextDueTimeHrs - tempDepartureTimeHrs === DEPARTURE_TIME_WARNING_SPAN &&
                                nextDueTimeMins < tempDepartureTimeMins)
                        ) {
                            return true;
                        }
                    }
                }
            }

            // check difference between last departure time on current day and first departure time on the following day
            if (tempList) {
                const lastDepartureTimePerDay = tempList[tempList.length - 1];
                const lastDepartureTimePerDayHrs = getTimeHrs(lastDepartureTimePerDay);
                const lastDepartureTimePerDayMins = getTimeMins(lastDepartureTimePerDay);
                const nextDayIndex = days.indexOf(weekday) === 6 ? 0 : days.indexOf(weekday) + 1;
                if (timerMap.get(days[nextDayIndex])) {
                    const tempDepartureTimesList = timerMap.get(days[nextDayIndex]);
                    if (tempDepartureTimesList) {
                        const nextDepartureTime = tempDepartureTimesList[0];
                        const nextDepartureTimeHrs = getTimeHrs(nextDepartureTime);
                        const nextDepartureTimeMins = getTimeMins(nextDepartureTime);
                        if (
                            Footer.isTimerOnNextDayTooSoon(
                                lastDepartureTimePerDayHrs,
                                nextDepartureTimeHrs,
                                lastDepartureTimePerDayMins,
                                nextDepartureTimeMins
                            )
                        ) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    static isTimerOnNextDayTooSoon = (
        departureTimeInHrsCurrentDay: number,
        departureTimeInHrsNextDay: number,
        departureTimeInMinsCurrentDay: number,
        departureTimeInMinsNextDay: number
    ): boolean => {
        return (
            24 - departureTimeInHrsCurrentDay + departureTimeInHrsNextDay < DEPARTURE_TIME_WARNING_SPAN ||
            (24 - departureTimeInHrsCurrentDay + departureTimeInHrsNextDay === DEPARTURE_TIME_WARNING_SPAN &&
                departureTimeInMinsNextDay < departureTimeInMinsCurrentDay)
        );
    };

    render(): JSX.Element {
        if (this.props.sendingChargingConfigSucceeded) {
            this.props.updateVehiclesTable(this.props.accessToken);
            this.props.updateVehicleDetails(this.props.accessToken, this.props.assetId, this.props.vehicle);
        }

        const handleSaveChargerConfig = (): void => {
            Footer.handleSaveChargerConfig(this.props);
        };

        const onConfirmConfigurationChange = (): void => {
            Footer.onConfirmConfigurationChange(this.props);
        };

        const onCancelConfigurationChange = (): void => {
            Footer.onCancelConfigurationChange(this.props);
        };

        const dialogContent = (
            <div>
                {confirmationDialogMessages.map((message, index) => (
                    <div key={index}>
                        {message}
                        <br />
                        <br />
                    </div>
                ))}
                <div>{messageSaveChanges}</div>
            </div>
        );

        return (
            <React.Fragment>
                <div className="white-space-pre-line">
                    <ConfirmationDialog
                        show={this.props.showConfigurationChangeConfirmDialog}
                        title={
                            <FormattedMessage id={'e4c.vehicle.details.configurationChangeConfirmationDialog.title'} />
                        }
                        content={dialogContent}
                        bsSize={'sm'}
                        onClickConfirm={onConfirmConfigurationChange}
                        onClickCancel={onCancelConfigurationChange}
                        cancelButtonText={<FormattedMessage id="e4c.global.cancel" />}
                        confirmButtonText={<FormattedMessage id="e4c.global.confirm" />}
                        useOverflow
                    />
                    <SwitchToImmediateChargingDialog
                        show={this.props.showSwitchToImmediateChargingDialog}
                        onCancel={(): void => this.onImmediateChargingDialogCancel()}
                        onConfirm={(): void => this.onImmediateChargingDialogConfirm()}
                        reason={this.reasonForSwitchToImmediateCharging()}
                    />
                </div>
                <SaveOrCancelFooter
                    disabled={this.props.saveButtonDisabled}
                    onClose={this.props.onSidebarClose}
                    onSave={handleSaveChargerConfig}
                />
            </React.Fragment>
        );
    }
}
