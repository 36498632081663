import AbstractElectricVehiclesSorter from './AbstractElectricVehiclesSorter';
import SortDirection from '../../common/utilities/sort/SortDirection';
import orderBy from 'lodash/orderBy';
import { SORT_KEY_REMAINING_RANGE } from './keys';
import { VehicleChargingData } from '../models/VehicleChargingData';

class RemainingRangeSorter extends AbstractElectricVehiclesSorter {
    private readonly DEFAULT_LOWER_REMAINING_RANGE = -1;

    constructor() {
        super(SORT_KEY_REMAINING_RANGE);
    }

    sort = (array: VehicleChargingData[], direction: SortDirection): VehicleChargingData[] =>
        orderBy<VehicleChargingData>(
            array,
            e => e.measurements.remainingRange.value ?? this.DEFAULT_LOWER_REMAINING_RANGE,
            direction
        );
}

export default RemainingRangeSorter;
