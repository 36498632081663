import { ChargeCyclesApi, ChargeCyclesResponseApi } from '../CycleDataTable/chargeCyclesResponse';
import { Cycle } from '../CycleDataTable/types';

export const mappingChargeCyclesFromServer = (response: ChargeCyclesResponseApi): Cycle[] => {
    return response.cycles?.map((cycle: ChargeCyclesApi) => {
        return {
            type: cycle.type,
            startTime: new Date(cycle.start_time),
            endTime: new Date(cycle.end_time),
            duration: cycle.duration,
            energyCharged: cycle.energy_charged ?? undefined,
            energyConsumed: cycle.energy_consumed ?? undefined,
            rangeDriven: cycle.range_driven ?? undefined,
            rangeAdded: cycle.range_added ?? undefined,
            consumption: cycle.consumption ?? undefined,
            socAtStart: cycle.state_of_charge_at_start ?? undefined,
            socAtEnd: cycle.state_of_charge_at_end ?? undefined,
            batteryTemperature: {
                status: cycle.battery_temperature.status,
                minBatteryTemperature: cycle.battery_temperature.min_battery_temperature ?? undefined,
                maxBatteryTemperature: cycle.battery_temperature.max_battery_temperature ?? undefined,
            },
        };
    });
};
