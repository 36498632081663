import SeeConsumptionHistoryLink from './SeeConsumptionHistoryLink';
import { Moment } from 'moment';

interface KpiPanelFooterProps {
    selectedVehicleIds: string[];
    startDate: Moment;
    endDate: Moment;
    localStoragePrefix: string;
}

const KpiPanelFooter = (props: KpiPanelFooterProps): JSX.Element => (
    <div className={'display-flex justify-content-end'}>
        <SeeConsumptionHistoryLink
            assetIds={props.selectedVehicleIds}
            currentStartDate={props.startDate}
            currentEndDate={props.endDate}
            localStoragePrefix={props.localStoragePrefix}
        />
    </div>
);

export default KpiPanelFooter;
