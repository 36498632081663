import { VehiclesStatisticsState } from './types';
import {
    VehiclesStatisticsActions,
    VEHICLES_STATISTICS_LOADING_STARTED,
    VEHICLES_STATISTICS_LOADING_SUCCESSFUL,
    VEHICLES_STATISTICS_LOADING_FAILED,
} from '../../actions/statistics/types';

const initialState: VehiclesStatisticsState = {
    vehiclesStatistics: [],
    fetchingVehiclesStatistics: false,
};

const reducer = (
    state: VehiclesStatisticsState = initialState,
    action: VehiclesStatisticsActions
): VehiclesStatisticsState => {
    switch (action.type) {
        case VEHICLES_STATISTICS_LOADING_STARTED:
            return {
                ...state,
                fetchingVehiclesStatistics: true,
                vehiclesStatistics: [],
            };
        case VEHICLES_STATISTICS_LOADING_SUCCESSFUL:
            return {
                ...state,
                fetchingVehiclesStatistics: false,
                vehiclesStatistics: action.payload,
            };
        case VEHICLES_STATISTICS_LOADING_FAILED:
            return {
                ...state,
                fetchingVehiclesStatistics: false,
                vehiclesStatistics: [],
            };
        default:
            return state;
    }
};

export default reducer;
