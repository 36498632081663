import StatsWidget from '@rio-cloud/rio-uikit/StatsWidget';
import StatsWidgets from '@rio-cloud/rio-uikit/StatsWidgets';
import StatsWidgetNumber from '@rio-cloud/rio-uikit/StatsWidgetNumber';
import {
    lblCharged,
    lblCharging,
    lblConnected,
    lblDisconnected,
    lblDriving,
    lblReadyToDrive,
} from '../../../vehicleCharging/components/FilterWidget/FilterWidget';
import ElectricVehicle from '../../../vehicleCharging/models/ElectricVehicle';
import { ChargingStatusEnum } from '../../../vehicleCharging/types';
import { getNumberOfVehiclesWithChargingStatusUsingVehicle } from '../../helpers/getNumberOfVehiclesChargingStatus';
import { SkeletonChargingPanel } from '../../skeleton/SkeletonStatusPanel';
import { FormattedMessage } from 'react-intl';

const StatsWrapper: React.FC<{
    header?: React.ReactNode;
    children: React.ReactChild | React.ReactChild[];
    headerStyle?: Object;
}> = ({ header, headerStyle, children }) => {
    return (
        <StatsWidget className="border-none">
            <div style={{ textAlign: 'center', padding: 0, border: 0, ...headerStyle }}>{header}</div>
            <StatsWidget.Body className="padding-0">{children}</StatsWidget.Body>
        </StatsWidget>
    );
};

const VehicleChargingStatus: React.FC<{
    vehicles: ElectricVehicle[];
    isFetching: boolean;
    hasMProductBooking: boolean;
    vehiclesDrivingCount: number;
    vehiclesReadyToDriveCount: number;
}> = ({ vehicles, isFetching, hasMProductBooking, vehiclesDrivingCount, vehiclesReadyToDriveCount }) => {
    if (isFetching) {
        return <SkeletonChargingPanel />;
    }
    return (
        <StatsWidgets className="gap-0 margin-0">
            <StatsWrapper
                header={<span className="rioglyph rioglyph-ok-sign text-size-h2" />}
                headerStyle={{ marginRight: '16px' }}
            >
                <StatsWidgetNumber
                    className="text-color-darkest margin-y-0 margin-right-15 margin-left-0"
                    value={vehiclesReadyToDriveCount}
                    label={<div style={{ fontSize: '14px' }}>{lblReadyToDrive}</div>}
                />
            </StatsWrapper>
            <div style={{ width: '2px', background: '#d0d8de' }} />
            <StatsWrapper header={<span className="rioglyph-plugged-on text-color-success text-size-h2" />}>
                <StatsWidgetNumber
                    className="text-color-success margin-y-0 margin-x-15"
                    value={getNumberOfVehiclesWithChargingStatusUsingVehicle(vehicles, [ChargingStatusEnum.CHARGED])}
                    label={<div style={{ fontSize: '14px' }}>{lblCharged}</div>}
                />
                <StatsWidgetNumber
                    className="text-color-success margin-y-0 margin-x-15"
                    value={getNumberOfVehiclesWithChargingStatusUsingVehicle(vehicles, [
                        ChargingStatusEnum.CHARGING,
                        ChargingStatusEnum.CHARGING_LONGPRESS,
                    ])}
                    label={<div style={{ fontSize: '14px' }}>{lblCharging}</div>}
                />
            </StatsWrapper>
            <div style={{ width: '2px', background: '#d0d8de' }} />
            <StatsWrapper header={<span className="rioglyph-plugged-on text-color-warning text-size-h2" />}>
                <StatsWidgetNumber
                    className="text-color-warning margin-y-0 margin-x-15"
                    value={getNumberOfVehiclesWithChargingStatusUsingVehicle(vehicles, [ChargingStatusEnum.CONNECTED])}
                    label={<div style={{ fontSize: '14px' }}>{lblConnected}</div>}
                />
            </StatsWrapper>

            <div style={{ width: '2px', background: '#d0d8de' }} />
            <StatsWrapper header={<span className="rioglyph-plugged-off text-color-gray text-size-h2" />}>
                <StatsWidgetNumber
                    className="text-color-gray margin-y-0 margin-x-15"
                    value={getNumberOfVehiclesWithChargingStatusUsingVehicle(vehicles, [
                        ChargingStatusEnum.DISCONNECTED,
                    ])}
                    label={<div style={{ fontSize: '14px' }}>{lblDisconnected}</div>}
                />
            </StatsWrapper>

            <div style={{ width: '2px', background: '#d0d8de' }} />
            {hasMProductBooking ? (
                <StatsWrapper
                    header={<span className="rioglyph-steering-wheel text-color-status-driving text-size-h2" />}
                    headerStyle={{ marginLeft: '16px' }}
                >
                    <StatsWidgetNumber
                        className="text-color-status-driving margin-y-0 margin-right-0 margin-left-15"
                        value={vehiclesDrivingCount}
                        label={<div style={{ fontSize: '14px' }}>{lblDriving}</div>}
                    />
                    <div className="position-relative width-100pct" style={{ marginLeft: '16px' }}>
                        <small className="text-color-dark text-size-10 text-normal position-absolute width-100pct text-center">
                            <FormattedMessage id={'e4c.overview.vehicleStatus.vehiclesDriving.explanation'} />
                        </small>
                    </div>
                </StatsWrapper>
            ) : (
                <StatsWrapper>
                    <div className="display-flex flex-column text-medium align-self-end align-items-center">
                        <small
                            style={{ fontSize: '12px', marginTop: '12px' }}
                            className="text-color-dark text-normal width-80 text-center"
                        >
                            <FormattedMessage id="e4c.overview.widget.explanationMProduct" />
                            <span
                                style={{ fontSize: '12px' }}
                                className={'label label-condensed width-30 label-primary'}
                            >
                                M
                            </span>
                        </small>

                        <div style={{ fontSize: '14px', fontWeight: 400, marginTop: '6px' }}>
                            <FormattedMessage id={'e4c.overview.vehicleStatus.vehiclesDriving.title'} />
                        </div>
                    </div>
                </StatsWrapper>
            )}
        </StatsWidgets>
    );
};

export default VehicleChargingStatus;
