export enum ChargingCycleType {
    CHARGING = 'CHARGING',
    DISCHARGING = 'DISCHARGING',
}

export enum BatteryTemperatureStatus {
    OK = 'OK',
    WARNING = 'WARNING',
    MIN_WARNING = 'MIN_WARNING',
    MAX_WARNING = 'MAX_WARNING',
}

export interface Cycle {
    type: ChargingCycleType;
    startTime: Date;
    endTime: Date;
    duration: number;
    energyCharged?: number;
    energyConsumed?: number;
    rangeDriven?: number;
    rangeAdded?: number;
    consumption?: number;
    socAtStart?: number;
    socAtEnd?: number;
    batteryTemperature: BatteryTemperature;
}

export interface BatteryTemperature {
    status: BatteryTemperatureStatus;
    minBatteryTemperature?: number;
    maxBatteryTemperature?: number;
}
