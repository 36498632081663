import { useIntl } from 'react-intl';
import FleetEventWidgetEmpty from '../../../../assets/svg/fleet-event-widget-empty';
import { EventStatus } from '../../../models/EventStatusEnum';
import InformativeState from '../../../../common/components/states/InformativeState';

const EmptyEvents = ({ status }: { status?: EventStatus }): JSX.Element => {
    const intl = useIntl();

    if (!status) {
        const headline = (
            <div className="margin-top-15">
                {intl.formatMessage({ id: 'e4c.overview.events.widget.modal.events.empty.title' })}
            </div>
        );
        return (
            <InformativeState
                headline={headline}
                message={intl.formatMessage({ id: 'e4c.overview.events.widget.modal.events.empty.subtitle' })}
                testId="empty-events"
                image={<FleetEventWidgetEmpty />}
                withoutBorder
            />
        );
    }

    const isNewStatus = status === EventStatus.NEW;
    return (
        <div>
            <InformativeState
                headline={
                    isNewStatus
                        ? intl.formatMessage({ id: 'e4c.overview.events.widget.modal.events.empty.title' })
                        : intl.formatMessage({ id: 'e4c.overview.events.widget.modal.seenEvents.empty.title' })
                }
                message={
                    isNewStatus
                        ? intl.formatMessage({ id: 'e4c.overview.events.widget.modal.events.empty.subtitle' })
                        : intl.formatMessage({
                              id: 'e4c.overview.events.widget.modal.seenEvents.empty.subtitle',
                          })
                }
                testId="empty-events"
                withoutBorder
            />
        </div>
    );
};

export default EmptyEvents;
