import { AccessToken } from '../../../../../configuration';
import {
    VEHICLES_STATISTICS_LOADING_FAILED,
    VEHICLES_STATISTICS_LOADING_STARTED,
    VEHICLES_STATISTICS_LOADING_SUCCESSFUL,
    VehiclesStatisticsThunkAction,
    VehiclesStatisticsThunkDispatch,
} from './types';
import { config } from '../../../../../config';
import _ from 'lodash';
import { VehicleStatistics } from '../../types';
import { getEndDate, getStartDate } from '../../../utils/dates';

let token = '';

export const fetchVehiclesStatistics = (accessToken: AccessToken): VehiclesStatisticsThunkAction<void> => {
    return async (dispatch: VehiclesStatisticsThunkDispatch): Promise<void> => {
        dispatch({
            type: VEHICLES_STATISTICS_LOADING_STARTED,
        });
        if (import.meta.env.DEV) {
            token = await getAccessTokenFromMonitoringService();
        }
        const assetsStatisticsEndpoint = `${config.backend.OTA_CHARGING_MONITORING_SERVICE}/assets/statistics`
            .concat(`?start_date=${getStartDate()}`)
            .concat(`&end_date=${getEndDate()}`);
        const response = await fetch(assetsStatisticsEndpoint, {
            headers: {
                Authorization: `Bearer ${import.meta.env.DEV ? token : accessToken}`,
            },
        });

        if (response.status === 200) {
            const json = await response.json();
            const vehiclesStatistics: VehicleStatistics[] = json.items.map((vehicleStatistics: any) => {
                return {
                    vehicleId: vehicleStatistics.asset_id,
                    history: _.get(vehicleStatistics, 'history', []).map((historyData: any) => {
                        return {
                            date: historyData.date,
                            drivenDistance: historyData.driven_distance,
                            energyConsumption: historyData.energy_consumption,
                            powertrainEnergyConsumption: historyData.power_train_energy_consumption,
                            auxiliaryEnergyConsumption: historyData.aux_energy_consumption,
                            amsEnergyConsumption: historyData.ams_energy_consumption,
                            recuperatedEnergy: historyData.recuperated_energy,
                        };
                    }),
                };
            });
            dispatch({
                type: VEHICLES_STATISTICS_LOADING_SUCCESSFUL,
                payload: vehiclesStatistics,
            });
        } else {
            dispatch({
                type: VEHICLES_STATISTICS_LOADING_FAILED,
            });
        }
    };
};

export const getAccessTokenFromMonitoringService = async (): Promise<string> => {
    const tokenEndpoint = `${config.backend.OTA_CHARGING_MONITORING_SERVICE}/monitoringservicetoken`;
    const response = await fetch(tokenEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            sub: 'test:38675105-97ab-48be-b88d-9763a4cea6f8',
            scope: 'ota-charging.read ota-charging.write',
            iss: 'http://localhost:9001',
            iat: 1571922764,
            exp: 2572452024,
            account: '237c58e3-60b5-4442-a9ec-8641ea39325b',
            tenant: 'prod',
            azp: 'client-id',
        }),
    });

    if (response.status === 200) {
        token = await response.text();
        return token;
    } else {
        return 'valid-mocked-oauth-bogus-token';
    }
};
