import { KpiEnum } from '../types';
import moment from 'moment';

export const STORAGE_SUFFIX_TIMEFRAME = 'timeFrame';
export const STORAGE_SUFFIX_VEHICLE_IDS = 'vehicleIds';
const STORAGE_SUFFIX_KPI = 'kpi';

export interface Timeframe {
    startDate: string;
    endDate: string;
}

const hasStorage = (): boolean => typeof Storage !== 'undefined';

const parseJson = (value: string): any | null => {
    try {
        return JSON.parse(value);
    } catch (e) {
        return null;
    }
};

const stringToTimeframe = (value: string | null): Timeframe => (value === null ? null : parseJson(value));

const stringToKpiEnum = (value: string | null): KpiEnum => value as KpiEnum;

const stringToStringArray = (value: string | null): string[] => (value === null ? null : parseJson(value));

const isValidTimeframe = (timeframe: Timeframe): boolean => {
    let startDateValid = false;
    let endDateValid = false;
    if (timeframe !== null) {
        if (timeframe.startDate !== undefined) {
            startDateValid = isNaN(Number(timeframe.startDate)) && moment(timeframe.startDate).isValid();
        }
        if (timeframe.endDate !== undefined) {
            endDateValid = isNaN(Number(timeframe.endDate)) && moment(timeframe.endDate).isValid();
        }
    }
    return startDateValid && endDateValid;
};

export const readTimeframeFromStorage = (prefix: string): Timeframe | null => {
    if (hasStorage()) {
        const timeframe = stringToTimeframe(localStorage.getItem(`${prefix}${STORAGE_SUFFIX_TIMEFRAME}`));
        if (isValidTimeframe(timeframe)) {
            return timeframe;
        }
    }
    return null;
};

export const saveTimeframeOnStorage = (prefix: string, timeframe: Timeframe): void => {
    if (hasStorage()) {
        localStorage.setItem(`${prefix}${STORAGE_SUFFIX_TIMEFRAME}`, JSON.stringify(timeframe));
    }
};

export const readKpiFromStorage = (prefix: string): KpiEnum | null =>
    !hasStorage() ? null : stringToKpiEnum(localStorage.getItem(`${prefix}${STORAGE_SUFFIX_KPI}`));

export const saveKpiOnStorage = (prefix: string, kpi: KpiEnum): void => {
    if (hasStorage()) {
        localStorage.setItem(`${prefix}${STORAGE_SUFFIX_KPI}`, kpi);
    }
};

export const readVehiclesFromStorage = (prefix: string): string[] | null =>
    !hasStorage() ? null : stringToStringArray(localStorage.getItem(`${prefix}${STORAGE_SUFFIX_VEHICLE_IDS}`));

export const saveVehicleIdsOnStorage = (prefix: string, vehicleIds: string[]): void => {
    if (hasStorage()) {
        localStorage.setItem(`${prefix}${STORAGE_SUFFIX_VEHICLE_IDS}`, JSON.stringify(vehicleIds));
    }
};

/**
 * Initializers
 */
const runCallback = (callback: Function, value: any): void => {
    if (callback !== undefined) {
        callback(value);
    }
};

export const initKpi = (prefix: string, defaultValue: KpiEnum, callback: (kpi: KpiEnum) => void): void => {
    let kpi = readKpiFromStorage(prefix);
    if (kpi === null || KpiEnum[kpi] === undefined) {
        kpi = defaultValue;
    }
    runCallback(callback, kpi);
};

export const initTimeframe = (
    prefix: string,
    defaultValue: Timeframe,
    callback: (timeframe: Timeframe) => void
): void => {
    let timeframe = readTimeframeFromStorage(prefix);
    if (timeframe === null) {
        timeframe = defaultValue;
    }
    runCallback(callback, timeframe);
};

export const initVehicleIds = (
    prefix: string,
    defaultValue: string[],
    callback: (vehicleIds: string[]) => void
): void => {
    let vehicleIds = readVehiclesFromStorage(prefix);
    if (vehicleIds == null || typeof vehicleIds !== 'object') {
        vehicleIds = defaultValue;
    }
    runCallback(callback, vehicleIds);
};
