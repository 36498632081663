import { ReactElement, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import Multiselect from '@rio-cloud/rio-uikit/Multiselect';
import classNames from 'classnames';
import { MultiselectOption, MultiselectOptionsType, SelectionDropdownProps } from '../../types';
import useOutsideClick from './hooks/useOutsideClick';
import { ElectricVehicleIcon } from '../../../common/components/ElectricVehicleIcon/ElectricVehicleIcon';
import VehicleType from '../../../common/vehicles/VehicleType';
import DateRangePicker from '../../../common/components/DateRangePicker/DateRangePicker';

const selectionDropdownClassNames = classNames(
    'selectionDropdown',
    'padding-15',
    'width-400',
    'bg-white',
    'border-width-1',
    'border-style-solid',
    'border-color-gray',
    'shadow-smooth',
    'position-absolute',
    'top-10',
    'right-0',
    'z-index-max'
);

const SelectionDropdown = ({
    currentEndDate,
    currentStartDate,
    changeDatesFromDropDown,
    handleChange,
    onOutsideClick,
    selectedVehicleIds,
    vehicles,
}: SelectionDropdownProps): ReactElement => {
    const selectionDropdownRef = useRef(null);
    const filteredVehiclesM = vehicles.filter(v => v.isMbooking());

    useOutsideClick(selectionDropdownRef, onOutsideClick);

    const allVehiclesOption: MultiselectOption = {
        id: MultiselectOptionsType.ALL_OPTIONS,
        label: <FormattedMessage id={'e4c.overview.charts.multiselect.allVehiclesOption'} />,
        icon: <ElectricVehicleIcon vehicleType={VehicleType.BUS} />,
        selected: selectedVehicleIds.includes(MultiselectOptionsType.ALL_OPTIONS),
    };

    const getAvailableOptions = (): MultiselectOption[] => {
        return filteredVehiclesM.map(vehicle => {
            return {
                id: vehicle.assetId,
                label: vehicle.name,
                icon: <ElectricVehicleIcon vehicleType={vehicle.vehicleType} />,
                selected: selectedVehicleIds.includes(vehicle.assetId),
            };
        });
    };

    return (
        <div className={selectionDropdownClassNames} ref={selectionDropdownRef}>
            <div className="form-group">
                <div id={'vehicles-selection'}>
                    <label className={'text-size-14'} style={{ textTransform: 'capitalize' }}>
                        <FormattedMessage id="e4c.overview.vehicles" />
                    </label>
                    <Multiselect
                        options={[allVehiclesOption, ...getAvailableOptions()]}
                        placeholder={<FormattedMessage id={'e4c.overview.charts.multiselect.selectionMessage'} />}
                        noItemMessage={<FormattedMessage id={'e4c.global.notFoundState.title'} />}
                        onChange={handleChange}
                    />
                </div>
                <div
                    id={'time-frame-selection'}
                    className="margin-top-15 border-style-solid border-color-lighter border-width-1 border-right-0 border-left-0 border-bottom-0"
                >
                    <label className={'text-size-14 margin-top-10'}>
                        <FormattedMessage id="e4c.overview.charts.timePeriod" />
                    </label>
                    <DateRangePicker
                        defaultStartValue={currentStartDate}
                        defaultEndValue={currentEndDate}
                        changeSelectedRangeDate={changeDatesFromDropDown}
                    />
                </div>
            </div>
        </div>
    );
};

export default SelectionDropdown;
