import React from 'react';
import RadioButton from '@rio-cloud/rio-uikit/RadioButton';
import { FormattedMessage } from 'react-intl';

import './ImmediatelyChargingMode.css';

interface ImmediatelyChargingModeProperties {
    isChecked: boolean;
    isReadOnly: boolean;
    onChargingModeChange: () => void;
}

export default class ImmediatelyChargingMode extends React.Component<ImmediatelyChargingModeProperties> {
    renderBody(): JSX.Element {
        return (
            <div className="display-inline-block">
                <div className="display-inline-block align-middle">
                    <span className="rioglyph rioglyph-filling-e-station text-size-24" />
                    <span className="rioglyph rioglyph-angle-double-right" />
                </div>
                <div className="display-inline-block align-middle padding-left-15 charging-configuration-immediate">
                    <span className="display-block text-color-highlight text-bold">
                        <FormattedMessage id="e4c.vehicles.charging.immediate" />
                    </span>
                    <span className="display-block text-size-12">
                        <FormattedMessage id="e4c.vehicles.charging.immediate.info" />
                    </span>
                </div>
            </div>
        );
    }

    renderWithButton(): JSX.Element {
        return (
            <RadioButton
                name="chargingMode"
                value="IMMEDIATELY"
                checked={this.props.isChecked}
                tabIndex={1}
                onChange={this.props.onChargingModeChange}
            >
                {this.renderBody()}
            </RadioButton>
        );
    }

    render(): JSX.Element {
        const selectedBorderClass =
            this.props.isChecked && !this.props.isReadOnly
                ? 'border-style-solid border-width-2 border-color-highlight'
                : '';

        return (
            <div className={`width-100pct panel panel-default margin-bottom-0 ${selectedBorderClass}`}>
                <div className="panel-body">{this.props.isReadOnly ? this.renderBody() : this.renderWithButton()}</div>
            </div>
        );
    }
}
