import { StatisticsData } from '../statistics/types';
import KpiBarChart from './KpiBarChart';
import BarTooltip from './BarTooltip';
import isEmpty from 'lodash/fp/isEmpty';

interface AverageRecuperationBarChartProps {
    statistics: StatisticsData;
}

const AverageRecuperationBarChart = (props: AverageRecuperationBarChartProps): JSX.Element => (
    <div className="padding-bottom-20 height-250">
        <KpiBarChart
            aggregationPeriodType={props.statistics.aggregationPeriodType}
            data={props.statistics.data}
            barSettings={[
                {
                    dataKey: 'averageRecuperation',
                    color: 'color-highlight',
                },
            ]}
            onAxisLeftFormat={(value): string => Number(value).toFixed(2)}
            tooltip={({ active, payload }): JSX.Element => {
                if (!active || isEmpty(payload)) {
                    return <></>;
                }
                const data = payload[0].payload;
                return (
                    <BarTooltip
                        aggregationPeriodType={props.statistics.aggregationPeriodType}
                        date={data.date}
                        vehiclesQuantity={data.vehiclesQuantity}
                        value={data.averageRecuperation}
                        units={'kWh/km'}
                    />
                );
            }}
        />
    </div>
);

export default AverageRecuperationBarChart;
