import { useInfiniteQuery } from 'react-query';
import { getEventsWithPagination } from '../../../../api/events';
import { EventsResponseApi } from '../../vehicleCharging/models/api/eventsResponse';
import mappingEventsFromServer from '../helpers/mappingEventsFromServer';
import { Event } from '../models/Event';
import { AccessToken } from '../../../../configuration';
import { EventStatus } from '../models/EventStatusEnum';
import { isEmpty } from 'lodash';

const useEventsInfinitePaginationApi = (
    accessToken: AccessToken,
    status: string,
    severityLevel: string[]
): {
    isLoading: boolean;
    isError: boolean;
    refetch: Function;
    fetchNextPage: Function;
    events: Event[];
    hasNextPage: boolean;
    countNumber: number;
    lastEventId: number | undefined;
} => {
    const { isLoading, data, refetch, fetchNextPage, isError, hasNextPage } = useInfiniteQuery(
        [`events-${status}`, severityLevel],
        ({ pageParam = '' }) =>
            getEventsWithPagination(pageParam, accessToken, status, severityLevel).then(res => {
                if (res.ok) {
                    return res.json();
                }
                throw new Error(`${res.status}: ${res.statusText}`);
            }),
        {
            getNextPageParam: lastPage => lastPage?.cursor?.next,
            refetchInterval: false,
        }
    );
    const { events, countNumber } = mappingEventsFromServer(data?.pages as EventsResponseApi[]);
    const lastEventId =
        !isEmpty(events) && status === EventStatus.NEW.toLowerCase() ? getLatestEventId(events) : undefined;

    return {
        isLoading,
        isError,
        refetch,
        fetchNextPage,
        events,
        hasNextPage: !!hasNextPage,
        countNumber,
        lastEventId,
    };
};

const getLatestEventId = (events: Event[]) => {
    const ids = events.map(event => event.id).map(id => Number(id));
    return Math.max(...ids);
};

export default useEventsInfinitePaginationApi;
