import { ReactElement, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import detailTimerChargingPanelModalImage from '../../../../../assets/images/detail-timer-charging-panel-modal.png';
import {
    createGoogleAnalyticsEvent,
    gtmUtil,
    TrackingAction,
    TrackingCategory,
} from '../../../../../utils/googleTagManager';
import UserManualPage from '../components/UserManualPage';

const TimerChargingItem = (): ReactElement => {
    const intl = useIntl();
    useEffect(() => {
        gtmUtil.pushGaEvent(
            createGoogleAnalyticsEvent(TrackingCategory.GENERAL, TrackingAction.USER_MANUAL_TIMER_CHARGING)
        );
    }, []);

    return (
        <UserManualPage
            title={<FormattedMessage id={'e4c.userManual.details.timerCharging.title'} />}
            footer={<FormattedMessage id={'e4c.userManual.overview.etgeObs'} />}
            dataTestId="timer-charging-item"
        >
            <div className="margin-bottom-20">
                <img
                    alt={intl.formatMessage({ id: 'e4c.userManual.altText.chargingModes' })}
                    src={detailTimerChargingPanelModalImage}
                    width="645"
                    height="325"
                />
            </div>
            <div className="counter color-onboarding size-small filled">
                <div>
                    <p className="margin-bottom-0 margin-top-0">
                        <span className="text-bold">
                            <FormattedMessage id={'e4c.userManual.details.timerCharging.timerCharging'} />
                        </span>{' '}
                        <FormattedMessage id={'e4c.userManual.details.timerCharging.timerChargingDescription'} />
                    </p>
                </div>
                <div>
                    <p className="margin-bottom-0 margin-top-0">
                        <span className="text-bold">
                            <FormattedMessage id={'e4c.userManual.details.timerCharging.immediateCharging'} />
                        </span>{' '}
                        <FormattedMessage id={'e4c.userManual.details.timerCharging.immediateChargingDescription'} />
                    </p>
                </div>
                <div>
                    <p className="margin-bottom-0 margin-top-0">
                        <span className="text-bold">
                            <FormattedMessage id={'e4c.userManual.details.timerCharging.vehicleProgrammed'} />
                        </span>{' '}
                        <FormattedMessage id={'e4c.userManual.details.timerCharging.vehicleProgrammedDescription'} />
                    </p>
                </div>
            </div>
        </UserManualPage>
    );
};

export default TimerChargingItem;
