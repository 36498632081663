import FleetType from './models/enums/FleetType';
import { etgeOnlyFleetAllowedColumns, initialAllowedColumns, mixedFleetAllowedColumns } from './config';

export const getAllowedColumns = (fleetType: FleetType): string[] => {
    switch (fleetType) {
        case FleetType.MIXED:
            return mixedFleetAllowedColumns;
        case FleetType.E4C_ONLY:
            return mixedFleetAllowedColumns;
        case FleetType.ETGE_ONLY:
            return etgeOnlyFleetAllowedColumns;
        default:
            return initialAllowedColumns;
    }
};
