import { AccessToken } from '../configuration';
import { isDevEnvironment } from './utils';
import { config } from '../config';
import { getLocalAccessToken } from './Api';
import { FileFormat } from '../features/app/common/features/NewHistoryDownload/createFile';

export const downloadFile = async (accessToken: AccessToken, startDate: string, endDate: string): Promise<Response> => {
    const params = `start_date=${startDate}&end_date=${endDate}`;
    const endpoint = `${config.backend.DOWNLOAD_HISTORY_SERVICE}?${params}`;

    const bearerToken = isDevEnvironment() ? await getLocalAccessToken() : accessToken;

    const response = await fetch(endpoint, {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
        },
    });

    if (!response.ok) {
        return Promise.reject(response);
    }
    return response;
};

const downloadDataFile = async (
    url: string | undefined,
    accessToken: AccessToken,
    startTime: string,
    endTime: string,
    format: FileFormat,
    language?: string
): Promise<Response> => {
    const endpoint = `${url}?start_time=${startTime}&end_time=${endTime}`;

    const bearerToken = isDevEnvironment() ? await getLocalAccessToken() : accessToken;

    const response = await fetch(endpoint, {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
            Accept: format,
            ...(language && { 'Accept-language': language }),
        },
    });

    if (!response.ok) {
        return Promise.reject(response);
    }
    return response;
};

export const downloadHistoryFile = (
    accessToken: AccessToken,
    startTime: string,
    endTime: string,
    format: FileFormat,
    language: string
) => downloadDataFile(config.backend.EXPORTS_HISTORY_SERVICE, accessToken, startTime, endTime, format, language);

export const downloadMinimalDatasetFile = (
    accessToken: AccessToken,
    startTime: string,
    endTime: string,
    format: FileFormat
) => downloadDataFile(config.backend.EXPORTS_MINIMAL_DATASET_SERVICE, accessToken, startTime, endTime, format);

export const fetchChargeStats = async (
    accessToken: AccessToken,
    startDate: string,
    endDate: string,
    assetIds: string[],
    pageParam: string
): Promise<Response> => {
    const bearerToken = isDevEnvironment() ? await getLocalAccessToken() : accessToken;
    const datesQueryParameter = `?start_time=${startDate}&end_time=${endDate}`;
    const assetIdsQueryParameter = assetIds.length === 0 ? '' : `&ids=${assetIds.join(',')}`;
    const cursor = pageParam ? `&cursor=${pageParam}` : '';
    const response = await fetch(
        `${config.backend.OTA_CHARGING_MONITORING_SERVICE}/assets/charge-stats${datesQueryParameter}${assetIdsQueryParameter}${cursor}`,
        {
            headers: {
                Authorization: `Bearer ${bearerToken}`,
                'Content-Type': 'application/json',
            },
            method: 'GET',
        }
    );
    if (!response.ok) {
        throw new Error(response.statusText);
    }
    return response;
};

export const fetchChargeCycles = async (
    accessToken: AccessToken,
    assetId: string,
    startDate: string,
    endDate: string
): Promise<Response> => {
    const bearerToken = isDevEnvironment() ? await getLocalAccessToken() : accessToken;
    const queryParameters = `?start_time=${startDate}&end_time=${endDate}`;
    const response = await fetch(
        `${config.backend.OTA_CHARGING_MONITORING_SERVICE}/assets/${assetId}/charge-cycles${queryParameters}`,
        {
            headers: {
                Authorization: `Bearer ${bearerToken}`,
                'Content-Type': 'application/json',
            },
            method: 'GET',
        }
    );
    if (!response.ok) {
        throw new Error(response.statusText);
    }
    return response;
};
