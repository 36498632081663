import { TreeItem } from '@rio-cloud/rio-uikit';
import { VehicleChargingData } from '../../../vehicleCharging/models/VehicleChargingData';

export const findAssetsFromSelectedGroups = (selectedGroups: string[], allAssets: TreeItem[]): string[] => {
    const assetsFound: string[] = [];
    selectedGroups.forEach(groupId => {
        assetsFound.push(
            ...allAssets.filter(asset => asset.groupIds && asset.groupIds.includes(groupId)).map(asset => asset.id)
        );
    });
    return assetsFound;
};

export const filterSelectedAssetsFromSelectedGroups = (
    selectedAssets: string[],
    selectedGroups: string[],
    allAssets: TreeItem[]
): string[] => {
    return selectedAssets.filter(selectedAssetId => {
        const assetFound = allAssets.find(asset => asset.id === selectedAssetId);
        if (assetFound && assetFound.groupIds) {
            const intersection = selectedGroups.filter(
                groupId => assetFound.groupIds && assetFound.groupIds.includes(groupId)
            );
            return !(intersection && intersection.length > 0);
        } else {
            return false;
        }
    });
};

export const filterAssetGroupIds = (assetGroups: string[] | null | undefined, groupIdsSelected: string[]): boolean =>
    assetGroups ? assetGroups.some(group => groupIdsSelected.includes(group)) : false;

export const filterAssetsByExistentData = (assets: TreeItem[], vehicleData: VehicleChargingData[]) =>
    assets.filter(asset => vehicleData?.some(vehicle => vehicle.assetId === asset.id));
