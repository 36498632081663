import ButtonDropdown from '@rio-cloud/rio-uikit/ButtonDropdown';
import BUTTON_STYLE from '@rio-cloud/rio-uikit/Button';
import DeleteEvents from './DeleteEvents';
import MarkAsSeen from './MarkAsSeen';
import { AccessToken } from '../../../../../../configuration';
import { EventStatus } from '../../../models/EventStatusEnum';
import { ReactElement, useState } from 'react';
import EventSettings from './EventSettings';

export const EventsModalDropdown = ({
    eventId,
    accessToken,
    status,
    severityLevel,
    animateEvent,
}: {
    eventId: string;
    accessToken: AccessToken;
    status: string;
    severityLevel: string[];
    animateEvent?: Function;
}): JSX.Element => {
    return (
        <div className="display-flex flex-column padding-15" style={{ gap: '16px' }}>
            <MarkAsSeen
                eventId={eventId}
                accessToken={accessToken}
                severityLevel={severityLevel}
                animateEvent={animateEvent}
            />
            <DeleteEvents eventId={eventId} isNewStatus={status === EventStatus.NEW} />
        </div>
    );
};

export const EventsWidgetDropdown = ({
    accessToken,
    disabled,
    lastEventId,
}: {
    accessToken: AccessToken;
    disabled: boolean;
    lastEventId?: number;
}): JSX.Element => {
    return (
        <div className="padding-15">
            <MarkAsSeen accessToken={accessToken} isFromWidget markAll disabled={disabled} lastEventId={lastEventId} />
            <div className="margin-top-15">
                <EventSettings />
            </div>
        </div>
    );
};

const OptionsDropdown = ({ customDropdown }: { customDropdown: JSX.Element }): ReactElement => {
    const [isOpen, setIsOpen] = useState(false);

    const customDropdownWrapper = <div onClick={() => setIsOpen(false)}>{customDropdown}</div>;

    return (
        <ButtonDropdown
            title={
                <span
                    className="rioglyph rioglyph-option-vertical text-size-14 text-color-black"
                    style={{ marginRight: '-10px' }}
                />
            }
            variant={BUTTON_STYLE.VARIANT_LINK}
            iconOnly
            customDropdown={customDropdownWrapper}
            pullRight
            items={[]}
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
        />
    );
};

export default OptionsDropdown;
