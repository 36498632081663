import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { NEW_EVENTS_PATH } from '../../../common/constants/paths/paths';
import { createGoogleAnalyticsEvent, gtmUtil, TrackingAction, TrackingCategory } from '../../../utils/googleTagManager';

export const LinkEvents = (): JSX.Element => {
    const intl = useIntl();
    const history = useHistory();

    const handleClickEvents = () => {
        gtmUtil.pushGaEvent(
            createGoogleAnalyticsEvent(TrackingCategory.OVERVIEW_TAB, TrackingAction.LINK_TO_ALL_EVENTS)
        );
        history.push(NEW_EVENTS_PATH);
    };

    return (
        <a onClick={handleClickEvents}>{intl.formatMessage({ id: 'e4c.overview.events.widget.modal.seeAllEvents' })}</a>
    );
};
