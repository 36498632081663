import React, { CSSProperties, ReactElement } from 'react';

const horizontalCardStyle: CSSProperties = {
    border: '1px solid #d0d8de',
    padding: '16px',
    display: 'flex',
    borderRadius: '4px',
    position: 'relative',
};

interface HorizontalCardProps {
    children: ReactElement | ReactElement[];
    className?: string;
    left?: ReactElement;
    right?: ReactElement;
    testId?: string;
}

const HorizontalCard: React.FC<HorizontalCardProps> = ({
    children,
    left,
    right,
    testId,
    className,
}: HorizontalCardProps) => (
    <div className={className} style={horizontalCardStyle} data-testid={testId}>
        {left}
        <div style={{ maxWidth: 'calc(100%)', minWidth: 0, flex: 1 }}>{children}</div>
        {right}
    </div>
);

export default HorizontalCard;
