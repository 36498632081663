type CollapsedEnergyConsumedProps = {
    sumEnergyConsumed: number | null;
};

const CollapsedEnergyConsumed = (props: CollapsedEnergyConsumedProps): JSX.Element => {
    const { sumEnergyConsumed } = props;
    const hasValidSumEnergyConsumedValue = sumEnergyConsumed && sumEnergyConsumed > 0 && !isNaN(sumEnergyConsumed);
    return (
        <>
            <span data-testid="energy-consumed" className="ellipsis-1 display-block">
                <span className={'rioglyph rioglyph-battery-level-low text-size-20 text-color-gray'} />
                <span className={'rioglyph rioglyph-arrow-down text-size-20 text-color-gray'} />
                {hasValidSumEnergyConsumedValue ? (
                    <span>{`${sumEnergyConsumed?.toFixed(0)} kWh`}</span>
                ) : (
                    <span>-</span>
                )}
            </span>
        </>
    );
};

export default CollapsedEnergyConsumed;
