import { Link } from 'react-router-dom';
import { EventStatus } from '../../../models/EventStatusEnum';
import OptionsDropdown, { EventsModalDropdown } from './OptionsDropdown';
import { AccessToken } from '../../../../../../configuration';
import { FC } from 'react';
import DeleteEvents from './DeleteEvents';
import { HISTORY_PATH } from '../../../../../../configuration/setup/path';

export interface FooterProps {
    vehicleId: string;
    eventId: string;
    status: EventStatus;
    accessToken: AccessToken;
    severityLevel: string[];
    animateEvent?: Function;
}

const CriticalCardFooter: FC<{
    eventId: string;
    status: EventStatus;
    accessToken: AccessToken;
    severityLevel: string[];
    animateEvent?: Function;
}> = ({ eventId, status, accessToken, severityLevel, animateEvent }) => {
    return (
        <Footer eventId={eventId} status={status} severityLevel={severityLevel}>
            <OptionsDropdown
                customDropdown={
                    <EventsModalDropdown
                        eventId={eventId}
                        accessToken={accessToken}
                        status={status}
                        severityLevel={severityLevel}
                        animateEvent={animateEvent}
                    />
                }
            />
        </Footer>
    );
    // return <Link to={{ pathname: '/vehicles' }}>See details</Link>; add later when details modal panel for fire risk is done
};

const GoToVehicleChargingFooter: FC<{
    vehicleId: string;
    eventId: string;
    status: EventStatus;
    accessToken: AccessToken;
    severityLevel: string[];
    animateEvent?: Function;
}> = ({ vehicleId, eventId, status, accessToken, severityLevel, animateEvent }) => {
    return (
        <Footer eventId={eventId} status={status} severityLevel={severityLevel}>
            <Link className="text-size-14" to={{ pathname: '/vehicles', search: `?assetIds=${vehicleId}` }}>
                Go to vehicle charging
            </Link>
            <OptionsDropdown
                customDropdown={
                    <EventsModalDropdown
                        eventId={eventId}
                        accessToken={accessToken}
                        status={status}
                        severityLevel={severityLevel}
                        animateEvent={animateEvent}
                    />
                }
            />
        </Footer>
    );
};

const GoToMonitoringFooter: FC<{
    vehicleId: string;
    eventId: string;
    status: EventStatus;
    accessToken: AccessToken;
    severityLevel: string[];
    animateEvent?: Function;
}> = ({ vehicleId, eventId, status, accessToken, severityLevel, animateEvent }) => {
    return (
        <Footer eventId={eventId} status={status} accessToken={accessToken} severityLevel={severityLevel}>
            <Link className="text-size-14" to={{ pathname: HISTORY_PATH, search: `?assetIds=${vehicleId}` }}>
                Go to monitoring
            </Link>
            <OptionsDropdown
                customDropdown={
                    <EventsModalDropdown
                        eventId={eventId}
                        accessToken={accessToken}
                        status={status}
                        severityLevel={severityLevel}
                        animateEvent={animateEvent}
                    />
                }
            />
        </Footer>
    );
};

const Footer = ({ eventId, status, children }: any) => {
    return <div>{status === EventStatus.SEEN ? <DeleteEvents eventId={eventId} /> : <div>{children}</div>}</div>;
};

const FooterEventCard: (props: FooterProps) => { [key: string]: JSX.Element } = ({
    vehicleId,
    eventId,
    status,
    accessToken,
    severityLevel,
    animateEvent,
}: FooterProps) => ({
    critical: (
        <CriticalCardFooter
            eventId={eventId}
            status={status}
            accessToken={accessToken}
            severityLevel={severityLevel}
            animateEvent={animateEvent}
        />
    ),
    warning: (
        <GoToVehicleChargingFooter
            vehicleId={vehicleId}
            eventId={eventId}
            status={status}
            accessToken={accessToken}
            severityLevel={severityLevel}
            animateEvent={animateEvent}
        />
    ),
    success: (
        <GoToVehicleChargingFooter
            vehicleId={vehicleId}
            eventId={eventId}
            status={status}
            accessToken={accessToken}
            severityLevel={severityLevel}
            animateEvent={animateEvent}
        />
    ),
    info: (
        <GoToMonitoringFooter
            vehicleId={vehicleId}
            eventId={eventId}
            status={status}
            accessToken={accessToken}
            severityLevel={severityLevel}
            animateEvent={animateEvent}
        />
    ),
});

export default FooterEventCard;
