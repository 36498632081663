import { ReactElement, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import moment, { Moment } from 'moment';
import ButtonDropdown from '@rio-cloud/rio-uikit/ButtonDropdown';
import { createGoogleAnalyticsEvent, gtmUtil, TrackingAction, TrackingCategory } from '../../../utils/googleTagManager';
import { useLocation } from 'react-router';
import { OVERVIEW_PATH } from '../../../../../configuration/setup/path';

export enum TimeFrameKey {
    TODAY = 'today',
    YESTERDAY = 'yesterday',
    LAST_7_DAYS = 'last7Days',
    LAST_MONTH = 'lastMonth',
    LAST_3_MONTHS = 'last3Months',
    CUSTOM = 'custom',
}

const getTimeFrames = (setDates: (startDate: Moment, endDate: Moment) => void) => [
    {
        key: TimeFrameKey.TODAY,
        onSelect: () => setDates(moment(), moment()),
    },
    {
        key: TimeFrameKey.YESTERDAY,
        onSelect: () => setDates(moment().subtract(1, 'day'), moment().subtract(1, 'day')),
    },
    {
        key: TimeFrameKey.LAST_7_DAYS,
        onSelect: () => setDates(moment().subtract(7, 'day'), moment()),
    },
    {
        key: TimeFrameKey.LAST_MONTH,
        onSelect: () =>
            setDates(moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')),
    },
    {
        key: TimeFrameKey.LAST_3_MONTHS,
        onSelect: () =>
            setDates(moment().subtract(3, 'months').startOf('month'), moment().subtract(1, 'month').endOf('month')),
    },
    {
        key: TimeFrameKey.CUSTOM,
        onSelect: () => {},
    },
];

const createDropdownItem = (
    { key, onSelect }: { key: TimeFrameKey; onSelect: () => void },
    selectedItem: string,
    setSelectedItem: (key: TimeFrameKey) => void,
    setIsOpen: (isOpen: boolean) => void,
    trackingCategory: TrackingCategory
): ReactElement => (
    <li
        key={key}
        role="presentation"
        className="width-200"
        onClick={() => {
            onSelect();
            setIsOpen(false);
            setSelectedItem(key);
            gtmUtil.pushGaEvent(createGoogleAnalyticsEvent(trackingCategory, TrackingAction.CHANGE_TIMEFRAME, key));
        }}
    >
        <a role="menuitem">
            {selectedItem === key && (
                <span data-testid="item-selected-icon" className="rioglyph rioglyph-ok margin-right-5" />
            )}
            <FormattedMessage id={`e4c.global.dateRange.${key}`} />
        </a>
    </li>
);

const getGATrackingCategory = (pathname: string) =>
    pathname.includes(OVERVIEW_PATH) ? TrackingCategory.OVERVIEW_TAB : TrackingCategory.HISTORY_TAB;

interface TimeFrameSelectorProps {
    changeDates: (startDate: Moment, endDate: Moment) => void;
    customDatesSelected: boolean;
}

export const TimeFrameSelector = ({ changeDates, customDatesSelected }: TimeFrameSelectorProps) => {
    const { pathname } = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(TimeFrameKey.CUSTOM);

    useEffect(() => {
        if (customDatesSelected) {
            setSelectedItem(TimeFrameKey.CUSTOM);
        }
    }, [customDatesSelected]);

    const setDates = (startDate: Moment, endDate: Moment) =>
        changeDates(startDate.startOf('day'), endDate.endOf('day'));

    const customDropdown = getTimeFrames(setDates).map(item =>
        createDropdownItem(item, selectedItem, setSelectedItem, setIsOpen, getGATrackingCategory(pathname))
    );

    return (
        <ButtonDropdown
            data-testid="time-frame-selector"
            title={<span className="rioglyph rioglyph-settings" />}
            customDropdown={customDropdown}
            dropdownClassName="white-space-nowrap"
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            iconOnly
        />
    );
};
