import ContentLoader from '@rio-cloud/rio-uikit/ContentLoader';

const TableLoader = () => {
    return (
        <tbody data-testid="table-loader">
            <tr role="row" className="height-70">
                <td>
                    <ContentLoader width="75%" />
                </td>
                <td>
                    <ContentLoader width="25%" />
                </td>
                <td>
                    <ContentLoader width="25%" />
                </td>
                <td>
                    <ContentLoader width="25%" />
                </td>
                <td>
                    <ContentLoader width="25%" />
                </td>
                <td>
                    <ContentLoader width="25%" />
                </td>
                <td>
                    <ContentLoader width="25%" />
                </td>
                <td>
                    <ContentLoader width="25%" />
                </td>
                <td>
                    <ContentLoader width="25%" />
                </td>
            </tr>
        </tbody>
    );
};

export default TableLoader;
