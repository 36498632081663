import { NO_DATA_INFO } from '../../../types';

type EnergyAddedProps = {
    energyAdded: number | null;
    processType: string;
};

const EnergyAdded = (props: EnergyAddedProps): JSX.Element => {
    const { energyAdded, processType } = props;

    const hasValue = energyAdded !== null && energyAdded !== undefined && !isNaN(energyAdded);

    const fetchElement = () => {
        return hasValue ? (
            <span>{`${energyAdded.toFixed(0)} kWh`}</span>
        ) : (
            <div className={'text-color-lighter'}>{NO_DATA_INFO}</div>
        );
    };

    return processType === 'CHARGING' ? fetchElement() : <span>{'-'}</span>;
};

export default EnergyAdded;
