import { Timer, VehicleChargingConfiguration } from './vehicleChargingConfigurationTypes';
import { SwitchToImmediateChargingReason } from '../../../types';

export const checkAllDisabledOrDeleted = (timers: Timer[]) => {
    if (timers.length === 0) {
        return SwitchToImmediateChargingReason.ALL_DELETED;
    }

    if (timers.every(timer => !timer.enabled)) {
        return SwitchToImmediateChargingReason.ALL_DISABLED;
    }

    return null;
};

export const isReadOnlyUser = (vehicleDetailsData: VehicleChargingConfiguration | null) =>
    !(
        vehicleDetailsData &&
        vehicleDetailsData.userAction &&
        vehicleDetailsData.userAction.postTimerConfigurations &&
        vehicleDetailsData.userAction.postImmediateConfigurations
    );
