import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import { memo } from 'react';
import { useTimezone } from '../../../../../common/providers/timezone';
import { CustomTooltip } from '../../../../../components/shared/CustomTooltip';
import ChargingStatus from '../../../../models/enums/ChargingStatus';
import DrivingState from '../../../../models/enums/DrivingState';
import ReadyToDriveStatus from '../../../../models/enums/ReadyToDriveStatus';
import VehicleModel from '../../../../models/enums/VehicleModel';
import { NO_DATA } from '../../../types';

interface VehicleStatusColProps {
    chargingState: ChargingStatus | undefined;
    readyToDriveUntil?: string | null;
    readyToDriveStatus?: string | null;
    drivingState?: DrivingState;
    vehicleModel: VehicleModel;
    lastUpdated: string;
    locale: string;
}

export const VehicleStatusCol = memo(
    ({
        chargingState,
        readyToDriveStatus,
        readyToDriveUntil,
        drivingState,
        vehicleModel,
        lastUpdated,
        locale,
    }: VehicleStatusColProps) => {
        let status;
        let statusSign;
        let statusTooltip;
        let statusClass = '';
        let formattedReadyToDriveUntil = '';
        let formattedReadyToDriveUntilDate = '';
        let hasReadyToDriveUntilData = false;
        const currentTimezone = useTimezone();

        if (readyToDriveUntil) {
            const parsedDate = new Date(Date.parse(readyToDriveUntil));
            const formattedReadyToDriveUntilTime = moment(parsedDate).tz(currentTimezone).format('HH:mm');

            const parsedLastUpdated = new Date(Date.parse(lastUpdated));

            if (parsedDate.toDateString() !== parsedLastUpdated.toDateString()) {
                formattedReadyToDriveUntilDate = parsedDate.toLocaleDateString(locale, {
                    day: '2-digit',
                    month: '2-digit',
                });
            }

            formattedReadyToDriveUntil = `${formattedReadyToDriveUntilDate} ${formattedReadyToDriveUntilTime}`;
            hasReadyToDriveUntilData = parsedDate && formattedReadyToDriveUntil.trim() !== '';
        }

        switch (readyToDriveStatus) {
            case ReadyToDriveStatus.NOT_READY:
                status = 'e4c.vehicles.vehicleStatus.notReady';
                statusSign = 'rioglyph rioglyph-remove-sign';
                statusClass = 'text-color-gray';
                break;

            case ReadyToDriveStatus.READY_TO_DRIVE:
                status = 'e4c.vehicles.vehicleStatus.readyToDrive';
                statusSign = 'rioglyph rioglyph-ok-sign';
                break;

            default:
                // NO_SIGNAL
                status = 'e4c.vehicles.table.noSignal';
                statusSign = 'rioglyph rioglyph-question-sign';
                statusClass = 'text-color-gray';
        }

        const chargingStatus = chargingState || ChargingStatus.DISCONNECTED;
        if (chargingStatus === ChargingStatus.DISCONNECTED) {
            switch (vehicleModel) {
                case VehicleModel.ETGE:
                    status = NO_DATA;
                    statusSign = 'rioglyph rioglyph-error-sign';
                    statusClass = 'text-color-lighter align-self-center';
                    statusTooltip = 'e4c.vehicles.vehicleStatus.notAvailableForETGE';
                    break;
                case VehicleModel.E4C:
                case VehicleModel.TRUE:
                case VehicleModel.ELSA:
                    switch (drivingState) {
                        case DrivingState.DS_ACTIVE:
                            status = 'e4c.vehicles.vehicleStatus.driving';
                            statusSign = 'rioglyph rioglyph-status-driving text-color-status-driving';
                            statusClass = '';
                            break;
                        case DrivingState.DS_NOT_ACTIVE:
                            status = 'e4c.vehicles.vehicleStatus.notDriving';
                            statusSign = 'rioglyph rioglyph-status-driving';
                            statusClass = 'text-color-gray';
                            break;
                        default:
                            status = NO_DATA;
                            statusSign = '';
                            statusClass = 'text-color-lighter align-self-center';
                    }
                    break;
            }
        }

        return (
            <div>
                {status === NO_DATA ? (
                    <span className={`${statusClass}`}>
                        {status}
                        {statusTooltip && (
                            <CustomTooltip
                                iconClassName={statusSign}
                                messageId={statusTooltip}
                                tooltipProps={{ placement: 'right' }}
                                overlayProps={{ placement: 'right' }}
                            />
                        )}
                    </span>
                ) : (
                    <div className={`display-flex ${statusClass}`}>
                        <span className={`${statusSign} text-size-18 align-self-center`} />
                        <div className={'display-block'}>
                            <p className="padding-left-5 margin-bottom-0 margin-top-0">
                                <FormattedMessage id={status} />
                            </p>
                            {readyToDriveStatus === ReadyToDriveStatus.READY_TO_DRIVE && hasReadyToDriveUntilData && (
                                <span className={'padding-left-5 display-block'}>
                                    <FormattedMessage id={'e4c.vehicles.table.vehicleStatus.readyToDriveUntil'} />
                                    {` ${formattedReadyToDriveUntil}`}
                                </span>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
);

VehicleStatusCol.displayName = 'VehicleStatusCol';
