interface UserManualSectionProps {
    title: JSX.Element;
    children: JSX.Element | JSX.Element[];
}

const UserManualSection = ({ title, children }: UserManualSectionProps) => {
    return (
        <>
            <div className="padding-top-20 padding-bottom-10 text-size-h4 text-bold">{title}</div>
            {children}
        </>
    );
};

export default UserManualSection;
