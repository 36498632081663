import * as H from 'history';
import { Moment } from 'moment';

const getQuery = (history: H.History): URLSearchParams => new URLSearchParams(history.location.search);

const applyTimeframeParams = (query: URLSearchParams, currentStartDate: Moment, currentEndDate: Moment): void => {
    query.delete('startDate');
    query.delete('endDate');
    query.append('startDate', currentStartDate.format('YYYY-MM-DD'));
    query.append('endDate', currentEndDate.format('YYYY-MM-DD'));
};

const applyAssetIdsParams = (query: URLSearchParams, assets: string[]): void => {
    query.append('assetIds', assets.toString());
};

const applyHistoryTab = (query: URLSearchParams): void => query.set('tab', 'consumption');

export const buildQuery = (
    history: H.History,
    currentEndDate: Moment,
    currentStartDate: Moment,
    assetIds: string[]
): URLSearchParams => {
    const query = getQuery(history);
    applyHistoryTab(query);
    applyTimeframeParams(query, currentStartDate, currentEndDate);
    applyAssetIdsParams(query, assetIds);
    return query;
};
