import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Timer, Weekday, DepartureTimesPreviewProps, DepartureTime, ChargingConfiguration } from './types';
import {
    weekdaysArray,
    getTimeHrs,
    convertDepartureTime,
    convertDateToDayMonthYear,
    getTimeMins,
    DEPARTURE_TIMES_PREVIEW_LENGTH,
} from './vehicleDetailsHelper';
import moment from 'moment-timezone';
import { ChargingConfigurationStatusEnum } from '../../../types';
import { useTimezone } from '../../../../common/providers/timezone';

const DepartureTimesPreview = React.memo((props: DepartureTimesPreviewProps) => {
    const currentTimezone = useTimezone();
    const nextDepartureTimes = getNextDepartureTimes(props.timers, props.chargingConfigurations, currentTimezone);
    return (
        <React.Fragment>
            {nextDepartureTimes.length > 0 && (
                <div className="padding-top-10">
                    <table className="table table-sm preview">
                        <thead>
                            <tr>
                                <th scope="col" colSpan={3}>
                                    <label className="text-size-12">
                                        <FormattedMessage id={'e4c.vehicles.details.preview.nextDepartureTimes'} />
                                    </label>
                                </th>
                                <th scope="col">
                                    <label className="text-size-12">
                                        <FormattedMessage id={'e4c.vehicles.details.preview.status'} />
                                    </label>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {nextDepartureTimes.map((departure, index) => (
                                <tr key={index}>
                                    <td className="width-30">{getShortWeekday(departure.day)}</td>
                                    <td className="width-50 white-space-nowrap">
                                        {convertDateToDayMonthYear(departure.date, props.locale)}
                                    </td>
                                    <td className="width-70">{departure.time}</td>
                                    <td className="width-200">
                                        {getTranslatedChargingConfigurationLabel(departure.status)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </React.Fragment>
    );
});

DepartureTimesPreview.displayName = 'DepartureTimesPreview';
export default DepartureTimesPreview;

export const getShortWeekday = (weekday: Weekday): JSX.Element | undefined => {
    switch (weekday) {
        case Weekday.MONDAY:
            return <FormattedMessage id="e4c.vehicles.details.timerSettings.monday" />;
        case Weekday.TUESDAY:
            return <FormattedMessage id="e4c.vehicles.details.timerSettings.tuesday" />;
        case Weekday.WEDNESDAY:
            return <FormattedMessage id="e4c.vehicles.details.timerSettings.wednesday" />;
        case Weekday.THURSDAY:
            return <FormattedMessage id="e4c.vehicles.details.timerSettings.thursday" />;
        case Weekday.FRIDAY:
            return <FormattedMessage id="e4c.vehicles.details.timerSettings.friday" />;
        case Weekday.SATURDAY:
            return <FormattedMessage id="e4c.vehicles.details.timerSettings.saturday" />;
        case Weekday.SUNDAY:
            return <FormattedMessage id="e4c.vehicles.details.timerSettings.sunday" />;
        default:
            return;
    }
};

export const getTranslatedChargingConfigurationLabel = (
    chargingConfigStatus: ChargingConfigurationStatusEnum
): JSX.Element => {
    switch (chargingConfigStatus) {
        case ChargingConfigurationStatusEnum.ACCEPTED:
            return <FormattedMessage id={'e4c.vehicles.details.preview.status.accepted'} />;
        case ChargingConfigurationStatusEnum.SENDING:
            return <FormattedMessage id={'e4c.vehicles.details.preview.status.sending'} />;
        case ChargingConfigurationStatusEnum.REJECTED:
            return <FormattedMessage id={'e4c.vehicles.details.preview.status.rejected'} />;
        default:
            return <FormattedMessage id={'e4c.vehicles.details.preview.status.notSentYet'} />;
    }
};

export const getNextDepartureTimes = (
    timers: Timer[],
    chargingConfigurations: ChargingConfiguration[],
    currentTimezone: string
): DepartureTime[] => {
    const nextDepartureTimes: DepartureTime[] = [];
    const today = moment.tz(new Date(), currentTimezone).toDate();
    let currentDay = today.getDay();
    let currentDayName = weekdaysArray[currentDay];
    let currentDate = today;
    for (let i = 0; i < 7; i++) {
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let j = 0; j < timers.length; j++) {
            if (timers[j].enabled) {
                if (timers[j].weekdays.includes(currentDayName)) {
                    const nextDate = moment.tz(currentDate, currentTimezone).toDate();
                    const convertedDepartureTime = convertDepartureTime(timers[j].departure_time, timers[j].zone_id);
                    const departureTime = createDepartureTimeObject(
                        currentDayName,
                        nextDate,
                        convertedDepartureTime,
                        null
                    );

                    if (i === 0) {
                        const timeHrs = getTimeHrs(convertedDepartureTime);
                        const timeMins = getTimeMins(convertedDepartureTime);
                        const timeHrsNow = today.getHours();
                        const timeMinsNow = today.getMinutes();
                        if (timeHrs < timeHrsNow || (timeHrs === timeHrsNow && timeMins < timeMinsNow)) {
                            departureTime.date = moment.tz(today, currentTimezone).add(7, 'days').toDate();
                        }
                    }
                    if (!nextDepartureTimes.includes(departureTime)) {
                        nextDepartureTimes.push(departureTime);
                    }
                }
            }
        }
        currentDate = moment
            .tz(today, currentTimezone)
            .add(i + 1, 'days')
            .toDate();
        currentDay = currentDate.getDay();
        currentDayName = weekdaysArray[currentDay];
    }

    nextDepartureTimes.sort(compareDepartureTimes);

    if (nextDepartureTimes.length === 1) {
        const nextDate = moment.tz(nextDepartureTimes[0].date, currentTimezone).add(7, 'days').toDate();
        const departureTime = createDepartureTimeObject(
            nextDepartureTimes[0].day,
            nextDate,
            nextDepartureTimes[0].time,
            null
        );
        nextDepartureTimes.push(departureTime);
    }

    const nextDepartureTimesForPreview = nextDepartureTimes.slice(0, DEPARTURE_TIMES_PREVIEW_LENGTH);
    const nextDepartureTimeForPreviewWithStatus = getNextDepartureTimesWithStatus(
        nextDepartureTimesForPreview,
        chargingConfigurations,
        currentTimezone
    );
    return nextDepartureTimeForPreviewWithStatus;
};

export const getNextDepartureTimesWithStatus = (
    nextDepartureTimesForPreview: DepartureTime[],
    chargingConfigurations: ChargingConfiguration[],
    currentTimezone: string
): DepartureTime[] => {
    const nextDepartureTimeForPreviewWithStatus = nextDepartureTimesForPreview.map(departureTime => {
        const tempDate = moment.tz(departureTime.date, currentTimezone).toDate();
        const tempHrs = getTimeHrs(departureTime.time);
        const tempMins = getTimeMins(departureTime.time);
        const tempMoment = moment(tempDate);
        for (const chargingConfiguration of chargingConfigurations) {
            const chargingConfigDepartureTimeDate = moment.utc(chargingConfiguration.departure_time);
            const convertedDepartureTimeDate = new Date(
                chargingConfigDepartureTimeDate.tz(currentTimezone).format('YYYY-MM-DD HH:mm')
            );
            const tempConfigHrs = convertedDepartureTimeDate.getHours();
            const tempConfigMins = convertedDepartureTimeDate.getMinutes();

            if (
                tempMoment.isSame(convertedDepartureTimeDate, 'day') &&
                tempHrs === tempConfigHrs &&
                tempMins === tempConfigMins
            ) {
                departureTime.status = chargingConfiguration.status;
            }
        }
        return departureTime;
    });
    return nextDepartureTimeForPreviewWithStatus;
};

export const createDepartureTimeObject = (
    day: Weekday,
    date: Date,
    time: string,
    status: ChargingConfigurationStatusEnum | null
): DepartureTime => {
    const departureTime: DepartureTime = {
        day,
        date,
        time,
        status: status ? status : ChargingConfigurationStatusEnum.NOT_SENT_YET,
    };
    return departureTime;
};

export const compareDepartureTimes = (first: DepartureTime, second: DepartureTime): number => {
    const firstDateTimeMins = getTimeMins(first.time);
    const firstDateTimeHrs = getTimeHrs(first.time);
    const secondDateTimeMins = getTimeMins(second.time);
    const secondDateTimeHrs = getTimeHrs(second.time);
    const firstDate = moment(first.date);
    const secondDate = moment(second.date);

    if (
        firstDate.isSame(secondDate) &&
        firstDateTimeHrs === secondDateTimeHrs &&
        firstDateTimeMins <= secondDateTimeMins
    ) {
        return -1;
    }
    if (
        firstDate.isSame(secondDate) &&
        firstDateTimeHrs === secondDateTimeHrs &&
        firstDateTimeMins > secondDateTimeMins
    ) {
        return 1;
    }

    if (firstDate.isSame(secondDate) && getTimeHrs(first.time) <= getTimeHrs(second.time)) {
        return -1;
    }
    if (firstDate.isSame(secondDate) && getTimeHrs(first.time) > getTimeHrs(second.time)) {
        return 1;
    }

    if (firstDate < secondDate) {
        return -1;
    }
    if (firstDate > secondDate) {
        return 1;
    }

    return 0;
};
