import { ContactDetail, EventsSettings, SettingsDetails } from './IModel';

interface EventsSettingsAPI {
    events_settings?: EventsSettings;
    contacts_info?: ContactDetail[];
}

export const requestEventSetting = (
    severity: string,
    settings: SettingsDetails[],
    contactsInfo: ContactDetail[] | null
): EventsSettingsAPI =>
    contactsInfo
        ? {
              events_settings: { [severity]: settings },
              contacts_info: contactsInfo,
          }
        : {
              events_settings: { [severity]: settings },
          };
