import Select from '@rio-cloud/rio-uikit/Select';
import React, { useState } from 'react';

interface PageSizeSelectorProps {
    sizeOptions: number[];
    onSelectionChanges: (size: number) => void;
}

export class SizeSelectOption {
    id: string;
    label: string | React.ReactNode;
    value: number;
    selected: boolean | undefined;

    constructor(id: string, value: number, selected: boolean) {
        this.id = `${id}_${value}`;
        this.label = `${value}`;
        this.value = value;
        this.selected = selected;
    }
}

const PageSizeSelector = (props: PageSizeSelectorProps): JSX.Element => {
    const [selectedSize, setSelectedSize] = useState<number>(props.sizeOptions[0]);

    const selectionItems = (): SizeSelectOption[] => {
        return props.sizeOptions.map(
            (value: number, index: number) => new SizeSelectOption(index.toString(), value, value === selectedSize)
        );
    };

    const onSelectionChange = (option?: SizeSelectOption): void => {
        if (option) {
            setSelectedSize(option.value);
            props.onSelectionChanges(option.value);
        }
    };

    return (
        <div className="form-group margin-0">
            <Select
                id={'pageSizeSelector'}
                options={selectionItems()}
                onChange={onSelectionChange}
                className={'dropup'}
                bsSize="sm"
            />
        </div>
    );
};

export default PageSizeSelector;
