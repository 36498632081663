import ChargingConnectorPlug from './ChargingConnectorPlug';
import ChargingConnectorColor from './ChargingConnectorColor';

interface ConnectorIconProps {
    color: ChargingConnectorColor;
    plug: ChargingConnectorPlug;
}

const ConnectorIcon = (props: ConnectorIconProps): JSX.Element => {
    const { color, plug } = props;
    return <span className={`rioglyph ${plug} text-size-h2 ${color} align-self-center`} data-testid={'cs-connector'} />;
};

export default ConnectorIcon;
