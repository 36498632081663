import classNames from 'classnames';
import TableViewToggles from '@rio-cloud/rio-uikit/TableViewToggles';

const tableClassNames = (tableViewType: string): string => {
    return classNames(
        'table',
        'table-layout-fixed',
        'table-column-overflow-hidden',
        'table-bordered',
        'table-sticky',
        'table-head-filled',
        'table-hover',
        tableViewType === TableViewToggles.VIEW_TYPE_SINGLE_CARD && 'table-cards table-single-card',
        tableViewType === TableViewToggles.VIEW_TYPE_MULTI_CARDS && 'table-cards table-multi-cards'
    );
};

const separatorClassNames = (isSingleTable: boolean, tableViewType: string): string => {
    return classNames(
        'height-20',
        (isSingleTable || tableViewType !== TableViewToggles.VIEW_TYPE_TABLE) && 'display-none'
    );
};

export { separatorClassNames, tableClassNames };
