/* eslint-disable react-hooks/exhaustive-deps */
// we should discuss the relevance of this warning
import { useEffect, useState } from 'react';
import PageSizeSelector from './PageSizeSelector';
import PageSelector from './PageSelector';

interface PaginationProps {
    numberOfItems: number;
    sizeOptions: number[];
    defaultPage: number;
    defaultSize: number;
    onPaginationChanges: (options: PaginationOptions) => void;
}

export interface PaginationOptions {
    pageSize: number;
    pageNumber: number;
}

const Pagination = (props: PaginationProps): JSX.Element => {
    const { numberOfItems, sizeOptions, defaultPage, defaultSize, onPaginationChanges } = props;

    const solveNumberOfPages = (items: number, size: number): number => (items < size ? 1 : Math.ceil(items / size));

    const [pageSize, setPageSize] = useState<number>(defaultSize);
    const [pageNumber, setPageNumber] = useState<number>(defaultPage);
    const [numberOfPages, setNumberOfPages] = useState<number>(solveNumberOfPages(numberOfItems, defaultSize));

    useEffect(() => {
        const pages = solveNumberOfPages(numberOfItems, pageSize);
        const page = pageNumber > pages ? pages : pageNumber;
        setNumberOfPages(pages);
        setPageNumber(page);
        onPaginationChanges({ pageSize, pageNumber: page });
    }, [numberOfItems, pageSize, pageNumber]);

    return (
        <div className={'form-inline position-relative'}>
            <PageSelector numberOfPages={numberOfPages} onSelectionChanges={setPageNumber} selectedPage={pageNumber} />
            <PageSizeSelector sizeOptions={sizeOptions} onSelectionChanges={setPageSize} />
        </div>
    );
};

export default Pagination;
