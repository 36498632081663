import { useIntl } from 'react-intl';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';

export interface PendingChangesProps {
    handleCancel: VoidFunction;
    handleDiscard: VoidFunction;
}

const PendingChanges = ({ handleCancel, handleDiscard }: PendingChangesProps) => {
    const intl = useIntl();
    const dialogTitle = intl.formatMessage({ id: 'e4c.overview.events.widget.settings.confirmation.pendingChanges' });
    const content = {
        title: intl.formatMessage({
            id: 'e4c.overview.events.widget.settings.confirmation.discardChanges',
        }),
        description: intl.formatMessage({
            id: 'e4c.overview.events.widget.settings.confirmation.discardChangesDescription2',
        }),
    };
    const buttons = {
        discard: intl.formatMessage({ id: 'e4c.global.discardChanges' }),
        keepEditing: intl.formatMessage({ id: 'e4c.global.keepEditing' }),
    };

    const dialogContent = (
        <>
            <p className="text-size-20 text-medium margin-0">{content.title}</p>
            <p className="text-size-15 margin-top-10">{content.description}</p>
        </>
    );

    return (
        <div data-testid="pending-changes-panel">
            <ConfirmationDialog
                show={true}
                title={dialogTitle}
                content={dialogContent}
                onClickConfirm={handleDiscard}
                onClickCancel={handleCancel}
                confirmButtonText={buttons.discard}
                cancelButtonText={buttons.keepEditing}
                useOverflow
            />
        </div>
    );
};

export default PendingChanges;
