import { ReactElement, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import detailClimateControlPanelModalImage from '../../../../../assets/images/detail-climate-control-panel-modal.png';
import {
    createGoogleAnalyticsEvent,
    gtmUtil,
    TrackingAction,
    TrackingCategory,
} from '../../../../../utils/googleTagManager';
import UserManualPage from '../components/UserManualPage';

const ClimateControlItem = (): ReactElement => {
    const intl = useIntl();
    useEffect(() => {
        gtmUtil.pushGaEvent(
            createGoogleAnalyticsEvent(TrackingCategory.GENERAL, TrackingAction.USER_MANUAL_CLIMATE_CONTROL)
        );
    }, []);

    return (
        <UserManualPage
            title={<FormattedMessage id={'e4c.userManual.details.climateControl.title'} />}
            footer={<FormattedMessage id={'e4c.userManual.overview.etgeObs'} />}
            dataTestId="climate-control-item"
        >
            <div className="margin-bottom-20">
                <FormattedMessage id={'e4c.userManual.details.climateControl.titleDescription'} />
            </div>
            <img
                alt={intl.formatMessage({ id: 'e4c.userManual.altText.preliminaryAirConditioning' })}
                src={detailClimateControlPanelModalImage}
                width="627"
                height="226"
            />
            <div className="margin-top-20">
                <FormattedMessage id={'e4c.userManual.details.climateControl.description'} />
            </div>
        </UserManualPage>
    );
};

export default ClimateControlItem;
