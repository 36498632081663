import { ReactElement } from 'react';
import { ElectricVehicleIcon } from '../../../../../common/components/ElectricVehicleIcon/ElectricVehicleIcon';
import VehicleType from '../../../../../common/vehicles/VehicleType';
import { NO_DATA_INFO } from '../../../types';

type CollapsedVehicleQuantityProps = {
    quantityOfVehicles: number | null;
};

const CollapsedVehicleQuantity = (props: CollapsedVehicleQuantityProps): ReactElement => {
    const { quantityOfVehicles } = props;
    const hasValidQuantityOfVehiclesValue = quantityOfVehicles && !isNaN(quantityOfVehicles);
    return (
        <>
            {hasValidQuantityOfVehiclesValue ? (
                <>
                    <ElectricVehicleIcon
                        vehicleType={VehicleType.BUS}
                        size="text-size-20"
                        extraClasses="padding-right-5 text-color-gray"
                    />
                    <span>{quantityOfVehicles}</span>
                </>
            ) : (
                <span className="text-color-lighter">{NO_DATA_INFO}</span>
            )}
        </>
    );
};

export default CollapsedVehicleQuantity;
