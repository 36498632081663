import { ReactElement } from 'react';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import VehiclesContainer from '../../vehicleCharging/containers/Vehicles.container';
import AssetTreeSidebar from '../../common/components/AssetTreeSidebar/AssetTreeSidebar';

const Assets = (): ReactElement => (
    <>
        <ApplicationLayout.Sidebar>
            <AssetTreeSidebar />
        </ApplicationLayout.Sidebar>
        <VehiclesContainer />
    </>
);

export default Assets;
