/* eslint-disable max-len */
const ComingSoon = (): JSX.Element => {
    return (
        <svg width="90" height="88" viewBox="0 0 90 88" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
                <path
                    d="M37.7482 3.09559L3.74951 33.0977L5.67424 35.2788L39.6729 5.27672L37.7482 3.09559Z"
                    fill="#516E8A"
                />
                <path
                    d="M39.3143 2.8631L38.1084 5.51074L74.836 22.2382L76.0418 19.5905L39.3143 2.8631Z"
                    fill="#516E8A"
                />
                <path
                    d="M38.7114 7.64157C40.6193 7.64157 42.166 6.0949 42.166 4.18699C42.166 2.27909 40.6193 0.732422 38.7114 0.732422C36.8035 0.732422 35.2568 2.27909 35.2568 4.18699C35.2568 6.0949 36.8035 7.64157 38.7114 7.64157Z"
                    fill="#697A8B"
                />
                <path
                    d="M77.445 17.9992L2.95708 31.1334C1.96817 31.3077 1.30786 32.2508 1.48223 33.2397L10.4488 84.092C10.6232 85.0809 11.5662 85.7412 12.5551 85.5668L87.043 72.4326C88.0319 72.2583 88.6922 71.3152 88.5179 70.3263L79.5513 19.474C79.3769 18.4851 78.4339 17.8248 77.445 17.9992Z"
                    fill="#697A8B"
                />
                <path
                    d="M12.2325 87.0499C11.4642 87.0477 10.7211 86.7755 10.1332 86.2809C9.54529 85.7863 9.14991 85.1008 9.01622 84.3443L0.049702 33.4923C-0.100969 32.6375 0.0940789 31.7578 0.591948 31.0467C1.08982 30.3357 1.84973 29.8515 2.70455 29.7007L77.1924 16.5668C78.0472 16.4161 78.9268 16.6112 79.6378 17.1091C80.3488 17.6069 80.8329 18.3668 80.9838 19.2216L89.9503 70.0739C90.0249 70.4972 90.0154 70.931 89.9224 71.3506C89.8294 71.7702 89.6546 72.1673 89.4081 72.5194C89.1616 72.8714 88.8481 73.1715 88.4856 73.4024C88.1231 73.6333 87.7187 73.7905 87.2954 73.8651L12.8078 86.9995C12.6179 87.0329 12.4254 87.0498 12.2325 87.0499ZM77.763 19.4258C77.741 19.4258 77.719 19.4278 77.6973 19.4318L3.20953 32.5658C3.16249 32.5741 3.11756 32.5916 3.07729 32.6173C3.03702 32.643 3.00221 32.6763 2.97484 32.7155C2.94746 32.7546 2.92807 32.7988 2.91777 32.8454C2.90746 32.8921 2.90645 32.9403 2.91478 32.9873L11.8813 83.8393C11.8892 83.8864 11.9065 83.9315 11.9322 83.9718C11.9579 84.0122 11.9914 84.0469 12.0308 84.074C12.0696 84.1021 12.1137 84.122 12.1605 84.1324C12.2072 84.1427 12.2556 84.1434 12.3026 84.1344L86.7905 71C86.8854 70.9833 86.9697 70.9296 87.025 70.8507C87.0804 70.7718 87.1021 70.6741 87.0854 70.5792L78.1187 19.7266C78.1039 19.6428 78.0602 19.5668 77.9953 19.5118C77.9303 19.4569 77.8481 19.4264 77.763 19.4258H77.763Z"
                    fill="#A7AFBB"
                />
                <path
                    d="M20.3671 47.0805C19.9783 46.7639 19.53 46.5283 19.0487 46.3874C18.5674 46.2466 18.0628 46.2035 17.5646 46.2606C15.0006 46.7123 15.072 49.2105 15.2158 50.0269C15.3596 50.8433 16.159 53.1981 18.7226 52.7457C19.7164 52.5057 20.5959 51.9277 21.2105 51.1107L22.6015 52.2983C22.2048 52.9173 21.6841 53.4474 21.0723 53.8552C20.4605 54.263 19.7709 54.5396 19.0468 54.6676C15.2226 55.342 13.5322 52.539 13.1533 50.3906C12.7847 48.2996 13.4069 45.0443 17.2311 44.3699C18.9495 44.067 20.2699 44.6022 21.4719 45.5567L20.3671 47.0805Z"
                    fill="white"
                />
                <path
                    d="M33.5646 46.7914C33.9435 48.9395 33.3185 52.1807 29.523 52.8497C25.6991 53.5241 24.0038 50.6924 23.6249 48.5439C23.2563 46.453 23.8731 43.1692 27.6973 42.4949C31.4925 41.8258 33.196 44.7001 33.5646 46.7914ZM31.5017 47.1551C31.3579 46.3386 30.5514 43.9409 28.0308 44.3855C25.4669 44.8376 25.5432 47.3642 25.687 48.1803C25.8312 48.997 26.6284 51.4257 29.1924 50.9736C31.7134 50.529 31.6459 47.9715 31.5017 47.1551Z"
                    fill="white"
                />
                <path
                    d="M42.0728 50.5631L38.3271 45.5673L37.4744 51.2854L35.3118 51.6668L37.2152 40.9348L37.3725 40.9071L41.4372 46.5403L43.3306 39.8563L43.488 39.8286L48.9465 49.2626L46.7838 49.6437L44.027 44.5623L42.2159 50.5375L42.0728 50.5631Z"
                    fill="white"
                />
                <path
                    d="M52.8446 48.5751L50.8105 48.9338L49.0427 38.9081L51.0768 38.5494L52.8446 48.5751Z"
                    fill="white"
                />
                <path
                    d="M54.0561 37.9359L61.1605 42.634L60.1577 36.9486L62.1915 36.59L63.9617 46.6298L63.8289 46.7122L56.7312 41.9682L57.7436 47.7115L55.7095 48.0702L53.9368 38.0158L54.0561 37.9359Z"
                    fill="white"
                />
                <path
                    d="M70.2883 41.8812L69.9651 40.0481L73.9754 39.3407L74.68 43.3369C74.2886 43.9091 73.7823 44.3935 73.1933 44.7593C72.6043 45.125 71.9456 45.3641 71.2591 45.4612C67.4349 46.1355 65.7449 43.3322 65.366 41.1838C64.9974 39.0929 65.6192 35.8379 69.4434 35.1635C70.1407 35.0234 70.8603 35.0384 71.5512 35.2075C72.242 35.3765 72.8872 35.6955 73.441 36.1418L72.4321 37.7079C71.6568 37.1726 70.7122 36.9399 69.7769 37.0538C67.213 37.5058 67.279 39.975 67.4232 40.7917C67.567 41.6078 68.3696 44.0656 70.9335 43.6135C71.5237 43.5144 72.0679 43.2324 72.4893 42.8074L72.2645 41.5329L70.2883 41.8812Z"
                    fill="white"
                />
                <path
                    d="M29.1716 61.9964C30.8261 62.2511 32.928 62.5302 33.178 64.701C33.3655 66.3516 32.1247 67.8552 30.0338 68.2238C28.6745 68.4726 27.2735 68.3581 25.9727 67.8917L26.565 66.0593C27.5423 66.385 28.5797 66.4897 29.6023 66.3658C30.4759 66.2117 31.0406 65.728 31.037 65.1232C31.0253 64.3871 30.2128 64.1314 29.1858 64.0025C27.5842 63.7976 25.4645 63.669 25.0806 61.4922C24.7575 59.6591 25.9258 58.3306 27.8165 57.9971C29.0929 57.8209 30.392 57.9138 31.6304 58.2699L31.2004 60.103C30.678 59.9883 29.2955 59.6857 28.2934 59.8622C27.5913 59.9861 27.0018 60.3267 27.0966 61.0334C27.244 61.7013 28.1229 61.827 29.1716 61.9964Z"
                    fill="white"
                />
                <path
                    d="M44.4385 60.3825C44.8174 62.5306 44.1924 65.7717 40.3973 66.4411C36.573 67.1151 34.8773 64.2834 34.4988 62.135C34.1298 60.044 34.747 56.7603 38.5712 56.0859C42.3664 55.4169 44.0699 58.2915 44.4385 60.3825ZM42.376 60.7461C42.2318 59.9297 41.4253 57.5319 38.9047 57.9766C36.3408 58.4286 36.4171 60.9553 36.5613 61.7713C36.7051 62.5881 37.5023 65.0167 40.0663 64.5647C42.5869 64.1201 42.5198 61.5625 42.376 60.7461Z"
                    fill="white"
                />
                <path
                    d="M56.0985 58.3267C56.4774 60.4748 55.8528 63.7159 52.0573 64.3849C48.2334 65.0593 46.5377 62.2273 46.1588 60.0792C45.7902 57.9882 46.4074 54.7045 50.2313 54.0298C54.0267 53.3607 55.7299 56.2357 56.0985 58.3267ZM54.036 58.6903C53.8922 57.8735 53.0857 55.4758 50.5647 55.9204C48.0011 56.3725 48.0771 58.8991 48.2213 59.7155C48.3651 60.5319 49.1627 62.9609 51.7263 62.5085C54.2473 62.0643 54.1802 59.5064 54.036 58.6903Z"
                    fill="white"
                />
                <path
                    d="M57.6243 52.8149L64.7292 57.5134L63.7267 51.8277L65.7601 51.469L67.5303 61.5088L67.3975 61.5916L60.2994 56.8472L61.3122 62.5906L59.2785 62.9492L57.5054 52.8952L57.6243 52.8149Z"
                    fill="white"
                />
            </g>
        </svg>
    );
};

export default ComingSoon;
