import { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import TooltipWrapper from '../../../../../common/components/Tooltip/TooltipWrapper';
import ChargingModeColumnIntl from './ChargingModeColumnIntl';
import { ElectricVehicleIcon } from '../../../../../common/components/ElectricVehicleIcon/ElectricVehicleIcon';
import VehicleType from '../../../../../common/vehicles/VehicleType';

const ProgrammedChargingMode = (): ReactElement => {
    const intl = useIntl();
    return (
        <div className="display-flex flex-row">
            <ElectricVehicleIcon vehicleType={VehicleType.VAN} extraClasses="padding-right-5" />
            <TooltipWrapper
                tooltipContent={intl.formatMessage({ id: ChargingModeColumnIntl.PROGRAMMED_CHARGING_MODE })}
                dataTestId="programmed-charging-mode"
            >
                <>{intl.formatMessage({ id: ChargingModeColumnIntl.PROGRAMMED_CHARGING_MODE })}</>
            </TooltipWrapper>
        </div>
    );
};

export default ProgrammedChargingMode;
