import { FormattedMessage, FormattedNumber } from 'react-intl';
import List from './List';
import NoDataElement from './NoDataElement';
import WithUnit from './WithUnit';

type Props = {
    temperatures: {
        min: number;
        max: number;
        avg: number;
    } | null;
};

const Temperature = ({ temperatures }: Props): JSX.Element => {
    if (!temperatures) {
        return <NoDataElement />;
    }

    const temperatureValues = Object.entries(temperatures).map(([key, value]) => ({
        label: <FormattedMessage id={`e4c.history.${key}`} />,
        value: (
            <WithUnit unit="C" dataTestId={key}>
                <span>
                    <FormattedNumber value={value} maximumFractionDigits={0} />°
                </span>
            </WithUnit>
        ),
    }));

    return <List data-testid="temperatures" values={temperatureValues} />;
};

export default Temperature;
