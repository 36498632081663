import { SortDirection } from '../../vehicleCharging/types';
import classNames from 'classnames';
import toLower from 'lodash/fp/toLower';
import { CustomTooltip } from '../../components/shared/CustomTooltip';
import _ from 'lodash';
import { ChargeCycleActivity } from '../../history/components/types';
import { PaginationOptions } from '../components/pagination/Pagination';
import ChargingProcessList from '../../history/models/ChargingProcessList';
import AbstractElectricVehiclesSorter from '../../vehicleCharging/sorters/AbstractElectricVehiclesSorter';
import React from 'react';
import TooltipWrapper from '../components/Tooltip/TooltipWrapper';

export const renderTableHead = (
    column: string,
    label: string,
    sortable: boolean,
    sorter: AbstractElectricVehiclesSorter | undefined,
    width: number | undefined,
    tooltip: string | undefined,
    currentSortDir: SortDirection,
    currentsSorter: AbstractElectricVehiclesSorter,
    onSortChange: (sorter: AbstractElectricVehiclesSorter, sortDir: SortDirection) => void
): JSX.Element => {
    const optionalWidthClassName = width ? `width-${width}-md` : '';
    const optionalTooltip = tooltip ? (
        <CustomTooltip messageId={tooltip} iconClassName={'text-color-darkest cursor-pointer'} />
    ) : null;
    if (sortable && sorter) {
        const tableHeadClassNames = classNames('user-select-none', 'sort-column', optionalWidthClassName);
        return (
            <th key={column} className={tableHeadClassNames}>
                <div className="display-flex">
                    <div
                        onClick={(): void => onSortChange(sorter, currentSortDir)}
                        data-field={label}
                        className="display-flex ellipsis-1"
                    >
                        {currentsSorter.key === column ? (
                            <span className={`sort-arrows sort-${toLower(currentSortDir)}`} />
                        ) : (
                            <span className={'sort-arrows'} />
                        )}
                        <TooltipWrapper tooltipContent={label} dataTestId={`${label} ${column}`}>
                            <>{label}</>
                        </TooltipWrapper>
                    </div>
                    {optionalTooltip}
                </div>
            </th>
        );
    } else {
        return (
            <th
                key={column}
                className={`user-select-none ${optionalWidthClassName}`}
                data-field={label}
                data-sortby={column}
            >
                <span className={'display-flex'}>
                    <span className={'ellipsis-1'}>
                        <TooltipWrapper tooltipContent={label} dataTestId={`${label} ${column}`}>
                            <>{label}</>
                        </TooltipWrapper>
                        {optionalTooltip}
                    </span>
                </span>
            </th>
        );
    }
};

export const handleTableSortChange = (
    e: React.MouseEvent,
    currentSortDir: SortDirection,
    currentSortBy: string,
    handleSortChange: (sortDir: SortDirection, sortBy: string) => void
): void => {
    let sortBy = e.currentTarget.getAttribute('data-sortby');
    sortBy = sortBy ? sortBy : currentSortBy;
    let sortDir = SortDirection.ASCENDING;
    if (sortBy === currentSortBy) {
        sortDir = currentSortDir === SortDirection.ASCENDING ? SortDirection.DESCENDING : SortDirection.ASCENDING;
    }
    handleSortChange(sortDir, sortBy);
};

export const groupRowsByStartDate = (rows: ChargingProcessList): { [p: string]: Pick<ChargeCycleActivity, any>[] } => {
    return _.mapValues(_.groupBy(rows, 'startDate'), clist => clist.map(row => _.omit(row, 'startDate')));
};

export const filterRowsByPagination = (
    rows: { [p: string]: Pick<ChargeCycleActivity, any>[] },
    pagination: PaginationOptions
): { [p: string]: Pick<ChargeCycleActivity, any>[] } => {
    const offset = pagination.pageSize * (pagination.pageNumber - 1);
    const filteredKeys = _.drop(_.keys(rows), offset).slice(0, pagination.pageSize);
    const filteredRows: { [p: string]: Pick<ChargeCycleActivity, any>[] } = {};
    filteredKeys.forEach(key => (filteredRows[key] = rows[key]));
    return filteredRows;
};
