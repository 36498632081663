import { DateTimeColProps } from '../../../types';

export const DateTimeCol = (props: DateTimeColProps): JSX.Element => {
    const { date } = props;
    const displayValue = (dateTime: string): string => {
        if (dateTime) {
            return date.substring(10, date.length - 3);
        }
        return '-';
    };
    return <span>{`${displayValue(date)}`}</span>;
};
