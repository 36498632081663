import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { LocationChangeAction } from 'connected-react-router';

import { SortDetails, SortDirection } from '../vehicleCharging/types';
import ChargingProcess from './models/ChargingProcess';
import {
    HISTORY_ASSETS_SELECTED,
    HISTORY_TABLE_SORT_CHANGED,
    SELECTED_HISTORY_CONTENT_CHANGED,
    VEHICLE_CHARGING_PROCESSES_LOADING_FAILED,
    VEHICLE_CHARGING_PROCESSES_LOADING_STARTED,
    VEHICLE_CHARGING_PROCESSES_LOADING_SUCCESSFUL,
} from './actions';
import { State } from '../../../types';
import { ErrorCodeEnum } from '../common/enums/ErrorCode';

export interface VehicleChargingProcessesLoadingStartedAction {
    type: typeof VEHICLE_CHARGING_PROCESSES_LOADING_STARTED;
}

export interface VehicleChargingProcessesLoadingSuccessfulAction {
    type: typeof VEHICLE_CHARGING_PROCESSES_LOADING_SUCCESSFUL;
    payload: ChargingProcess[];
}

export interface VehicleChargingProcessesLoadingFailedAction {
    type: typeof VEHICLE_CHARGING_PROCESSES_LOADING_FAILED;
    payload: ErrorCodeEnum;
}

export interface HistoryAssetsSelectedAction {
    type: typeof HISTORY_ASSETS_SELECTED;
    payload: string[];
}

export interface BatteryHistoryTableSortChangedAction {
    type: typeof HISTORY_TABLE_SORT_CHANGED;
    payload: SortDetails;
}

export interface SelectedHistoryContentChangedAction {
    type: typeof SELECTED_HISTORY_CONTENT_CHANGED;
    payload: HistoryContentEnum;
}

export interface HistoryTableSortChangedAction {
    type: typeof HISTORY_TABLE_SORT_CHANGED;
    payload: SortDetails;
}

export interface HistoryContentProps {
    locale: string;
    selectedAssetIds: string[];
    chargingProcesses: ChargingProcess[];
    fetchingChargingProcesses: boolean;
    selectedHistoryContent: HistoryContentEnum;
    batteryHistorySortBy: string;
    batteryHistorySortDir: SortDirection;
    handleBatteryHistoryTableSortChange: (sortDir: SortDirection, sortBy: string) => void;
}

export type VehiclesHistoryActions =
    | VehicleChargingProcessesLoadingStartedAction
    | VehicleChargingProcessesLoadingSuccessfulAction
    | VehicleChargingProcessesLoadingFailedAction
    | HistoryAssetsSelectedAction
    | BatteryHistoryTableSortChangedAction
    | SelectedHistoryContentChangedAction
    | LocationChangeAction;

export type VehiclesHistoryThunkAction<R, T> = ThunkAction<R, State, T, VehiclesHistoryActions>;

export type VehiclesHistoryThunkDispatch = ThunkDispatch<State, any, VehiclesHistoryActions>;

export enum HistoryContentEnum {
    BATTERY_HISTORY = 'BATTERY_HISTORY',
    CONSUMPTION_HISTORY = 'CONSUMPTION_HISTORY',
}

export enum ChargeCycleActivityType {
    CHARGING = 'CHARGING',
    DISCHARGING = 'DISCHARGING',
}

export interface VehiclesHistoryState {
    selectedHistoryAssetIds: string[];
    chargingProcesses: ChargingProcess[];
    fetchingChargingProcesses: boolean;
    chargingProcessesErrorCode: ErrorCodeEnum | null;
    historySortBy: string;
    historySortDir: SortDirection;
    selectedHistoryContent: HistoryContentEnum;
}
