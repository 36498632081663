import { useIntl } from 'react-intl';

interface HeaderProps {
    vehicleName: string;
    titleMessage: string;
    date: string;
}
const Header = ({ vehicleName, titleMessage, date }: HeaderProps) => {
    const intl = useIntl();
    return (
        <div className="event-text-card">
            <div className="text-size-14">
                <span className="text-bold">{vehicleName}</span> |{' '}
                <span>{intl.formatMessage({ id: titleMessage })}</span>
            </div>
            <div className="text-size-12" style={{ color: '#697A8B' }}>
                {date}
            </div>
        </div>
    );
};

export default Header;
