import { ReactElement, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import detailChargingStatusTableModalImage from '../../../../../assets/images/images-details-charging-status/detail-charging-status-table-modal.jpg';
import failureRedImage from '../../../../../assets/images/images-details-charging-status/failure-red.jpeg';
import unknownRedImage from '../../../../../assets/images/images-details-charging-status/unknown-red.jpeg';
import connectedImage from '../../../../../assets/images/images-details-charging-status/connected.jpeg';
import chargingImage from '../../../../../assets/images/images-details-charging-status/charging.jpg';
import delayOnChargingImage from '../../../../../assets/images/images-details-charging-status/delay-on-charging.jpeg';
import chargedImage from '../../../../../assets/images/images-details-charging-status/charged.jpeg';
import disconnectedImage from '../../../../../assets/images/images-details-charging-status/disconnected.jpeg';
import {
    createGoogleAnalyticsEvent,
    gtmUtil,
    TrackingAction,
    TrackingCategory,
} from '../../../../../utils/googleTagManager';
import UserManualPage from '../components/UserManualPage';
import UserManualOptions from '../components/UserManualOptions';

const ChargingStatusItem = (): ReactElement => {
    const intl = useIntl();
    useEffect(() => {
        gtmUtil.pushGaEvent(
            createGoogleAnalyticsEvent(TrackingCategory.GENERAL, TrackingAction.USER_MANUAL_CHARGING_STATUS)
        );
    }, []);

    const manualOptions = [
        {
            image: (
                <img
                    alt={intl.formatMessage({ id: 'e4c.userManual.altText.chargingStatus.failure' })}
                    src={failureRedImage}
                    width="141"
                    height={'50'}
                />
            ),
            boldText: <FormattedMessage id={'e4c.userManual.details.chargingStatus.failure'} />,
            description: <FormattedMessage id={'e4c.userManual.details.chargingStatus.failureDescription'} />,
        },
        {
            image: (
                <img
                    alt={intl.formatMessage({ id: 'e4c.userManual.altText.chargingStatus.unknown' })}
                    src={unknownRedImage}
                    width="141"
                    height={'45'}
                />
            ),
            boldText: <FormattedMessage id={'e4c.userManual.details.chargingStatus.unknown'} />,
            description: <FormattedMessage id={'e4c.userManual.details.chargingStatus.unknownDescription'} />,
        },
        {
            image: (
                <img
                    alt={intl.formatMessage({ id: 'e4c.userManual.altText.chargingStatus.connected' })}
                    src={connectedImage}
                    width="141"
                    height={'50'}
                />
            ),
            boldText: <FormattedMessage id={'e4c.userManual.details.chargingStatus.connected'} />,
            description: <FormattedMessage id={'e4c.userManual.details.chargingStatus.connectedDescription'} />,
        },
        {
            image: (
                <img
                    alt={intl.formatMessage({ id: 'e4c.userManual.altText.chargingStatus.charging' })}
                    src={chargingImage}
                    width="141"
                    height={'50'}
                />
            ),
            boldText: <FormattedMessage id={'e4c.userManual.details.chargingStatus.charging'} />,
            description: <FormattedMessage id={'e4c.userManual.details.chargingStatus.chargingDescription'} />,
        },
        {
            image: (
                <img
                    alt={intl.formatMessage({ id: 'e4c.userManual.altText.chargingStatus.chargingDelay' })}
                    src={delayOnChargingImage}
                    width="141"
                    height={'50'}
                />
            ),
            boldText: <FormattedMessage id={'e4c.userManual.details.chargingStatus.delayOnCharging'} />,
            description: <FormattedMessage id={'e4c.userManual.details.chargingStatus.delayOnChargingDescription'} />,
        },
        {
            image: (
                <img
                    alt={intl.formatMessage({ id: 'e4c.userManual.altText.chargingStatus.charged' })}
                    src={chargedImage}
                    width="141"
                    height={'50'}
                />
            ),
            boldText: <FormattedMessage id={'e4c.userManual.details.chargingStatus.charged'} />,
            description: <FormattedMessage id={'e4c.userManual.details.chargingStatus.chargedDescription'} />,
        },
        {
            image: (
                <img
                    alt={intl.formatMessage({ id: 'e4c.userManual.altText.chargingStatus.disconnected' })}
                    src={disconnectedImage}
                    width="141"
                    height={'45'}
                />
            ),
            boldText: <FormattedMessage id={'e4c.userManual.details.chargingStatus.disconnected'} />,
            description: <FormattedMessage id={'e4c.userManual.details.chargingStatus.disconnectedDescription'} />,
        },
    ];

    return (
        <UserManualPage
            title={<FormattedMessage id={'e4c.userManual.details.chargingStatus.title'} />}
            footer={<FormattedMessage id={'e4c.userManual.overview.etgeObs'} />}
            dataTestId="charging-status-item"
        >
            <FormattedMessage id={'e4c.userManual.details.chargingStatus.description'} />
            <img
                alt={intl.formatMessage({ id: 'e4c.userManual.altText.chargingStatus' })}
                src={detailChargingStatusTableModalImage}
                width="683"
                height="337"
                className={'margin-top-10'}
            />
            <>
                {manualOptions.map((option, index) => (
                    <UserManualOptions
                        key={index}
                        image={option.image}
                        boldText={option.boldText}
                        description={option.description}
                    />
                ))}
            </>
        </UserManualPage>
    );
};

export default ChargingStatusItem;
