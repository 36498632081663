import { MemoChargingStatusColumn } from './ChargingStatusColumn/ChargingStatusColumn';
import { MemoBatteryStatusColumn } from './BatteryStatusColumn/BatteryStatusColumn';
import { MemoRemainingCapacityColumn } from './RemainingCapacityColumn/RemainingCapacityColumn';
import VehicleNameSorter from '../../../sorters/VehicleNameSorter';
import ChargingStatusSorter from '../../../sorters/ChargingStatusSorter';
import StateOfChargeSorter from '../../../sorters/StateOfChargeSorter';
import RemainingRangeSorter from '../../../sorters/RemainingRangeSorter';
import ReadyToDriveStatusSorter from '../../../sorters/ReadyToDriveStatusSorter';
import LastUpdatedSorter from '../../../sorters/LastUpdatedSorter';
import { MemoLastUpdatedColumn } from './LastUpdatedColumn/LastUpdatedColumn';
import { MemoChargingModeColumn } from './ChargingModeColumn/ChargingModeColumn';
import { MemoVehicleIdentificationColumn } from './VehicleIdentificationColumn/VehicleIdentificationColumn';
import { VehicleChargingData } from '../../../models/VehicleChargingData';
import {
    getModifiedAt,
    hasRemainingCapacityKWhAvailable,
    hasStateOfChargeAvailable,
} from '../../../helpers/rulesVehicleCharging';
import { VehicleStatusCol } from './VehicleStatusCol/VehicleStatusCol';
import AbstractElectricVehiclesSorter from '../../../sorters/AbstractElectricVehiclesSorter';

export interface TableColumnDetails {
    label?: string;
    sortable: boolean;
    sorter: AbstractElectricVehiclesSorter | undefined;
    width?: number; // not all values are supported, see https://uikit.developers.rio.cloud/#utilityClasses#sizing-width
    tooltip?: string;
}

const getColumnConfig = (): { [key: string]: TableColumnDetails } => {
    return {
        name: {
            label: 'e4c.vehicles.vehicle',
            sortable: true,
            sorter: new VehicleNameSorter(),
        },
        chargingStatus: {
            label: 'e4c.vehicles.chargingStatus',
            sortable: true,
            sorter: new ChargingStatusSorter(),
        },
        stateOfCharge: {
            label: 'e4c.vehicles.stateOfCharge',
            sortable: true,
            sorter: new StateOfChargeSorter(),
        },
        remainingRange: {
            label: 'e4c.vehicles.remainingCapacity',
            sortable: true,
            sorter: new RemainingRangeSorter(),
            tooltip: 'e4c.vehicles.remainingCapacity.tooltip',
        },
        configuration: {
            label: 'e4c.vehicles.configuration',
            sortable: false,
            sorter: undefined,
        },
        readyToDriveStatus: {
            label: 'e4c.vehicles.vehicleStatus',
            sortable: true,
            sorter: new ReadyToDriveStatusSorter(),
        },
        timestamp: {
            label: 'e4c.vehicles.timestamp',
            sortable: true,
            sorter: new LastUpdatedSorter(),
        },
    };
};

const renderColumnValue = (column: string, vehicle: VehicleChargingData, locale: string): JSX.Element => {
    switch (column) {
        case 'name': {
            return (
                <MemoVehicleIdentificationColumn
                    name={vehicle.assetInfo.name}
                    vehicleType={vehicle.assetInfo.type}
                    productVersion={vehicle.productVersion}
                />
            );
        }
        case 'chargingStatus': {
            return (
                <MemoChargingStatusColumn
                    chargingState={vehicle.measurements?.chargingState.value}
                    chargingUntil={vehicle.chargingUntil}
                    delayedChargingUntil={vehicle.delayedChargingUntil}
                    disconnectedAt={vehicle.disconnectedAt}
                    lastUpdated={getModifiedAt(vehicle.assetInfo)}
                />
            );
        }
        case 'stateOfCharge': {
            return (
                <MemoBatteryStatusColumn
                    isDisabled={!hasStateOfChargeAvailable(vehicle.measurements.stateOfCharge.value)}
                    value={vehicle.measurements.stateOfCharge.value}
                />
            );
        }
        case 'remainingRange': {
            return (
                <MemoRemainingCapacityColumn
                    isRemainingCapacityKWhAvailable={hasRemainingCapacityKWhAvailable(
                        vehicle.assetInfo,
                        vehicle.productVersion
                    )}
                    valueInKm={vehicle.measurements.remainingRange.value}
                    valueInKWh={vehicle.measurements.energyLevel.value}
                />
            );
        }
        case 'configuration': {
            return (
                <MemoChargingModeColumn
                    assetInfo={vehicle.assetInfo}
                    chargingConfiguration={vehicle.chargingConfiguration}
                />
            );
        }
        case 'readyToDriveStatus': {
            return (
                <VehicleStatusCol
                    vehicleModel={vehicle.assetInfo.vehicleModel}
                    drivingState={vehicle.measurements.drivingState.value}
                    chargingState={vehicle.measurements.chargingState.value}
                    readyToDriveStatus={vehicle.readyToDriveStatus}
                    readyToDriveUntil={vehicle.readyToDriveUntil}
                    locale={locale}
                    lastUpdated={getModifiedAt(vehicle.assetInfo)}
                />
            );
        }
        case 'timestamp': {
            return <MemoLastUpdatedColumn timestamp={vehicle.measurementsLastUpdated} locale={locale} />;
        }
        default:
            throw Error('Column from ColumnConfig not handled in renderColumnValue - please add it');
    }
};

export { getColumnConfig, renderColumnValue };
