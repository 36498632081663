import { FormattedMessage } from 'react-intl';
import HorizontalCard from '../../../../../common/components/HorizontalCard';
import Header from './Header';
import { Events } from '../../../../models/Event';
import SeverityIcon from './SeverityIcon';
import React from 'react';

interface ContentProps {
    message: string;
    interpolationMessage: string;
}

const Content: React.FC<ContentProps> = ({ message, interpolationMessage }: ContentProps) => {
    const interpolatedMessage = JSON.parse(JSON.stringify(interpolationMessage));

    return (
        <div className={'text-size-12 ellipsis-1'}>
            <FormattedMessage
                id={message}
                values={{
                    ...interpolatedMessage,
                    b: (chunks: any) => <b>{chunks}</b>,
                    ul: () => '...',
                    li: () => null,
                }}
            />
        </div>
    );
};

interface EventsBySeverityCardProps {
    event: Events;
}

const Body: React.FC<EventsBySeverityCardProps> = ({ event }) => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Header titleMessage={event.eventTitleMessage} date={event.date} vehicleName={event.eventVehicle} />
        <Content message={event.eventSubtitleMessage} interpolationMessage={event.eventInterpolationMessage} />
    </div>
);

const EventsBySeverityCard: React.FC<EventsBySeverityCardProps> = ({
    event,
}: EventsBySeverityCardProps): JSX.Element => {
    return (
        <HorizontalCard testId="event-severity-card" left={<SeverityIcon severityLevel={event.severityLevel} />}>
            <Body event={event} />
        </HorizontalCard>
    );
};

export default EventsBySeverityCard;
