import { VehicleDetailsProps } from './types';
import { ChargingModeEnum } from '../../../types';
import * as _ from 'lodash';

export const isReadOnlyUserValidation = (vehicleDetailsProps: VehicleDetailsProps): boolean => {
    let val = true;
    if (
        vehicleDetailsProps.userAction &&
        vehicleDetailsProps.userAction.post_immediate_configurations &&
        vehicleDetailsProps.userAction.post_timer_configurations
    ) {
        val = false;
    }
    return val;
};

export const hasUnsavedChanges = (vehicleDetailsProps: VehicleDetailsProps): boolean => {
    if (
        vehicleDetailsProps.selectedVehicle &&
        vehicleDetailsProps.selectedVehicle.chargingConfiguration.chargingMode === ChargingModeEnum.TIMER
    ) {
        if (areTimersChanged(vehicleDetailsProps) || isReadyToDriveDurationChanged(vehicleDetailsProps)) {
            return true;
        }
    }
    if (
        isClimateModeChanged(vehicleDetailsProps) ||
        isTargetStateOfChargeChanged(vehicleDetailsProps) ||
        isChargingModeChanged(vehicleDetailsProps)
    ) {
        return true;
    }
    vehicleDetailsProps.toggleUnsavedVehicleChanges(false);
    return false;
};

const areTimersChanged = (vehicleDetailsProps: VehicleDetailsProps): boolean => {
    const currentTimerConfiguration = vehicleDetailsProps.timerConfiguration;
    const timerConfigurationBeforeChanges = vehicleDetailsProps.timerConfigurationBeforeChanges;
    if (
        (currentTimerConfiguration === undefined && timerConfigurationBeforeChanges !== undefined) ||
        (currentTimerConfiguration !== undefined && timerConfigurationBeforeChanges === undefined)
    ) {
        return true;
    } else if (
        timerConfigurationBeforeChanges &&
        currentTimerConfiguration &&
        currentTimerConfiguration.timers.length !== timerConfigurationBeforeChanges.timers.length
    ) {
        return true;
    } else if (
        timerConfigurationBeforeChanges &&
        currentTimerConfiguration &&
        currentTimerConfiguration.timers.length === timerConfigurationBeforeChanges.timers.length
    ) {
        return !_.isEqual(_.sortBy(timerConfigurationBeforeChanges.timers), _.sortBy(currentTimerConfiguration.timers));
    }

    return false;
};

const isReadyToDriveDurationChanged = (vehicleDetailsProps: VehicleDetailsProps): boolean =>
    vehicleDetailsProps.timerConfiguration?.ready_to_drive_duration !==
    vehicleDetailsProps.timerConfigurationBeforeChanges?.ready_to_drive_duration;

const isClimateModeChanged = (vehicleDetailsProps: VehicleDetailsProps): boolean => {
    return (
        vehicleDetailsProps.selectedVehicle?.chargingConfiguration.climateMode !==
        vehicleDetailsProps.selectedVehicleBeforeChanges?.chargingConfiguration.climateMode
    );
};

const isTargetStateOfChargeChanged = (vehicleDetailsProps: VehicleDetailsProps): boolean => {
    return (
        vehicleDetailsProps.selectedVehicle?.chargingConfiguration.targetStateOfCharge !==
        vehicleDetailsProps.selectedVehicleBeforeChanges?.chargingConfiguration.targetStateOfCharge
    );
};

const isChargingModeChanged = (vehicleDetailsProps: VehicleDetailsProps): boolean => {
    return (
        vehicleDetailsProps.selectedVehicle?.chargingConfiguration.chargingMode !==
        vehicleDetailsProps.selectedVehicleBeforeChanges?.chargingConfiguration.chargingMode
    );
};
