import { AccessToken } from '../configuration';
import { isDevEnvironment } from './utils';
import { getLocalAccessToken } from './Api';
import { config } from '../config';

const generateHeaders = async (accessToken: AccessToken, contentType = 'application/json') => {
    const bearerToken = isDevEnvironment() ? await getLocalAccessToken() : accessToken;
    return {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': contentType,
    };
};

const fetchWithHandling = async (url: string, options: RequestInit): Promise<Response> => {
    const response = await fetch(url, options);
    if (!response.ok) {
        if (response.status === 400 || response.status === 404) {
            const errorData = await response.json();
            throw new Error(errorData.detail);
        }
        throw new Error(response.statusText);
    }
    return response;
};

const buildEndpoint = (assetId: string, path: string = '') =>
    `${config.backend.OTA_CHARGING_STATE_SERVICE}/assets/${assetId}/charging-configurations${path}`;

export const fetchVehicleChargingConfigurations = async (
    accessToken: AccessToken,
    assetId: string
): Promise<Response> => {
    const headers = await generateHeaders(accessToken);
    return fetchWithHandling(buildEndpoint(assetId), {
        headers,
        method: 'GET',
    });
};

export const postImmediateConfigurations = async (
    accessToken: AccessToken,
    assetId: string,
    body: string
): Promise<Response> => {
    const headers = await generateHeaders(accessToken);
    return fetchWithHandling(buildEndpoint(assetId, '/immediate'), {
        headers,
        method: 'POST',
        body,
    });
};

export const postTimerConfigurations = async (
    accessToken: AccessToken,
    assetId: string,
    body: string
): Promise<Response> => {
    const headers = await generateHeaders(accessToken);
    return fetchWithHandling(buildEndpoint(assetId, '/timers'), {
        headers,
        method: 'POST',
        body,
    });
};

export const putTimerConfigurations = async (
    accessToken: AccessToken,
    assetId: string,
    timerId: string,
    body: string
): Promise<Response> => {
    const headers = await generateHeaders(accessToken);
    return fetchWithHandling(buildEndpoint(assetId, `/timers/${timerId}`), {
        headers,
        method: 'PUT',
        body,
    });
};

export const patchTimers = async (accessToken: AccessToken, assetId: string, body: string): Promise<Response> => {
    const headers = await generateHeaders(accessToken, 'application/json-patch+json');
    return fetchWithHandling(buildEndpoint(assetId, '/timers'), {
        headers,
        method: 'PATCH',
        body,
    });
};
