export type VerticalAlign = '20' | '40' | '60' | '80' | '100';
interface WrapperProps {
    children: JSX.Element | JSX.Element[];
    verticalAlign?: VerticalAlign;
    testId?: string;
    className?: string;
}

export const Wrapper = ({ verticalAlign = '100', testId, children, className }: WrapperProps): JSX.Element => {
    const defaultWrapperStyle = 'display-flex align-items-center justify-content-center width-100pct';
    const wrapperStyleWithHeigth = verticalAlign
        ? `${defaultWrapperStyle} height-${verticalAlign}pct`
        : defaultWrapperStyle;
    const wrapperStyleWithExtraStyle = className ? `${wrapperStyleWithHeigth} ${className}` : wrapperStyleWithHeigth;

    return (
        <div data-testid={testId} className={wrapperStyleWithExtraStyle}>
            {children}
        </div>
    );
};
