import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { createGoogleAnalyticsEvent, gtmUtil, TrackingAction, TrackingCategory } from '../../../utils/googleTagManager';

export const LinkVehicleCharging = (): JSX.Element => {
    const history = useHistory();
    const intl = useIntl();

    const handleClickVehicle = () => {
        gtmUtil.pushGaEvent(
            createGoogleAnalyticsEvent(
                TrackingCategory.OVERVIEW_TAB,
                TrackingAction.LINK_TO_VEHICLE_CHARGING_TAB_WIDGET
            )
        );
        history.push('/vehicles');
    };

    return <a onClick={handleClickVehicle}>{intl.formatMessage({ id: 'e4c.overview.widget.linkToVC' })}</a>;
};
