import { useCallback, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ASSETS_PATH, DEFAULT_PATH, HISTORY_PATH, OVERVIEW_PATH } from '../../../../configuration/setup/path';
import OverviewContainer from '../../overview/containers/Overview.container';
import { MainProperties } from './types';
import HistoryWrapper from '../../history/HistoryWrapper';
import Assets from '../assets/Assets';

const Main = ({
    fetchElectricVehiclesFromStateService,
    fetchElectricVehiclesFromMonitoringService,
    accessToken,
}: MainProperties): JSX.Element => {
    const initialFetches = useCallback(() => {
        fetchElectricVehiclesFromStateService(accessToken);
        fetchElectricVehiclesFromMonitoringService(accessToken);
    }, [accessToken, fetchElectricVehiclesFromStateService, fetchElectricVehiclesFromMonitoringService]);

    useEffect(() => {
        initialFetches();
    }, [initialFetches]);

    return (
        <Switch>
            <Route path={ASSETS_PATH} component={Assets} />
            <Route path={OVERVIEW_PATH} component={OverviewContainer} />
            <Route path={HISTORY_PATH} component={HistoryWrapper} />
            <Redirect to={DEFAULT_PATH} />
        </Switch>
    );
};

export default Main;
