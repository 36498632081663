import { State } from '../../../../../types';
import { VehicleStatistics } from '../../../overview/types';

export const getFetchingVehiclesStatistics = (state: State): boolean => {
    return state.vehiclesStatistics.fetchingVehiclesStatistics;
};

export const getVehiclesStatistics = (state: State): VehicleStatistics[] => {
    return state.vehiclesStatistics.vehiclesStatistics;
};
