import { ChargeCycleActivityType } from '../../../types';
import { FormattedMessage } from 'react-intl';
import List from './List';

type ConsumptionProps = {
    processType: string;
    powertrainEnergyConsumptionKwh: number | null;
    powertrainEnergyConsumptionPercentage: number | null;
    auxEnergyConsumptionKwh: number | null;
    auxEnergyConsumptionPercentage: number | null;
    amsEnergyConsumptionKwh: number | null;
    amsEnergyConsumptionPercentage: number | null;
};

type PercentAndKwhProps = {
    kwh: string | null;
    percent: number | null;
};

const PercentAndKwh = ({ kwh, percent, ...props }: PercentAndKwhProps) => {
    if (kwh == null && percent == null) {
        return (
            <div className={'display-flex flex-row'} {...props}>
                <span className={'text-color-lighter'}>NO DATA</span>
            </div>
        );
    }
    return (
        <div className={'display-flex flex-row'} {...props}>
            {percent !== null && <span> {percent} %</span>}
            {percent !== null && kwh !== null && <span className={'margin-left-5 margin-right-5'}>-</span>}
            {kwh !== null && <span>{kwh} kWh</span>}
        </div>
    );
};

export const Consumption = (props: ConsumptionProps): JSX.Element => {
    const values = [
        {
            label: <FormattedMessage id="e4c.history.aggregateUsage.powertrain" />,
            value: (
                <PercentAndKwh
                    percent={props.powertrainEnergyConsumptionPercentage}
                    kwh={props.powertrainEnergyConsumptionKwh?.toFixed(0) || null}
                    data-testid="aggregateUsage-powertrain"
                />
            ),
        },
        {
            label: <FormattedMessage id="e4c.history.aggregateUsage.auxiliary" />,
            value: (
                <PercentAndKwh
                    percent={props.auxEnergyConsumptionPercentage}
                    kwh={props.auxEnergyConsumptionKwh?.toString() || null}
                    data-testid="aggregateUsage-aux"
                />
            ),
        },
        {
            label: <FormattedMessage id="e4c.history.aggregateUsage.ac" />,
            value: (
                <PercentAndKwh
                    percent={props.amsEnergyConsumptionPercentage}
                    kwh={props.amsEnergyConsumptionKwh?.toFixed(0) || null}
                    data-testid="aggregateUsage-ams"
                />
            ),
        },
    ];
    return props.processType === ChargeCycleActivityType.CHARGING ? (
        <span>-</span>
    ) : (
        <List data-testid="aggregates" values={values} />
    );
};
