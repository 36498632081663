import { FormattedMessage } from 'react-intl';
import { ChargeCycleActivityType, StatusProps } from '../../../types';

export const Status = (props: StatusProps): JSX.Element => {
    let statusKey;
    if (props.processType === ChargeCycleActivityType.CHARGING) {
        statusKey = 'e4c.history.status.charging';
    } else if (props.processType === ChargeCycleActivityType.DISCHARGING) {
        statusKey = 'e4c.history.status.discharging';
    }
    return (
        <span className={'text-bold ellipsis-1 display-block'}>
            <FormattedMessage id={statusKey} />
        </span>
    );
};
