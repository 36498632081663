export enum SeverityEventLevel {
    CRITICAL = 'critical',
    WARNING = 'warning',
    SUCCESS = 'success',
    INFO = 'info',
}

export enum SeverityEventLevelMVP {
    CRITICAL = 'critical',
    WARNING = 'warning',
    SUCCESS = 'success',
}
