import ChargingStatus from '../../models/enums/ChargingStatus';
import StatsWidget from '@rio-cloud/rio-uikit/StatsWidget';
import StatsWidgetNumber from '@rio-cloud/rio-uikit/StatsWidgetNumber';
import StatsWidgetSpacer from '@rio-cloud/rio-uikit/StatsWidgetSpacer';
import ResetFilterButton from './ResetFilterButton';
import { getNumberOfVehiclesWithChargingStatusUsingElectricVehicle, getWidgetOpacityClass, hasFilter } from './utils';
import { VehicleChargingData } from '../../models/VehicleChargingData';

interface DoubleFilterPanelProps {
    chargingStatusFilterValues: (ChargingStatus | undefined)[];
    dataTestIdHeader: string;
    dataTestIdSpacer: string;
    firstChargingStatus: (ChargingStatus | undefined)[];
    firstDataTestId: string;
    firstLabelType: JSX.Element;
    firstTextColor: string;
    onFilterChargingStatusChange: (
        firstChargingStatus: (ChargingStatus | undefined)[],
        secondChargingStatus: (ChargingStatus | undefined)[]
    ) => void;
    onResetFilter: () => void;
    plugIcon: string;
    secondChargingStatus: ChargingStatus[];
    secondDataTestId: string;
    secondLabelType: JSX.Element;
    secondTextColor: string;
    vehicles: VehicleChargingData[];
}

export const DoubleFilterPanel = (props: DoubleFilterPanelProps): JSX.Element => {
    const {
        firstChargingStatus,
        chargingStatusFilterValues,
        firstTextColor,
        firstDataTestId,
        dataTestIdHeader,
        dataTestIdSpacer,
        firstLabelType,
        onFilterChargingStatusChange,
        onResetFilter,
        plugIcon,
        secondLabelType,
        secondChargingStatus,
        secondDataTestId,
        secondTextColor,
        vehicles,
    } = props;

    const hasAnyFilter = (): boolean =>
        hasFilter(chargingStatusFilterValues, firstChargingStatus) ||
        hasFilter(chargingStatusFilterValues, secondChargingStatus);

    return (
        <StatsWidget>
            {hasAnyFilter() && <ResetFilterButton onClick={onResetFilter} />}
            <StatsWidget.Header data-testid={dataTestIdHeader}>
                <span className={`${plugIcon} ${firstTextColor} text-size-h1`} />
            </StatsWidget.Header>
            <StatsWidget.Body>
                <StatsWidgetNumber
                    data-testid={firstDataTestId}
                    className={`width-40pct width-30pct-ls ${firstTextColor}${getWidgetOpacityClass(
                        chargingStatusFilterValues,
                        firstChargingStatus
                    )}`}
                    value={getNumberOfVehiclesWithChargingStatusUsingElectricVehicle(vehicles, firstChargingStatus)}
                    label={firstLabelType}
                    clickable
                    onClick={(): void => onFilterChargingStatusChange(firstChargingStatus, secondChargingStatus)}
                />
                <StatsWidgetSpacer data-testid={dataTestIdSpacer} />
                <StatsWidgetNumber
                    data-testid={secondDataTestId}
                    className={`width-40pct width-30pct-ls ${secondTextColor}${getWidgetOpacityClass(
                        chargingStatusFilterValues,
                        secondChargingStatus
                    )}`}
                    value={getNumberOfVehiclesWithChargingStatusUsingElectricVehicle(vehicles, secondChargingStatus)}
                    label={secondLabelType}
                    clickable
                    onClick={(): void => onFilterChargingStatusChange(secondChargingStatus, firstChargingStatus)}
                />
            </StatsWidget.Body>
        </StatsWidget>
    );
};
