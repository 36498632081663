import { AssetsChargeStatsApi, AssetsChargeStatsResponseApi } from '../chargeStatsResponse';
import { AssetsChargeStats, AssetsChargeStatsByDate } from '../types';
import { ProductVersion } from '../../../common/vehicles/productVersion';

const roundValue = (value: number | undefined) => (value != null ? Math.round(value) : undefined);

const mapAssetsChargeStatsApiToAssetsChargeStats = (
    data: AssetsChargeStatsApi,
    isOpen: boolean
): AssetsChargeStats => ({
    startDate: data.first_cycle_start_time_at,
    endDate: data.last_cycle_start_time_at,
    assetId: data.asset_id,
    assetName: data.asset_name,
    vehicleType: data.asset_type,
    productVersion: data.product_version,
    stats: data?.stats
        ? {
              batteryTemperatureWarningCount: data.stats.battery_temperature_warning_count,
              totalEnergyCharged: roundValue(data.stats.total_energy_charged),
              totalEnergyConsumed: roundValue(data.stats.total_energy_consumed),
          }
        : undefined,
    isOpen,
});

const mappingChargeStatsFromServer = (response: AssetsChargeStatsResponseApi[]): AssetsChargeStatsByDate[] => {
    let isFirstOpenSet = false;

    return response
        ?.flatMap(e => e?.items)
        ?.reduce((accumulator: AssetsChargeStatsByDate[], data: AssetsChargeStatsApi) => {
            const { date_at: dateAt } = data;
            const isFirstOpen = !isFirstOpenSet && data.product_version === ProductVersion.EMANAGER_M;
            if (isFirstOpen) {
                isFirstOpenSet = true;
            }

            const assetsChargeStats = mapAssetsChargeStatsApiToAssetsChargeStats(data, isFirstOpen);
            const existingEntry = accumulator.find(item => item.date === dateAt);

            if (existingEntry) {
                existingEntry.assetsChargeStats.push(assetsChargeStats);
            } else {
                accumulator.push({
                    date: dateAt,
                    assetsChargeStats: [assetsChargeStats],
                });
            }

            return accumulator;
        }, []);
};

export default mappingChargeStatsFromServer;
