import React from 'react';

export interface DeleteButtonProps {
    handleClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}
const DeleteButton = ({ handleClick }: DeleteButtonProps): JSX.Element => {
    return (
        <div
            className={
                'display-flex margin-top-20 justify-content-center align-items-center text-color-dark cursor-pointer'
            }
            onClick={handleClick}
            data-testid="delete-button"
        >
            <span className="rioglyph rioglyph-trash text-size-20" />
        </div>
    );
};

export default DeleteButton;
