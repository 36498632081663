import { ProductVersion } from '../../common/vehicles/productVersion';
import ElectricVehicle from './ElectricVehicle';

class ElectricVehicleList {
    private readonly _electricVehicles: ElectricVehicle[];

    constructor(electricVehicles: ElectricVehicle[]) {
        this._electricVehicles = electricVehicles;
    }

    public get electricVehicles(): ElectricVehicle[] {
        return this._electricVehicles;
    }

    public get size(): number {
        return this._electricVehicles.length;
    }

    public get drivingCountForMVehicles(): number {
        return this._electricVehicles.filter(ev => ev.isDriving && ev.productVersion === ProductVersion.EMANAGER_M)
            .length;
    }

    public get readyToDriveCount(): number {
        return this._electricVehicles.filter(ev => ev.isReadyToDrive).length;
    }

    public get chargingCount(): number {
        return this._electricVehicles.filter(ev => ev.isCharging).length;
    }

    public get productBookings(): Record<ProductVersion, number> {
        return this._electricVehicles.reduce(
            (acc, vehicle) => {
                acc[vehicle.productVersion]++;
                return acc;
            },
            {
                [ProductVersion.NOT_BOOKED]: 0,
                [ProductVersion.EMANAGER_S]: 0,
                [ProductVersion.EMANAGER_M]: 0,
            }
        );
    }
}

export default ElectricVehicleList;
