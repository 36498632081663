import { FormattedMessage } from 'react-intl';
import { OVERVIEW_FEEDBACK_PATH } from '../../../../../../common/constants/paths/paths';

const InfoSection = () => {
    const formatValues = {
        b: (chunks: any) => <b>{chunks}</b>,
    };

    return (
        <div className="alert alert-info display-flex" data-testid="info-section">
            <span className="text-color-info text-size-h4 margin-right-10 rioglyph rioglyph-info-sign"></span>
            <div>
                <strong>
                    <FormattedMessage id="e4c.overview.events.widget.settings.critical.sms.importantInformation.title" />
                </strong>
                <div className="margin-y-5 padding-bottom-10">
                    <FormattedMessage
                        id="e4c.overview.events.widget.settings.critical.sms.importantInformation.description1"
                        values={formatValues}
                    />
                </div>
                <div className="margin-y-5 padding-bottom-10">
                    <FormattedMessage
                        id="e4c.overview.events.widget.settings.critical.sms.importantInformation.description2"
                        values={formatValues}
                    />
                </div>
                <div className="text-medium text-uppercase text-size-12">
                    <a href={OVERVIEW_FEEDBACK_PATH} className="alert-link" target="_blank" rel="noreferrer noopener">
                        <FormattedMessage id="e4c.feedbackForm.sendFeedback.button" />
                    </a>
                    <span className="text-color-info margin-left-3 rioglyph rioglyph-new-window"></span>
                </div>
            </div>
        </div>
    );
};

export default InfoSection;
