import CustomState from '@rio-cloud/rio-uikit/CustomState';
import { Wrapper } from '../../../components/shared/Wrapper';
import HorizontalButtons from '../HorizontalButtons';
import { Icon, InformativeStateProps } from './types';

const successIcon: Icon[] = [
    {
        name: 'rioglyph-ok-sign',
        color: 'text-color-highlight',
        className: 'text-size-300pct',
    },
];

const InformativeState = ({
    type,
    headline,
    message,
    testId = 'informative-state',
    icons,
    buttons,
    image,
    children,
    withoutBorder,
    verticalAlign = '100',
}: InformativeStateProps) => {
    const componentIcons = type === 'success' ? successIcon : icons;
    const withoutImage = !type && !icons && !image;
    const outerClassName = withoutBorder ? 'border-none' : '';

    return (
        <Wrapper verticalAlign={verticalAlign}>
            <CustomState
                headline={headline}
                message={message}
                data-testid={testId}
                icons={componentIcons}
                image={withoutImage ? <></> : image}
                outerClassName={outerClassName}
            >
                {buttons && <HorizontalButtons buttons={buttons} />}
                {children}
            </CustomState>
        </Wrapper>
    );
};

export default InformativeState;
