import { VehiclesPropertiesFromState, VehiclesPropertiesFromDispatch } from '../containers/vehicles/types';
export interface Vehicle {
    vehicleId: string;
    name: string;
    vin: string; // vehicle identification number
    chargingStatus: ChargingStatusEnum;
    chargingUntil: string | null;
    delayedChargingUntil: string | null;
    disconnectedAt: string | null;
    stateOfCharge: number; // in percent
    chargingConfiguration: {
        chargingMode: ChargingModeEnum;
        status: ChargingConfigurationStatusEnum;
        departureDate: Date | null;
        climateMode: boolean;
        targetStateOfCharge: number;
    };
    readyToDriveStatus: ReadyToDriveStatusEnum;
    readyToDriveUntil: string | null; // when the asset is ready to drive, this is the time when it stops recharging and preconditioning
    timestamp: string;
    // TODO: rename remainingDistance to remainingRange
    remainingRange: number | null;
    hasMonitoringService: boolean;
    // from monitoring service
    energyLevel?: number;
    driving?: boolean;
    type: AssetType;
}

export enum ChargingStatusEnum {
    UNKNOWN = 'UNKNOWN',
    DISCONNECTED = 'DISCONNECTED',
    CONNECTED = 'CONNECTED',
    CHARGING = 'CHARGING',
    CHARGING_LONGPRESS = 'CHARGING_LONGPRESS',
    CHARGED = 'CHARGED',
    FAILURE = 'FAILURE',
}

export enum ChargingModeEnum {
    IMMEDIATE = 'IMMEDIATELY',
    TIMER = 'TIMER',
}

export enum ReadyToDriveStatusEnum {
    NOT_READY = 'NOT_READY',
    READY_TO_DRIVE = 'READY_TO_DRIVE',
    NO_SIGNAL = 'NO_SIGNAL',
}

export enum ChargingConfigurationStatusEnum {
    ACCEPTED = 'ACCEPTED',
    SENDING = 'SENDING',
    REJECTED = 'REJECTED',
    NOT_SENT_YET = 'NOT_SENT_YET',
}

export type VehiclesProperties = VehiclesPropertiesFromState & VehiclesPropertiesFromDispatch;

export enum SortDirection {
    ASCENDING = 'asc',
    DESCENDING = 'desc',
}

export interface SortDetails {
    sortDir: SortDirection;
    sortBy: string;
}

export enum AssetType {
    BUS = 'bus',
    VAN = 'van',
    UNKNOWN = 'unknown',
}
