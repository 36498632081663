import SortDirection from '../../common/utilities/sort/SortDirection';
import orderBy from 'lodash/orderBy';
import AbstractElectricVehiclesSorter from './AbstractElectricVehiclesSorter';
import { SORT_KEY_VEHICLE_NAME } from './keys';
import { VehicleChargingData } from '../models/VehicleChargingData';

class VehicleNameSorter extends AbstractElectricVehiclesSorter {
    constructor() {
        super(SORT_KEY_VEHICLE_NAME);
    }

    sort = (array: VehicleChargingData[], direction: SortDirection): VehicleChargingData[] =>
        orderBy<VehicleChargingData>(array, e => e.assetInfo.name.toLowerCase(), direction);
}

export default VehicleNameSorter;
