import { FormattedMessage } from 'react-intl';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { CustomTooltipProps } from './types';

export const CustomTooltip = (props: CustomTooltipProps): JSX.Element => {
    return (
        <FormattedMessage id={props.messageId}>
            {(translatedMessage): JSX.Element => {
                return (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id={'tooltip'} placement={'top'} width={200} {...props.tooltipProps}>
                                {translatedMessage}
                            </Tooltip>
                        }
                        {...props.overlayProps}
                    >
                        <span
                            className={`rioglyph rioglyph-info-sign text-size-16 padding-left-3 ${props.iconClassName}`}
                            aria-describedby="tooltip"
                            data-testid="tooltip"
                            data-messageid={props.messageId}
                        />
                    </OverlayTrigger>
                );
            }}
        </FormattedMessage>
    );
};
