import CustomState from '@rio-cloud/rio-uikit/CustomState';
import { Wrapper } from '../../../components/shared/Wrapper';
import { ErrorStateProps, ForbiddenStateProps, GeneralStateProps, NotFoundStateProps } from './types';
import HorizontalButtons from '../HorizontalButtons';
import NotFoundState from '@rio-cloud/rio-uikit/NotFoundState';
import ForbiddenState from '@rio-cloud/rio-uikit/ForbiddenState';
import { useIntl } from 'react-intl';

const outerClassName = (withoutBorder: boolean | undefined) => (withoutBorder ? 'border-none' : '');

const NotFound = ({ message, withoutBorder }: NotFoundStateProps) => {
    const intl = useIntl();
    return (
        <NotFoundState
            data-testid={'notFound-state'}
            headline={intl.formatMessage({ id: 'e4c.global.notFoundState.title' })}
            message={message}
            outerClassName={outerClassName(withoutBorder)}
        />
    );
};

const Forbidden = ({ withoutBorder }: ForbiddenStateProps) => {
    const intl = useIntl();
    return (
        <ForbiddenState
            data-testid={'forbidden-state'}
            headline={intl.formatMessage({ id: 'e4c.global.errorCodes.forbidden.text' })}
            outerClassName={outerClassName(withoutBorder)}
        />
    );
};

const General = ({ buttons, withoutBorder, headline, message }: GeneralStateProps) => {
    const intl = useIntl();
    const componentButtons = buttons
        ? buttons
        : [
              {
                  className: 'btn-primary',
                  text: intl.formatMessage({ id: 'e4c.global.errorState.btnTryAgain' }),
                  onClick: () => history.go(0),
              },
          ];
    return (
        <CustomState
            data-testid={'general-state'}
            headline={headline ? headline : intl.formatMessage({ id: 'e4c.global.errorState.title' })}
            message={message ? message : intl.formatMessage({ id: 'e4c.global.errorState.message' })}
            icons={[
                {
                    name: 'rioglyph-bus-breakdown',
                    color: 'text-color-light',
                    className: 'text-size-300pct',
                },
            ]}
            outerClassName={outerClassName(withoutBorder)}
        >
            <HorizontalButtons buttons={componentButtons}></HorizontalButtons>
        </CustomState>
    );
};

const ErrorState = ({ verticalAlign, stateProps }: ErrorStateProps) => {
    const error = () => {
        switch (stateProps.type) {
            case 'notfound': {
                return <NotFound {...stateProps} />;
            }
            case 'forbidden': {
                return <Forbidden {...stateProps} />;
            }
            case 'general': {
                return <General {...stateProps} />;
            }
        }
    };
    return <Wrapper verticalAlign={verticalAlign ? verticalAlign : '100'}>{error()}</Wrapper>;
};

export default ErrorState;
