import UpsellButton from './UpSellButton';
import NotBookedState from '@rio-cloud/rio-uikit/NotBookedState';
import { ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

const UpsellStateWrapper = ({ features }: { features: ReactNode[] }): ReactElement => {
    return (
        <NotBookedState
            data-testid="upsell-state"
            headline={<FormattedMessage id="e4v.upselling.title" />}
            message={<FormattedMessage id="e4v.upselling.message" />}
            features={features}
        >
            <div className="width-150 center-block">
                <UpsellButton />
            </div>
        </NotBookedState>
    );
};

export default UpsellStateWrapper;
