import Dialog from '@rio-cloud/rio-uikit/Dialog';
import { EVENT_SETTINGS_PATH } from '../../../../../common/constants/paths/paths';
import { useHistory } from 'react-router';
import EventSettingsMenu from './menu';
import EventSettings from './EventSeveritySettings';
import { useIntl } from 'react-intl';
import { useState } from 'react';
import { SeverityEventLevel } from '../../../../models/SeverityEventLevelEnum';
import { OVERVIEW_PATH } from '../../../../../../../configuration/setup/path';

export type NavigationDirections = 'goBack' | 'close';
export type NavigationOperation = NavigationDirections | null;

interface BodyProps {
    goBack: Function;
    handleUndoDiscardChanges: Function;
    discardOperation: NavigationOperation;
    navigationActions: NavigationActions;
}

interface WrapperProps {
    children: JSX.Element;
    testId: string;
    goBack: Function;
}

export interface NavigationActions {
    goBack: Function;
    close: Function;
}

const Wrapper = ({ children, goBack, testId }: WrapperProps): JSX.Element => {
    const intl = useIntl();
    return (
        <div data-testid={testId}>
            <div
                data-testid="go-back-click"
                className="text-color-primary cursor-pointer display-inline-flex align-items-center margin-bottom-15"
                onClick={() => goBack()}
            >
                <span className="rioglyph rioglyph-chevron-left" />
                <p className="text-size-14 text-normal margin-0">
                    {intl.formatMessage({ id: 'e4c.overview.events.widget.modal.title' })}
                </p>
            </div>
            {children}
        </div>
    );
};

const Body = ({ goBack, handleUndoDiscardChanges, discardOperation, navigationActions }: BodyProps): JSX.Element => {
    const history = useHistory();
    const path = history.location.pathname;
    const levelSeverity = path.replace(`${EVENT_SETTINGS_PATH}/`, '');
    return (
        <Wrapper testId={path} goBack={goBack}>
            {levelSeverity === EVENT_SETTINGS_PATH ? (
                <EventSettingsMenu />
            ) : (
                <EventSettings
                    severity={levelSeverity}
                    handleUndoDiscardChanges={handleUndoDiscardChanges}
                    discardOperation={discardOperation}
                    navigationActions={navigationActions}
                />
            )}
        </Wrapper>
    );
};

const EventSettingsModal = () => {
    const history = useHistory();
    const intl = useIntl();
    const [discardChangesOperations, setDiscardChangesOperations] = useState<NavigationOperation>(null);
    const path = history.location.pathname;
    const showEventSettingsModal = path.includes(EVENT_SETTINGS_PATH);
    const navigationActions: NavigationActions = {
        goBack: () => history.goBack(),
        close: () => history.push(OVERVIEW_PATH),
    };

    const navigateTo = (direction: NavigationDirections) => {
        if (history.location.pathname.includes(SeverityEventLevel.CRITICAL)) {
            setDiscardChangesOperations(direction);
            return false;
        }
        setDiscardChangesOperations(null);
        navigationActions[direction]();
        return true;
    };

    const body = (
        <Body
            goBack={() => navigateTo('goBack')}
            handleUndoDiscardChanges={() => setDiscardChangesOperations(null)}
            discardOperation={discardChangesOperations}
            navigationActions={navigationActions}
        />
    );

    const translateModalTitle = () => {
        const level = path.replace(`${EVENT_SETTINGS_PATH}/`, '');
        switch (level) {
            case SeverityEventLevel.CRITICAL:
                return intl.formatMessage({ id: 'e4c.overview.events.widget.settings.critical.title' });
            case SeverityEventLevel.WARNING:
                return intl.formatMessage({ id: 'e4c.overview.events.widget.settings.warning.title' });
            case SeverityEventLevel.SUCCESS:
                return intl.formatMessage({ id: 'e4c.overview.events.widget.settings.success.title' });
            case SeverityEventLevel.INFO:
                return intl.formatMessage({ id: 'e4c.overview.events.widget.settings.informational.title' });
            default:
                return intl.formatMessage({ id: 'e4c.overview.events.widget.modal.eventSettings' });
        }
    };

    return (
        <Dialog
            show={showEventSettingsModal}
            title={translateModalTitle()}
            body={body}
            onCloseValidation={() => navigateTo('close')}
        />
    );
};

export default EventSettingsModal;
