type Props = {
    temperature: number | null;
};

const CollapsedTemperature = ({ temperature }: Props): JSX.Element => {
    return (
        <span className="ellipsis-1 display-block">
            <span className={'rioglyph rioglyph-temperature text-size-20 text-color-gray'} />
            {temperature ? <span data-testid="temperature">{temperature?.toFixed(0)}° C</span> : <span>-</span>}
        </span>
    );
};

export default CollapsedTemperature;
