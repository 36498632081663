import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { format, lastDayOfMonth, startOfMonth, subMonths } from 'date-fns';
import ButtonDropdown from '@rio-cloud/rio-uikit/ButtonDropdown';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { createGoogleAnalyticsEvent, gtmUtil, TrackingAction, TrackingCategory } from '../../../utils/googleTagManager';
import { State } from '../../../../../types';
import { getAccessToken } from '../../../../../configuration';
import { downloadFile } from '../../../../../api/history';
import { createFile } from '../NewHistoryDownload/createFile';

const HistoryDownloadButton = (): ReactElement => {
    const intl = useIntl();
    const accessToken = useSelector((state: State) => getAccessToken(state));

    const downloadHistory = (firstDay: string, lastDay: string) => {
        downloadFile(accessToken, firstDay, lastDay)
            .then(response => {
                createFile(response, firstDay, lastDay);
                pushGaEvent(TrackingAction.MINIMAL_DATASET_DOWNLOAD);
                Notification.success(intl.formatMessage({ id: 'e4c.history.dataDownload.success' }));
            })
            .catch(() => Notification.error(intl.formatMessage({ id: 'e4c.history.dataDownload.error' })));
    };

    const pushGaEvent = (action: TrackingAction) => {
        gtmUtil.pushGaEvent(createGoogleAnalyticsEvent(TrackingCategory.HISTORY_TAB, action));
    };

    const monthItems = [...Array(12)].map((_, i) => {
        const startDate = startOfMonth(subMonths(new Date(), i));
        const endDate = i === 0 ? new Date() : lastDayOfMonth(startDate);
        const monthText = intl.formatDate(startDate, { month: 'long' });
        return {
            value: `${monthText} ${startDate.getFullYear()}`,
            onSelect: () => downloadHistory(format(startDate, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd')),
        };
    });

    return (
        <ButtonDropdown
            data-testid="history-download-button"
            title={intl.formatMessage({ id: 'e4c.history.dataDownload.button' })}
            onOpen={() => pushGaEvent(TrackingAction.MINIMAL_DATASET_CLICK)}
            items={monthItems}
            className="height-35 rounded-none"
            dropdownClassName="overflow-y-scroll max-height-200"
        />
    );
};

export default HistoryDownloadButton;
