import { useEffect, useState } from 'react';

import VehicleRowsTable from './VehiclesRowsTable';
import { separatorClassNames } from '../../helpers/table/VehiclesTableCssHelper';
import { applyPagination, filterConnectionStatus } from '../../helpers/table/VehiclesRowHelper';
import { VehicleChargingData } from '../../models/VehicleChargingData';
import { PaginationOptions } from '../../../common/components/pagination/Pagination';
import AbstractElectricVehiclesSorter from '../../sorters/AbstractElectricVehiclesSorter';
import { SortDirection } from '../../types';

export interface VehiclesBodyTableProperties {
    vehicles: VehicleChargingData[];
    columnOrder: string[];
    hiddenColumns: string[];
    sorter: AbstractElectricVehiclesSorter;
    sortDir: SortDirection;
    tableViewType: string;
    pagination: PaginationOptions;
}

const VehiclesBodyTable = ({
    columnOrder,
    hiddenColumns,
    pagination,
    sorter,
    sortDir,
    tableViewType,
    vehicles,
}: VehiclesBodyTableProperties): JSX.Element => {
    const [connectedVehicles, setConnectedVehicles] = useState<VehicleChargingData[]>([]);
    const [disconnectedVehicles, setDisconnectedVehicles] = useState<VehicleChargingData[]>([]);
    useEffect(() => {
        const finalConnectVehicles: VehicleChargingData[] = [];
        const finalDisconnectVehicles: VehicleChargingData[] = [];
        filterConnectionStatus(vehicles, finalConnectVehicles, finalDisconnectVehicles);
        sorter.direction = sortDir;
        sorter.of(finalConnectVehicles);
        sorter.of(finalDisconnectVehicles);
        applyPagination(pagination, finalConnectVehicles, finalDisconnectVehicles);
        setConnectedVehicles(finalConnectVehicles);
        setDisconnectedVehicles(finalDisconnectVehicles);
    }, [vehicles, sorter, sortDir, pagination]);

    const isSingleTable = (): boolean => !connectedVehicles.length || !disconnectedVehicles.length;

    return (
        <tbody data-testid={'vehicle-table-body'}>
            {
                <VehicleRowsTable
                    columnOrder={columnOrder}
                    hiddenColumns={hiddenColumns}
                    sortedRows={connectedVehicles}
                />
            }
            <tr className={separatorClassNames(isSingleTable(), tableViewType)} id="vehiclesTableSeparator" />
            {
                <VehicleRowsTable
                    columnOrder={columnOrder}
                    hiddenColumns={hiddenColumns}
                    sortedRows={disconnectedVehicles}
                />
            }
        </tbody>
    );
};

export default VehiclesBodyTable;
