import { ChargeCycleActivityType } from '../../../types';
import NoDataElement from './NoDataElement';
import { FormattedNumber } from 'react-intl';

type MileageProps = {
    processType: string;
    mileage: number | null;
};

const Mileage = (props: MileageProps) => {
    const displayValue = (): JSX.Element | string => {
        if (props.mileage) {
            return (
                <span>
                    <FormattedNumber value={props.mileage} maximumFractionDigits={0} /> km
                </span>
            );
        }
        return <NoDataElement />;
    };
    return (
        <div data-testid="mileage">
            {props.processType === ChargeCycleActivityType.DISCHARGING ? <span>{displayValue()}</span> : <span>-</span>}
        </div>
    );
};

export default Mileage;
