import React from 'react';
import TableViewToggles, { type TableViewTogglesViewType } from '@rio-cloud/rio-uikit/TableViewToggles';
import { useIntl } from 'react-intl';

interface VehiclesTableViewTogglesProps {
    dispatchTableViewToggle: (viewType: TableViewTogglesViewType) => void;
}

const VehiclesTableViewToggles = ({ dispatchTableViewToggle }: VehiclesTableViewTogglesProps): JSX.Element => {
    const intl = useIntl();
    return (
        <div className="table-toolbar-column table-toolbar-column-spacer" data-testid={'vt-toggles'}>
            <TableViewToggles
                onViewTypeChange={dispatchTableViewToggle}
                tableViewTooltipContent={intl.formatMessage({ id: 'e4c.vehicles.table.table.view' })}
                singleCardViewTooltipContent={intl.formatMessage({ id: 'e4c.vehicles.table.list.view' })}
                multiCardsViewTooltipContent={intl.formatMessage({ id: 'e4c.vehicles.table.grid.view' })}
            />
        </div>
    );
};

export default React.memo(VehiclesTableViewToggles);
