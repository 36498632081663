import FileSaver from 'file-saver';

export enum FileFormat {
    CSV = 'text/csv',
    XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

const getFilenameFromHeaders = (headers: Headers) =>
    headers
        .get('Content-Disposition')
        ?.split(';')
        ?.find((n: string) => n.includes('filename='))
        ?.replace('filename=', '')
        .trim();

const getFileExtension = (format?: FileFormat): string => {
    switch (format) {
        case FileFormat.CSV:
            return '.csv';
        case FileFormat.XLSX:
            return '.xlsx';
        default:
            return '.csv';
    }
};

const getDefaultFilename = (startDateParam: string, endDateParam: string, format?: FileFormat) =>
    `eManager_${startDateParam}_${endDateParam}${getFileExtension(format)}`;

export const createFile = async (data: Response, startDateParam: string, endDateParam: string, format?: FileFormat) => {
    const filename = getFilenameFromHeaders(data.headers) || getDefaultFilename(startDateParam, endDateParam, format);

    const blob = await data.clone().blob();
    FileSaver.saveAs(blob, filename);
};
