import React from 'react';
import { convertDepartureTime } from '../vehicleDetailsHelper';
import Select from '@rio-cloud/rio-uikit/Select';
import { currentTimezone } from '../../../../../utils/timezone';

interface DepartureTimeProperties {
    id: number;
    selectedTime: string;
    times: string[];
    isEnabled: boolean;
    locale: string;
    zoneId: string | undefined;
    onDepartureTimeChange: (departureTime: string, timerNo: number) => void;
    toggleUnsavedVehicleChanges: (unsavedChanges: boolean) => void;
}

export default class DepartureTime extends React.Component<DepartureTimeProperties> {
    onDepartureTimeChange(event: any): void {
        this.props.toggleUnsavedVehicleChanges(true);
        const newDepartureTime = event.label;
        const timerId = event.id;
        const timerNo = timerId.split('_')[1];
        this.props.onDepartureTimeChange(newDepartureTime, timerNo);
    }

    render(): JSX.Element {
        const options = this.props.times.map((option, ind) => {
            const zoneId = this.props.zoneId ?? currentTimezone;
            const formattedDepartureTime = convertDepartureTime(this.props.selectedTime, zoneId);
            const isSelected = option === formattedDepartureTime;

            return {
                id: `opt_${this.props.id}_${ind}`,
                selected: isSelected,
                label: option,
            };
        });

        return (
            <Select
                id={`departureTime_${this.props.id}`}
                disabled={!this.props.isEnabled}
                onChange={(event: any): void => this.onDepartureTimeChange(event)}
                options={options}
                className="form-control padding-0 border-none display-inline-block"
            />
        );
    }
}
