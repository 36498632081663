import { History, Location } from 'history';

export interface WindowWithDataLayer extends Window {
    dataLayer?: Object[];
}

export enum PageView {
    vehicleCharging = 'vehicle-charging',
    otherPages = 'other-pages',
}

export enum EventType {
    virtPath = 'virtPath',
}

/**
 * Track page views with app specific ("virtual") URLs.
 */
interface VirtPathEvent {
    event: EventType;
    virtPath: PageView;
}

export interface GoogleAnalyticsEvent {
    event: string;
    eventCategory: string;
    eventAction: string;
    eventLabel?: string;
}

export class GoogleTagManagerUtil {
    lastLocation: Location | null = null;
    lastPageView: PageView | null = null;
    windowWithDataLayer: WindowWithDataLayer;

    constructor(windowWithDataLayer: WindowWithDataLayer) {
        this.windowWithDataLayer = windowWithDataLayer;
    }

    triggerPageView = (pageView: PageView): void => {
        this.pushVirtPathEvent({
            event: EventType.virtPath,
            virtPath: pageView,
        });
    };

    pushVirtPathEvent = (event: VirtPathEvent): void => {
        const gtmEvent = {
            ...event,
            virtPath: event.virtPath,
        };
        if (this.windowWithDataLayer.dataLayer) {
            this.windowWithDataLayer.dataLayer.push(gtmEvent);
        }

        this.lastPageView = event.virtPath;
    };

    pushGaEvent = (event: GoogleAnalyticsEvent): void => {
        if (this.windowWithDataLayer.dataLayer) {
            this.windowWithDataLayer.dataLayer.push(event);
        }
    };

    triggerPageViewFromLocation = (location: Location): void => {
        if ('/vehicles' === location.pathname) {
            this.triggerPageView(PageView.vehicleCharging);
        } else {
            this.triggerPageView(PageView.otherPages);
        }
    };

    startHandlingPageViews = (history: History<any>): void => {
        const initialLocation = history.location;
        this.triggerPageViewFromLocation(initialLocation);
        this.lastLocation = initialLocation;

        history.listen(location => {
            this.triggerPageViewFromLocation(location);
            this.lastLocation = location;
        });
    };
}

export const createGoogleAnalyticsEvent = (
    category: TrackingCategory,
    action: TrackingAction,
    label?: string
): GoogleAnalyticsEvent => ({
    event: 'ga_event',
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
});

/**
 * Category naming structure: service name, interacted object, position(optional)
 */
export enum TrackingCategory {
    CHARGING_CONFIGURATION = 'e4c, chargingConfiguration',
    HISTORY_TAB = 'e4c, historyTab',
    OVERVIEW_TAB = 'e4c, overviewTab',
    VEHICLES_TAB = 'e4c, vehiclesTab',
    GENERAL = 'e4c, general',
}

export enum TrackingAction {
    LINK_TO_VEHICLE_CHARGING_TAB_WIDGET = 'vehicleAndChargingStatusWidgetLinkToVehicleCharging',
    LINK_TO_ALL_EVENTS = 'linkToAllEvents',
    SAVED_CHARGING_CONFIGURATION = 'savedChargingConfiguration',
    SEND_IMMEDIATE_CONFIGURATION = 'changeToImmediateCharging',
    SEND_TIMER_CONFIGURATION = 'changeToTimerCharging',
    SWITCH_CLIMATE_MODE_ON = 'toggleToClimateControlON',
    SWITCH_CLIMATE_MODE_OFF = 'toggleToClimateControlOFF',
    CHANGE_READY_TO_DRIVE_DURATION = 'changeReadyToDriveDuration',
    MINIMAL_DATASET_DOWNLOAD = 'minimalDatasetDownload',
    MINIMAL_DATASET_XLSX_DOWNLOAD = 'minimalDatasetXlsxDownload',
    HISTORY_CSV_DOWNLOAD = 'historyCsvDownload',
    HISTORY_XLSX_DOWNLOAD = 'historyXlsxDownload',
    OPEN_VEHICLE_DETAILS = 'openVehicleDetails',
    FILTER_PANEL_USAGE = 'filterPanelUsage',
    CLICK_TARGET_STATE_OF_CHARGE = 'clickOnTargetStateOfCharge',
    LINK_TO_VEHICLE_CHARGING_TAB = 'chargingStatusWidgetLinkToVehicleChargingTab',
    ADD_TIMER = 'timerChargingAddTimer',
    CLICK_USER_MANUAL = 'clickUserManual',
    USER_MANUAL_WELCOME = 'userManualWelcome',
    USER_MANUAL_CHARGING_STATUS = 'userManualChargingStatus',
    USER_MANUAL_TIMER_CHARGING = 'userManualTimerCharging',
    USER_MANUAL_CLIMATE_CONTROL = 'userManualPreliminaryAir',
    USER_MANUAL_STATE_CHARGE = 'userManualChargeLevel',
    USER_MANUAL_VEHICLE_STATUS = 'userManualVehicleStatus',
    FEEDBACK_BUTTON = 'feedbackModalOpened',
    FEEDBACK_SENT = 'feedbackSuccessfullySent',
    CLICK_IMMEDIATE_CHARGING = 'clickImmediateCharging',
    CLICK_TIMER_CHARGING = 'clickTimerCharging',
    CHANGE_CHARTS_FILTER_SELECTION = 'changeChartsFilterSelection',
    CHANGE_CHARTS_FILTER_SELECTION_ONE_CONSUMPTION = 'changeChartOneToConsumption', // left
    CHANGE_CHARTS_FILTER_SELECTION_TWO_CONSUMPTION = 'changeChartTwoToConsumption', // right
    CHANGE_CHARTS_FILTER_SELECTION_ONE_RECUPERATION = 'changeChartOneToRecuperation', // left
    CHANGE_CHARTS_FILTER_SELECTION_TWO_RECUPERATION = 'changeChartTwoToRecuperation', // right
    CHANGE_CHARTS_FILTER_SELECTION_ONE_MILEAGE = 'changeChartOneToMileage', // left
    CHANGE_CHARTS_FILTER_SELECTION_TWO_MILEAGE = 'changeChartTwoToMileage', // right
    CHANGE_CHART_ONE_TIMEFRAME = 'changeChartOneTimeframe', // left
    CHANGE_CHART_TWO_TIMEFRAME = 'changeChartTwoTimeframe', // right
    CHANGE_CHART_ONE_VEHICLE = 'changeChartOneVehicle', // left
    CHANGE_CHART_TWO_VEHICLE = 'changeChartTwoVehicle', // right
    CHART_ONE_WIDGET_LINK_TO_HISTORY = 'chartOneWidgetLinkToHistory', // left
    CHART_TWO_WIDGET_LINK_TO_HISTORY = 'chartTwoWidgetLinkToHistory', // right
    CHANGE_VEHICLE_CHARGING_TAB_TO_GRID_VIEW = 'changeVehicleChargingTabToGridView',
    CHANGE_VEHICLE_CHARGING_TAB_TO_LIST_VIEW = 'changeVehicleChargingTabToListView',
    CHANGE_VEHICLE_CHARGING_TAB_TO_TABLE_VIEW = 'changeVehicleChargingTabToTableView',
    CLICK_ON_VEHICLE_CHARGING_TAB_TABLE_SETTINGS = 'clickOnVehicleChargingTabTableSettings',
    CHANGE_VEHICLE_CHARGING_TAB_TABLE_SETTINGS = 'changeVehicleChargingTabTableSettings',
    SWITCH_TO_CONSUMPTION_HISTORY = 'switchToConsumptionHistory',
    SWITCH_TO_BATTERY_HISTORY = 'switchToBatteryHistory',
    CHANGE_DATES = 'changeDates',
    MINIMAL_DATASET_CLICK = 'minimalDatasetClick',
    VISIT_OLD_HISTORY = 'visitOldHistory',
    VISIT_NEW_HISTORY = 'visitNewHistory',
    CHANGE_TIMEFRAME = 'changeTimeframe',
    ASSET_TREE_OPTION_CHANGE = 'assetTreeOptionChange',
}

export interface TrackingAttributes {
    'data-track-ga-event-trigger': string;
    'data-track-ga-event-category': string;
    'data-track-ga-event-action': string;
    'data-track-ga-event-label'?: string;
}

export const trackingAttributes = (
    category: TrackingCategory,
    action: TrackingAction,
    label?: string
): TrackingAttributes => {
    const attributes: TrackingAttributes = {
        'data-track-ga-event-trigger': 'click',
        'data-track-ga-event-category': category,
        'data-track-ga-event-action': action,
    };

    if (label) {
        attributes['data-track-ga-event-label'] = label;
    }

    return attributes;
};

export const gtmUtil = new GoogleTagManagerUtil(window);
