import { FormattedMessage } from 'react-intl';
import toLower from 'lodash/fp/toLower';
import classNames from 'classnames';
import { CustomTooltip } from '../../../components/shared/CustomTooltip';
import { SortableHistoryTableHeaderItemProps } from '../types';
import { isArray } from 'lodash';

// This is a utility function to make behaviour of table rendering the same as it was
// before upgrading react-intl to v5. With previous version we were bounding translation
// chunks as a variadic variable so that only first item is used, this function repeates
// the same behavior for array of strings, which is passed from react-intl currently
export const getTranslationDataField = (translations: React.ReactNode): React.ReactNode => {
    return translations && isArray<string>(translations) && translations.length > 0 && translations[0];
};

const SortableHistoryTableHeaderItem = (props: SortableHistoryTableHeaderItemProps): JSX.Element => {
    const { column, handleSortChange, label, sortBy, sortDir, tooltip, width } = props;
    const tableHeadContentClassNames = classNames('');
    const optionalWidthClassName = width ? `width-${width}-md` : '';
    const optionalTooltip = tooltip ? (
        <CustomTooltip messageId={tooltip} iconClassName={'text-color-darkest cursor-pointer'} />
    ) : null;
    const tableHeadClassNames = classNames('user-select-none', 'sort-column', optionalWidthClassName);
    return (
        <FormattedMessage key={column} id={label}>
            {(translation): JSX.Element => (
                <th key={column} className={tableHeadClassNames}>
                    <span>
                        <span
                            onClick={handleSortChange}
                            data-field={getTranslationDataField(translation)}
                            data-sortby={column}
                        >
                            <span className={tableHeadContentClassNames}>
                                {sortBy === column ? (
                                    <span className={`sort-arrows sort-${toLower(sortDir)}`} />
                                ) : (
                                    <span className={'sort-arrows'} />
                                )}
                                <span className={'ellipsis-1'}>{translation}</span>
                            </span>
                        </span>
                        {optionalTooltip}
                    </span>
                </th>
            )}
        </FormattedMessage>
    );
};

export default SortableHistoryTableHeaderItem;
