import { FormattedMessage } from 'react-intl';

interface HistoryDownloadAlertProps {
    isVisible?: boolean;
    handleClose: () => void;
}

export const HistoryDownloadAlert = ({ isVisible = false, handleClose }: HistoryDownloadAlertProps) => {
    if (!isVisible) {
        return null;
    }

    return (
        <div className="alert alert-dismissible alert-primary z-index-max position-absolute width-600">
            <button type="button" className="btn btn-icon-only close" onClick={handleClose}>
                <span className="rioglyph rioglyph-remove"></span>
            </button>
            <div className="display-flex gap-10">
                <span className="text-color-primary text-size-h4 rioglyph rioglyph-info-sign"></span>
                <div>
                    <strong className="text-size-16">
                        <FormattedMessage id="e4c.history.historyDownloadAlert.title" />
                    </strong>
                    <div className="margin-y-5">
                        <FormattedMessage id="e4c.history.historyDownloadAlert.description" />
                    </div>
                </div>
            </div>
        </div>
    );
};
