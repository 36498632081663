import MeasurementType from './enums/MeasurementType';

class Measurement {
    private readonly _type: MeasurementType;
    private readonly _receivedAt: string;
    private readonly _value: any;

    constructor(type: MeasurementType, receivedAt: string, value: any) {
        this._type = type;
        this._receivedAt = receivedAt;
        this._value = value;
    }

    public get type(): MeasurementType {
        return this._type;
    }

    public get receivedAt(): string {
        return this._receivedAt;
    }

    public get value(): any {
        return this._value;
    }
}

export default Measurement;
