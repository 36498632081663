import { FormattedMessage } from 'react-intl';
import FooterEventCard, { FooterProps } from '../FooterEventCard';
import { Event } from '../../../../models/Event';
import { EventStatus } from '../../../../models/EventStatusEnum';
import { AccessToken } from '../../../../../../../configuration';
import React, { FC, useState } from 'react';
import HorizontalCard from '../../../../../common/components/HorizontalCard';
import SeverityIcon from './SeverityIcon';
import Header from './Header';
import { SeverityEventLevel } from '../../../../models/SeverityEventLevelEnum';

interface BodyProps {
    message: string;
    interpolationMessage: string;
}

const Body: FC<BodyProps> = ({ message, interpolationMessage }: BodyProps): JSX.Element => {
    const interpolatedMessage = JSON.parse(JSON.stringify(interpolationMessage));

    return (
        <div className={'text-size-12'}>
            <FormattedMessage
                id={message}
                values={{
                    ...interpolatedMessage,
                    b: (chunks: any) => <b>{chunks}</b>,
                    ul: (chunks: any) => <ul>{chunks}</ul>,
                    li: (chunks: any) => <li>{chunks}</li>,
                }}
            />
        </div>
    );
};

interface ModalFooterProps extends FooterProps {
    eventSeverityLevel: SeverityEventLevel;
}

const Footer: FC<ModalFooterProps> = (footerProps: ModalFooterProps): JSX.Element => (
    <div className="display-flex justify-content-end">
        {FooterEventCard({ ...footerProps })[footerProps.eventSeverityLevel]}
    </div>
);

interface ContentProps {
    event: Event;
    accessToken: AccessToken;
    status: EventStatus;
    severityLevel: string[];
    animateEvent: Function;
}

const Content: React.FC<ContentProps> = ({
    event,
    accessToken,
    status,
    severityLevel,
    animateEvent,
}: ContentProps): JSX.Element => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Header date={event.date} titleMessage={event.eventTitleMessage} vehicleName={event.eventVehicle} />
        <Body message={event.eventSubtitleMessage} interpolationMessage={event.eventInterpolationMessage} />
        <Footer
            vehicleId={event.vehicleId}
            eventId={event.id}
            status={status}
            accessToken={accessToken}
            severityLevel={severityLevel}
            eventSeverityLevel={event.severityLevel}
            animateEvent={animateEvent}
        />
    </div>
);

interface EventCardModalProps {
    event: Event;
    status: EventStatus;
    accessToken: AccessToken;
    severityLevel: string[];
}

const EventCardModal: React.FC<EventCardModalProps> = ({
    event,
    status,
    accessToken,
    severityLevel,
}: EventCardModalProps): JSX.Element => {
    const [animated, setAnimated] = useState(false);

    return (
        <HorizontalCard
            className={animated ? 'animated-03s repeat-1 ease-out restart slide-out-right' : ''}
            left={<SeverityIcon severityLevel={event.severityLevel} status={status} />}
            testId="event-card-modal"
        >
            <Content
                event={event}
                accessToken={accessToken}
                status={status}
                severityLevel={severityLevel}
                animateEvent={setAnimated}
            />
        </HorizontalCard>
    );
};

export default EventCardModal;
