import Dialog from '@rio-cloud/rio-uikit/Dialog';
import { useHistory } from 'react-router';
import { E_MANAGER } from '../../../constants/common';
import FeedbackItem from './FeedbackItem';
import { removeQueryParam } from '../../../helpers/queryParametersHelper';
import { FEEDBACK_SEARCH_QUERY } from '../../../constants/paths/paths';

const FeedbackModal = (): JSX.Element => {
    const history = useHistory();

    return (
        <Dialog
            show={history.location.search.includes(FEEDBACK_SEARCH_QUERY)}
            title={E_MANAGER}
            body={<FeedbackItem />}
            onHide={() =>
                history.push({
                    pathname: history.location.pathname,
                    search: removeQueryParam(history.location.search, FEEDBACK_SEARCH_QUERY),
                })
            }
            showCloseButton={true}
        />
    );
};

export default FeedbackModal;
