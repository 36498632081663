import AbstractElectricVehiclesSorter from './AbstractElectricVehiclesSorter';
import SortDirection from '../../common/utilities/sort/SortDirection';
import orderBy from 'lodash/orderBy';
import ReadyToDriveStatus from '../models/enums/ReadyToDriveStatus';
import { SORT_KEY_READY_TO_DRIVE } from './keys';
import { VehicleChargingData } from '../models/VehicleChargingData';

class ReadyToDriveStatusSorter extends AbstractElectricVehiclesSorter {
    constructor() {
        super(SORT_KEY_READY_TO_DRIVE);
    }

    sort = (array: VehicleChargingData[], direction: SortDirection): VehicleChargingData[] =>
        orderBy<VehicleChargingData>(array, this.mapReadyToDriveStatusToIndex, direction);

    mapReadyToDriveStatusToIndex = (ev: VehicleChargingData): number => {
        switch (ev.readyToDriveStatus) {
            case ReadyToDriveStatus.NO_SIGNAL:
                return 1;
            case ReadyToDriveStatus.NOT_READY:
                return 2;
            case ReadyToDriveStatus.READY_TO_DRIVE:
                return 3;
            default:
                return 4;
        }
    };
}

export default ReadyToDriveStatusSorter;
