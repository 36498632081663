enum ChargingStatus {
    UNKNOWN = 'UNKNOWN',
    DISCONNECTED = 'DISCONNECTED',
    CONNECTED = 'CONNECTED',
    CHARGING = 'CHARGING',
    CHARGING_LONGPRESS = 'CHARGING_LONGPRESS',
    CHARGED = 'CHARGED',
    FAILURE = 'FAILURE',
}

export default ChargingStatus;
