import HorizontalCard from '../../../../../../common/components/HorizontalCard';

interface ContentProps {
    subTitle: string;
    title: string;
}

interface ItemProps extends ContentProps {
    handleClick: Function;
    severityIcon: JSX.Element;
}

const Content = ({ title, subTitle }: ContentProps): JSX.Element => {
    return (
        <div data-testid="item-menu-content" style={{ display: 'flex', flexDirection: 'column' }}>
            <p className={'text-size-12 text-medium margin-0'}>{title}</p>
            <p className={'text-size-12 text-normal margin-0'}>{subTitle}</p>
        </div>
    );
};

const RightIcon = (): JSX.Element => {
    return (
        <div data-testid="right-icon" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span className="rioglyph rioglyph-chevron-right text-color-primary" />
        </div>
    );
};

const LeftIcon = ({ children }: { children: JSX.Element }): JSX.Element => (
    <div data-testid="left-icon" style={{ padding: '1px 7px 0 0' }}>
        {children}
    </div>
);

const MenuItem = ({ title, subTitle, handleClick, severityIcon }: ItemProps) => {
    return (
        <div data-testid="clickable-menu-item" className="hover-bg-highlight-lightest" onClick={() => handleClick()}>
            <HorizontalCard left={<LeftIcon>{severityIcon}</LeftIcon>} right={<RightIcon />}>
                <Content title={title} subTitle={subTitle} />
            </HorizontalCard>
        </div>
    );
};

export default MenuItem;
