import { useIntl } from 'react-intl';
import NoDataRio from '@rio-cloud/rio-uikit/NoData';

const NoData = ({
    hasTooltip = true,
    tooltip = 'e4c.table.noDataTooltip',
}: {
    hasTooltip?: boolean;
    tooltip?: string;
}) => {
    const intl = useIntl();
    return (
        <div data-testid="no-data-with-tooltip">
            <NoDataRio
                className="text-size-14 margin-y-10"
                text="No data"
                tooltipPlacement="right"
                tooltip={hasTooltip ? intl.formatMessage({ id: tooltip }) : ''}
            />
        </div>
    );
};

export default NoData;
