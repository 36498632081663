import VehicleDetailsFooter from './VehicleDetailsFooter';
import EtgeVehicleDetailsFooter from './EtgeVehicleDetailsFooter';
import E4CVehicleDetailsFooter from './E4CVehicleDetailsFooter';
import { Vehicle } from '../../../types';
import ElectricVehicle from '../../../models/ElectricVehicle';
import VehicleModel from '../../../models/enums/VehicleModel';
import { VehicleDetailsProps } from '../vehicleDetails/types';

class VehicleDetailsFooterFactory {
    private readonly _vehicle: ElectricVehicle;
    private readonly _selectedVehicle: Vehicle;
    private readonly _vehicleDetailsProps: VehicleDetailsProps;
    private readonly _onSidebarClose: () => void;
    private readonly _hasUnsavedChanges: () => boolean;

    constructor(
        vehicle: ElectricVehicle,
        selectedVehicle: Vehicle,
        vehicleDetailsProps: VehicleDetailsProps,
        onSidebarClose: () => void,
        hasUnsavedChanges: () => boolean
    ) {
        this._vehicle = vehicle;
        this._selectedVehicle = selectedVehicle;
        this._vehicleDetailsProps = vehicleDetailsProps;
        this._onSidebarClose = onSidebarClose;
        this._hasUnsavedChanges = hasUnsavedChanges;
    }

    private vehicleModel(): VehicleModel {
        return this._vehicle.assetInfo.vehicleModel;
    }

    get selectedVehicle(): Vehicle {
        return this._selectedVehicle;
    }

    createFooter(): VehicleDetailsFooter | null {
        if (this.vehicleModel() === VehicleModel.E4C) {
            return new E4CVehicleDetailsFooter(
                this._vehicle,
                this._vehicleDetailsProps,
                this._selectedVehicle,
                this._onSidebarClose,
                this._hasUnsavedChanges
            );
        } else if (this.vehicleModel() === VehicleModel.ETGE) {
            return new EtgeVehicleDetailsFooter(this._vehicleDetailsProps.onClose);
        } else {
            return null;
        }
    }
}

export default VehicleDetailsFooterFactory;
