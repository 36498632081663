import useTooltip from '../../hooks/useTooltip';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';

const TooltipWrapperList = ({
    values,
    dataTestId,
    tooltipContent,
}: {
    values: { label: React.ReactNode; value: React.ReactNode }[];
    dataTestId: string;
    tooltipContent: string;
}): JSX.Element => {
    const { isEllipsisActive, labelRef } = useTooltip();

    if (isEllipsisActive) {
        return (
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip width="auto" className="top">
                        <span style={{ whiteSpace: 'pre-line' }}>{tooltipContent}</span>
                    </Tooltip>
                }
            >
                <div className={'display-flex text-size-12 '} data-testid={dataTestId}>
                    <div className={'justify-content-start ellipsis-1'}>
                        {values.map((v, idx) => (
                            <div key={idx} className={'display-flex flex-row'}>
                                <div className="ellipsis-1" ref={labelRef}>
                                    {' '}
                                    {v.label}:{' '}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={'justify-content-end padding-left-5 ellipsis-1'}>
                        {values.map((v, idx) => (
                            <div key={idx} className={'display-flex flex-row'}>
                                <div className="ellipsis-1"> {v.value} </div>
                            </div>
                        ))}
                    </div>
                </div>
            </OverlayTrigger>
        );
    }
    return (
        <div className={'display-flex text-size-12 '} data-testid={dataTestId}>
            <div className={'justify-content-start ellipsis-1'}>
                {values.map((v, idx) => (
                    <div key={idx} className={'display-flex flex-row'}>
                        <div className="ellipsis-1" ref={labelRef}>
                            {' '}
                            {v.label}:{' '}
                        </div>
                    </div>
                ))}
            </div>
            <div className={'justify-content-end padding-left-5 ellipsis-1'}>
                {values.map((v, idx) => (
                    <div key={idx} className={'display-flex flex-row'}>
                        <div className="ellipsis-1"> {v.value} </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TooltipWrapperList;
