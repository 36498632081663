import ChargingStatus from '../../models/enums/ChargingStatus';
import { isEmpty } from '../../../utils/functions';
import { VehicleChargingData } from '../../models/VehicleChargingData';

export const hasFilter = (
    values: (ChargingStatus | undefined)[],
    chargingStatus: (ChargingStatus | undefined)[]
): boolean => values.some(el => chargingStatus.includes(el));

const allHasAll = (arr1: any[], arr2: any[]): boolean =>
    arr1.every((el: any) => arr2.includes(el)) && arr2.every((el: any) => arr1.includes(el));

export const updateFilterValues = (
    chargingStatus: (ChargingStatus | undefined)[],
    relatedChargingStatus: (ChargingStatus | undefined)[],
    actualFiltersSelected: (ChargingStatus | undefined)[]
): (ChargingStatus | undefined)[] => {
    if (actualFiltersSelected.length === 0) {
        return chargingStatus;
    }
    if (relatedChargingStatus.length === 0) {
        return chargingStatus;
    }
    if (allHasAll(chargingStatus, actualFiltersSelected)) {
        return [...chargingStatus].concat(relatedChargingStatus);
    }
    if (allHasAll(actualFiltersSelected, [...chargingStatus].concat([...relatedChargingStatus]))) {
        return relatedChargingStatus;
    }
    if (allHasAll(relatedChargingStatus, actualFiltersSelected)) {
        return [...chargingStatus].concat(relatedChargingStatus);
    }
    return chargingStatus;
};

export const getNumberOfVehiclesWithChargingStatusUsingElectricVehicle = (
    vehicles: VehicleChargingData[],
    chargingStatuses: any[]
): number => {
    return vehicles.filter(vehicle => chargingStatuses.includes(vehicle.measurements?.chargingState?.value)).length;
};

export const getWidgetOpacityClass = (
    actualFiltersSelected: (ChargingStatus | undefined)[],
    chargingStatus: (ChargingStatus | undefined)[] | undefined
): string => {
    if (chargingStatus) {
        return isEmpty(actualFiltersSelected) || hasFilter(actualFiltersSelected, chargingStatus) ? '' : ' opacity-40';
    } else {
        return '';
    }
};
