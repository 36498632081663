import classnames from 'classnames';

const ContentCharging = ({
    value,
    climateMode,
    isClimateControlEnabled,
}: {
    value: string;
    climateMode: string;
    isClimateControlEnabled: boolean;
}): JSX.Element => (
    <>
        <span className="rioglyph rioglyph-time text-size-18 padding-right-5" />
        {value}
        <br />
        <span className="rioglyph rioglyph-climate-control text-size-18 padding-right-5" />
        <span className={classnames({ 'text-color-gray': !isClimateControlEnabled })}>{climateMode}</span>
    </>
);

export default ContentCharging;
