import { config } from '../../../../../config';

interface UpsellButtonProps {
    btnLink?: boolean;
    resourceId?: string[];
}

export const BASE_URL = config.upsellUrl;

const UpsellButton = ({ btnLink, resourceId }: UpsellButtonProps) => {
    let urlWithConfig = BASE_URL;

    if (btnLink) {
        urlWithConfig = `${urlWithConfig}&textOnly`;
    }

    if (resourceId) {
        urlWithConfig = `${urlWithConfig}&resourceIds=${resourceId.join(',')}`;
    }

    return <iframe data-testid="upsell" className="buyButton" src={urlWithConfig}></iframe>;
};

export default UpsellButton;
