import { FormattedMessage } from 'react-intl';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { ProductVersion } from '../../common/vehicles/productVersion';
import TooltipRef from '../../common/components/Tooltip/Tooltip';

type ProductLabelProps = {
    productVersion: ProductVersion;
    tooltipText?: string;
    dataTestId?: string;
};

const TooltipWrapper = ({ tooltipText, children }: { tooltipText?: string; children: JSX.Element }) => {
    return tooltipText ? (
        <div className="display-flex flex-column">
            <TooltipRef tooltipContent={tooltipText} dataTestId="product-label-tooltip" placement={Tooltip.TOP}>
                {children}
            </TooltipRef>
        </div>
    ) : (
        children
    );
};

export const ProductLabel = (props: ProductLabelProps) => {
    if (props.productVersion === ProductVersion.NOT_BOOKED) {
        return null;
    }
    return (
        <TooltipWrapper tooltipText={props.tooltipText}>
            <span
                data-testid={props.dataTestId}
                className={`label label-condensed height-20 ${
                    props.productVersion === ProductVersion.EMANAGER_M ? 'label-primary' : 'label-default'
                }`}
                style={{ width: '22px' }}
            >
                <span className="text-bold text-uppercase text-size-12">
                    <FormattedMessage id={`e4c.global.product.${props.productVersion.toLocaleLowerCase()}`} />
                </span>
            </span>
        </TooltipWrapper>
    );
};
