import { FormattedMessage, useIntl } from 'react-intl';
import TableSettingsDialog from '@rio-cloud/rio-uikit/TableSettingsDialog';
import { initialDisabledColumns } from '../../config';

interface VehiclesTableSettingsDialogProps {
    columnLabels: Object;
    columnOrder: string[];
    defaultColumnOrder: string[];
    hiddenColumns: string[];
    onApply: (columnOrder: string[], hiddenColumns: string[]) => void;
    onHide: () => void;
    show: boolean;
}

const VehiclesTableSettingsDialog = (props: VehiclesTableSettingsDialogProps): JSX.Element => {
    const { columnLabels, columnOrder, defaultColumnOrder, hiddenColumns, onApply, onHide, show } = props;
    const intl = useIntl();
    const disabledColumns = initialDisabledColumns;

    return (
        <FormattedMessage id="e4c.vehicles.tableSettings.search">
            {(placeholder): JSX.Element => (
                <TableSettingsDialog
                    show={show}
                    title={intl.formatMessage({ id: 'e4c.vehicles.tableSettings.title' })}
                    onHide={onHide}
                    onColumnChange={(): void => {}}
                    defaultColumnOrder={defaultColumnOrder}
                    defaultHiddenColumns={[]}
                    columnOrder={columnOrder}
                    hiddenColumns={hiddenColumns}
                    columnLabels={columnLabels}
                    disabledColumns={disabledColumns}
                    applyButtonText={intl.formatMessage({ id: 'e4c.vehicles.tableSettings.apply' })}
                    cancelButtonText={intl.formatMessage({ id: 'e4c.vehicles.tableSettings.cancel' })}
                    onApply={onApply}
                    resetButtonText={intl.formatMessage({ id: 'e4c.vehicles.tableSettings.reset' })}
                    searchPlaceholder={placeholder}
                    notFoundMessage={intl.formatMessage({ id: 'e4c.vehicles.tableSettings.notFoundMessage' })}
                    onColumnDetailsChange={(): void => {}}
                    immediateChange={false}
                />
            )}
        </FormattedMessage>
    );
};

export default VehiclesTableSettingsDialog;
