import UpsellButton from '../../upsell/UpSellButton';
import { ProductVersion } from '../../../vehicles/productVersion';

interface BookNowProps {
    assetId: string;
    productVersion: ProductVersion;
}

const UpsellButtonWrapper = ({ assetId }: { assetId: string }) => (
    <div className="display-flex gap-5 align-items-center margin-top-15">
        <UpsellButton btnLink resourceId={[assetId]} />
        <span className="rioglyph rioglyph-shopping-cart text-color-primary margin-bottom-15"></span>
    </div>
);

const BookNow = ({ assetId, productVersion }: BookNowProps) => {
    return productVersion === ProductVersion.EMANAGER_S ? <UpsellButtonWrapper assetId={assetId} /> : null;
};

export default BookNow;
