import RioLogo from '../../../assets/svg/RIO-logo';
import { useIntl } from 'react-intl';

const PageLoader = () => {
    const intl = useIntl();

    return (
        <div data-testid="page-loader" id="pageLoader" style={{ position: 'static' }}>
            <div className="pageLoaderCenter">
                <div className="pageLoaderAnimation">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <div className="pageLoaderLogo">
                        <RioLogo />
                    </div>
                </div>
                <div className="pageLoaderText">{intl.formatMessage({ id: 'e4c.spinner.loading' })}</div>
            </div>
        </div>
    );
};

export default PageLoader;
