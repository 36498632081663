export const addQueryParam = (currentQueryParams: string, newParamName: string, newParamValue?: string): string => {
    const searchParams = new URLSearchParams(currentQueryParams);
    searchParams.append(newParamName, newParamValue ?? '');
    return searchParams.toString();
};

export const removeQueryParam = (currentQueryParams: string, param: string): string => {
    const searchParams = new URLSearchParams(currentQueryParams);
    searchParams.delete(param);
    return searchParams.toString();
};
