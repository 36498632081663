import { Weekday, Timer } from '../../../vehicleDetails/types';
import WeekdaySelectorButton from '../WeekdaySelectorButton/WeekdaySelectorButton';

interface WeekDaySelectorProps {
    isEnabled: boolean;
    onToggleWeekDay: (timerId: number, weekDay: Weekday) => void;
    timer: Timer;
    timerId: number;
    toggleUnsavedVehicleChanges: (unsavedChanges: boolean) => void;
}

const validWeekDays = [
    Weekday.MONDAY,
    Weekday.TUESDAY,
    Weekday.WEDNESDAY,
    Weekday.THURSDAY,
    Weekday.FRIDAY,
    Weekday.SATURDAY,
    Weekday.SUNDAY,
];

const WeekdaySelector = (props: WeekDaySelectorProps): JSX.Element => {
    const { isEnabled, onToggleWeekDay, timer, timerId } = props;
    return (
        <div className="btn-group days-group" role="group">
            {validWeekDays.map(weekDay => (
                <WeekdaySelectorButton
                    key={weekDay}
                    weekday={weekDay}
                    isEnabled={isEnabled && timer.enabled}
                    isSelected={timer.weekdays.includes(weekDay)}
                    onClick={() => onToggleWeekDay(timerId, weekDay)}
                />
            ))}
        </div>
    );
};

export default WeekdaySelector;

/**
 * TODO simplify component after adding TimerChargingMode tests by:
 *      1) removing isEnabled logic;
 *      2) remove timerId - onToggleWeeDay returns only the weekday
 *      3) receive array of selected Weekdays instead of Timer
 */
