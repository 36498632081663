/* eslint-disable max-len */
const FleetEventWidgetEmpty = (): JSX.Element => {
    return (
        <svg
            id="empty-event-image"
            width="96"
            height="96"
            viewBox="0 0 96 96"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M48 3.20001C40.9307 3.20001 35.2 8.93073 35.2 16C35.2 23.0693 40.9307 28.8 48 28.8C55.0692 28.8 60.7999 23.0693 60.7999 16C60.7999 8.93073 55.0692 3.20001 48 3.20001ZM45.024 25.6L47.264 17.3344L43.52 18.8384L45.76 8.32001H50.2816L48.0416 14.3296L52.5504 12.8256L45.024 25.6Z"
                fill="#D0D8DE"
            />
            <path
                d="M16.008 32.6264L0.648926 48.008L16.008 63.2933L31.3908 47.9598L16.008 32.6264ZM17.7241 55.6919H14.5225V52.4902H17.7241V55.6919ZM14.5225 50.1093V40.0968H17.7241V50.1093H14.5225Z"
                fill="#D0D8DE"
            />
            <path
                d="M48 92.8C55.0692 92.8 60.7999 87.0693 60.7999 80C60.7999 72.9307 55.0692 67.2 48 67.2C40.9307 67.2 35.2 72.9307 35.2 80C35.2 87.0693 40.9307 92.8 48 92.8ZM46.72 72.32H49.92V75.52H46.72V72.32ZM45.44 87.68V84.48H46.72V80.64H45.44V77.44H49.92V84.48H51.2V87.68H45.44Z"
                fill="#D0D8DE"
            />
            <path
                d="M80 35.2C72.9307 35.2 67.2 40.9307 67.2 48C67.2 55.0693 72.9307 60.8 80 60.8C87.0692 60.8 92.7999 55.0693 92.7999 48C92.7999 40.9307 87.0692 35.2 80 35.2ZM77.0496 55.0592L71.68 49.6768L73.92 47.4144L77.0464 50.5408L86.5536 41.104L88.7936 43.3728L77.0496 55.0592Z"
                fill="#D0D8DE"
            />
        </svg>
    );
};

export default FleetEventWidgetEmpty;
