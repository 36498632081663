import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { NO_DATA } from '../../../types';

export interface RemainingCapacityColumnProps {
    isRemainingCapacityKWhAvailable: boolean;
    valueInKm: number | undefined;
    valueInKWh: number | undefined;
}

export const RemainingCapacityColumn = ({
    isRemainingCapacityKWhAvailable,
    valueInKm,
    valueInKWh,
}: RemainingCapacityColumnProps): ReactElement => {
    const isRemainingCapacityKmAvailable = true; // this was set as true in electric vehicle class model in every obj
    const hasKmOnly = isRemainingCapacityKmAvailable && !isRemainingCapacityKWhAvailable;
    const hasKmDefined = isRemainingCapacityKmAvailable && valueInKm !== undefined;

    const hasKWhOnly = isRemainingCapacityKWhAvailable && !isRemainingCapacityKmAvailable;
    const hasKWhDefined = isRemainingCapacityKWhAvailable && valueInKWh !== undefined;

    const hasNoDataAvailable = !isRemainingCapacityKmAvailable && !isRemainingCapacityKWhAvailable;
    const hasNoData = hasNoDataAvailable || (hasKmOnly && !hasKmDefined) || (hasKWhOnly && !hasKWhDefined);
    const hasSingleLine = hasNoData || hasKmOnly || hasKWhOnly;

    const contentClasses = classNames('display-block', 'ellipsis-1', { 'justify-content-center': hasSingleLine });

    const showNoData =
        (!isRemainingCapacityKmAvailable && !isRemainingCapacityKWhAvailable) ||
        (hasKmOnly && valueInKm === undefined) ||
        (hasKWhOnly && valueInKWh === undefined) ||
        (isRemainingCapacityKmAvailable &&
            valueInKm === undefined &&
            isRemainingCapacityKWhAvailable &&
            valueInKWh === undefined);

    const showKmLine = !showNoData && isRemainingCapacityKmAvailable;
    const showKWhLine = !showNoData && isRemainingCapacityKWhAvailable;

    const renderNoData = (): JSX.Element | null => {
        return showNoData ? (
            <div data-testid={'rc-no-data'} className={'text-color-lighter'}>
                {NO_DATA}
            </div>
        ) : null;
    };

    // TODO: test what happens if 0 null undef
    const renderValueInKm = (): JSX.Element | null => {
        const value = hasKmDefined ? `${Math.round(valueInKm)} km` : `${NO_DATA} (km)`;
        return showKmLine ? (
            <div data-testid={'rc-km'} className={classNames({ 'text-color-lighter': !hasKmDefined })}>
                {value}
            </div>
        ) : null;
    };

    const renderValueInKWh = (): JSX.Element | null => {
        const value = hasKWhDefined ? `${Math.round(valueInKWh)} kWh` : `${NO_DATA} (kWh)`;
        return showKWhLine ? (
            <div data-testid={'rc-kwh'} className={classNames({ 'text-color-lighter': !hasKWhDefined })}>
                {value}
            </div>
        ) : null;
    };

    return (
        <div className={contentClasses}>
            {renderNoData()}
            {renderValueInKm()}
            {renderValueInKWh()}
        </div>
    );
};

export const MemoRemainingCapacityColumn = React.memo(RemainingCapacityColumn);
