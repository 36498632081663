import React from 'react';
import Teaser from '@rio-cloud/rio-uikit/Teaser';
import TeaserContainer from '@rio-cloud/rio-uikit/TeaserContainer';
import { OverviewTeaserProperties } from '../types';
import { FormattedMessage } from 'react-intl';
import overviewElectricBusImage from '../../assets/images/overview_man_electric_bus_800.png';
import OverviewContentTeaser from './OverviewContentTeaser';
import { SkeletonOverviewWelcomeTeaser } from '../skeleton/SkeletonOverviewTeaser';

const WelcomeTitle = ({ isFetching }: { isFetching: boolean }) => {
    return isFetching ? <SkeletonOverviewWelcomeTeaser /> : <FormattedMessage id={'e4c.overview.welcomeback'} />;
};

const OverviewTeaser = React.memo((props: OverviewTeaserProperties) => {
    return (
        <TeaserContainer>
            <Teaser
                className="overview-teaser"
                segmentation="50"
                headline={<WelcomeTitle isFetching={props.fetchingElectricVehicles} />}
                content={<OverviewContentTeaser {...props} />}
                image={{
                    src: overviewElectricBusImage,
                    align: 'right',
                    className: 'width-100pct overview-teaser-image',
                }}
            />
        </TeaserContainer>
    );
});

OverviewTeaser.displayName = 'OverviewTeaser';
export default OverviewTeaser;
