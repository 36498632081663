import { VehicleStatistics } from '../../types';
import { StatisticsData } from './types';
import moment from 'moment';

abstract class StatisticsBuilder {
    protected readonly _endDate: Date;
    protected readonly _startDate: Date;
    protected readonly _vehicleStatistics: VehicleStatistics[];

    private readonly BARCHART_DAYS_LIMIT: number = 31;

    constructor(startDate: Date, endDate: Date, vehicleStatistics: VehicleStatistics[]) {
        this._startDate = startDate;
        this._endDate = endDate;
        this._vehicleStatistics = vehicleStatistics;
    }

    abstract build(): StatisticsData;

    protected isDailyAggregated(): boolean {
        return moment(this._endDate).diff(moment(this._startDate), 'days') <= this.BARCHART_DAYS_LIMIT;
    }
}

export default StatisticsBuilder;
