interface UserManualPageProps {
    title: JSX.Element;
    children: JSX.Element | JSX.Element[];
    footer?: JSX.Element;
    dataTestId?: string;
}

const UserManualPage = ({ title, children, footer, dataTestId }: UserManualPageProps) => {
    return (
        <div data-testid={dataTestId}>
            <div className="margin-bottom-10 margin-top-0 text-size-h2">{title}</div>
            <div className={'white-space-pre-line'}>{children}</div>
            {footer && <div className="margin-top-20 text-size-12">{footer}</div>}
        </div>
    );
};

export default UserManualPage;
