import { ReactElement, useRef } from 'react';
import { useLocation } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { config } from '../../../../../config';
import { createGoogleAnalyticsEvent, gtmUtil, TrackingAction, TrackingCategory } from '../../../utils/googleTagManager';
import {
    MANUAL_DETAILS_CHARGING_SEARCH,
    MANUAL_WELCOME_SEARCH,
    MANUAL_WHATS_NEW_SEARCH,
    USER_MANUAL,
} from '../../constants/paths/paths';
import TooltipRef from '../Tooltip/Tooltip';
import { addQueryParam } from '../../helpers/queryParametersHelper';

const title = (
    <div data-testid="title">
        <span>{config.title}</span>
    </div>
);

const ServiceInfoComponent = (): ReactElement => {
    const { search } = useLocation();

    return (
        <>
            <div className="line-height-largest">
                <span className="text-color-darkest rioglyph rioglyph-hand-right margin-right-5" />
                <Link to={{ search: addQueryParam(search, USER_MANUAL, MANUAL_WELCOME_SEARCH) }}>
                    <FormattedMessage id="e4c.userManual.welcome" />
                </Link>
            </div>
            <div className="line-height-largest">
                <span className="text-color-darkest rioglyph rioglyph-info-sign margin-right-5" />
                <Link to={{ search: addQueryParam(search, USER_MANUAL, MANUAL_WHATS_NEW_SEARCH) }}>
                    <FormattedMessage id="e4c.whatsNew.title" />
                </Link>
            </div>
            <div className="line-height-largest">
                <span className="text-color-darkest rioglyph rioglyph-filling-e-station margin-right-5" />
                <Link to={{ search: addQueryParam(search, USER_MANUAL, MANUAL_DETAILS_CHARGING_SEARCH) }}>
                    <FormattedMessage id="e4c.userManual.functionsDetail" />
                </Link>
            </div>
        </>
    );
};

const handleActionBarInfoClick = () => {
    gtmUtil.pushGaEvent(createGoogleAnalyticsEvent(TrackingCategory.GENERAL, TrackingAction.CLICK_USER_MANUAL));
};

const ServiceInfoIconButton = ({ hasNewEntry }: { hasNewEntry?: boolean }): ReactElement => {
    const intl = useIntl();
    const ref = useRef<HTMLDivElement>(null);
    return (
        <ActionBarItem id="serviceInfo" className="myItem">
            <ActionBarItem.Icon className="myItem">
                <TooltipRef
                    ref={ref}
                    dataTestId="tooltip-service-info"
                    tooltipContent={intl.formatMessage({ id: 'e4c.global.user.manual' })}
                    placement={Tooltip.BOTTOM}
                >
                    <span className="icon rioglyph rioglyph-info-sign" onClick={handleActionBarInfoClick} />
                </TooltipRef>
                {hasNewEntry && (
                    <span className="badge badge-indicator badge-indicator-border badge-primary top-2 margin-left-2" />
                )}
            </ActionBarItem.Icon>
            <ActionBarItem.Popover className="myItemPopover" title={title}>
                <ServiceInfoComponent />
            </ActionBarItem.Popover>
        </ActionBarItem>
    );
};

export { ServiceInfoIconButton };
