import React from 'react';
import { FormattedMessage } from 'react-intl';
import Switch from '@rio-cloud/rio-uikit/Switch';

interface ClimateModeProprieties {
    isChecked: boolean;
    isEnabled: boolean;
    onChange: () => void;
}

export default class ClimateMode extends React.Component<ClimateModeProprieties> {
    render(): JSX.Element {
        return (
            <div className="width-100pct panel panel-default margin-top-10 margin-bottom-0">
                <div className="panel-body">
                    <span className="rioglyph rioglyph-climate-control text-size-16" />
                    <span className="text-color-highlight text-bold padding-left-5">
                        <FormattedMessage id="e4c.vehicles.charging.climateControl" />
                    </span>
                    <div className="pull-right">
                        <Switch
                            keyName="climateMode"
                            onChange={(): void => this.props.onChange()}
                            checked={this.props.isChecked}
                            disabled={!this.props.isEnabled}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
