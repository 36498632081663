interface UserManualOptionsProps {
    image?: JSX.Element | JSX.Element[];
    className?: string;
    description: JSX.Element;
    boldText?: JSX.Element;
}

const UserManualOptions = ({ image, className, description, boldText }: UserManualOptionsProps) => {
    return (
        <div className="display-flex align-items-center">
            {image}
            <div className="display-flex">
                <div className={`margin-left-10 ${className}`}>
                    {boldText && <span className="text-bold">{boldText}</span>}
                    {description}
                </div>
            </div>
        </div>
    );
};

export default UserManualOptions;
