import { useIntl } from 'react-intl';
import MenuItem from './MenuItem';
import SeverityIcon from '../../eventCard/SeverityIcon';
import { SeverityEventLevel } from '../../../../../models/SeverityEventLevelEnum';
import { useHistory } from 'react-router';
import { EVENT_SETTINGS_PATH } from '../../../../../../common/constants/paths/paths';
import { EventsSettingsUI } from '../Models/IModel';

export interface MenuProps {
    events: EventsSettingsUI[];
}

const Menu = ({ events }: MenuProps): JSX.Element => {
    const intl = useIntl();
    const history = useHistory();
    const translateEventsNames = (names: string): string =>
        names
            .split(',')
            .map((name: string) => intl.formatMessage({ id: name }))
            .join(', ');
    const translateEventSeverityTitle = (level: SeverityEventLevel) => {
        switch (level) {
            case SeverityEventLevel.CRITICAL:
                return intl.formatMessage({ id: 'e4c.overview.events.widget.modal.severity.critical' });
            case SeverityEventLevel.WARNING:
                return intl.formatMessage({ id: 'e4c.overview.events.widget.modal.severity.warning' });
            case SeverityEventLevel.SUCCESS:
                return intl.formatMessage({ id: 'e4c.overview.events.widget.modal.severity.success' });
            case SeverityEventLevel.INFO:
                return intl.formatMessage({ id: 'e4c.overview.events.widget.modal.severity.informational' });
        }
    };
    return (
        <div className="display-flex flex-column">
            <p className="text-size-14 text-medium">
                {intl.formatMessage({ id: 'e4c.overview.events.widget.settings.typesEvents' })}
            </p>
            {events.map((value: EventsSettingsUI) => (
                <div key={value.eventSeverityLevel} className="margin-bottom-10 cursor-pointer">
                    <MenuItem
                        handleClick={() =>
                            history.push({
                                pathname: `${EVENT_SETTINGS_PATH}/${value.eventSeverityLevel}`,
                                state: { ...value.eventSettings },
                            })
                        }
                        severityIcon={<SeverityIcon severityLevel={value.eventSeverityLevel} />}
                        title={translateEventSeverityTitle(value.eventSeverityLevel)}
                        subTitle={translateEventsNames(value.names)}
                    />
                </div>
            ))}
        </div>
    );
};

export default Menu;
