import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import TableSearch from '@rio-cloud/rio-uikit/TableSearch';
import { State } from '../../../../../types';
import { vehiclesTableSearchValueChanged } from '../../../actions/vehicles/Vehicles.actions';

const VehiclesSearchTextBox = (): JSX.Element => {
    const value = useSelector((state: State) => state.vehicles.searchValue);
    const dispatch = useDispatch();

    const onValueChange = (searchValue: string) => {
        dispatch(vehiclesTableSearchValueChanged(searchValue));
    };

    return (
        <div className="table-toolbar-column table-toolbar-column-spacer" data-testid={'vt-search'}>
            <FormattedMessage id="e4c.vehicles.search.placeholder">
                {(placeholder): JSX.Element => (
                    <TableSearch value={value} onChange={onValueChange} placeholder={placeholder} />
                )}
            </FormattedMessage>
        </div>
    );
};

export default VehiclesSearchTextBox;
