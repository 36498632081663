import {
    InMemoryWebStorage,
    User,
    UserManager,
    UserManagerSettings,
    UserProfile as Profile,
    WebStorageStateStore,
} from 'oidc-client-ts';
import join from 'lodash/fp/join';
import { config } from '../../config';
import { OAuthConfig, SessionRenewedResult } from '../types';
import omit from 'lodash/fp/omit';
import { AccessToken, UserProfile } from '../index';

const RETRY_SIGNIN_TIMEOUT_IN_MS = 30000;

const retrySigninSilent = (oauthConfig: OAuthConfig, userManager: UserManager) => {
    userManager.signinSilent().catch((error: Error) => {
        if (error.message === 'login_required') {
            oauthConfig.onSessionExpired();
        } else {
            setTimeout(() => retrySigninSilent(oauthConfig, userManager), RETRY_SIGNIN_TIMEOUT_IN_MS);
        }
    });
};

const stripSnakeProps = omit(['family_name', 'given_name']);
export const mapUserProfile = (profile: Profile): UserProfile => ({
    sub: profile.sub,
    azp: profile.azp,
    givenName: profile.given_name,
    familyName: profile.family_name,
    name: profile.name,
    locale: profile.locale,
    email: profile.email,
    ...stripSnakeProps(profile),
});

export const adaptPublishedInfo = (result: User): SessionRenewedResult => ({
    accessToken: result.access_token,
    idToken: result.profile,
    locale: result.profile?.locale ?? 'en-GB',
    profile: mapUserProfile(result.profile),
});

const trace = import.meta.env.DEV ? (...args: any | string) => console.debug('[oidcLogin]', ...args) : () => {};

export const createUserManager = () => {
    const redirectUri = config.login.redirectUri;
    const silentRedirectUri = config.login.silentRedirectUri;

    const settings: UserManagerSettings = {
        authority: `${config.login.authority}`,
        client_id: `${config.login.clientId}`,
        loadUserInfo: false,
        redirect_uri: `${redirectUri}`,
        response_type: 'code',
        scope: join(' ', config.login.oauthScope),
        silent_redirect_uri: `${silentRedirectUri || redirectUri}`,
        includeIdTokenInSilentRenew: false,
        automaticSilentRenew: true,
        monitorSession: true,
        staleStateAgeInSeconds: 600,
        userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
        filterProtocolClaims: false,
    };

    return new UserManager(settings);
};

export const configureUserManager = (oauthConfig: OAuthConfig, userManager: UserManager) => {
    userManager.events.addUserLoaded(user => {
        trace('oidc.signinSilent success!');
        oauthConfig.onSessionRenewed(adaptPublishedInfo(user));
    });

    userManager.events.addUserUnloaded(() => {
        oauthConfig.onSessionExpired();
    });

    userManager.events.addSilentRenewError(error => {
        trace('oidc.silentRenewError', error);
        retrySigninSilent(oauthConfig, userManager);
    });

    userManager.events.addUserSignedOut(() => {
        oauthConfig.onSessionExpired();
    });

    return userManager;
};

export const configureMockUserManager = (oauthConfig: OAuthConfig, accessToken: AccessToken): UserManager => {
    // eslint-disable-next-line no-console
    console.warn('[feature/login/oidc-session] Using mocked authorization due to config setting');

    const signinSilent = async () => {
        const userSettings = {
            access_token: accessToken || 'valid-mocked-oauth-bogus-token',
            profile: {
                iss: 'Issuer Identifier',
                aud: 'Audience(s): client_id',
                exp: 10,
                iat: 5,
                account: 'mockaccount',
                azp: 'test-client',
                email: 'test@example.com',
                family_name: 'Client',
                given_name: 'Test',
                name: 'Test Client',
                sub: 'prod-rio-users:mock-user',
                username: 'test@example.com',
                locale: config.login.mockLocale,
            },
            id_token: 'id_token',
            session_state: 'session_state',
            refresh_token: 'refresh_token',
            token_type: 'token_type',
            scope: 'scope',
            expires_at: 100000,
            state: 'state',
        };

        const user = new User(userSettings);
        oauthConfig.onSessionRenewed(adaptPublishedInfo(user));
        return Promise.resolve(user);
    };

    const clearStaleState = () => {
        console.info('[configuration/login/oidc-session] Stale state cleared');
        return Promise.resolve();
    };

    return { signinSilent, clearStaleState } as UserManager;
};
