import BarChartPanel from './BarChartPanel';
import moment from 'moment';
import { KpiEnum } from '../types';
import UpsellButton from '../../common/components/upsell/UpSellButton';
import { FormattedMessage } from 'react-intl';
import { ReactElement } from 'react';

const overlayClassNames = [
    'height-250',
    'position-absolute',
    'flex-column',
    'position-relative',
    'z-index-1',
    'text-color-gray',
    'text-size-10',
    'graph-overlay',
    'display-flex',
    'align-items-center',
    'justify-content-center',
].join(' ');

const UpsellingBarChartPanel = ({
    initiallySelectedKpi,
    localStoragePrefix,
}: {
    initiallySelectedKpi: KpiEnum;
    localStoragePrefix: string;
}): ReactElement => {
    const start = moment().startOf('month');
    const end = moment();
    return (
        <div className="graph-placeholder position-relative">
            <BarChartPanel
                defaultStartValue={start}
                defaultEndValue={end}
                fetchingVehiclesStatistics={false}
                initiallySelectedKpi={initiallySelectedKpi}
                localStoragePrefix={localStoragePrefix}
                vehicles={[]}
                vehiclesCount={0}
                vehiclesStatistics={[
                    {
                        vehicleId: 'dummy',
                        history: [
                            {
                                date: start.format('YYYY-MM-DD'),
                                drivenDistance: 20,
                                energyConsumption: 10,
                                powertrainEnergyConsumption: 10,
                                auxiliaryEnergyConsumption: 10,
                                amsEnergyConsumption: 10,
                                recuperatedEnergy: 10,
                            },
                        ],
                    },
                ]}
            />
            <div className={overlayClassNames} style={{ bottom: 43, width: 'calc(100% - 2px)', left: 1 }}>
                <div
                    className="height-100pct width-100pct position-absolute opacity-80"
                    style={{ background: 'white', zIndex: -1 }}
                />
                <FormattedMessage id="e4c.overview.vehicleStatus.vehiclesDriving.explanation" />
                <div className="width-200 margin-top-15">
                    <UpsellButton />
                </div>
            </div>
        </div>
    );
};

export default UpsellingBarChartPanel;
