/* eslint-disable react-hooks/exhaustive-deps */
// we should discuss the relevance of this warning
import React, { useEffect, useState } from 'react';
import './HistoryTable.css';
import { HistoryTableProps } from '../types';
import { handleTableSortChange as handleTableSortChangeUtils } from '../../../common/tablesUtils/tableUtilsTS';
import _ from 'lodash';
import {
    buildMapOfLabelsPerColumnDetails,
    buildMapOfSortablesPerColumnDetails,
    buildMapOfWidthsPerColumnDetails,
    findColumnsWithRightSeparator,
    getKeysFromColumnsDetailsMap,
    historyTableClassNames,
} from './HistoryTableHelper';
import renderHistoryTableHeader from './HistoryTableHeader';
import Pagination, { PaginationOptions } from '../../../common/components/pagination/Pagination';
import { useIntl } from 'react-intl';

const PAGINATION_OPTIONS = [20, 40, 60];
const PAGINATION_DEFAULT_PAGE_SIZE = PAGINATION_OPTIONS[0];
const PAGINATION_DEFAULT_PAGE = 1;

const HistoryTable = ({
    assets,
    columnsDetails,
    handleTableSortChange,
    renderColumnValue,
    renderRows,
    rows,
    sortDir,
    sortBy,
}: HistoryTableProps): JSX.Element => {
    const { locale } = useIntl();
    const [keysOpened, setKeysOpened] = useState<string[]>([]);
    const [pagination, setPagination] = useState<PaginationOptions>({
        pageSize: PAGINATION_DEFAULT_PAGE_SIZE,
        pageNumber: 1,
    });
    const [numberOfItems, setNumberOfItems] = useState<number>(0);

    const sortedRows = rows.orderByProperty(rows, sortBy, sortDir);

    useEffect(() => {
        setNumberOfItems(
            _.chain(rows.processList)
                .map('startDate')
                .groupBy(row => row.valueOf())
                .size()
                .value()
        );
    }, [rows]);

    useEffect(() => {
        rows.setAssetNameOnAllProcess(assets);
    }, []);

    useEffect(() => {
        if (
            rows &&
            keysOpened &&
            rows.processList &&
            rows.processList[0] &&
            rows.processList[0].startDate &&
            !keysOpened.includes(rows.processList[0].startDate)
        ) {
            setKeysOpened(keysOpened.concat(sortedRows.processList[0].startDate));
        }
    }, []);

    const handleSortChange = (e: React.MouseEvent): void => {
        handleTableSortChangeUtils(e, sortDir, sortBy, handleTableSortChange);
    };

    const changeRowState = (key: string): void => {
        if (keysOpened.includes(key)) {
            setKeysOpened(keysOpened.filter(k => k !== key));
        } else {
            setKeysOpened(keysOpened.concat(key));
        }
    };
    const columns: string[] = getKeysFromColumnsDetailsMap(columnsDetails);
    return (
        <div>
            <table className={historyTableClassNames}>
                {renderHistoryTableHeader(
                    columns,
                    buildMapOfLabelsPerColumnDetails(columnsDetails),
                    buildMapOfSortablesPerColumnDetails(columnsDetails),
                    buildMapOfWidthsPerColumnDetails(columnsDetails),
                    columnsDetails,
                    sortDir,
                    sortBy,
                    handleSortChange
                )}
                <tbody>
                    {renderRows(
                        sortedRows,
                        columns,
                        keysOpened,
                        locale,
                        findColumnsWithRightSeparator(columnsDetails, columns),
                        renderColumnValue,
                        changeRowState,
                        pagination
                    )}
                </tbody>
            </table>
            <Pagination
                numberOfItems={numberOfItems}
                sizeOptions={PAGINATION_OPTIONS}
                defaultPage={PAGINATION_DEFAULT_PAGE}
                defaultSize={PAGINATION_DEFAULT_PAGE_SIZE}
                onPaginationChanges={setPagination}
            />
        </div>
    );
};

export default HistoryTable;
