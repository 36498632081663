import moment from 'moment';
import { EventsResponseApi } from '../../vehicleCharging/models/api/eventsResponse';
import { Event } from '../models/Event';

const mappingEventsFromServer = (response: EventsResponseApi[]): { events: Event[]; countNumber: number } => {
    const events = response
        ?.flatMap(e => e?.events)
        ?.map(data => {
            return {
                id: data?.id,
                eventTitleMessage: data?.event_title_message,
                eventSubtitleMessage: data?.event_subtitle_message,
                eventInterpolationMessage: data?.event_interpolation_message,
                eventVehicle: data?.event_vehicle,
                date: moment(new Date(data?.date)).format('DD/MM/YYYY, HH:mm'),
                severityLevel: data?.event_level,
                vehicleId: data?.event_vehicle_id,
            };
        });

    const countOfFirstPage = response?.[0]?.count; // the first count has all the obj, the others are substracted by the number of obj pulled
    return { events, countNumber: countOfFirstPage };
};

export default mappingEventsFromServer;
