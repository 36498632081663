import { FormattedMessage } from 'react-intl';
import { NothingSelectedStateProps } from './types';
import InformativeState from '../../common/components/states/InformativeState';

export const NothingSelectedState = (props: NothingSelectedStateProps): JSX.Element => {
    const icons = [
        { className: 'rioglyph-bus-baseline text-size-200pct margin-10', name: 'small-truck' },
        { className: 'rioglyph-bus-baseline text-size-300pct margin-10', name: 'big-truck' },
        { className: 'rioglyph-bus-baseline text-size-200pct margin-10', name: 'small-truck' },
    ];
    const message = (
        <FormattedMessage id={'e4c.history.nothingSelected.message'}>
            {(content): JSX.Element => <div className="text-color-gray">{content}</div>}
        </FormattedMessage>
    );

    return (
        <InformativeState
            headline={<FormattedMessage id={'e4c.history.nothingSelected.title'} />}
            message={message}
            icons={icons}
            verticalAlign={props.verticalAlign}
            testId="nothing-selected-state"
        />
    );
};
