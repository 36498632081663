import ElectricVehicle from '../models/ElectricVehicle';
import AssetInfo from '../models/AssetInfo';
import VehicleType from '../../common/vehicles/VehicleType';
import ChargingState from '../models/ChargingState';
import { ChargingStatusEnum } from '../types';
import VehicleModel from '../models/enums/VehicleModel';
import { ProductVersion } from '../../common/vehicles/productVersion';
import Measurement from '../models/Measurement';
import MeasurementType from '../models/enums/MeasurementType';

export const mapStateJsonToElectricVehicleArray = (items: Object[]): ElectricVehicle[] => {
    return items.map((item: any): ElectricVehicle => {
        return new ElectricVehicle(
            item.asset_id,
            ProductVersion.EMANAGER_S,
            false,
            new AssetInfo(item.last_updated, item.vin, item.name, VehicleType.BUS, VehicleModel.E4C),
            mapStateJsonToMeasurementArray(item),
            item.last_updated,
            mapStateJsonToChargingState(item)
        );
    });
};

const mapStateJsonToMeasurementArray = (item: any): Measurement[] => {
    const measurements = [
        new Measurement(MeasurementType.STATE_OF_CHARGE, item.last_updated, Math.round(item.state_of_charge)),
    ];
    if (item.remaining_range !== null) {
        measurements.push(
            new Measurement(MeasurementType.REMAINING_RANGE, item.last_updated, Math.floor(item.remaining_range))
        );
    }
    if (item.charging_status !== null) {
        measurements.push(new Measurement(MeasurementType.CHARGING_STATE, item.last_updated, item.charging_status));
    }
    return measurements;
};

const mapStateJsonToChargingState = (item: any): ChargingState => {
    const readyToDriveStatus =
        item.charging_status === ChargingStatusEnum.DISCONNECTED ? '' : item.ready_to_drive_status;

    return new ChargingState(
        item.charging_status,
        item.charging_until,
        item.delayed_charging_until,
        readyToDriveStatus,
        item.ready_to_drive_until
    );
};
