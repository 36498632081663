import ConfigItem, { ConfigItemProps } from './ConfigItem';
import { useIntl } from 'react-intl';

interface ConfigSectionProps {
    configItems: ConfigItemProps[];
}

const ConfigSection = ({ configItems }: ConfigSectionProps): JSX.Element => {
    const intl = useIntl();

    return (
        <div className="display-flex flex-column">
            <p className={'text-size-14 text-medium margin-0 margin-top-10'}>
                {intl.formatMessage({ id: 'e4c.overview.events.widget.settings.availableEvents' })}
            </p>
            <p className={'text-size-12 text-normal margin-0 margin-y-10'}>
                {intl.formatMessage({ id: 'e4c.overview.events.widget.settings.availableEventsDescription' })}
            </p>
            {configItems?.map((configItem: ConfigItemProps) => (
                <div data-testid="config-section-item" key={configItem.eventName} style={{ marginBottom: '8px' }}>
                    <ConfigItem {...configItem} />
                </div>
            ))}
        </div>
    );
};

export default ConfigSection;
