import SortDirection from './SortDirection';
import _ from 'lodash';

abstract class Sorter<Type> {
    private _direction: SortDirection;

    protected constructor(direction: SortDirection) {
        this._direction = direction;
    }

    abstract sort: (array: Type[], direction: SortDirection) => Type[];

    get direction(): SortDirection {
        return this._direction;
    }

    set direction(value: SortDirection) {
        this._direction = value;
    }

    /**
     * This method calls the abstract sort function that will apply
     * the sort rule on the given array using the instance direction
     *
     * @param array collection to sort
     */
    of(array: Type[]): Type[] {
        const sorted = this.sort(array, this.direction);
        _.remove(array);
        array.push(...sorted);
        return array;
    }
}

export default Sorter;
