import CollapsedHeaderRowItem from './CollapsedHeaderRowItem';
import { CollapsedHeaderRowProps } from '../../consumptionHistoryTable/types';

const CollapsedHeaderRow = (props: CollapsedHeaderRowProps): JSX.Element => {
    const { collapsedItems, cycle, locale, renderColumnValue, tableColumns, tableColumnsWithRightSeparator } = props;
    return (
        <>
            <tr key={cycle.startDateTime} className={`${collapsedItems}`}>
                {tableColumns.map((column, index) => {
                    return (
                        <CollapsedHeaderRowItem
                            column={column}
                            cycle={cycle}
                            key={index}
                            locale={locale}
                            renderColumnValue={renderColumnValue}
                            tableColumnsWithRightSeparator={tableColumnsWithRightSeparator}
                        />
                    );
                })}
            </tr>
        </>
    );
};

export default CollapsedHeaderRow;
