import { useEffect, useState } from 'react';

interface PageSelectorProps {
    numberOfPages: number;
    selectedPage: number;
    onSelectionChanges: (page: number) => void;
}

const PageSelector = (props: PageSelectorProps): JSX.Element => {
    const { numberOfPages, onSelectionChanges } = props;
    const [selectedPage, setSelectedPage] = useState<number>(props.selectedPage);

    useEffect(() => {
        if (selectedPage > numberOfPages) {
            setSelectedPage(numberOfPages);
        }
    }, [numberOfPages, selectedPage]);

    const onSelectPage = (value: number): void => {
        if (selectedPage !== value) {
            setSelectedPage(value);
            onSelectionChanges(value);
        }
    };

    const renderPages = (): JSX.Element[] => {
        return [...Array(numberOfPages).keys()].map(i => {
            const page = i + 1;
            return (
                <li
                    key={page}
                    onClick={(): void => onSelectPage(page)}
                    className={page === selectedPage ? 'active' : ''}
                >
                    <span>{page}</span>
                </li>
            );
        });
    };

    return (
        <div className={'form-group position-absolute left-0 margin-0 width-100pct'}>
            <nav>
                <ul className="pagination margin-0 display-flex justify-content-center align-items-center">
                    <li onClick={(): void => onSelectPage(1)} className={selectedPage === 1 ? 'disabled' : ''}>
                        <a aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    {renderPages()}
                    <li
                        onClick={(): void => onSelectPage(numberOfPages)}
                        className={selectedPage === numberOfPages ? 'disabled' : ''}
                    >
                        <a aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default PageSelector;
