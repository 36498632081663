import { FormattedNumber } from 'react-intl';

type CollapsedMileageProps = {
    sumMileages: number | null;
};

const CollapsedMileage = (props: CollapsedMileageProps): JSX.Element => {
    return (
        <>
            <span data-testid="mileage" className="ellipsis-1 display-block">
                <span className={'rioglyph rioglyph-road text-size-20 text-color-gray margin-right-10'} />
                {props.sumMileages ? (
                    <span>
                        <FormattedNumber value={props.sumMileages} maximumFractionDigits={0} /> km
                    </span>
                ) : (
                    <span>-</span>
                )}
            </span>
        </>
    );
};

export default CollapsedMileage;
