import { useIntl } from 'react-intl';
import { format, isSameDay, subDays } from 'date-fns';
import { AssetsChargeStatsByDate } from '../types';
import { VehicleExpanderList } from './VehicleExpanderList';

const DateFormatted = ({ date }: { date: string }) => {
    const intl = useIntl();

    // Convert to UTC by subtracting the timezone offset
    const parsedDate = new Date(Date.parse(date));
    const inputDate = new Date(parsedDate.getTime() + parsedDate.getTimezoneOffset() * 60000);

    const currentDate = new Date();

    const isToday = isSameDay(inputDate, currentDate);
    const isYesterday = isSameDay(inputDate, subDays(currentDate, 1));

    let relativeTime = '';
    if (isToday) {
        relativeTime = `${intl.formatMessage({ id: 'e4c.history.dateToday' })}, `;
    } else if (isYesterday) {
        relativeTime = `${intl.formatMessage({ id: 'e4c.history.dateYesterday' })}, `;
    }

    const formattedDate = `${relativeTime}${format(inputDate, 'dd/MM/yyyy')}`;

    return <div className="text-size-18 margin-bottom-10">{formattedDate}</div>;
};

export const VehicleListDateWrapper = ({
    assetsChargeStatsByDate,
}: {
    assetsChargeStatsByDate: AssetsChargeStatsByDate;
}) => {
    const { date, assetsChargeStats } = assetsChargeStatsByDate;

    return (
        <>
            <DateFormatted date={date} />
            <VehicleExpanderList assetsChargeStats={assetsChargeStats} />
        </>
    );
};
