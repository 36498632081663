import Select from '@rio-cloud/rio-uikit/Select';
import { useIntl } from 'react-intl';
import { supportedLocaleMap } from '../../../../../../../../configuration/lang/lang';

export interface SelectOptions {
    id: string;
    label: string;
    selected: boolean;
}

export interface LanguageSelectorProps {
    handleChange: Function;
    error?: boolean;
    languageSelected?: string;
    setError: Function;
}

const LanguageSelector = ({ handleChange, error, languageSelected, setError }: LanguageSelectorProps) => {
    const intl = useIntl();

    const handleLanguageChange = (language?: SelectOptions) => {
        if (language) {
            setError(false);
            handleChange(language);
        }
    };

    const generateSelectOptions = () => {
        const allSupportedLanguages = { ...supportedLocaleMap, en: 'en-GB' };
        return Object.entries(allSupportedLanguages)
            .reduce((optionsList, [key, value]: [string, string]) => {
                if (!key.includes('-')) {
                    optionsList.push({
                        id: value,
                        label: intl.formatMessage({
                            id: `e4c.global.language.locale.${value}`,
                        }),
                        selected: value === languageSelected,
                    });
                }
                return optionsList;
            }, [] as SelectOptions[])
            .sort((a, b) => a.label.localeCompare(b.label, 'en', { sensitivity: 'base' }));
    };

    const selectLanguageText = intl.formatMessage({
        id: 'e4c.overview.events.widget.settings.critical.sms.selectLanguage',
    });

    return (
        <div
            className={`flex-1-1 padding-right-15 form-group ${error ? 'has-error has-feedback' : ''}`}
            data-testid="language-selector"
        >
            <div>
                <label>{selectLanguageText}</label>
                <span className="text-color-dark">*</span>
            </div>
            <Select
                placeholder={selectLanguageText}
                options={generateSelectOptions()}
                onChange={handleLanguageChange}
                className="test-className"
                btnClassName="test-btnClassName"
                dropdownClassName="test-dropdownClassName"
            />
            {error && (
                <span data-testid="language-selector-error" className="help-block">
                    <span>
                        {intl.formatMessage({
                            id: 'e4c.overview.events.widget.settings.critical.sms.validation.language',
                        })}
                    </span>
                </span>
            )}
        </div>
    );
};

export default LanguageSelector;
