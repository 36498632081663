import { FormattedMessage } from 'react-intl';
import FleetType from './models/enums/FleetType';

export const mixedFleetAllowedColumns = [
    'name',
    'chargingStatus',
    'stateOfCharge',
    'remainingRange',
    'configuration',
    'readyToDriveStatus',
    'timestamp',
];

export const etgeOnlyFleetAllowedColumns = ['name', 'chargingStatus', 'stateOfCharge', 'remainingRange', 'timestamp'];

export const initialAllowedColumns = mixedFleetAllowedColumns;

export const initialColumnLabels = {
    name: <FormattedMessage id={'e4c.vehicles.vehicle'} />,
    chargingStatus: <FormattedMessage id={'e4c.vehicles.chargingStatus'} />,
    stateOfCharge: <FormattedMessage id={'e4c.vehicles.stateOfCharge'} />,
    remainingRange: <FormattedMessage id={'e4c.vehicles.remainingCapacity'} />,
    configuration: <FormattedMessage id={'e4c.vehicles.configuration'} />,
    readyToDriveStatus: <FormattedMessage id={'e4c.vehicles.vehicleStatus'} />,
    timestamp: <FormattedMessage id={'e4c.vehicles.timestamp'} />,
};

export const initialColumnOrder = [
    'name',
    'chargingStatus',
    'stateOfCharge',
    'remainingRange',
    'configuration',
    'readyToDriveStatus',
    'timestamp',
];

export const initialDisabledColumns = ['name', 'chargingStatus', 'timestamp'];

export const initialHiddenColumns = [];

export const initialFleetType = FleetType.MIXED;
