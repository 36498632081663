import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { buildQuery } from './SeeConsumptionHistoryLinkHelper';
import { Moment } from 'moment';
import { createGoogleAnalyticsEvent, gtmUtil, TrackingAction, TrackingCategory } from '../../../utils/googleTagManager';
import { CHARTS } from '../../../common/constants/common';
import { HISTORY_PATH } from '../../../../../configuration/setup/path';

interface SeeConsumptionHistoryLinkProps {
    assetIds: string[];
    currentEndDate: Moment;
    currentStartDate: Moment;
    localStoragePrefix: string;
}

const processCorrectGAEvent = (chartPosition: string): TrackingAction =>
    chartPosition === CHARTS.left
        ? TrackingAction.CHART_ONE_WIDGET_LINK_TO_HISTORY
        : TrackingAction.CHART_TWO_WIDGET_LINK_TO_HISTORY;

const SeeConsumptionHistoryLink = (props: SeeConsumptionHistoryLinkProps): JSX.Element => {
    const { assetIds, currentEndDate, currentStartDate, localStoragePrefix } = props;

    const history = useHistory();

    const handleClick = () => {
        const trackingAction = processCorrectGAEvent(localStoragePrefix);
        gtmUtil.pushGaEvent(createGoogleAnalyticsEvent(TrackingCategory.OVERVIEW_TAB, trackingAction));
    };

    return (
        <Link
            to={{
                pathname: HISTORY_PATH,
                search: buildQuery(history, currentEndDate, currentStartDate, assetIds)?.toString(),
            }}
            onClick={handleClick}
            target={'_blank'}
        >
            <FormattedMessage id={'e4c.overview.charts.link'} />
        </Link>
    );
};

export default SeeConsumptionHistoryLink;
