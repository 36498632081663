import StatsWidget from '@rio-cloud/rio-uikit/StatsWidget';
import StatsWidgets from '@rio-cloud/rio-uikit/StatsWidgets';
import SkeletonLoader from '../../../common/components/Skeleton/SkeletonLoader';

const SkeletonStatusVehicleTable = (): JSX.Element => {
    return (
        <StatsWidgets>
            <StatsWidget className="width-20pct">
                <StatsWidget.Header>
                    <div style={{ height: '52px' }} data-testid="skeleton-stats-failure">
                        <SkeletonLoader>
                            <circle cx="50%" cy="21" r="18" />
                        </SkeletonLoader>
                    </div>
                </StatsWidget.Header>
                <StatsWidget.Body>
                    <div style={{ height: '64px' }}>
                        <SkeletonLoader>
                            <rect x="26%" y="0" rx="0" ry="0" width="30" height="30" />
                            <rect x="22%" y="53" rx="0" ry="0" width="50" height="9" />
                            <rect x="62%" y="0" rx="0" ry="0" width="30" height="30" />
                            <rect x="58%" y="53" rx="0" ry="0" width="50" height="9" />
                        </SkeletonLoader>
                    </div>
                </StatsWidget.Body>
            </StatsWidget>
            <StatsWidget className="width-20pct">
                <StatsWidget.Header>
                    <div style={{ height: '52px' }} data-testid="skeleton-stats-charging">
                        <SkeletonLoader>
                            <circle cx="50%" cy="21" r="18" />
                        </SkeletonLoader>
                    </div>
                </StatsWidget.Header>
                <StatsWidget.Body>
                    <div style={{ height: '64px' }}>
                        <SkeletonLoader>
                            <rect x="26%" y="0" rx="0" ry="0" width="30" height="30" />
                            <rect x="22%" y="53" rx="0" ry="0" width="50" height="9" />
                            <rect x="62%" y="0" rx="0" ry="0" width="30" height="30" />
                            <rect x="58%" y="53" rx="0" ry="0" width="50" height="9" />
                        </SkeletonLoader>
                    </div>
                </StatsWidget.Body>
            </StatsWidget>
            <StatsWidget className="width-10pct">
                <StatsWidget.Header>
                    <div style={{ height: '52px' }} data-testid="skeleton-stats-connected">
                        <SkeletonLoader>
                            <circle cx="50%" cy="21" r="18" />
                        </SkeletonLoader>
                    </div>
                </StatsWidget.Header>
                <StatsWidget.Body>
                    <div style={{ height: '64px' }}>
                        <SkeletonLoader>
                            <rect x="44%" y="0" rx="0" ry="0" width="30" height="30" />
                            <rect x="36%" y="53" rx="0" ry="0" width="70" height="9" />
                        </SkeletonLoader>
                    </div>
                </StatsWidget.Body>
            </StatsWidget>
            <StatsWidget className="width-10pct">
                <StatsWidget.Header>
                    <div style={{ height: '52px' }} data-testid="skeleton-stats-disconnect">
                        <SkeletonLoader>
                            <circle cx="50%" cy="21" r="18" />
                        </SkeletonLoader>
                    </div>
                </StatsWidget.Header>
                <StatsWidget.Body>
                    <div style={{ height: '64px' }}>
                        <SkeletonLoader>
                            <rect x="44%" y="0" rx="0" ry="0" width="30" height="30" />
                            <rect x="36%" y="53" rx="0" ry="0" width="70" height="9" />
                        </SkeletonLoader>
                    </div>
                </StatsWidget.Body>
            </StatsWidget>
        </StatsWidgets>
    );
};

export default SkeletonStatusVehicleTable;
