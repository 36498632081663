import ElectricVehicle from './models/ElectricVehicle';
import AssetInfo from './models/AssetInfo';
import Measurement from './models/Measurement';
import ChargingState from './models/ChargingState';
import VehicleType from '../common/vehicles/VehicleType';
import MeasurementType from './models/enums/MeasurementType';
import ChargingStatus from './models/enums/ChargingStatus';
import VehicleModel from './models/enums/VehicleModel';
import moment from 'moment';

export const mergeVehicles = (
    existingVehicles: ElectricVehicle[],
    newVehicles: ElectricVehicle[],
    merge: ((destination: ElectricVehicle, source: ElectricVehicle) => ElectricVehicle) | undefined
): ElectricVehicle[] => {
    const mergedData = new Array(...existingVehicles);
    newVehicles.forEach(newVehicle => {
        const index = mergedData.findIndex(v => v.assetId === newVehicle.assetId);
        const existingVehicle = mergedData[index];
        if (existingVehicle && merge) {
            const mergedVehicle = merge(existingVehicle, newVehicle);
            mergedData.splice(index, 1, mergedVehicle);
        } else {
            mergedData.push(newVehicle);
        }
    });
    return mergedData;
};

/**
 * This function is not called without existingData
 * @param existingData
 * @param monitoringServiceData
 */
export const mergeWithMonitoringData = (
    existingData: ElectricVehicle,
    monitoringServiceData: ElectricVehicle
): ElectricVehicle => {
    const measurements = mergeMeasurementsWithMonitoringData(
        existingData.measurements,
        monitoringServiceData.measurements
    );
    return new ElectricVehicle(
        existingData.assetId,
        monitoringServiceData.productVersion,
        monitoringServiceData.hasMinimalDatenset,
        mergeAssetInfoWithMonitoringData(existingData.assetInfo, monitoringServiceData.assetInfo),
        measurements,
        mergeMeasurementsLastUpdatedWithMonitoringData(existingData.measurementsLastUpdated, monitoringServiceData),
        mergeChargingStateWithMonitoringData(existingData, monitoringServiceData)
    );
};

/**
 * This function does a simple comparison to choose which date will taken.
 * In the future we know that the last measurement always comes from monitoring service.
 * If the vehicle is an E4C, we have to check if the last_updated coming from state-service is
 * more recent than the lastMeasurementsUpdated from monitoring-service. ex: If the user has
 * just updated the timerCharging config, this scenario will happen.
 */
const mergeMeasurementsLastUpdatedWithMonitoringData = (
    currentMeasurementsLastUpdated: string | undefined | null,
    monitoringServiceData: ElectricVehicle
): string | undefined | null => {
    const validModels = [VehicleModel.E4C, VehicleModel.ELSA, VehicleModel.TRUE];

    if (
        validModels.includes(monitoringServiceData.assetInfo.vehicleModel) &&
        moment(monitoringServiceData.measurementsLastUpdated).isBefore(currentMeasurementsLastUpdated)
    ) {
        return currentMeasurementsLastUpdated;
    }
    return monitoringServiceData.measurementsLastUpdated !== null
        ? monitoringServiceData.measurementsLastUpdated
        : currentMeasurementsLastUpdated;
};

const mergeAssetInfoWithMonitoringData = (existingAssetInfo: AssetInfo, newAssetInfo: AssetInfo): AssetInfo => {
    return new AssetInfo(
        newAssetInfo.modifiedAt,
        existingAssetInfo.vin.length === 0 ? newAssetInfo.vin : existingAssetInfo.vin,
        existingAssetInfo.name.length === 0 ? newAssetInfo.name : existingAssetInfo.name,
        newAssetInfo.type,
        newAssetInfo.vehicleModel
    );
};

/**
 * This function does a simple comparison to choose which measurements will take.
 * In the future we know that the last measurement always comes from monitoring service.
 */
const mergeMeasurementsWithMonitoringData = (
    existingMeasurements: Measurement[],
    newMeasurements: Measurement[]
): Measurement[] => {
    const desiredMeasurements: Measurement[] = [];
    const newMeasurementTypes = newMeasurements.map(m => m.type);
    const measurementsNotPresentInNewMeasurements = existingMeasurements.filter(
        m => !newMeasurementTypes.includes(m.type)
    );
    newMeasurements.forEach(measurement => desiredMeasurements.push(measurement));
    measurementsNotPresentInNewMeasurements.forEach(measurement => desiredMeasurements.push(measurement));
    return desiredMeasurements;
};

const mergeChargingStateWithMonitoringData = (
    existingData: ElectricVehicle,
    monitoringServiceData: ElectricVehicle
): ChargingState | undefined => {
    const chargingStatus = getPreferredChargingStatusData(existingData, monitoringServiceData);
    return new ChargingState(
        chargingStatus,
        existingData.chargingState?.chargingUntil,
        existingData.chargingState?.delayedChargingUntil,
        existingData.chargingState?.readyToDriveStatus,
        existingData.chargingState?.readyToDriveUntil
    );
};

const getPreferredChargingStatusData = (
    existingData: ElectricVehicle,
    monitoringServiceData: ElectricVehicle
): ChargingStatus | undefined => {
    const hasNoMeasurement =
        monitoringServiceData.measurements.find(m => m.type === MeasurementType.CHARGING_STATE) === undefined;

    return hasNoMeasurement && existingData.chargingState?.chargingStatus !== undefined
        ? existingData.chargingState?.chargingStatus
        : monitoringServiceData.chargingState?.chargingStatus;
};

/**
 * This function is not called without existingData
 * @param existingData
 * @param stateServiceData
 */
export const mergeWithStateData = (
    existingData: ElectricVehicle,
    stateServiceData: ElectricVehicle
): ElectricVehicle => {
    return new ElectricVehicle(
        existingData.assetId,
        existingData.productVersion,
        false,
        existingData.assetInfo,
        existingData.measurements,
        stateServiceData.measurementsLastUpdated,
        mergeChargingStateWithStateData(existingData, stateServiceData)
    );
};

const mergeChargingStateWithStateData = (
    existingData: ElectricVehicle,
    stateServiceData: ElectricVehicle
): ChargingState | undefined => {
    if (existingData.assetInfo.type === VehicleType.VAN) {
        return existingData.chargingState;
    } else {
        return stateServiceData.chargingState;
    }
};
