import { AccessToken } from '../configuration';
import { config } from '../config';

interface FetchProps {
    url: string;
    accessToken: AccessToken;
}

const fetchWithAuthorization = async ({ url, accessToken }: FetchProps): Promise<Response> => {
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    return response;
};

export const fetchAssets = async (accessToken: AccessToken): Promise<Response> => {
    const url = `${config.backend.ASSET_ADMINISTRATION_SERVICE}/assets?embed=(tags)`;
    return fetchWithAuthorization({ url, accessToken });
};

export const fetchTags = async (accessToken: AccessToken): Promise<Response> => {
    const url = `${config.backend.TAG_SERVICE}/tags`;
    return fetchWithAuthorization({ url, accessToken });
};
