import { ReactElement } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { itemsObj } from './UserManualItems';
import VehicleChargingItem from './items/VehicleChargingItem';

const InfoItem = (): ReactElement => {
    const history = useHistory();
    const {
        location: { pathname, search },
    } = history;
    const infoItem = itemsObj.find(item => search.endsWith(item.search));
    return (
        <Switch>
            <Route path={pathname} component={infoItem ? infoItem.component : VehicleChargingItem} />
        </Switch>
    );
};

export default InfoItem;
