import { FormattedMessage } from 'react-intl';
import WeekdayIntlMapper from '../../../../../../common/enums/intl/WeekdayIntlMapper';
import { Weekday } from '../../../vehicleDetails/types';

interface WeekdaySelectorButtonProps {
    isEnabled: boolean;
    isSelected: boolean;
    onClick: () => void;
    weekday: Weekday;
}

const SELECTED_CLASSES = 'btn btn-default text-size-12 active text-bold';
const DEFAULT_CLASSES = 'btn btn-default text-size-12 border-color-gray text-color-darker hover-text-color-white';

const WeekdaySelectorButton = (props: WeekdaySelectorButtonProps): JSX.Element => {
    const { isEnabled, isSelected, onClick, weekday } = props;
    return (
        <button
            disabled={!isEnabled}
            type="button"
            className={isSelected ? SELECTED_CLASSES : DEFAULT_CLASSES}
            style={{ width: '39px', height: '33px' }}
            onMouseDown={e => {
                /**
                 * Listening to onMouseDown event and calling preventDefault method
                 * on the element prevents focus state to be activated.
                 * This solution was chosen to avoid custom CSS since we use UIKit classes.
                 */
                e.preventDefault();
                onClick();
            }}
        >
            <FormattedMessage id={WeekdayIntlMapper.map(weekday)} />
        </button>
    );
};

export default WeekdaySelectorButton;
