import classNames from 'classnames';
import { CollapsedHeaderRowItemProps } from '../../consumptionHistoryTable/types';

const CollapsedHeaderRowItem = (props: CollapsedHeaderRowItemProps): JSX.Element => {
    const { column, cycle, locale, renderColumnValue, tableColumnsWithRightSeparator } = props;
    const tdClassNames = classNames(
        'height-60',
        tableColumnsWithRightSeparator.includes(column)
            ? 'border-width-1 border-style-solid border-color-lighter border-top-none border-bottom-none border-left-none'
            : ''
    );
    return (
        <td key={column} data-field={column} className={tdClassNames}>
            {renderColumnValue(column, cycle, locale)}
        </td>
    );
};

export default CollapsedHeaderRowItem;
