import VehiclesToolbar from '../VehiclesToolbar/VehiclesToolbar';
import { FilterWidget } from '../FilterWidget/FilterWidget';
import { saveColumnsDetailsInLocalStorage } from '../../localStorage';
import { useSelector } from 'react-redux';
import { State } from '../../../../../types';
import { getChargingStatusFilterValue, getVehiclesSearchValue } from '../../../selectors/vehicles/Vehicles.selectors';
import useTableSettings from './hooks/useTableSettings';
import { intersection } from 'lodash';
import { VehicleChargingData } from '../../models/VehicleChargingData';
import { filterRowsBySearchAndTreeAsset, filterRowsByChargingStatus } from '../../helpers/table/VehiclesRowHelper';
import VehiclesTable from './VehiclesTable';

const VehiclesBody = ({
    vehicleChargingData,
    selectedVehicleIds,
    isLoading,
}: {
    vehicleChargingData: VehicleChargingData[];
    selectedVehicleIds: string[];
    isLoading: boolean;
}): JSX.Element => {
    const {
        allowedColumns,
        columnLabels,
        columnOrder,
        defaultColumnOrder,
        hiddenColumns,
        setColumnOrder,
        setHiddenColumns,
    } = useTableSettings();

    const searchValue = useSelector((state: State) => getVehiclesSearchValue(state));
    const filterByChargingStatusWidget = useSelector((state: State) => getChargingStatusFilterValue(state));

    const handleColumnsChange = (order: string[], hidden: string[]): void => {
        const filteredColumnOrder = intersection(order, allowedColumns);
        const filteredHiddenColumns = intersection(hidden, allowedColumns);
        saveColumnsDetailsInLocalStorage(filteredColumnOrder, filteredHiddenColumns);
        setColumnOrder(filteredColumnOrder);
        setHiddenColumns(filteredHiddenColumns);
    };

    const filteredVehicles: VehicleChargingData[] = filterRowsBySearchAndTreeAsset(
        vehicleChargingData,
        selectedVehicleIds,
        searchValue
    );

    return (
        <>
            <VehiclesToolbar
                columnLabels={columnLabels}
                columnOrder={columnOrder}
                defaultColumnOrder={defaultColumnOrder}
                hiddenColumns={hiddenColumns}
                onColumnsChange={handleColumnsChange}
            />
            <FilterWidget
                filterByChargingStatusWidget={filterByChargingStatusWidget}
                vehicles={filteredVehicles}
                isLoading={isLoading}
            />
            <VehiclesTable
                vehicles={filterRowsByChargingStatus(filteredVehicles, filterByChargingStatusWidget)}
                isLoading={isLoading}
                columnOrder={columnOrder}
                hiddenColumns={hiddenColumns}
            />
        </>
    );
};

export default VehiclesBody;
