import moment from 'moment';

const getStartDate = (): string => {
    return moment().subtract(1, 'years').format('YYYY-MM-DD');
};

const getDefaultStartDate = (): string => {
    return moment().subtract(14, 'days').startOf('days').format('YYYY-MM-DD');
};

const getEndDate = (): string => {
    return moment().format('YYYY-MM-DD');
};

const buildUrlWithDates = (endpoint: string, startDate?: any, endDate?: any): string => {
    if (startDate) {
        endpoint = `${endpoint}start_date=${startDate.format('YYYY-MM-DD')}`;
    } else {
        endpoint = `${endpoint}start_date=${getDefaultStartDate()}`;
    }
    if (endDate) {
        endpoint = `${endpoint}&end_date=${endDate.format('YYYY-MM-DD')}`;
    } else {
        endpoint = `${endpoint}&end_date=${getEndDate()}`;
    }
    return endpoint;
};

export { buildUrlWithDates, getDefaultStartDate, getEndDate, getStartDate };
