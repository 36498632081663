import Sorter from '../../common/utilities/sort/Sorter';
import SortDirection from '../../common/utilities/sort/SortDirection';
import { VehicleChargingData } from '../models/VehicleChargingData';

abstract class AbstractElectricVehiclesSorter extends Sorter<VehicleChargingData> {
    private readonly _key: string;

    constructor(key: string) {
        super(SortDirection.ASCENDING);
        this._key = key;
    }

    get key(): string {
        return this._key;
    }
}

export default AbstractElectricVehiclesSorter;
