import { ThunkDispatch } from 'redux-thunk';
import { OverviewPropertiesFromDispatch, OverviewPropertiesFromState } from './types';
import { AccessToken, getAccessToken } from '../../../../configuration';
import { fetchElectricVehicles } from '../../actions/vehicles/Vehicles.actions';
import { State } from '../../../../types';
import { getElectricVehicles, isFetchingElectricVehicles } from '../../selectors/vehicles/Vehicles.selectors';
import { withRouter } from 'react-router';
import Overview from '../Overview';
import { connect } from 'react-redux';
import { fetchVehiclesStatistics } from '../actions/statistics/VehiclesStatistics.actions';
import {
    getFetchingVehiclesStatistics,
    getVehiclesStatistics,
} from '../selectors/statistics/VehiclesStatistics.selectors';
import { getElectricVehiclesListFromServices } from '../../vehicleCharging/selectors/selectors';
import { VehicleThunkAction } from '../../actions/vehicles/types';
import { VehiclesStatisticsThunkAction } from '../actions/statistics/types';

const mapDispatchToProps = (dispatch: ThunkDispatch<null, undefined, any>): OverviewPropertiesFromDispatch => {
    return {
        fetchElectricVehicles: (accessToken: AccessToken): VehicleThunkAction<void> =>
            dispatch(fetchElectricVehicles(accessToken)),
        fetchVehiclesStatistics: (accessToken: AccessToken): VehiclesStatisticsThunkAction<void> =>
            dispatch(fetchVehiclesStatistics(accessToken)),
    };
};

const mapStateToProps = (state: State): OverviewPropertiesFromState => {
    return {
        accessToken: getAccessToken(state),
        fetchingElectricVehicles: isFetchingElectricVehicles(state),
        electricVehicles: getElectricVehicles(state),
        fetchingVehiclesStatistics: getFetchingVehiclesStatistics(state),
        vehiclesStatistics: getVehiclesStatistics(state),
        electricVehiclesFromServices: getElectricVehiclesListFromServices(state),
    };
};

const OverviewContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(Overview));

export default OverviewContainer;
