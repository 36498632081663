import StatsWidget from '@rio-cloud/rio-uikit/StatsWidget';
import StatsWidgets from '@rio-cloud/rio-uikit/StatsWidgets';
import SkeletonLoader from '../../common/components/Skeleton/SkeletonLoader';

const SkeletonStatusPanel = ({ isM }: { isM?: boolean }): JSX.Element => {
    return (
        <SkeletonLoader>
            <circle cx="30" cy="20" r="18" />
            <rect x="15" y="55" rx="0" ry="0" width="30" height="30" />
            <rect x="10" y="110" rx="0" ry="0" width="70" height="9" />
            {isM ? <rect x="10" y="130" rx="0" ry="0" width="110" height="5" /> : <></>}
        </SkeletonLoader>
    );
};

const SkeletonTwoStatusPanel = (): JSX.Element => {
    return (
        <SkeletonLoader>
            <circle cx="60" cy="20" r="18" />
            <rect x="10" y="55" rx="0" ry="0" width="30" height="30" />
            <rect x="0" y="110" rx="0" ry="0" width="50" height="9" />
            <rect x="80" y="55" rx="0" ry="0" width="30" height="30" />
            <rect x="70" y="110" rx="0" ry="0" width="50" height="9" />
        </SkeletonLoader>
    );
};

export const SkeletonChargingPanel = (): JSX.Element => {
    return (
        <StatsWidgets className="padding-none">
            <StatsWidget className="border-none align-items-center width-10pct">
                <StatsWidget.Body>
                    <SkeletonStatusPanel />
                </StatsWidget.Body>
            </StatsWidget>
            <div className="StatsWidget-body-column-spacer" />
            <StatsWidget className="border-none align-items-center width-20pct">
                <StatsWidget.Body>
                    <SkeletonTwoStatusPanel />
                </StatsWidget.Body>
            </StatsWidget>
            <div className="StatsWidget-body-column-spacer" />
            <StatsWidget className="border-none align-items-center width-10pct">
                <StatsWidget.Body>
                    <SkeletonStatusPanel />
                </StatsWidget.Body>
            </StatsWidget>
            <div className="StatsWidget-body-column-spacer" />
            <StatsWidget className="border-none align-items-center width-10pct">
                <StatsWidget.Body>
                    <SkeletonStatusPanel />
                </StatsWidget.Body>
            </StatsWidget>
            <div className="StatsWidget-body-column-spacer" />
            <StatsWidget className="border-none align-items-center width-10pct">
                <StatsWidget.Body>
                    <SkeletonStatusPanel isM />
                </StatsWidget.Body>
            </StatsWidget>
        </StatsWidgets>
    );
};

export const SkeletonVehicleStatusPanel = (): JSX.Element => {
    return (
        <StatsWidgets>
            <StatsWidget className="border-top-0 border-bottom-0 border-left-0 align-items-center">
                <StatsWidget.Body>
                    <div style={{ width: '150px', margin: 'auto' }} data-testid="vehicle-panel-skeleton-m">
                        <SkeletonStatusPanel isM />
                    </div>
                </StatsWidget.Body>
            </StatsWidget>
            <div className="StatsWidget-body-column-spacer" />
            <StatsWidget className="border-top-0 border-bottom-0 border-left-0 align-items-center">
                <div style={{ width: '150px', margin: 'auto' }} data-testid="vehicle-panel-skeleton">
                    <SkeletonStatusPanel />
                </div>
            </StatsWidget>
        </StatsWidgets>
    );
};
