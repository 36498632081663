import { SyntheticEvent } from 'react';
import { useIntl } from 'react-intl';
import Button from '@rio-cloud/rio-uikit/Button';
import HorizontalCard from '../../../../../../common/components/HorizontalCard';
import DeleteButton from './DeleteButton';
import LanguageSelector, { SelectOptions } from './LanguageSelector';
import ContactInput from './ContactInput';
import { ContactInfoSection } from './ContactSection';
import HorizontalButtons, { HorizontalButton } from '../../../../../../common/components/HorizontalButtons';
import InfoSection from './InfoSection';

export interface ContactFormProps {
    contacts: ContactInfoSection[];
    handleError: Function;
    handleChange: Function;
    handleAddContact: (event: SyntheticEvent<HTMLButtonElement, MouseEvent>) => void;
    handleDelete: Function;
    onSubmit: Function;
    onDiscard: Function;
    showButtons: boolean;
}

const ContactForm = ({
    contacts,
    handleError,
    handleChange,
    handleAddContact,
    handleDelete,
    onSubmit,
    onDiscard,
    showButtons,
}: ContactFormProps) => {
    const intl = useIntl();

    const buttons: HorizontalButton[] = [
        {
            className: 'btn-default',
            text: intl.formatMessage({ id: 'e4c.global.discardChanges' }),
            onClick: () => onDiscard(),
        },
        {
            className: 'btn-primary',
            text: intl.formatMessage({ id: 'e4c.global.saveChanges' }),
            onClick: () => onSubmit(),
        },
    ];

    return (
        <div className={'padding-bottom-25'}>
            {contacts?.length ? (
                <>
                    {contacts.map((value: ContactInfoSection, index: number) => (
                        <HorizontalCard
                            testId={`contact-form-${value.type.toLowerCase()}`}
                            key={index}
                            className="bg-lightest margin-bottom-10"
                        >
                            <div className="display-flex margin-bottom-5">
                                <div style={{ flexBasis: '50%' }}>
                                    <ContactInput
                                        handleChange={(phone: string) => handleChange('contact', index, phone)}
                                        error={!!value.contactError}
                                        setError={(errorValue: boolean) =>
                                            handleError('contactError', value.contact, errorValue)
                                        }
                                        contact={value.contact}
                                    />
                                </div>
                                <div style={{ flexBasis: '50%' }}>
                                    <LanguageSelector
                                        languageSelected={value.language}
                                        handleChange={(language: SelectOptions) =>
                                            handleChange('language', index, language.id)
                                        }
                                        error={!!value.languageError}
                                        setError={(errorValue: boolean) =>
                                            handleError('languageError', value.contact, errorValue)
                                        }
                                    />
                                </div>
                                <DeleteButton handleClick={() => handleDelete(value.contact)} />
                            </div>
                            <span className="text-color-dark text-size-12">
                                {intl.formatMessage({ id: 'e4c.overview.events.widget.settings.critical.sms.consent' })}
                            </span>
                        </HorizontalCard>
                    ))}
                    <InfoSection />
                </>
            ) : (
                <div data-testid="add-new-contact-button">
                    <Button onClick={handleAddContact}>
                        <span className="rioglyph rioglyph-plus-light"></span>
                        <span>
                            {intl.formatMessage({ id: 'e4c.overview.events.widget.settings.critical.sms.addPhone' })}
                        </span>
                    </Button>
                </div>
            )}
            {showButtons && (
                <div className="display-flex flex-column align-items-end margin-top-50">
                    <HorizontalButtons buttons={buttons} />
                </div>
            )}
        </div>
    );
};

export default ContactForm;
