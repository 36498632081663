import { useRef } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';
import OnboardingTip from '@rio-cloud/rio-uikit/OnboardingTip';
import useLocalStorage from '@rio-cloud/rio-uikit/useLocalStorage';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { createGoogleAnalyticsEvent, gtmUtil, TrackingAction, TrackingCategory } from '../../../utils/googleTagManager';
import TooltipRef from '../Tooltip/Tooltip';
import { addQueryParam } from '../../helpers/queryParametersHelper';
import { FEEDBACK_SEARCH_QUERY } from '../../constants/paths/paths';

const FeedbackButton = (): JSX.Element => {
    const history = useHistory();
    const intl = useIntl();
    const ref = useRef<HTMLDivElement>(null);
    const [onBoardingFeedback, setOnBoardingFeedback] = useLocalStorage<boolean>('onBoardingFeedback', false);

    const handleClickFeedback = () => {
        history.push({ search: addQueryParam(history.location.search, FEEDBACK_SEARCH_QUERY) });
        gtmUtil.pushGaEvent(createGoogleAnalyticsEvent(TrackingCategory.GENERAL, TrackingAction.FEEDBACK_BUTTON));
    };

    return (
        <ActionBarItem id="feedbackButton" className="myItem">
            <OnboardingTip
                show={!onBoardingFeedback}
                placement={'bottom-end'}
                width={300}
                title={intl.formatMessage({ id: 'e4c.feedbackForm.onboarding.title' })}
                content={intl.formatMessage({ id: 'e4c.feedbackForm.onboarding.content' })}
                onHide={() => setOnBoardingFeedback(true)}
                className="z-index-max margin-top--10"
            >
                <ActionBarItem.Icon className="myItem">
                    <TooltipRef
                        ref={ref}
                        dataTestId="tooltip-service-info"
                        tooltipContent={intl.formatMessage({ id: 'e4c.global.send.us.feedback' })}
                        placement={Tooltip.BOTTOM}
                    >
                        <span role="button" className="icon rioglyph rioglyph-comment" onClick={handleClickFeedback} />
                    </TooltipRef>
                </ActionBarItem.Icon>
            </OnboardingTip>
        </ActionBarItem>
    );
};

export default FeedbackButton;
