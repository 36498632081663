import _ from 'lodash';
import moment from 'moment';
import { VehicleStatistics } from '../../types';
import {
    arrayOfDatesInRange,
    arrayOfMonthsInRange,
    divideIfPositive,
    drivenDistanceThreshold,
    isCurrentMonthAndYear,
    overallAverageFromAveragePerDay,
} from './StatisticsHelpers';
import { AvgMileageChartData } from './types';

const buildAggregatedMileageObject = (
    maxNumberOfVehicles: number,
    month: string,
    numberOfDays: number,
    totalAverageMileage: number
): AvgMileageChartData => {
    return {
        date: month,
        averageMileage: divideIfPositive(totalAverageMileage, totalAverageMileage, numberOfDays),
        vehiclesQuantity: maxNumberOfVehicles,
    };
};

const averageMileageDaily = (vehiclesStatistics: VehicleStatistics[], date: string) => {
    return vehiclesStatistics.reduce(
        (acc, vehicle) => {
            const vehicleData = vehicle.history.find(data => data.date === date);
            if (vehicleData && vehicleData.drivenDistance && vehicleData.drivenDistance > drivenDistanceThreshold) {
                acc.totalDrivenDistance += vehicleData.drivenDistance;
                acc.numberOfVehicles++;
            }
            return acc;
        },
        {
            totalDrivenDistance: 0,
            numberOfVehicles: 0,
        }
    );
};

export const aggregatedAverageMileageDaily = (
    vehiclesStatistics: VehicleStatistics[],
    startDate: Date,
    endDate: Date
): AvgMileageChartData[] => {
    const datesInRange = arrayOfDatesInRange(startDate, endDate);
    return datesInRange.reduce((acc: AvgMileageChartData[], date) => {
        const { totalDrivenDistance, numberOfVehicles } = averageMileageDaily(vehiclesStatistics, date);

        acc.push({
            date,
            averageMileage: divideIfPositive(totalDrivenDistance, totalDrivenDistance, numberOfVehicles),
            vehiclesQuantity: numberOfVehicles,
        });

        return acc;
    }, []);
};

export const aggregatedAverageMileageMonthly = (
    vehiclesStatistics: VehicleStatistics[],
    startDate: Date,
    endDate: Date
): AvgMileageChartData[] => {
    const aggregatedAverage: AvgMileageChartData[] = [];
    const valuesCalculatedPerDayFromStartDateToEndDate = aggregatedAverageMileageDaily(
        vehiclesStatistics,
        startDate,
        endDate
    );
    const months = arrayOfMonthsInRange(startDate, endDate);
    let totalAverageMileagePerMonth = 0;
    let totalAverageMileage = 0;
    let numberOfDays = 0;
    let numberOfDaysInMonth = 0;
    let maxNumberOfVehiclesInMonth = 0;
    months.forEach(month => {
        const monthDate = moment(month);
        valuesCalculatedPerDayFromStartDateToEndDate
            .filter(object => isCurrentMonthAndYear(monthDate, moment(object.date)))
            .forEach(value => {
                if (value.averageMileage > 0) {
                    totalAverageMileage += value.averageMileage;
                    totalAverageMileagePerMonth += value.averageMileage;
                    numberOfDaysInMonth++;
                    numberOfDays++;
                }
                if (value.vehiclesQuantity > maxNumberOfVehiclesInMonth) {
                    maxNumberOfVehiclesInMonth = value.vehiclesQuantity;
                }
            });
        aggregatedAverage.push(
            buildAggregatedMileageObject(
                maxNumberOfVehiclesInMonth,
                month,
                numberOfDaysInMonth,
                totalAverageMileagePerMonth
            )
        );
        totalAverageMileagePerMonth = 0;
        numberOfDaysInMonth = 0;
        maxNumberOfVehiclesInMonth = 0;
    });
    aggregatedAverage.push(
        buildAggregatedMileageObject(maxNumberOfVehiclesInMonth, 'total', numberOfDays, totalAverageMileage)
    );
    return aggregatedAverage;
};

export const getMileageSummaryValue = (data: AvgMileageChartData[]): number => {
    const totalFromMileageMonths = data.find(rec => rec.date === 'total')?.averageMileage;
    let overallMileageAverage = 0;
    if (totalFromMileageMonths) {
        _.remove(data, rec => rec.date === 'total');
        overallMileageAverage = totalFromMileageMonths;
    } else {
        overallMileageAverage = overallAverageFromAveragePerDay(data.map(item => item.averageMileage));
    }
    return overallMileageAverage;
};
