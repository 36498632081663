import ChevronDownIcon from '../components/collapsed/ChevronDownIcon';
import CollapsedEnergyAdded from '../components/collapsed/CollapsedEnergyAdded';
import CollapsedEnergyConsumed from '../components/collapsed/CollapsedEnergyConsumed';
import CollapsedVehicleQuantity from '../components/collapsed/CollapsedVehicleQuantity';
import CollapsedDateCol from '../components/collapsed/CollapsedDateCol';
import CollapsedCurrent from '../components/collapsed/CollapsedCurrent';
import { CollapsedHeaderProps } from './types';
import CollapsedVoltage from '../components/collapsed/CollapsedVoltage';
import CollapsedBatteryTemperature from '../components/collapsed/CollapsedBatteryTemperature';

const CollapsedHeader = (props: CollapsedHeaderProps): JSX.Element => {
    const {
        maximumVoltage,
        maximumCurrent,
        sumEnergyAdded,
        sumEnergyConsumed,
        date,
        collapsedType,
        quantityOfVehicles,
        setKeysOpened,
        totalOfBatteryTemperatureWarnings,
    } = props;
    return (
        <>
            <td>
                <CollapsedDateCol date={date} />
            </td>
            <td>
                <CollapsedVehicleQuantity quantityOfVehicles={quantityOfVehicles} />
            </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td>
                <CollapsedEnergyConsumed sumEnergyConsumed={sumEnergyConsumed} />
            </td>
            <td>
                <CollapsedEnergyAdded sumEnergyAdded={sumEnergyAdded} />
            </td>
            <td>
                <CollapsedCurrent maximumCurrent={maximumCurrent} />
            </td>
            <td>
                <CollapsedVoltage maximumVoltage={maximumVoltage} />
            </td>
            <td>
                <CollapsedBatteryTemperature totalOfBatteryTemperatureWarnings={totalOfBatteryTemperatureWarnings} />
            </td>
            <td>
                <ChevronDownIcon collapsedType={collapsedType} setKeysOpened={setKeysOpened} date={date} />
            </td>
        </>
    );
};

export default CollapsedHeader;
