import { NO_DATA_INFO } from '../../../types';
import { roundValue } from '../utils';

type RemainingCapacityProps = {
    warningColor?: string;
    remainingRange: number | null;
    energyLevel: number | null;
};

const NoDataElement = ({ unit }: { unit?: string }) => (
    <span className="text-color-lighter ellipsis-1 display-block">
        {NO_DATA_INFO} {unit ? `(${unit})` : ''}
    </span>
);

const ValueWithUnit = ({ value, unit }: { value: number; unit: string }) => {
    return (
        <>
            {roundValue(value)} {unit}
        </>
    );
};

const RemainingCapacity = (props: RemainingCapacityProps): JSX.Element => {
    const { remainingRange, energyLevel, warningColor } = props;
    if (!remainingRange && !energyLevel) {
        return (
            <div>
                <NoDataElement />
            </div>
        );
    }
    return (
        <span>
            <div data-testid="remaining-range" className={`ellipsis-1 ${warningColor}`}>
                {remainingRange ? <ValueWithUnit value={remainingRange} unit="km" /> : <NoDataElement unit="km" />}
            </div>
            <div data-testid="energy-level" className={`ellipsis-1 ${warningColor}`}>
                {energyLevel ? <ValueWithUnit value={energyLevel} unit="kWh" /> : <NoDataElement unit="kWh" />}
            </div>
        </span>
    );
};

export default RemainingCapacity;
