import moment from 'moment/moment';
import { ChargingConfigurationStatusEnum } from '../../../../../types';
import { Weekday } from '../vehicleChargingConfigurationTypes';

export interface DepartureTime {
    day: Weekday;
    date: Date;
    time: string;
    status?: ChargingConfigurationStatusEnum;
}

export const getNextOccurrences = (
    weekdays: Weekday[],
    departureTime: string,
    repeatTimer: boolean,
    timersSentToVehicle?: {
        status: ChargingConfigurationStatusEnum;
        departureTime: Date;
    }[],
    calculateWithStatus?: boolean
) => {
    let timersSentToVehicleFormatted: DepartureTime[] = [];
    if (calculateWithStatus && timersSentToVehicle) {
        timersSentToVehicleFormatted = timersSentToVehicle.map(timer =>
            formatDepartureTime(timer.departureTime, timer.status)
        );
        if (timersSentToVehicleFormatted.length >= 2) {
            return timersSentToVehicleFormatted;
        }
    }
    const currentDate = new Date();
    const currentDayIndex = currentDate.getDay();
    const time = parseTimeString(departureTime);

    // Helper function to create the next date for a given weekday
    const getNextDateForWeekday = (targetDayIndex: number): Date => {
        const nextDate = new Date(currentDate);
        const isToday = targetDayIndex === currentDayIndex;

        // Check if the time has passed for today
        const timePassedToday =
            currentDate.getHours() > time.hours ||
            (currentDate.getHours() === time.hours && currentDate.getMinutes() >= time.minutes);

        // If target day is today and time has passed, skip to the next week
        const daysToAdd = isToday && timePassedToday ? 7 : (targetDayIndex - currentDayIndex + 7) % 7;

        nextDate.setDate(currentDate.getDate() + daysToAdd);
        nextDate.setHours(time.hours, time.minutes, 0, 0);

        return nextDate;
    };

    const weekdayValues = Object.values(Weekday);

    // Generate the next two dates (only one if repeat timer is off) for each weekday
    let nextDates = weekdays.flatMap(weekday => {
        const targetDayIndex = weekdayValues.indexOf(weekday);
        const firstOccurrence = getNextDateForWeekday(targetDayIndex);

        if (repeatTimer) {
            const secondOccurrence = new Date(firstOccurrence);
            secondOccurrence.setDate(secondOccurrence.getDate() + 7);
            return [firstOccurrence, secondOccurrence];
        }
        return [firstOccurrence];
    });

    // remove calculated next dates if they were already sent to the vehicle
    if (calculateWithStatus && timersSentToVehicle) {
        nextDates = nextDates.filter(date => {
            return !timersSentToVehicle.map(timer => timer.departureTime.getTime()).includes(date.getTime());
        });
    }

    const numberOfNextDates = 2 - timersSentToVehicleFormatted.length;

    // Sort the date list and return the first two occurrences
    const twoOccurrences = nextDates.sort((a, b) => a.getTime() - b.getTime()).slice(0, numberOfNextDates);

    return timersSentToVehicleFormatted.concat(
        twoOccurrences.map(occ =>
            formatDepartureTime(occ, calculateWithStatus ? ChargingConfigurationStatusEnum.NOT_SENT_YET : undefined)
        )
    );
};

const parseTimeString = (time: string): { hours: number; minutes: number } => {
    const [hours, minutes] = time.split(':').map(Number);
    return { hours, minutes };
};

const formatDepartureTime = (date: Date, status?: ChargingConfigurationStatusEnum): DepartureTime => {
    const weekdayValues = Object.values(Weekday);
    const weekday = weekdayValues[date.getDay()];
    const time = moment(date).format('HH:mm');

    return {
        day: weekday,
        date,
        time,
        status,
    };
};
