import { useCallback, useState } from 'react';

export interface UnsavedChangesDialogHook {
    isOpen: boolean;
    openDialog: (confirmCallback: () => void, cancelCallback?: () => void) => void;
    confirmDialog: () => void;
    cancelDialog: () => void;
}

export const useUnsavedChangesDialog = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [onConfirm, setOnConfirm] = useState<() => void>(() => () => {});
    const [onCancel, setOnCancel] = useState<() => void>(() => () => {});

    const openDialog = useCallback((confirmCallback: () => void, cancelCallback = () => {}) => {
        setOnConfirm(() => confirmCallback);
        setOnCancel(() => cancelCallback);
        setIsOpen(true);
    }, []);

    const confirmDialog = useCallback(() => {
        onConfirm();
        setIsOpen(false);
    }, [onConfirm]);

    const cancelDialog = useCallback(() => {
        onCancel();
        setIsOpen(false);
    }, [onCancel]);

    return { isOpen, openDialog, confirmDialog, cancelDialog };
};
