import ContentLoader from 'react-content-loader';

const SkeletonLoader = ({ children }: { children: JSX.Element[] | JSX.Element }): JSX.Element => {
    return (
        <ContentLoader speed={2} width="100%" height="100%" backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
            {children}
        </ContentLoader>
    );
};

export default SkeletonLoader;
