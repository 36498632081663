import ChargingStatus from '../../models/enums/ChargingStatus';
import StatsWidget from '@rio-cloud/rio-uikit/StatsWidget';
import StatsWidgetNumber from '@rio-cloud/rio-uikit/StatsWidgetNumber';
import ResetFilterButton from './ResetFilterButton';
import { getNumberOfVehiclesWithChargingStatusUsingElectricVehicle, getWidgetOpacityClass, hasFilter } from './utils';
import { VehicleChargingData } from '../../models/VehicleChargingData';

interface SingleFilterPanelProps {
    chargingStatus: (ChargingStatus | undefined)[];
    chargingStatusFilterValues: (ChargingStatus | undefined)[];
    color: string;
    dataTestId: string;
    dataTestIdHeader: string;
    lblType: JSX.Element;
    onFilterChargingStatusChange: (chargingStatusValues?: (ChargingStatus | undefined)[]) => void;
    onResetFilter: () => void;
    plugIcon: string;
    vehicles: VehicleChargingData[];
}

export const SingleFilterPanel = (props: SingleFilterPanelProps): JSX.Element => {
    const {
        chargingStatus,
        chargingStatusFilterValues,
        color,
        dataTestId,
        dataTestIdHeader,
        lblType,
        onFilterChargingStatusChange,
        onResetFilter,
        plugIcon,
        vehicles,
    } = props;

    return (
        <StatsWidget>
            {hasFilter(chargingStatusFilterValues, chargingStatus) && <ResetFilterButton onClick={onResetFilter} />}
            <StatsWidget.Header data-testid={dataTestIdHeader}>
                <span className={`${plugIcon} ${color} text-size-h1`} />
            </StatsWidget.Header>
            <StatsWidget.Body>
                <StatsWidgetNumber
                    data-testid={dataTestId}
                    className={`${color}${getWidgetOpacityClass(chargingStatusFilterValues, chargingStatus)}`}
                    value={getNumberOfVehiclesWithChargingStatusUsingElectricVehicle(vehicles, chargingStatus)}
                    label={lblType}
                    clickable
                    onClick={(): void => onFilterChargingStatusChange(chargingStatus)}
                />
            </StatsWidget.Body>
        </StatsWidget>
    );
};
