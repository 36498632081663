import WeekdayIntl from './WeekdayIntl';
import { Weekday } from '../../../vehicleCharging/components/details/vehicleDetails/types';

class WeekdayIntlMapper {
    static map(weekday: Weekday): WeekdayIntl {
        switch (weekday) {
            case Weekday.FRIDAY:
                return WeekdayIntl.FRIDAY;
            case Weekday.MONDAY:
                return WeekdayIntl.MONDAY;
            case Weekday.SATURDAY:
                return WeekdayIntl.SATURDAY;
            case Weekday.SUNDAY:
                return WeekdayIntl.SUNDAY;
            case Weekday.THURSDAY:
                return WeekdayIntl.THURSDAY;
            case Weekday.TUESDAY:
                return WeekdayIntl.TUESDAY;
            case Weekday.WEDNESDAY:
                return WeekdayIntl.WEDNESDAY;
        }
    }
}

export default WeekdayIntlMapper;
