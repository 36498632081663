import { FormattedMessage } from 'react-intl';

interface ChargingStatusMessageProps {
    messageId: string;
}

const ChargingStatusMessage = (props: ChargingStatusMessageProps): JSX.Element => {
    const { messageId } = props;
    return (
        <span className={'padding-left-5 display-block'} data-testid={'cs-name'}>
            <FormattedMessage id={messageId} />
        </span>
    );
};

export default ChargingStatusMessage;
