import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import { FormattedMessage } from 'react-intl';
import { SwitchToImmediateChargingReason } from '../../../types';
import SwitchToImmediateChargingDialogIntl from './SwitchToImmediateChargingDialogIntl';

interface SwitchToImmediateChargingDialogProps {
    onCancel: () => void;
    onConfirm: () => void;
    reason: SwitchToImmediateChargingReason | null;
    show: boolean | false;
}

const SwitchToImmediateChargingDialog = (props: SwitchToImmediateChargingDialogProps) => {
    const { onCancel, onConfirm, reason, show } = props;

    if (reason === null) {
        return;
    }

    const contentMessageId =
        reason === SwitchToImmediateChargingReason.ALL_DISABLED
            ? SwitchToImmediateChargingDialogIntl.ALL_TIMERS_DISABLED
            : SwitchToImmediateChargingDialogIntl.ALL_TIMERS_DELETED;

    return (
        <ConfirmationDialog
            show={show}
            title={<FormattedMessage id={SwitchToImmediateChargingDialogIntl.TITLE} />}
            content={<FormattedMessage id={contentMessageId} />}
            onClickConfirm={onConfirm}
            onClickCancel={onCancel}
            bsSize={'sm'}
            cancelButtonText={<FormattedMessage id={SwitchToImmediateChargingDialogIntl.CANCEL_BUTTON} />}
            confirmButtonText={<FormattedMessage id={SwitchToImmediateChargingDialogIntl.CONFIRM_BUTTON} />}
            useOverflow
        />
    );
};

export default SwitchToImmediateChargingDialog;
