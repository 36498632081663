import React from 'react';

interface ChargingTimerRemoveButtonProperties {
    id: number;
    isEnabled: boolean;
    onRemove: (timerNo: number) => void;
    toggleUnsavedVehicleChanges: (unsavedChanges: boolean) => void;
}

export default class ChargingTimerRemoveButton extends React.Component<ChargingTimerRemoveButtonProperties> {
    onRemoveTimer(targetRemoveTimes: number): void {
        this.props.toggleUnsavedVehicleChanges(true);
        this.props.onRemove(targetRemoveTimes);
    }
    render(): JSX.Element {
        return (
            <button
                type="button"
                id={`delTimer_${this.props.id}`}
                className="btn btn-primary btn-link"
                onClick={(): void => this.onRemoveTimer(this.props.id)}
                disabled={!this.props.isEnabled}
            >
                <span className="rioglyph rioglyph-trash text-color-highlight text-size-20" />
            </button>
        );
    }
}
