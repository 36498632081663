import { FormattedMessage } from 'react-intl';
import { trackingAttributes, TrackingCategory, TrackingAction } from '../../../../../utils/googleTagManager';

interface CloseOrSaveFooterProps {
    disabled: boolean;
    onClose: () => void;
    onSave: () => void;
}

const trackingChargingConfigurationAttributes = trackingAttributes(
    TrackingCategory.CHARGING_CONFIGURATION,
    TrackingAction.SAVED_CHARGING_CONFIGURATION
);

const SaveOrCancelFooter = (props: CloseOrSaveFooterProps): JSX.Element => {
    const { disabled, onClose, onSave } = props;
    return (
        <div className="non-printable">
            <div className="btn-group pull-right padding-left-10">
                <button
                    className="btn btn-primary"
                    type="button"
                    id="save-button"
                    disabled={disabled}
                    onClick={onSave}
                    {...trackingChargingConfigurationAttributes}
                >
                    <FormattedMessage id="e4c.global.save" />
                </button>
            </div>
            <div className="btn-group pull-right">
                <button className="btn btn-primary btn-outline" type="button" disabled={disabled} onClick={onClose}>
                    <FormattedMessage id="e4c.global.cancel" />
                </button>
            </div>
        </div>
    );
};

export default SaveOrCancelFooter;
