import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import Select from '@rio-cloud/rio-uikit/Select';
import { CustomTooltip } from '../../../../../components/shared/CustomTooltip';
import { targetStateOfChargeOptions } from '../vehicleDetailsHelper';
import { PercentageSelectOption } from '../types';

import './TargetStateOfCharge.css';

export interface TargetStateOfChargeProperties {
    isEnabled: boolean;
    value: number;
    onChange: (value: number) => void;
}

export default class TargetStateOfCharge extends React.Component<TargetStateOfChargeProperties> {
    render(): JSX.Element {
        return (
            <div className="width-100pct panel panel-default target-state-of-charge-panel">
                <div className="panel-body target-state-of-charge-container">
                    <div className="display-flex flex-row align-items-center justify-content-between">
                        <div>
                            <span className="rioglyph rioglyph-battery-level-low text-size-16" />
                            <span className="text-color-highlight text-bold padding-left-5">
                                <FormattedMessage id="e4c.vehicles.charging.targetStateOfCharge" />
                            </span>
                            <CustomTooltip
                                messageId={'e4c.vehicles.charging.targetStateOfCharge.info'}
                                iconClassName={'text-color-info'}
                            />
                        </div>
                        <div>{renderTargetStateOfChargeDropdown(this.props)}</div>
                    </div>
                </div>
            </div>
        );
    }
}

const renderTargetStateOfChargeDropdown = (props: TargetStateOfChargeProperties): ReactElement => {
    const options = targetStateOfChargeOptions.map((option, ind) => {
        const selected = option === props.value;
        return new PercentageSelectOption(`opt_${ind}`, option, selected);
    });

    return (
        <Select
            id={'targetStateOfCharge'}
            disabled={!props.isEnabled}
            onChange={(option?: PercentageSelectOption): void => option && props.onChange(option.value)}
            options={options}
            className="form-control padding-0 border-none display-inline-block width-172"
        />
    );
};
