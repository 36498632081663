type VehicleNameProps = {
    name: string | null;
};

const VehicleName = (props: VehicleNameProps): JSX.Element => {
    const { name } = props;

    return <span>{name}</span>;
};

export default VehicleName;
