const isEmpty = (o: any): boolean => {
    if (o) {
        if (o.length) {
            return !o.length;
        }
        if (Object.keys(o).length) {
            return false;
        }
    }
    return true;
};

export { isEmpty };
