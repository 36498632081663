import React from 'react';
import TimerChargingModeHeader from './TimerChargingModeHeader/TimerChargingModeHeader';
import { FormattedMessage } from 'react-intl';
import { CustomTooltip } from '../../../../../components/shared/CustomTooltip';
import DepartureTime from './DepartureTime';
import ChargingTimerRemoveButton from './ChargingTimerRemoveButton';
import ChargingTimerEnableSwitch from './ChargingTimerEnableSwitch';
import DepartureTimesPreview from '../DepartureTimesPreview';
import ReadyToDriveDuration, { DEFAULT_READY_TO_DRIVE_DURATION_VALUE } from '../ReadyToDriveDuration';
import { ChargingConfiguration, TimerConfiguration, Weekday } from '../types';
import WeekdaySelector from '../../VehicleDetailsConfiguration/ChargingConfiguration/WeekdaySelector/WeekdaySelector';

interface TimerChargingModeProperties {
    isActive: boolean;
    isReadOnly: boolean;
    timerConfiguration: TimerConfiguration | undefined;
    locale: string;
    departureTimesOptions: string[];
    chargingConfigurations: ChargingConfiguration[];
    onChargingModeChange: () => void;
    onAddTimer: () => void;
    onRemoveTimer: (timerNo: number) => void;
    onToggleWeekDay: (timerId: number, weekDay: Weekday) => void;
    onDepartureTimeChange: (departureTime: string, timerNo: number) => void;
    onTimerEnabledChange: (id: number) => void;
    onReadyToDriveDurationChange: (durationRange: number) => void;
    toggleUnsavedVehicleChanges: (unsavedChanges: boolean) => void;
}

export default class TimerChargingMode extends React.Component<TimerChargingModeProperties> {
    renderDaysHeader(): JSX.Element {
        return (
            <div id="divDays" className="display-inline-block width-305">
                <label>
                    <FormattedMessage id="e4c.vehicles.details.timerSettings.days" />
                </label>
            </div>
        );
    }

    renderDepartureTimeHeader(): JSX.Element {
        return (
            <div className="display-inline-block">
                <label>
                    <FormattedMessage id="e4c.vehicles.details.timerSettings.departureTime" />
                </label>
                <CustomTooltip messageId={'e4c.vehicles.details.timezoneTooltip'} iconClassName={'text-color-info'} />
            </div>
        );
    }

    render(): JSX.Element {
        const selectedBorderClass =
            this.props.isActive && !this.props.isReadOnly
                ? 'border-style-solid border-width-2 border-color-highlight'
                : '';

        const readyToDriveDuration =
            this.props.timerConfiguration?.ready_to_drive_duration ?? DEFAULT_READY_TO_DRIVE_DURATION_VALUE;

        const timers = this.props.timerConfiguration?.timers;

        const isEnabled = this.props.isActive && !this.props.isReadOnly;

        return (
            <div
                id="timer-charging-option"
                className={`width-100pct panel panel-default margin-bottom-0 ${selectedBorderClass}`}
            >
                <div className="panel-body">
                    <TimerChargingModeHeader
                        isActive={this.props.isActive}
                        isReadOnly={this.props.isReadOnly}
                        onChargingModeChange={this.props.onChargingModeChange}
                        onAddTimer={this.props.onAddTimer}
                    />
                    {timers && timers.length > 0 && (
                        <div className="week-day-options">
                            {timers.map((timer, index) => (
                                <div
                                    key={`timerSettings_${index}`}
                                    id={`timerSettings_${index}`}
                                    className="display-block padding-bottom-5"
                                >
                                    <div className="form-inline width-100pct">
                                        <div className="display-inline-block width-305">
                                            {index === 0 && this.renderDaysHeader()}
                                            <WeekdaySelector
                                                timerId={index}
                                                timer={timer}
                                                isEnabled={isEnabled}
                                                onToggleWeekDay={this.props.onToggleWeekDay}
                                                toggleUnsavedVehicleChanges={this.props.toggleUnsavedVehicleChanges}
                                            />
                                        </div>
                                        <div className="display-inline-block departure-time-block pull-right">
                                            {index === 0 && this.renderDepartureTimeHeader()}
                                            <div className="display-block">
                                                <DepartureTime
                                                    id={index}
                                                    selectedTime={timer.departure_time}
                                                    times={this.props.departureTimesOptions}
                                                    isEnabled={isEnabled && timer.enabled}
                                                    locale={this.props.locale}
                                                    zoneId={this.props.timerConfiguration?.zone_id}
                                                    onDepartureTimeChange={this.props.onDepartureTimeChange}
                                                    toggleUnsavedVehicleChanges={this.props.toggleUnsavedVehicleChanges}
                                                />
                                                <div className="display-inline-block btn-disable-remove-timer">
                                                    <ChargingTimerRemoveButton
                                                        id={index}
                                                        isEnabled={isEnabled && timer.enabled}
                                                        onRemove={this.props.onRemoveTimer}
                                                        toggleUnsavedVehicleChanges={
                                                            this.props.toggleUnsavedVehicleChanges
                                                        }
                                                    />
                                                    <ChargingTimerEnableSwitch
                                                        id={index}
                                                        onToggle={this.props.onTimerEnabledChange}
                                                        isChecked={timer.enabled}
                                                        isEnabled={isEnabled}
                                                        toggleUnsavedVehicleChanges={
                                                            this.props.toggleUnsavedVehicleChanges
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <DepartureTimesPreview
                                timers={timers}
                                locale={this.props.locale}
                                chargingConfigurations={this.props.chargingConfigurations}
                            />
                            <ReadyToDriveDuration
                                selectedDurationRange={readyToDriveDuration}
                                onSelectReadyToDriveDurationRange={this.props.onReadyToDriveDurationChange}
                                isEnabled={isEnabled}
                                toggleUnsavedVehicleChanges={this.props.toggleUnsavedVehicleChanges}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
