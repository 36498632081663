import mapVehicleTypeToClass from '../../../vehicleCharging/mappers/vehicleIcons/VehicleTypeClassMapper';
import VehicleType from '../../vehicles/VehicleType';

interface ElectricVehicleIconProps {
    vehicleType: VehicleType;
    size?: string;
    extraClasses?: string;
}

export const ElectricVehicleIcon = ({ vehicleType, size = 'text-size-18', extraClasses }: ElectricVehicleIconProps) => {
    return (
        <span className={extraClasses}>
            <span className={`rioglyph-icon-pair ${size} align-self-center`}>
                <span className={`rioglyph ${mapVehicleTypeToClass(vehicleType)}`} data-testid="vehicle-icon" />
                <span className="rioglyph rioglyph-fuel-electric" />
            </span>
        </span>
    );
};
