import React, { useContext } from 'react';
import * as jstz from 'jstimezonedetect';

/**
 * Support functions
 */
const isVitestAvailable: boolean = import.meta.env.VITEST_WORKER_ID !== undefined;

// Intl.DateTimeFormat().resolvedOptions().timeZone is not supported in IE
export const determineTimezone = (): string => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
        ? Intl.DateTimeFormat().resolvedOptions().timeZone
        : jstz.determine().name();
};

/**
 * Timezone Hook
 */
const DEFAULT_TIMEZONE = 'Europe/Berlin';
const TimezoneContext = React.createContext(DEFAULT_TIMEZONE);

const TimezoneProvider = (props: { children: any }): JSX.Element => {
    return (
        <TimezoneContext.Provider value={isVitestAvailable ? DEFAULT_TIMEZONE : determineTimezone()}>
            {props.children}
        </TimezoneContext.Provider>
    );
};

const useTimezone = (): string => useContext(TimezoneContext);

export { TimezoneProvider, useTimezone };
