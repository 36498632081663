import './ProgrammedConfiguration.css';
import { FormattedMessage } from 'react-intl';
import VehicleTypeClass from '../../../../mappers/vehicleIcons/VehicleTypeClass';

interface ProgrammedConfigurationProps {
    typeClass: VehicleTypeClass;
}

const ProgrammedConfiguration = (props: ProgrammedConfigurationProps): JSX.Element => {
    const { typeClass } = props;
    return (
        <div className={'padding-20'}>
            <div className={'width-100pct panel panel-default margin-bottom-0'}>
                <div className="panel-body">
                    <div className="display-inline-block">
                        <div className="display-inline-block align-middle">
                            <div className="display-flex flex-row justify-content-center align-items-center">
                                <span className="rioglyph rioglyph-filling-e-station text-size-24" />
                                <span data-testid="vehicle-icon" className={`rioglyph ${typeClass} text-size-16`} />
                            </div>
                        </div>
                        <div className="display-inline-block align-middle padding-left-15 programmed-configuration">
                            <span className="display-block text-color-highlight text-bold">
                                <FormattedMessage id="e4c.vehicles.charging.programmed" />
                            </span>
                            <span className="display-block text-size-12">
                                <FormattedMessage id="e4c.vehicles.charging.programmed.info" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProgrammedConfiguration;
