type CollapsedBatteryTemperatureProps = {
    totalOfBatteryTemperatureWarnings: number | null;
};

const CollapsedBatteryTemperature = (props: CollapsedBatteryTemperatureProps) => {
    return (
        <div data-testid="total-of-warnings">
            {props.totalOfBatteryTemperatureWarnings ? (
                <>
                    <span className={'rioglyph rioglyph-warning-sign text-color-gray margin-right-5'} />
                    <span>{props.totalOfBatteryTemperatureWarnings}</span>
                </>
            ) : (
                <>
                    <span className={'rioglyph rioglyph-ok margin-right-5 text-color-gray'} />
                    <span>OK</span>
                </>
            )}
        </div>
    );
};

export default CollapsedBatteryTemperature;
