import { useRef, useState } from 'react';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import TooltipRef from '../../../common/components/Tooltip/Tooltip';

export const TooltipCellWrapper = ({ content }: { content: string }) => {
    const labelRef = useRef<HTMLDivElement>(null);
    const [showPopup, setShowPopup] = useState(false);
    const calculateAndSetShowPopup = () => {
        const isEllipsisActive =
            (labelRef?.current && labelRef?.current?.offsetWidth < labelRef?.current?.scrollWidth) ?? false;
        setShowPopup(isEllipsisActive);
    };

    const contentEllipsed = (
        <div className="ellipsis-1" ref={labelRef} onMouseOver={() => calculateAndSetShowPopup()}>
            {content}
        </div>
    );

    return showPopup ? (
        <TooltipRef placement={Tooltip.TOP_START} tooltipContent={content}>
            {contentEllipsed}
        </TooltipRef>
    ) : (
        contentEllipsed
    );
};
