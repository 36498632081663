import { TreeItem } from '@rio-cloud/rio-uikit';

export const TEAM_ACCOUNT = '237c58e3-60b5-4442-a9ec-8641ea39325b';

export const FAKE_ASSETS: TreeItem[] = [
    {
        id: 'fc978801-1df4-4426-b6e1-eec6290817cd',
        name: 'E4C Test-Fake-Asset 2',
        type: 'bus',
        groupIds: ['d61c3eb4-5838-451c-b6db-cd153bda2711'],
    },
    {
        id: '595a36bc-1427-43c2-ba32-22035ecdf628',
        name: 'E4C Test-Fake-Asset',
        type: 'bus',
        groupIds: ['d61c3eb4-5838-451c-b6db-cd153bda2711'],
    },
];
