import { forwardRef, ReactElement } from 'react';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip, { TooltipWidth } from '@rio-cloud/rio-uikit/Tooltip';
import { type Placement } from '@rio-cloud/rio-uikit/values/Placement';
import { type TextAlignment } from '@rio-cloud/rio-uikit/values/TextAlignment';

interface TooltipRefProps {
    tooltipContent: string;
    dataTestId?: string;
    placement: Placement;
    children: ReactElement;
    width?: TooltipWidth;
    textAlignment?: TextAlignment;
    ellipsedChildren?: boolean;
}

const TooltipRef = forwardRef<HTMLDivElement, TooltipRefProps>(
    (
        {
            tooltipContent,
            dataTestId,
            placement,
            children,
            width = 'auto',
            textAlignment = 'center',
            ellipsedChildren = true,
        }: TooltipRefProps,
        ref
    ) => (
        <OverlayTrigger
            placement={placement}
            overlay={
                <Tooltip width={width} textAlignment={textAlignment} className="top">
                    <span style={{ whiteSpace: 'pre-line' }}>{tooltipContent}</span>
                </Tooltip>
            }
        >
            {ellipsedChildren ? (
                <div className="ellipsis-1" ref={ref} data-testid={dataTestId}>
                    {children}
                </div>
            ) : (
                children
            )}
        </OverlayTrigger>
    )
);

TooltipRef.displayName = 'TooltipRef';
export default TooltipRef;
