type CollapsedCurrentProps = {
    maximumCurrent: number | null;
};

const CollapsedCurrent = (props: CollapsedCurrentProps): JSX.Element => {
    const { maximumCurrent } = props;
    return (
        <span data-testid="battery-current" className="ellipsis-1 display-block">
            <span className={'rioglyph rioglyph-plugged-on text-size-20 text-color-gray padding-right-5'} />
            {maximumCurrent != null ? <span>{`${maximumCurrent?.toFixed(0)} A`}</span> : <span>-</span>}
        </span>
    );
};

export default CollapsedCurrent;
