import moment from 'moment';
import { LatestEventApi, LatestEventsApiResponse } from '../../vehicleCharging/models/api/latestEventsResponse';
import { Events } from '../models/Event';
import { SeverityEventLevel } from '../models/SeverityEventLevelEnum';

const mappingEvents = (latestEvent: LatestEventApi, severityLevel: SeverityEventLevel): Events => ({
    eventTitleMessage: latestEvent.event_title_message,
    eventSubtitleMessage: latestEvent.event_subtitle_message,
    eventInterpolationMessage: latestEvent.event_interpolation_message,
    eventVehicle: latestEvent.event_vehicle,
    severityLevel,
    date: moment(new Date(latestEvent.date)).format('DD/MM/YYYY, HH:mm'),
    count: latestEvent.count,
});

const mappingSeverity = (severity: string | undefined) =>
    Object.values(SeverityEventLevel).find(level => severity === level);

const mappingLatestEventsFromServer = (response: LatestEventsApiResponse): Events[] =>
    Object.entries(response).reduce((mappedEvents, [key, value]: [key: string, value: LatestEventApi]) => {
        const parsedEventKey = key.split('_');
        const validEventLevel = parsedEventKey.length && mappingSeverity(parsedEventKey[0]);
        if (value && validEventLevel) {
            const events = mappingEvents(value, validEventLevel);
            mappedEvents.push(events);
        }
        return mappedEvents;
    }, [] as Events[]);

export default mappingLatestEventsFromServer;
