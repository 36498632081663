import { useHistory } from 'react-router';
import { EVENT_SETTINGS_PATH } from '../../../../common/constants/paths/paths';
import { useIntl } from 'react-intl';

const EventSettings = (): JSX.Element => {
    const intl = useIntl();
    const history = useHistory();

    return (
        <div
            data-testid="event-settings-clickable"
            className={'display-flex justify-content-start text-color-primary cursor-pointer'}
            onClick={() => history.push(EVENT_SETTINGS_PATH)}
        >
            <span
                data-testid="left-icon"
                className="rioglyph rioglyph-cog margin-right-4"
                style={{ marginTop: '3px' }}
            />
            <span data-testid="text" className="text-size-14">
                {intl.formatMessage({ id: 'e4c.overview.events.widget.modal.eventSettings' })}
            </span>
        </div>
    );
};

export default EventSettings;
