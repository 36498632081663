import moment from 'moment-timezone';
import React from 'react';
import { useTimezone } from '../../../../../common/providers/timezone';
import { NO_DATA } from '../../../types';

interface LastUpdatedColumnProps {
    locale: string;
    timestamp: string | undefined | null;
}

export const formatDate = (timestamp: string, locale: string): string => {
    return new Date(timestamp).toLocaleDateString(locale, { day: '2-digit', month: '2-digit' });
};

export const formatTime = (timestamp: string, currentTimezone: string): string => {
    return moment(new Date(timestamp)).tz(currentTimezone).format('HH:mm');
};

export const LastUpdatedColumn = (props: LastUpdatedColumnProps): JSX.Element => {
    const { locale, timestamp } = props;
    const currentTimezone = useTimezone();

    let date = null;
    let time = null;

    if (timestamp) {
        date = formatDate(timestamp, locale);
        time = formatTime(timestamp, currentTimezone);
    }

    return (
        <div className="display-block">
            {date && time ? (
                <div className={'text-size-small'}>
                    <span className="display-inline-block" data-testid={'lu-date'}>
                        {date}
                    </span>
                    <br />
                    <span className="display-inline-block" data-testid={'lu-time'}>
                        {time}
                    </span>
                </div>
            ) : (
                <p className="text-left margin-0 text-color-lighter" data-testid={'lu-no-data'}>
                    {NO_DATA}
                </p>
            )}
        </div>
    );
};

export const MemoLastUpdatedColumn = React.memo(LastUpdatedColumn);
