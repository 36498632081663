import { useIntl } from 'react-intl';
import TooltipWrapper from '../../../../../common/components/Tooltip/TooltipWrapper';
import ChargingModeColumnIntl from './ChargingModeColumnIntl';
import ContentCharging from './ContentCharging';

interface ImmediateChargingModeProps {
    isClimateControlEnabled: boolean;
}

const ImmediateChargingMode = ({ isClimateControlEnabled }: ImmediateChargingModeProps): JSX.Element => {
    const intl = useIntl();

    const climateModeId = isClimateControlEnabled
        ? ChargingModeColumnIntl.CLIMATE_CONTROL_ENABLED
        : ChargingModeColumnIntl.CLIMATE_CONTROL_DISABLED;

    const chargingMode = intl.formatMessage({ id: ChargingModeColumnIntl.IMMEDIATE_CHARGING_MODE });
    const climateMode = intl.formatMessage({ id: climateModeId });
    const tooltipContent = `${chargingMode}\n${climateMode}`;

    return (
        <div className="display-flex flex-column">
            <TooltipWrapper dataTestId="immediate-charging-mode" tooltipContent={tooltipContent}>
                <ContentCharging
                    value={chargingMode}
                    climateMode={climateMode}
                    isClimateControlEnabled={isClimateControlEnabled}
                />
            </TooltipWrapper>
        </div>
    );
};

export default ImmediateChargingMode;
