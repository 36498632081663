import moment from 'moment';
import { VehicleStatistics } from '../../types';
import _ from 'lodash';
import { AvgDrivingConsumptionChartData } from './types';
import {
    arrayOfDatesInRange,
    arrayOfMonthsInRange,
    divideIfPositive,
    drivenDistanceThreshold,
    isCurrentMonthAndYear,
    overallAverageFromAveragePerDay,
} from './StatisticsHelpers';

const buildAggregatedConsumptionObject = (
    maxNumberOfVehicles: number,
    month: string,
    numberOfDays: number,
    totalAverageAmsConsumption: number,
    totalAverageAuxiliaryConsumption: number,
    totalAveragePowertrainConsumption: number,
    totalAverageTotalConsumption: number
): AvgDrivingConsumptionChartData => {
    return {
        date: month,
        averageAmsConsumption: divideIfPositive(totalAverageAmsConsumption, totalAverageAmsConsumption, numberOfDays),
        averageAuxiliaryConsumption: divideIfPositive(
            totalAverageAuxiliaryConsumption,
            totalAverageAuxiliaryConsumption,
            numberOfDays
        ),
        averagePowertrainConsumption: divideIfPositive(
            totalAveragePowertrainConsumption,
            totalAveragePowertrainConsumption,
            numberOfDays
        ),
        averageTotalConsumption: divideIfPositive(
            totalAverageTotalConsumption,
            totalAverageTotalConsumption,
            numberOfDays
        ),
        vehiclesQuantity: maxNumberOfVehicles,
    };
};

const averageConsumptionDaily = (vehiclesStatistics: VehicleStatistics[], date: string) => {
    return vehiclesStatistics.reduce(
        (acc, vehicle) => {
            const vehicleData = vehicle.history.find(data => data.date === date);
            if (
                vehicleData &&
                vehicleData.energyConsumption &&
                vehicleData.energyConsumption > 0 &&
                vehicleData.drivenDistance &&
                vehicleData.drivenDistance > drivenDistanceThreshold
            ) {
                acc.powertrainConsumption += vehicleData.powertrainEnergyConsumption;
                acc.amsConsumption += vehicleData.amsEnergyConsumption;
                acc.auxiliaryConsumption += vehicleData.auxiliaryEnergyConsumption;
                acc.totalConsumption += vehicleData.energyConsumption;
                acc.totalDrivenDistance += vehicleData.drivenDistance;
                acc.numberOfVehicles++;
            }

            return acc;
        },
        {
            totalDrivenDistance: 0,
            totalConsumption: 0,
            powertrainConsumption: 0,
            amsConsumption: 0,
            auxiliaryConsumption: 0,
            numberOfVehicles: 0,
        }
    );
};

export const aggregatedAverageConsumptionDaily = (
    vehiclesStatistics: VehicleStatistics[],
    startDate: Date,
    endDate: Date
): AvgDrivingConsumptionChartData[] => {
    const datesInRange = arrayOfDatesInRange(startDate, endDate);

    return datesInRange.reduce((acc: AvgDrivingConsumptionChartData[], date) => {
        const {
            powertrainConsumption,
            amsConsumption,
            auxiliaryConsumption,
            totalConsumption,
            totalDrivenDistance,
            numberOfVehicles,
        } = averageConsumptionDaily(vehiclesStatistics, date);

        acc.push({
            date,
            averageTotalConsumption: divideIfPositive(totalDrivenDistance, totalConsumption, totalDrivenDistance),
            averagePowertrainConsumption: divideIfPositive(
                totalDrivenDistance,
                powertrainConsumption,
                totalDrivenDistance
            ),
            averageAuxiliaryConsumption: divideIfPositive(
                totalDrivenDistance,
                auxiliaryConsumption,
                totalDrivenDistance
            ),
            averageAmsConsumption: divideIfPositive(totalDrivenDistance, amsConsumption, totalDrivenDistance),
            vehiclesQuantity: numberOfVehicles,
        });

        return acc;
    }, []);
};

export const aggregatedAverageConsumptionMonthly = (
    vehiclesStatistics: VehicleStatistics[],
    startDate: Date,
    endDate: Date
): AvgDrivingConsumptionChartData[] => {
    const aggregatedAverage: AvgDrivingConsumptionChartData[] = [];
    const valuesCalculatedPerDayFromStartDateToEndDate = aggregatedAverageConsumptionDaily(
        vehiclesStatistics,
        startDate,
        endDate
    );
    const months = arrayOfMonthsInRange(startDate, endDate);
    let totalAverageAuxiliaryConsumptionPerMonth = 0;
    let totalAverageAuxiliaryConsumption = 0;
    let totalAveragePowertrainConsumptionPerMonth = 0;
    let totalAveragePowertrainConsumption = 0;
    let totalAverageTotalConsumptionPerMonth = 0;
    let totalAverageTotalConsumption = 0;
    let totalAverageAmsConsumptionPerMonth = 0;
    let totalAverageAmsConsumption = 0;
    let numberOfDays = 0;
    let numberOfDaysInMonth = 0;
    let maxNumberOfVehiclesInMonth = 0;
    months.forEach(month => {
        const monthDate = moment(month);
        valuesCalculatedPerDayFromStartDateToEndDate
            .filter(object => isCurrentMonthAndYear(monthDate, moment(object.date)))
            .forEach(value => {
                if (
                    value.averageAuxiliaryConsumption > 0 ||
                    value.averageAmsConsumption > 0 ||
                    value.averagePowertrainConsumption > 0 ||
                    value.averageTotalConsumption > 0
                ) {
                    totalAverageAuxiliaryConsumptionPerMonth += value.averageAuxiliaryConsumption;
                    totalAverageAuxiliaryConsumption += value.averageAuxiliaryConsumption;
                    totalAveragePowertrainConsumptionPerMonth += value.averagePowertrainConsumption;
                    totalAveragePowertrainConsumption += value.averagePowertrainConsumption;
                    totalAverageTotalConsumptionPerMonth += value.averageTotalConsumption;
                    totalAverageTotalConsumption += value.averageTotalConsumption;
                    totalAverageAmsConsumptionPerMonth += value.averageAmsConsumption;
                    totalAverageAmsConsumption += value.averageAmsConsumption;
                    numberOfDaysInMonth++;
                    numberOfDays++;
                }
                if (value.vehiclesQuantity > maxNumberOfVehiclesInMonth) {
                    maxNumberOfVehiclesInMonth = value.vehiclesQuantity;
                }
            });
        aggregatedAverage.push(
            buildAggregatedConsumptionObject(
                maxNumberOfVehiclesInMonth,
                month,
                numberOfDaysInMonth,
                totalAverageAmsConsumptionPerMonth,
                totalAverageAuxiliaryConsumptionPerMonth,
                totalAveragePowertrainConsumptionPerMonth,
                totalAverageTotalConsumptionPerMonth
            )
        );
        totalAverageAuxiliaryConsumptionPerMonth = 0;
        totalAveragePowertrainConsumptionPerMonth = 0;
        totalAverageTotalConsumptionPerMonth = 0;
        totalAverageAmsConsumptionPerMonth = 0;
        numberOfDaysInMonth = 0;
    });
    aggregatedAverage.push(
        buildAggregatedConsumptionObject(
            maxNumberOfVehiclesInMonth,
            'total',
            numberOfDays,
            totalAverageAmsConsumption,
            totalAverageAuxiliaryConsumption,
            totalAveragePowertrainConsumption,
            totalAverageTotalConsumption
        )
    );
    return aggregatedAverage;
};

export const getAverageConsumptionSummaryValue = (data: AvgDrivingConsumptionChartData[]): number => {
    const totalFromConsumptionMonths = data.find(rec => rec.date === 'total')?.averageTotalConsumption;
    let overallDrivingConsumptionAverage = 0;
    if (totalFromConsumptionMonths) {
        _.remove(data, rec => rec.date === 'total');
        overallDrivingConsumptionAverage = totalFromConsumptionMonths;
    } else {
        overallDrivingConsumptionAverage = overallAverageFromAveragePerDay(
            data.map(item => item.averageTotalConsumption)
        );
    }
    return overallDrivingConsumptionAverage;
};
