import { FormattedMessage } from 'react-intl';
import { Wrapper } from '../../components/shared/Wrapper';
import UpsellStateWrapper from '../../common/components/upsell/UpsellStateWrapper';

const UpsellPanel = () => (
    <Wrapper verticalAlign="80">
        <UpsellStateWrapper
            features={[
                <FormattedMessage key="a" id="e4v.upselling.chargingProcess" />,
                <FormattedMessage key="b" id="e4v.upselling.batteryAging" />,
                <FormattedMessage key="c" id="e4v.upselling.aggregateUsage" />,
                <FormattedMessage key="d" id="e4v.upselling.recuperatedData" />,
            ]}
        />
    </Wrapper>
);

export default UpsellPanel;
