enum VehicleModel {
    ETGE = 'ETGE',
    E4C = 'E4C',
    LION_CITY_E_10 = 'LION_CITY_E_10',
    LION_CITY_E_12 = 'LION_CITY_E_12',
    LION_CITY_E_18 = 'LION_CITY_E_18',
    TRUE = 'TRUE',
    ELSA = 'ELSA',
    UNKNOWN = 'UNKNOWN',
}

export default VehicleModel;
