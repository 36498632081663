import { HistoryContentEnum } from '../../components/types';
import * as H from 'history';
import moment from 'moment';

const SELECTED_DATE_END = 'endDate';
const SELECTED_DATE_START = 'startDate';
const SELECTED_TAB = 'tab';

const getQuery = (history: H.History): URLSearchParams => new URLSearchParams(history.location.search);

const pushQuery = (history: H.History, query: URLSearchParams): void => history.replace({ search: query.toString() });

export const updateUrl = (history: H.History, startDate: any, endDate: any): void => {
    const query = getQuery(history);
    query.delete(SELECTED_DATE_END);
    query.delete(SELECTED_DATE_START);

    if (startDate && endDate) {
        query.append(SELECTED_DATE_START, startDate.format('YYYY-MM-DD'));
        query.append(SELECTED_DATE_END, endDate.format('YYYY-MM-DD'));
    }
    pushQuery(history, query);
};

export const updateUrlWithTab = (history: H.History, tab: HistoryContentEnum): void => {
    const query = getQuery(history);
    query.set(SELECTED_TAB, tab === HistoryContentEnum.BATTERY_HISTORY ? 'battery' : 'consumption');
    pushQuery(history, query);
};

export const handleStartAndEndDate = (
    history: H.History,
    validCallback: (startDate: moment.Moment, endDate: moment.Moment) => void,
    invalidCallback: () => void
): void => {
    const query = getQuery(history);
    const endDate = moment(query.get(SELECTED_DATE_END)).endOf('day');
    const startDate = moment(query.get(SELECTED_DATE_START));

    if (endDate.isValid() && startDate.isValid() && !endDate.isBefore(startDate)) {
        if (validCallback) {
            validCallback(startDate, endDate);
        }
    } else if (invalidCallback) {
        invalidCallback();
    }
};

export const handleSelectedTab = (
    history: H.History,
    validCallback: (tab: HistoryContentEnum) => void,
    invalidCallback: () => void
): void => {
    const query = getQuery(history);
    if (query.has(SELECTED_TAB) && validCallback) {
        switch (query.get(SELECTED_TAB)) {
            case 'battery':
                validCallback(HistoryContentEnum.BATTERY_HISTORY);
                break;
            case 'consumption':
                validCallback(HistoryContentEnum.CONSUMPTION_HISTORY);
                break;
            default:
                if (invalidCallback) {
                    invalidCallback();
                }
                break;
        }
    } else if (invalidCallback) {
        invalidCallback();
    }
};
