import TableToolbar from '@rio-cloud/rio-uikit/TableToolbar';
import { type TableViewTogglesViewType } from '@rio-cloud/rio-uikit/TableViewToggles';
import VehiclesSearchTextBox from './VehiclesSearchTextBox';
import VehiclesTableViewToggles from './VehiclesTableViewToggles';
import VehiclesTableSettingsButton from './VehiclesTableSettingsButton';
import VehiclesTableSettingsDialog from './VehiclesTableSettingsDialog';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { vehiclesTableViewTypeChanged } from '../../../actions/vehicles/Vehicles.actions';
import { createGoogleAnalyticsEvent, gtmUtil, TrackingAction, TrackingCategory } from '../../../utils/googleTagManager';
import { TableViewTogglesValues } from '../../models/enums/TableToggles';

interface VehiclesToolbarProps {
    columnLabels: {
        name: any;
    };
    columnOrder: string[];
    defaultColumnOrder: string[];
    hiddenColumns: string[];
    onColumnsChange: (columnOrder: string[], hiddenColumns: string[]) => void;
}

const processTrackingActionTableToggles = (viewType: TableViewTogglesViewType): TrackingAction => {
    if (viewType === TableViewTogglesValues.VIEW_TYPE_MULTI_CARDS) {
        return TrackingAction.CHANGE_VEHICLE_CHARGING_TAB_TO_GRID_VIEW;
    }
    if (viewType === TableViewTogglesValues.VIEW_TYPE_TABLE) {
        return TrackingAction.CHANGE_VEHICLE_CHARGING_TAB_TO_TABLE_VIEW;
    }
    return TrackingAction.CHANGE_VEHICLE_CHARGING_TAB_TO_LIST_VIEW;
};

const VehiclesToolbar = ({
    columnLabels,
    columnOrder,
    defaultColumnOrder,
    hiddenColumns,
    onColumnsChange,
}: VehiclesToolbarProps): JSX.Element => {
    const [showTableSettingsDialog, setShowTableSettingsDialog] = useState<boolean>(false);
    const onShowTableSettings = () => {
        if (!showTableSettingsDialog) {
            // should only be triggered on clicking to open
            gtmUtil.pushGaEvent(
                createGoogleAnalyticsEvent(
                    TrackingCategory.VEHICLES_TAB,
                    TrackingAction.CLICK_ON_VEHICLE_CHARGING_TAB_TABLE_SETTINGS
                )
            );
        }
        setShowTableSettingsDialog(!showTableSettingsDialog);
    };

    const dispatch = useDispatch();
    const dispatchTableViewToggle = useCallback(
        (viewType: TableViewTogglesViewType) => {
            const trackingAction = processTrackingActionTableToggles(viewType);
            gtmUtil.pushGaEvent(createGoogleAnalyticsEvent(TrackingCategory.VEHICLES_TAB, trackingAction));
            dispatch(vehiclesTableViewTypeChanged(viewType));
        },
        [dispatch]
    );

    const onApplyColumnsChange = (colOrder: string[], hiddenCol: string[]) => {
        gtmUtil.pushGaEvent(
            createGoogleAnalyticsEvent(
                TrackingCategory.VEHICLES_TAB,
                TrackingAction.CHANGE_VEHICLE_CHARGING_TAB_TABLE_SETTINGS
            )
        );
        onColumnsChange(colOrder, hiddenCol);
    };

    return (
        <TableToolbar>
            <div className="table-toolbar-container">
                <div className="table-toolbar-group-right">
                    <VehiclesSearchTextBox />
                    <VehiclesTableViewToggles dispatchTableViewToggle={dispatchTableViewToggle} />
                    <VehiclesTableSettingsButton onClick={onShowTableSettings} />
                </div>
                {showTableSettingsDialog && (
                    <VehiclesTableSettingsDialog
                        columnLabels={columnLabels}
                        columnOrder={columnOrder}
                        defaultColumnOrder={defaultColumnOrder}
                        hiddenColumns={hiddenColumns}
                        onApply={onApplyColumnsChange}
                        onHide={onShowTableSettings}
                        show={showTableSettingsDialog}
                    />
                )}
            </div>
        </TableToolbar>
    );
};

export default VehiclesToolbar;
