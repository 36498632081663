import ElectricVehicle from '../../models/ElectricVehicle';
import {
    AssetType,
    ChargingConfigurationStatusEnum,
    ChargingModeEnum,
    ChargingStatusEnum,
    ReadyToDriveStatusEnum,
    Vehicle,
} from '../../types';
import { VehicleDetails } from '../../components/details/vehicleDetails/types';

export const createVehicleDetailsWithUnconfigurableVehicle = (electricVehicle: ElectricVehicle): VehicleDetails => {
    const vehicle: Vehicle = {
        vehicleId: electricVehicle.assetId,
        name: electricVehicle.name,
        vin: electricVehicle.vin,
        chargingStatus: ChargingStatusEnum.UNKNOWN,
        chargingUntil: null,
        delayedChargingUntil: null,
        disconnectedAt: null,
        stateOfCharge: 0,
        chargingConfiguration: {
            chargingMode: ChargingModeEnum.IMMEDIATE,
            status: ChargingConfigurationStatusEnum.ACCEPTED,
            departureDate: null,
            climateMode: false,
            targetStateOfCharge: 100,
        },
        readyToDriveStatus: ReadyToDriveStatusEnum.NO_SIGNAL,
        readyToDriveUntil: null,
        timestamp: '',
        remainingRange: 0,
        hasMonitoringService: false,
        type: AssetType.VAN,
    };

    const vehicleDetails: VehicleDetails = {
        vehicle,
        selectedVehicleBeforeChanges: vehicle,
        timerConfiguration: undefined,
        timerConfigurationBeforeChanges: undefined,
        chargingConfigurations: [],
        userAction: {
            post_immediate_configurations: {
                href: '',
            },
            post_timer_configurations: {
                href: '',
            },
        },
    };

    return vehicleDetails;
};
