import React, { useContext } from 'react';

/**
 * Token Hook
 */
const DEFAULT_TOKEN = '';
const TokenContext = React.createContext(DEFAULT_TOKEN);

const TokenProvider = (props: { children: any; accessToken: string }): JSX.Element => {
    return <TokenContext.Provider value={props.accessToken}>{props.children}</TokenContext.Provider>;
};

const useToken = (): string => useContext(TokenContext);

export { TokenProvider, useToken };
