import { isNil } from 'lodash';
import { NO_DATA } from '../../../types';

export const formatLastUpdated = (lastUpdated: string | null | undefined, locale: string, timezone: string): string => {
    if (lastUpdated) {
        return new Date(lastUpdated).toLocaleString(locale, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            timeZone: timezone,
        });
    }
    return '';
};

export const hasTextInformation = (value: number | string | undefined | null, unit?: string): string => {
    return isNil(value) ? NO_DATA : `${value}${unit}`;
};
