import { MutableRefObject, useEffect } from 'react';

const useOutsideClick = (ref: MutableRefObject<any>, onOutsideClick: () => void): void => {
    useEffect(() => {
        const handleOutsideClick = (event: Event): void => {
            if (ref.current && !ref.current.contains(event.target)) {
                onOutsideClick();
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return (): void => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [ref, onOutsideClick]);
};

export default useOutsideClick;
