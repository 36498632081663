/* eslint-disable max-len */
const RioLogo = (): JSX.Element => {
    return (
        <svg width="50" height="50" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
                <path fill="#FFF" d="M0 0h50v50H0z" />
                <path
                    d="M45.3 26.05c0 4.55-3.7 8.25-8.25 8.25s-8.25-3.7-8.25-8.25 3.7-8.25 8.25-8.25 8.25 3.7 8.25 8.25zm-23.6 7.8h5.05V18.2H21.7v15.65zm-8-15.65c2.7 0 4.9 2.2 4.9 4.9 0 1.5-.7 2.9-1.8 3.8-.3.3-.7.55-1.15.7l4.1 6.25H14.4l-4.25-6.25v6.25h-4.9V18.2h8.45zM0 50h50V0H0v50z"
                    fill="#000"
                />
            </g>
        </svg>
    );
};

export default RioLogo;
