import { Vehicle } from '../../../types';
import ElectricVehicle from '../../../models/ElectricVehicle';
import Footer from '../vehicleDetails/Footer';
import { VehicleDetailsProps } from '../vehicleDetails/types';
import VehicleDetailsFooter from './VehicleDetailsFooter';

class E4CVehicleDetailsFooter extends VehicleDetailsFooter {
    private readonly _vehicle: ElectricVehicle;
    private readonly _vehicleDetailsProps: VehicleDetailsProps;
    private readonly _selectedVehicle: Vehicle;
    private readonly _onSidebarClose: () => void;
    private readonly _hasUnsavedChanges: () => boolean;

    constructor(
        vehicle: ElectricVehicle,
        vehicleDetailsProps: VehicleDetailsProps,
        selectedVehicle: Vehicle,
        onSidebarClose: () => void,
        hasUnsavedChanges: () => boolean
    ) {
        super();
        this._vehicle = vehicle;
        this._vehicleDetailsProps = vehicleDetailsProps;
        this._selectedVehicle = selectedVehicle;
        this._onSidebarClose = onSidebarClose;
        this._hasUnsavedChanges = hasUnsavedChanges;
    }

    renderFooter(): JSX.Element {
        return (
            <Footer
                accessToken={this._vehicleDetailsProps.accessToken}
                assetId={this._selectedVehicle.vehicleId}
                chargingMode={this._selectedVehicle.chargingConfiguration.chargingMode}
                chargingStatus={this._selectedVehicle.chargingStatus}
                climateMode={this._selectedVehicle.chargingConfiguration.climateMode}
                targetStateOfCharge={this._selectedVehicle.chargingConfiguration.targetStateOfCharge}
                onImmediateChargingSave={this._vehicleDetailsProps.onImmediateChargingSent}
                onTimerChargingSave={this._vehicleDetailsProps.onTimerChargingSent}
                timerConfiguration={this._vehicleDetailsProps.timerConfiguration}
                onConfirmConfigurationChange={this._vehicleDetailsProps.onConfirmConfigurationChange}
                showConfigurationChangeConfirmDialog={this._vehicleDetailsProps.showDepartureTimeConfirmDialog}
                onShowConfigurationChangeConfirmDialog={this._vehicleDetailsProps.onShowDepartureTimeConfirmDialog}
                isConfigurationChangeDialogConfirmed={this._vehicleDetailsProps.isConfigurationChangeDialogConfirmed}
                showUnsavedChangesConfirmDialog={this._vehicleDetailsProps.showUnsavedChangesConfirmDialog}
                onSidebarClose={this._onSidebarClose}
                onConfirmSwitchToImmediateCharging={this._vehicleDetailsProps.onConfirmSwitchToImmediateCharging}
                onShowSwitchToImmediateChargingDialog={this._vehicleDetailsProps.onShowSwitchToImmediateChargingDialog}
                showSwitchToImmediateChargingDialog={this._vehicleDetailsProps.showSwitchToImmediateChargingDialog}
                isSwitchToImmediateChargingConfirmed={this._vehicleDetailsProps.isSwitchToImmediateChargingConfirmed}
                onChargingModeChange={this._vehicleDetailsProps.onChargingModeChange}
                toggleUnsavedVehicleChanges={this._vehicleDetailsProps.toggleUnsavedVehicleChanges}
                saveButtonDisabled={!this._hasUnsavedChanges()}
                toggleSendingChargingConfigSucceeded={this._vehicleDetailsProps.toggleSendingChargingConfigSucceeded}
                updateVehiclesTable={this._vehicleDetailsProps.fetchElectricVehicles}
                sendingChargingConfigSucceeded={this._vehicleDetailsProps.sendingChargingConfigSucceeded}
                updateVehicleDetails={this._vehicleDetailsProps.fetchVehicleDetails}
                vehicle={this._vehicle}
            />
        );
    }
}

export default E4CVehicleDetailsFooter;
