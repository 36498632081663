import { StatisticsData } from '../statistics/types';
import KpiBarChart from './KpiBarChart';
import { useIntl } from 'react-intl';
import OnHoverBarChartHeader from './OnHoverBarChartHeader/OnHoverBarChartHeader';
import _ from 'lodash';
import isEmpty from 'lodash/fp/isEmpty';

const AVG_PC_KEY = 'averagePowertrainConsumption';
const AVG_AC_KEY = 'averageAuxiliaryConsumption';
const AVG_AMSC_KEY = 'averageAmsConsumption';
const AVG_PC_COLOR = 'color-highlight-dark';
const AVG_AC_COLOR = 'color-highlight';
const AVG_AMSC_COLOR = 'color-highlight-light';
const AVG_PC_COLOR_HASH = '#268e97';
const AVG_AC_COLOR_HASH = '#30b4c0';
const AVG_AMSC_COLOR_HASH = '#8ddbe3';

interface AverageConsumptionBarChartProps {
    statistics: StatisticsData;
}

const AverageConsumptionBarChart = (props: AverageConsumptionBarChartProps): JSX.Element => {
    const intl = useIntl();

    return (
        <KpiBarChart
            aggregationPeriodType={props.statistics.aggregationPeriodType}
            data={props.statistics.data}
            barSettings={[
                {
                    dataKey: AVG_PC_KEY,
                    color: AVG_PC_COLOR,
                },
                {
                    dataKey: AVG_AC_KEY,
                    color: AVG_AC_COLOR,
                },
                {
                    dataKey: AVG_AMSC_KEY,
                    color: AVG_AMSC_COLOR,
                },
            ]}
            onAxisLeftFormat={(value): string => Number(value).toFixed(2)}
            tooltip={({ active, payload }): JSX.Element => {
                if (!active || isEmpty(payload)) {
                    return <></>;
                }
                const data = payload[0].payload;
                return (
                    <div
                        className={'text-size-12 padding-5'}
                        style={{ background: 'white', border: '1px solid #e5ebf0', borderRadius: '0' }}
                    >
                        <OnHoverBarChartHeader
                            aggregationPeriodType={props.statistics.aggregationPeriodType}
                            date={data.date}
                            vehiclesQuantity={data.vehiclesQuantity}
                        />
                        <span className={'display-block padding-top-5'} style={{ color: AVG_AMSC_COLOR_HASH }}>
                            {`${
                                data.averageAmsConsumption && data.averageAmsConsumption > 0
                                    ? _.round(Number(data.averageAmsConsumption), 2)
                                    : 0
                            } kWh/km`}
                        </span>
                        <span className={'display-block'} style={{ color: AVG_AC_COLOR_HASH }}>
                            {`+ ${
                                data.averageAuxiliaryConsumption
                                    ? _.round(Number(data.averageAuxiliaryConsumption), 2)
                                    : 0
                            } kWh/km`}
                        </span>
                        <span className={'display-block'} style={{ color: AVG_PC_COLOR_HASH }}>
                            {`+ ${
                                data.averagePowertrainConsumption
                                    ? _.round(Number(data.averagePowertrainConsumption), 2)
                                    : 0
                            } kWh/km`}
                        </span>
                        <span className={'display-block'}>
                            {`= ${
                                data.averageTotalConsumption ? _.round(Number(data.averageTotalConsumption), 2) : 0
                            } kWh/km`}
                        </span>
                    </div>
                );
            }}
            legends={[
                {
                    dataKey: AVG_PC_KEY,
                    color: AVG_PC_COLOR_HASH,
                    type: 'square',
                    value: intl.formatMessage({ id: 'e4c.overview.charts.legend.powertrainConsumption' }),
                },
                {
                    dataKey: AVG_AC_KEY,
                    color: AVG_AC_COLOR_HASH,
                    type: 'square',
                    value: intl.formatMessage({ id: 'e4c.overview.charts.legend.auxiliaryConsumption' }),
                },
                {
                    dataKey: AVG_AMSC_KEY,
                    color: AVG_AMSC_COLOR_HASH,
                    type: 'square',
                    value: intl.formatMessage({ id: 'e4c.overview.charts.legend.acConsumption' }),
                },
            ]}
        />
    );
};

export default AverageConsumptionBarChart;
