import Notification from '@rio-cloud/rio-uikit/Notification';

const notVanishingNotificationWarning = (message: string | JSX.Element | JSX.Element[]): any => {
    return Notification.warning(
        <span>
            {message}
            <span className={'notification-close'}>
                <span className="rioglyph rioglyph-remove" />
            </span>
        </span>,
        '',
        1000 * 60 * 15,
        () => {}
    );
};

export default notVanishingNotificationWarning;
