import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../../../../../types';
import { initialColumnLabels, initialColumnOrder, initialHiddenColumns } from '../../../config';
import {
    getColumnOrderFromLocalStorage,
    getFleetTypeFromLocalStorage,
    getHiddenColumnsFromLocalStorage,
    saveColumnsDetailsInLocalStorage,
    saveFleetTypeInLocalStorage,
} from '../../../localStorage';
import { getFleetType } from '../../../selectors/selectors';
import { getAllowedColumns } from '../../../tableUtils';

interface ColumnLabels {
    name: any;
}

const useTableSettings = () => {
    const storedFleetType = getFleetTypeFromLocalStorage();
    const initialAllowedColumns = getAllowedColumns(storedFleetType);
    const [allowedColumns, setAllowedColumns] = useState<string[]>(initialAllowedColumns);
    const [columnLabels, setColumnLabels] = useState<ColumnLabels>(initialColumnLabels);
    const [columnOrder, setColumnOrder] = useState<string[]>(getColumnOrderFromLocalStorage());
    const [defaultColumnOrder, setDefaultColumnOrder] = useState<string[]>(initialColumnOrder); // maybe we dont need to keep track of this in the future
    const [hiddenColumns, setHiddenColumns] = useState<string[]>(getHiddenColumnsFromLocalStorage());
    const fleetType = useSelector((state: State) => getFleetType(state));

    useEffect(() => {
        if (fleetType !== storedFleetType) {
            const newAllowedColumns = getAllowedColumns(fleetType);
            const newColumnOrder = _.intersection(initialColumnOrder, newAllowedColumns);
            const newHiddenColumns = _.intersection(initialHiddenColumns, newAllowedColumns);

            saveColumnsDetailsInLocalStorage(newColumnOrder, newHiddenColumns);
            saveFleetTypeInLocalStorage(fleetType);
            setAllowedColumns(newAllowedColumns);

            setColumnOrder(newColumnOrder);
            setHiddenColumns(newHiddenColumns);
            const newColumnLabels = _.pick(initialColumnLabels, newAllowedColumns) as ColumnLabels;
            setColumnLabels(newColumnLabels);
            setDefaultColumnOrder(newColumnOrder);
        }
    }, [fleetType, storedFleetType]);

    return {
        allowedColumns,
        columnLabels,
        columnOrder,
        defaultColumnOrder,
        hiddenColumns,
        setColumnOrder,
        setHiddenColumns,
    };
};

export default useTableSettings;
