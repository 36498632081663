import { WhatsNewDataItem } from './types';
import newHistory from '../../../../../assets/images/whatsNew/new-history.gif';
import notificationBell from '../../../../../assets/images/whatsNew/notification-bell.png';
import improvedDownload from '../../../../../assets/images/whatsNew/improved-download.png';
import { FeatureToggles } from '../../../../../../../configuration/featureToggle/featureToggles';

export const whatsNewData: WhatsNewDataItem[] = [
    {
        version: 'e4c.whatsNew.version.improvedDownload',
        date: '21.08.2024',
        content: {
            title: {
                value: 'e4c.whatsNew.improvedDownload.title',
            },
            description: {
                value: 'e4c.whatsNew.improvedDownload.description',
            },
            image: {
                value: improvedDownload,
            },
        },
        toggle: FeatureToggles.REDESIGN_DOWNLOAD,
    },
    {
        version: 'e4c.whatsNew.version.changevalues',
        date: '19.06.2024',
        content: {
            title: {
                value: 'e4c.whatsNew.changevalues.title',
            },
            description: {
                value: 'e4c.whatsNew.changevalues.description',
            },
        },
    },
    {
        version: 'e4c.whatsNew.version.notificationBell',
        date: '08.05.2024',
        content: {
            title: {
                value: 'e4c.whatsNew.notificationBell.title',
            },
            description: {
                value: 'e4c.whatsNew.notificationBell.description',
            },
            image: {
                value: notificationBell,
            },
        },
    },
    {
        version: 'e4c.whatsNew.version.history',
        date: '28.11.2023',
        content: {
            title: {
                value: 'e4c.whatsNew.history.title',
            },
            description: {
                value: 'e4c.whatsNew.history.description',
            },
            image: {
                value: newHistory,
            },
            footer: {
                value: 'e4c.whatsNew.bookingTypeAvailabilityM',
            },
        },
        toggle: FeatureToggles.NEW_HISTORY,
    },
];
