import { useState } from 'react';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { FormattedMessage } from 'react-intl';
import { config } from '../../../../../../../config';
import { useToken } from '../../../../providers/token';
import {
    createGoogleAnalyticsEvent,
    gtmUtil,
    TrackingAction,
    TrackingCategory,
} from '../../../../../utils/googleTagManager';

type Feedback = {
    rating: number | null;
    text: string;
    contactable: boolean | null;
};

export const useSendFeedback = (successCallback?: Function) => {
    const [isSending, setIsSending] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const feedbackEndpoint = `${config.backend.FEEDBACK_SERVICE}`;
    const accessToken = useToken();

    const sendFeedback = async (message: Feedback): Promise<boolean> => {
        setIsSending(true);
        const response = await fetch(feedbackEndpoint, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                message: message?.text,
                contact_allowed: message?.contactable,
                rating: message?.rating,
            }),
        });

        if (response.status < 300) {
            if (successCallback) {
                successCallback();
            }
            gtmUtil.pushGaEvent(createGoogleAnalyticsEvent(TrackingCategory.GENERAL, TrackingAction.FEEDBACK_SENT));
            setIsSending(false);
            setIsError(false);
            return true;
        } else {
            setIsSending(false);
            setIsError(true);
            Notification.error(<FormattedMessage id={'e4c.global.errorState.title'} />);
            return false;
        }
    };

    return { isSending, isError, sendFeedback };
};
