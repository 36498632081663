import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import HistoryContainer from './containers/HistoryContainer';
import NewHistory from './newHistory/NewHistory';
import { useFeatureToggle, useFullFeatureToggleByUsername } from '../common/hooks/useFeatureToggle';
import { FeatureToggles } from '../../../configuration/featureToggle/featureToggles';
import PageLoader from '../common/components/PageLoader';
import { NEW_HISTORY_PATH } from '../common/constants/paths/paths';
import { State } from '../../../types';
import { getUsername } from '../../../configuration';
import { HISTORY_PATH } from '../../../configuration/setup/path';

const HistoryWrapper = () => {
    const history = useHistory();
    const { value: newHistoryDevModeEnabled, loading: newHistoryDevModeEnabledLoading } = useFeatureToggle(
        FeatureToggles.NEW_HISTORY
    );
    const username = useSelector((state: State) => getUsername(state));
    const { value: newHistoryPreSelected, loading: newHistoryPreSelectedLoading } = useFullFeatureToggleByUsername(
        FeatureToggles.NEW_HISTORY_PRE_SELECTED
    );
    const loading = newHistoryDevModeEnabledLoading || newHistoryPreSelectedLoading;
    const { value: isEarlyFleet } = useFeatureToggle(FeatureToggles.EARLY_FLEET);

    useEffect(() => {
        if (newHistoryDevModeEnabled && !loading) {
            const isUserSelectionNewPage = localStorage.getItem(`newHistoryViewSelected_${username}`);
            if (isUserSelectionNewPage != null) {
                if (isUserSelectionNewPage === 'true') {
                    history.replace({ pathname: NEW_HISTORY_PATH, search: history.location.search });
                } else {
                    history.replace({ pathname: HISTORY_PATH, search: history.location.search });
                }
            } else if (newHistoryPreSelected) {
                history.replace({ pathname: NEW_HISTORY_PATH, search: history.location.search });
            } else {
                history.replace({ pathname: HISTORY_PATH, search: history.location.search });
            }
        }
    }, [loading, newHistoryDevModeEnabled, history, newHistoryPreSelected, username]);

    if (loading) {
        return (
            <div className="display-flex justify-content-center align-items-center height-100vh">
                <PageLoader />
            </div>
        );
    }

    return (newHistoryDevModeEnabled && history.location.pathname.includes(NEW_HISTORY_PATH)) || isEarlyFleet ? (
        <NewHistory />
    ) : (
        <HistoryContainer />
    );
};

export default HistoryWrapper;
