import { Event } from '../../models/Event';
import { Page, PaginationData } from './types';

const updateEventCount = (page: Page) => ({
    ...page,
    count: page.count - 1,
});

const removeMarkedEvent = (page: Page, id: string) => {
    const filteredEvents = page.events.filter((ev: Event) => ev.id !== id);
    return {
        count: page.count,
        cursor: { next: page.cursor.next },
        events: filteredEvents,
    };
};

const buildPaginationAfterMarkedEvent = (data: PaginationData, id: string): PaginationData => {
    return {
        pages: data.pages?.map((page: any, index: number) => {
            if (index === 0) {
                page = updateEventCount(page);
            }
            const markedEventExists = page.events?.find((ev: Event) => ev.id === id);
            if (markedEventExists) {
                removeMarkedEvent(page, id);
            }

            return page;
        }),
        pageParams: data.pageParams,
    };
};

export default buildPaginationAfterMarkedEvent;
