import { ReactElement } from 'react';

const List = ({ values, ...props }: { values: { label: ReactElement; value: ReactElement }[] }) => {
    return (
        <div className={'display-flex text-size-12 '} {...props}>
            <div className={'justify-content-start ellipsis-1'}>
                {values.map((v, idx) => (
                    <div key={idx} className={'display-flex flex-row'}>
                        <div className="ellipsis-1"> {v.label}: </div>
                    </div>
                ))}
            </div>
            <div className={'justify-content-end padding-left-5 ellipsis-1'}>
                {values.map((v, idx) => (
                    <div key={idx} className={'display-flex flex-row'}>
                        <div className="ellipsis-1"> {v.value} </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default List;
