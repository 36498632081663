import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import useTooltip from '../../hooks/useTooltip';
import TooltipRef from './Tooltip';

// use this tooltip if you ONLY want to render a tooltip when there is ellipsis (content cut)
const TooltipWrapper = ({
    dataTestId,
    tooltipContent,
    children,
}: {
    tooltipContent: string;
    dataTestId: string;
    children: JSX.Element;
}): JSX.Element => {
    const { isEllipsisActive, labelRef } = useTooltip();

    if (isEllipsisActive) {
        return (
            <TooltipRef ref={labelRef} placement={Tooltip.TOP} tooltipContent={tooltipContent} dataTestId={dataTestId}>
                {children}
            </TooltipRef>
        );
    }
    return (
        <div className="ellipsis-1" ref={labelRef} data-testid={dataTestId}>
            {children}
        </div>
    );
};

export default TooltipWrapper;
