import * as _ from 'lodash';
import { renderTableHead } from '../../../common/tablesUtils/tableUtilsTS';
import { useDispatch } from 'react-redux';
import { vehiclesTableSortChanged } from '../../../actions/vehicles/Vehicles.actions';
import SortDirection from '../../../common/utilities/sort/SortDirection';
import AbstractElectricVehiclesSorter from '../../../vehicleCharging/sorters/AbstractElectricVehiclesSorter';
import { useIntl } from 'react-intl';
import { getColumnConfig } from './columns/VehiclesColumn';

export interface VehiclesHeaderTableProperties {
    columnOrder: string[];
    hiddenColumns: string[];
    sorter: AbstractElectricVehiclesSorter;
    sortDir: SortDirection;
}

const VehiclesHeaderTable = (props: VehiclesHeaderTableProperties): JSX.Element => {
    const { columnOrder, hiddenColumns, sortDir, sorter } = props;
    const dispatch = useDispatch();
    const onSortChange = (sort: AbstractElectricVehiclesSorter, currSort: SortDirection) =>
        dispatch(vehiclesTableSortChanged(sort, currSort));
    const intl = useIntl();

    const columnsDetails = getColumnConfig();
    let columns = _.keysIn(columnsDetails);
    const columnLabels = _.mapValues(columnsDetails, column => column.label);
    const columnSorting = _.mapValues(columnsDetails, column => column.sortable);
    const columnSorters = _.mapValues(columnsDetails, column => column.sorter);
    const columnWidths = _.mapValues(columnsDetails, column => column.width);
    const columnTooltips = _.mapValues(columnsDetails, column => column.tooltip);

    columns = columnOrder.filter(name => !hiddenColumns.includes(name));

    return (
        <>
            <colgroup>
                {columns.map(column => (
                    <col key={column} />
                ))}
            </colgroup>
            <thead>
                <tr>
                    {columns.map(column =>
                        renderTableHead(
                            column,
                            intl.formatMessage({ id: columnLabels[column] }),
                            columnSorting[column],
                            columnSorters[column],
                            columnWidths[column],
                            columnTooltips[column],
                            sortDir,
                            sorter,
                            onSortChange
                        )
                    )}
                </tr>
            </thead>
        </>
    );
};

export default VehiclesHeaderTable;
