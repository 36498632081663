import { FormattedMessage } from 'react-intl';

interface CloseFooterProps {
    onCloseClick: () => void;
}

const CloseFooter = (props: CloseFooterProps): JSX.Element => {
    return (
        <div className="non-printable">
            <div className="btn-group pull-right padding-left-10">
                <button className="btn btn-primary" type="button" onClick={props.onCloseClick}>
                    <FormattedMessage id="e4c.global.close" />
                </button>
            </div>
        </div>
    );
};

export default CloseFooter;
