import AssetInfo from './AssetInfo';
import Measurement from './Measurement';
import MeasurementType from './enums/MeasurementType';
import VehicleType from '../../common/vehicles/VehicleType';
import ReadyToDriveStatus from './enums/ReadyToDriveStatus';
import ChargingState from './ChargingState';
import { ProductVersion } from '../../common/vehicles/productVersion';
import { isCharging, isChargingConfigurable, isValid } from '../helpers/rulesVehicleCharging';

class ElectricVehicle {
    private readonly _assetId: string;
    private readonly _productVersion: ProductVersion;
    private readonly _hasMinimalDatenset: boolean;
    private readonly _assetInfo: AssetInfo;
    private readonly _measurements: Measurement[];
    private readonly _measurementsLastUpdated: string | undefined | null;
    private readonly _chargingState: ChargingState | undefined;

    constructor(
        assetId: string,
        productVersion: ProductVersion,
        hasMinimalDatenset: boolean,
        assetInfo: AssetInfo,
        measurements: Measurement[],
        measurementsLastUpdated: string | undefined | null,
        chargingState: ChargingState | undefined
    ) {
        this._assetId = assetId;
        this._productVersion = productVersion;
        this._hasMinimalDatenset = hasMinimalDatenset;
        this._assetInfo = assetInfo;
        this._measurements = measurements;
        this._measurementsLastUpdated = measurementsLastUpdated;
        this._chargingState = chargingState;
    }

    public get assetId(): string {
        return this._assetId;
    }

    public get productVersion(): ProductVersion {
        return this._productVersion;
    }

    public get hasMinimalDatenset(): boolean {
        return this._hasMinimalDatenset;
    }

    public get assetInfo(): AssetInfo {
        return this._assetInfo;
    }

    public get measurements(): Measurement[] {
        return this._measurements;
    }

    public get stateOfCharge(): number | undefined {
        return this.measurement(MeasurementType.STATE_OF_CHARGE)?.value;
    }

    public get remainingRange(): number | undefined {
        return this.measurement(MeasurementType.REMAINING_RANGE)?.value;
    }

    public get isDriving(): boolean {
        return this.measurement(MeasurementType.DRIVING_STATE)?.value === 'DS_ACTIVE';
    }

    public get isReadyToDrive(): boolean {
        return this._chargingState?.readyToDriveStatus === ReadyToDriveStatus.READY_TO_DRIVE;
    }

    public get isCharging(): boolean {
        return isCharging(this._chargingState?.chargingStatus);
    }

    public get name(): string {
        return this._assetInfo.name;
    }

    public get vin(): string {
        return this._assetInfo.vin;
    }

    public get vehicleType(): VehicleType {
        return this._assetInfo.type;
    }

    public get measurementsLastUpdated(): string | undefined | null {
        return this._measurementsLastUpdated;
    }

    public get chargingState(): ChargingState | undefined {
        return this._chargingState;
    }

    /**
     * This method is used to temporarily filter vehicles from monitoring service
     */
    public get isValid(): boolean {
        return isValid(this._productVersion);
    }

    public isChargingConfigurable(): boolean {
        return isChargingConfigurable(this._assetInfo);
    }

    public isMbooking(): boolean {
        return this.productVersion === ProductVersion.EMANAGER_M;
    }

    private measurement(type: MeasurementType): Measurement | undefined {
        return this._measurements.find(measurement => measurement.type === type);
    }
}

export default ElectricVehicle;
