import Cookies from 'js-cookie';

interface CookiesProps {
    key: string;
    value: string;
    expireDate?: number;
}

const setCookie = ({ key, value, expireDate }: CookiesProps): boolean => {
    if (!getCookie(key)) {
        Cookies.set(key, value, { expires: expireDate });
        return true;
    }
    return false;
};

const getCookie = (key: string) => Cookies.get(key);

const removeCookie = (key: string) => Cookies.remove(key);

export { setCookie, getCookie, removeCookie };
