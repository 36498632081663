import { ReactElement } from 'react';
import Switch from '@rio-cloud/rio-uikit/Switch';
import TooltipRef from '../../../../../../common/components/Tooltip/Tooltip';
import { PLACEMENT } from '@rio-cloud/rio-uikit/values/Placement';

interface ConfigItemActionsProps {
    checked: boolean;
    disabled: boolean;
    handleChange: () => void;
    message?: string;
}

const ConfigItemActions = ({ checked, disabled, handleChange, message }: ConfigItemActionsProps): ReactElement => {
    const rightSwitch = (
        <Switch
            disabled={disabled}
            onChange={handleChange}
            checked={checked}
            labelPosition={Switch.LABEL_POSITION_LEFT}
        >
            <span>{checked ? 'ON' : 'OFF'}</span>
        </Switch>
    );

    const content = message ? (
        <TooltipRef
            tooltipContent={message}
            dataTestId="item-menu-toggle-tooltip"
            placement={PLACEMENT.LEFT}
            width={300}
            textAlignment="left"
        >
            {rightSwitch}
        </TooltipRef>
    ) : (
        rightSwitch
    );

    return (
        <div data-testid="item-menu-toggle" className="display-flex justify-content-center align-items-center">
            {content}
        </div>
    );
};

export default ConfigItemActions;
