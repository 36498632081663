import OnHoverBarChartHeader from './OnHoverBarChartHeader/OnHoverBarChartHeader';
import _ from 'lodash';
import { AggregationPeriodType } from '../statistics/types';

interface BarTooltipProps {
    aggregationPeriodType: AggregationPeriodType;
    date: string;
    vehiclesQuantity: number;
    value?: number;
    units: JSX.Element | string;
}

const BarTooltip = (props: BarTooltipProps): JSX.Element => (
    <div
        className={'text-size-12 padding-5'}
        style={{ background: 'white', border: '1px solid #e5ebf0', borderRadius: '0' }}
    >
        <OnHoverBarChartHeader
            aggregationPeriodType={props.aggregationPeriodType}
            date={props.date}
            vehiclesQuantity={props.vehiclesQuantity}
        />
        <span className={'display-block padding-top-5'} style={{ color: '#268e97' }}>
            {`${props.value ? _.round(Number(props.value), 2) : 0}`} {props.units}
        </span>
    </div>
);

export default BarTooltip;
