import { type TableViewTogglesViewType } from '@rio-cloud/rio-uikit/TableViewToggles';
import { State } from '../../../../types';
import { SortDirection, Vehicle } from '../../vehicleCharging/types';
import AbstractElectricVehiclesSorter from '../../vehicleCharging/sorters/AbstractElectricVehiclesSorter';
import ChargingStatus from '../../vehicleCharging/models/enums/ChargingStatus';
import ElectricVehicle from '../../vehicleCharging/models/ElectricVehicle';
import { createGoogleAnalyticsEvent, gtmUtil, TrackingAction, TrackingCategory } from '../../utils/googleTagManager';
import { ErrorCodeEnum } from '../../common/enums/ErrorCode';

export const getFetchingElectricVehicles = (state: State): boolean => {
    return state.vehicles.fetching;
};

export const isFetchingElectricVehicles = (state: State): boolean => {
    return state.electricVehicles.isFetchingMonitoringElectricVehicles || state.vehicles.fetching;
};

export const getVehicles = (state: State): Vehicle[] => {
    return state.vehicles.vehicles;
};

export const getElectricVehicles = (state: State): ElectricVehicle[] => {
    return state.electricVehicles.electricVehicles;
};

export const getVehiclesTableViewType = (state: State): string => {
    return state.vehicles.tableViewType || ('TABLE' as TableViewTogglesViewType);
};

export const getVehiclesSelectedRow = (state: State): string | null => {
    return state.vehicles.selectedRow;
};

export const getSorter = (state: State): AbstractElectricVehiclesSorter => {
    return state.vehicles.sorter;
};

export const getSortDir = (state: State): SortDirection => {
    return state.vehicles.sortDir;
};

export const getVehiclesSearchValue = (state: State): string => {
    return state.vehicles.searchValue;
};

export const getShowTableSettingDialog = (state: State): boolean => {
    return state.vehicles.showTableSettingsDialog;
};

export const getVehiclesLoadingErrorCode = (state: State): ErrorCodeEnum | null => {
    return state.vehicles.vehiclesLoadingErrorCode;
};

export const getChargingStatusFilterValue = (state: State): (ChargingStatus | undefined)[] => {
    if (state.vehicles.chargingStatusFilterValue.length > 0) {
        gtmUtil.pushGaEvent(
            createGoogleAnalyticsEvent(
                TrackingCategory.VEHICLES_TAB,
                TrackingAction.FILTER_PANEL_USAGE,
                state.vehicles.chargingStatusFilterValue.join(', ')
            )
        );
    }
    return state.vehicles.chargingStatusFilterValue;
};
