import VehicleDetailsConfiguration from './VehicleDetailsConfiguration';
import ProgrammedConfiguration from './ProgrammedConfiguration/ProgrammedConfiguration';
import VehicleTypeClass from '../../../mappers/vehicleIcons/VehicleTypeClass';

class EtgeVehicleDetailsConfiguration extends VehicleDetailsConfiguration {
    renderConfiguration(): JSX.Element {
        return <ProgrammedConfiguration typeClass={VehicleTypeClass.VAN} />;
    }
}

export default EtgeVehicleDetailsConfiguration;
