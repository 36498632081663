import { type BarChartDataType } from '@rio-cloud/rio-uikit/BarChart';

export interface StatisticsData {
    aggregationPeriodType: AggregationPeriodType;
    data: AggregatedData[];
    summaryValue: number;
}

export enum AggregationPeriodType {
    DAILY,
    MONTHLY,
}

export interface AggregatedData extends BarChartDataType {
    date: string;
    vehiclesQuantity: number;
}

export interface AvgDrivingConsumptionChartData extends AggregatedData {
    averageAuxiliaryConsumption: number;
    averagePowertrainConsumption: number;
    averageTotalConsumption: number;
    averageAmsConsumption: number;
}

export interface AvgRecuperationChartData extends AggregatedData {
    averageRecuperation: number;
}

export interface AvgMileageChartData extends AggregatedData {
    averageMileage: number;
}
