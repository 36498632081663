import { useEffect, useRef, useState } from 'react';

const useTooltip = () => {
    const labelRef = useRef<HTMLDivElement>(null);

    // State for tracking if ellipsis is active
    const [isEllipsisActive, setIsEllipsisActive] = useState(false);

    // Setup a use effect
    useEffect(() => {
        if (labelRef?.current) {
            if (labelRef?.current?.offsetWidth < labelRef?.current?.scrollWidth) {
                setIsEllipsisActive(true);
            }
        }
    }, []);
    return { isEllipsisActive, labelRef };
};

export default useTooltip;
