import React from 'react';
import RadioButton from '@rio-cloud/rio-uikit/RadioButton';
import { FormattedMessage } from 'react-intl';

import './TimerChargingModeHeader.css';

interface TimerChargingModeHeaderProperties {
    isActive: boolean;
    isReadOnly: boolean;
    onChargingModeChange: () => void;
    onAddTimer: () => void;
}

export default class TimerChargingModeHeader extends React.Component<TimerChargingModeHeaderProperties> {
    renderReadOnly(): JSX.Element {
        return (
            <div className="display-inline-block">
                <div className="display-inline-block align-middle">
                    <span className="rioglyph rioglyph-filling-e-station text-size-24" />
                    <span className="rioglyph rioglyph-time" />
                </div>
                <div className="display-inline-block align-middle padding-left-15 charging-configuration-timer">
                    <span className="display-block text-color-highlight text-bold">
                        <FormattedMessage id="e4c.vehicles.charging.timer" />
                    </span>
                    <span className="display-block text-size-12">
                        <FormattedMessage id="e4c.vehicles.charging.timer.info" />
                    </span>
                </div>
            </div>
        );
    }

    renderWithButtons(): JSX.Element {
        return (
            <RadioButton
                name="chargingMode"
                className="timerButton"
                value="TIMER"
                checked={this.props.isActive}
                tabIndex={2}
                onChange={this.props.onChargingModeChange}
            >
                <div className="display-inline-block align-middle">
                    <span className="rioglyph rioglyph-filling-e-station text-size-24" />
                    <span className="rioglyph rioglyph-time" />
                </div>
                <div className="display-inline-block align-middle padding-left-15 charging-configuration-timer">
                    <span className="display-block text-color-highlight text-bold">
                        <FormattedMessage id="e4c.vehicles.charging.timer" />
                    </span>
                    <span className="display-block text-size-12">
                        <FormattedMessage id="e4c.vehicles.charging.timer.info" />
                    </span>
                </div>
                <div className="display-inline-block btn-add-timer">
                    <button
                        type="button"
                        className="btn btn-primary btn-multiline word-break max-width-150"
                        disabled={!this.props.isActive}
                        onClick={this.props.onAddTimer}
                    >
                        <span className="rioglyph rioglyph-plus" aria-hidden="true" />
                        <FormattedMessage id="e4c.vehicles.details.timerSettings.addTimer" />
                    </button>
                </div>
            </RadioButton>
        );
    }

    render(): JSX.Element {
        return (
            <div className="display-inline-block width-100pct">
                {this.props.isReadOnly ? this.renderReadOnly() : this.renderWithButtons()}
            </div>
        );
    }
}
