import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import moment, { Moment } from 'moment';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import ApplicationLayoutSkeleton from '../../common/components/applicatonLayout';
import { NewHistoryBody } from './NewHistoryBody';
import { handleStartAndEndDate, updateUrl } from '../pages/helper/HistoryComponentHelper';
import { useFullFeatureToggleByUsername } from '../../common/hooks/useFeatureToggle';
import { FeatureToggles } from '../../../../configuration/featureToggle/featureToggles';
import { createGoogleAnalyticsEvent, gtmUtil, TrackingAction, TrackingCategory } from '../../utils/googleTagManager';
import AssetTreeSidebar from '../../common/components/AssetTreeSidebar/AssetTreeSidebar';
import { AssetTreeContext } from '../../../../providers/contexts';

const NewHistory = () => {
    const history = useHistory();
    const [defaultStartValue, setDefaultStartValue] = useState<Moment | undefined>();
    const [defaultEndValue, setDefaultEndValue] = useState<Moment | undefined>();
    const { selectedAssetIds, selectedGroupIds } = useContext(AssetTreeContext);
    const { variationId } = useFullFeatureToggleByUsername(FeatureToggles.NEW_HISTORY_PRE_SELECTED);

    useEffect(() => {
        if (variationId !== undefined) {
            gtmUtil.pushGaEvent(
                createGoogleAnalyticsEvent(TrackingCategory.HISTORY_TAB, TrackingAction.VISIT_NEW_HISTORY, variationId)
            );
        }
    }, [variationId]);

    const isOnlyGroupsWithoutAssetsSelected = useMemo(
        () => selectedGroupIds.length > 0 && selectedAssetIds.length === 0,
        [selectedGroupIds.length, selectedAssetIds.length]
    );

    const manageDateChange = useCallback(
        (start: Moment, end: Moment) => {
            updateUrl(history, start, end);
            setDefaultEndValue(end);
            setDefaultStartValue(start);
            gtmUtil.pushGaEvent(
                createGoogleAnalyticsEvent(
                    TrackingCategory.HISTORY_TAB,
                    TrackingAction.CHANGE_DATES,
                    `${start} - ${end}`
                )
            );
        },
        [history]
    );

    useEffect(() => {
        handleStartAndEndDate(
            history,
            (startDate: Moment, endDate: Moment) => {
                setDefaultEndValue(endDate);
                setDefaultStartValue(startDate);
            },
            () => {}
        );
    }, [history]);

    useEffect(() => {
        const searchParams = new URLSearchParams(history.location.search);
        if (!searchParams.has('startDate') && !searchParams.has('endDate')) {
            const startDate = startOfDay(subDays(new Date(), 4));
            const endDate = endOfDay(new Date());
            manageDateChange(moment(startDate), moment(endDate));
        }
    }, [history.location.search, manageDateChange]);

    return (
        <ApplicationLayoutSkeleton isLoading={false} sidebarLeftContent={{ content: <AssetTreeSidebar /> }}>
            <NewHistoryBody
                selectedAssetIds={selectedAssetIds}
                defaultStartValue={defaultStartValue}
                defaultEndValue={defaultEndValue}
                manageDateChange={manageDateChange}
                isOnlyGroupsWithoutAssetsSelected={isOnlyGroupsWithoutAssetsSelected}
            />
        </ApplicationLayoutSkeleton>
    );
};

export default NewHistory;
