import FleetType from './models/enums/FleetType';
import { initialColumnOrder, initialFleetType, initialHiddenColumns } from './config';

const COLUMN_ORDER_KEY = 'columnOrder';
const FLEET_TYPE_KEY = 'fleetType';
const HIDDEN_COLUMNS_KEY = 'hiddenColumns';

const withLocalStorage = (callback: (storage: Storage) => void): void => {
    if (callback && typeof Storage !== 'undefined') {
        callback(window.localStorage);
    }
};

export const getFleetTypeFromLocalStorage = (): FleetType => {
    let fleetType: FleetType = initialFleetType;
    withLocalStorage(storage => {
        const storedFleetType = storage.getItem(FLEET_TYPE_KEY);
        if (storedFleetType) {
            fleetType = JSON.parse(storedFleetType);
        } else {
            saveFleetTypeInLocalStorage(fleetType);
        }
    });
    return fleetType;
};

export const saveFleetTypeInLocalStorage = (fleetType: FleetType): void => {
    withLocalStorage(storage => storage.setItem(FLEET_TYPE_KEY, JSON.stringify(fleetType)));
};

export const getHiddenColumnsFromLocalStorage = (): string[] => {
    let hiddenColumns: string[] = initialHiddenColumns;
    withLocalStorage(storage => {
        const storedHiddenColumns = storage.getItem(HIDDEN_COLUMNS_KEY);
        if (storedHiddenColumns) {
            hiddenColumns = JSON.parse(storedHiddenColumns);
        }
    });
    return hiddenColumns;
};

export const getColumnOrderFromLocalStorage = (): string[] => {
    let columnOrder: string[] = initialColumnOrder;
    withLocalStorage(storage => {
        const storedColumnOrder = storage.getItem(COLUMN_ORDER_KEY);
        if (storedColumnOrder) {
            columnOrder = JSON.parse(storedColumnOrder);
        }
    });
    return columnOrder;
};

export const saveColumnsDetailsInLocalStorage = (columnOrder: string[], hiddenColumns: string[]): void => {
    withLocalStorage(storage => {
        storage.setItem(COLUMN_ORDER_KEY, JSON.stringify(columnOrder));
        storage.setItem(HIDDEN_COLUMNS_KEY, JSON.stringify(hiddenColumns));
    });
};
