import { FormattedMessage } from 'react-intl';
import { ReactElement } from 'react';
import InformativeState from '../../../../common/components/states/InformativeState';
import ComingSoon from '../../../../assets/svg/coming-soon';

export const UpcomingFeatureState = (): ReactElement => {
    const message = (
        <FormattedMessage id="e4c.vehicle.details.upcomingFeature.message">
            {(content): ReactElement => <div className="text-color-gray white-space-pre-line">{content}</div>}
        </FormattedMessage>
    );

    return (
        <InformativeState
            headline={<FormattedMessage id="e4c.vehicle.details.upcomingFeature.title" />}
            message={message}
            verticalAlign="60"
            image={ComingSoon()}
            withoutBorder
            testId="upcoming-feature-state"
        />
    );
};
