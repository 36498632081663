import { useHistory, useLocation } from 'react-router';
import { AccessToken } from '../../../../../../configuration';
import useMutationDeleteEventApi from '../../../queries/useMutationDeleteEventApi';
import { useIntl } from 'react-intl';
import ErrorState from '../../../../common/components/states/ErrorState';

interface LocationState {
    eventId?: string;
    deleteAll: boolean;
}

const ConfirmDeletion = ({
    accessToken,
    severityLevel,
    refetchEvents,
}: {
    accessToken: AccessToken;
    severityLevel?: string[];
    refetchEvents: Function;
}): JSX.Element => {
    const intl = useIntl();
    const history = useHistory();
    const state = useLocation<LocationState>()?.state;
    const mutation = useMutationDeleteEventApi(accessToken, state?.eventId, state?.deleteAll, severityLevel);

    const handleDelete = () => {
        mutation.mutate(
            {},
            {
                onSuccess: () => {
                    refetchEvents();
                    history.goBack();
                },
            }
        );
    };

    if (!state || mutation.isError) {
        return <ErrorState stateProps={{ type: 'general', withoutBorder: true }} />;
    }

    return (
        <div className={'overflow-hidden'}>
            <div style={{ fontSize: '22px', marginTop: '12px', fontWeight: 600 }}>
                {intl.formatMessage({
                    id: state?.deleteAll
                        ? 'e4c.overview.events.widget.modal.confirmDeleteAll.title'
                        : 'e4c.overview.events.widget.modal.confirmDelete.title',
                })}
            </div>
            <div style={{ fontSize: '18px', marginTop: '16px' }}>
                {intl.formatMessage({ id: 'e4c.overview.events.widget.modal.confirmDelete.subtitle' })}
            </div>
            <div className="display-flex justify-content-end" style={{ marginTop: '40px', gap: '16px' }}>
                <button type="button" className="btn btn-default" onClick={() => history.goBack()}>
                    {intl.formatMessage({ id: 'e4c.global.cancel' })}
                </button>
                <button type="button" className="btn btn-primary" onClick={handleDelete}>
                    {intl.formatMessage({ id: 'e4c.global.delete' })}
                </button>
            </div>
        </div>
    );
};

export default ConfirmDeletion;
