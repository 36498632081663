import ElectricVehicle from '../../vehicleCharging/models/ElectricVehicle';
import { ChargingStatusEnum } from '../../vehicleCharging/types';

export const getNumberOfVehiclesWithChargingStatusUsingVehicle = (
    vehicles: ElectricVehicle[], // here is the difference
    chargingStatuses: ChargingStatusEnum[]
): string => {
    return vehicles
        .filter(vehicle =>
            chargingStatuses.includes(vehicle.chargingState?.chargingStatus as unknown as ChargingStatusEnum)
        )
        .length.toString();
};
