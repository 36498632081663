import TableViewToggles, { type TableViewTogglesViewType } from '@rio-cloud/rio-uikit/TableViewToggles';
import {
    VEHICLES_LOADING_FAILED,
    VEHICLES_LOADING_STARTED,
    VEHICLES_LOADING_SUCCESSFUL,
    VEHICLES_TABLE_CHARGING_STATUS_FILTER_CHANGED,
    VEHICLES_TABLE_ROW_SELECTED,
    VEHICLES_TABLE_SEARCH_VALUE_CHANGED,
    VEHICLES_TABLE_SHOW_SETTINGS_TOGGLED,
    VEHICLES_TABLE_SORT_CHANGED,
    VEHICLES_TABLE_VIEW_TYPE_CHANGED,
    VehiclesActions,
} from '../../actions/vehicles/types';
import { VehiclesState } from './types';
import { SortDirection } from '../../vehicleCharging/types';
import ChargingStatusSorter from '../../vehicleCharging/sorters/ChargingStatusSorter';

const initialState: VehiclesState = {
    vehicles: [],
    fetching: false,
    selectedRow: null,
    selectedRows: [],
    tableViewType: TableViewToggles.VIEW_TYPE_TABLE,
    sorter: new ChargingStatusSorter(),
    sortDir: SortDirection.ASCENDING,
    searchValue: '',
    showTableSettingsDialog: false,
    vehiclesLoadingErrorCode: null,
    chargingStatusFilterValue: [],
};

const reducer = (state: VehiclesState = initialState, action: VehiclesActions): VehiclesState => {
    switch (action.type) {
        case VEHICLES_LOADING_STARTED:
            return {
                ...state,
                fetching: true,
                vehicles: [],
                vehiclesLoadingErrorCode: null,
            };
        case VEHICLES_LOADING_SUCCESSFUL:
            return {
                ...state,
                fetching: false,
                vehicles: action.payload,
                vehiclesLoadingErrorCode: null,
            };
        case VEHICLES_LOADING_FAILED:
            return {
                ...state,
                fetching: false,
                vehicles: [],
                vehiclesLoadingErrorCode: action.payload,
            };
        case VEHICLES_TABLE_VIEW_TYPE_CHANGED:
            return {
                ...state,
                tableViewType: action.payload as TableViewTogglesViewType,
            };
        case VEHICLES_TABLE_ROW_SELECTED: {
            const currentRowSelected = state.selectedRow;
            const selectedRow = action.payload === currentRowSelected ? null : action.payload;
            return {
                ...state,
                selectedRow,
            };
        }
        case VEHICLES_TABLE_SORT_CHANGED: {
            const direction =
                action.sortDir === SortDirection.ASCENDING ? SortDirection.DESCENDING : SortDirection.ASCENDING;
            return {
                ...state,
                sortDir: direction,
                sorter: action.sorter,
            };
        }
        case VEHICLES_TABLE_SEARCH_VALUE_CHANGED:
            return {
                ...state,
                searchValue: action.payload,
            };
        case VEHICLES_TABLE_SHOW_SETTINGS_TOGGLED:
            return {
                ...state,
                showTableSettingsDialog: !state.showTableSettingsDialog,
            };
        case VEHICLES_TABLE_CHARGING_STATUS_FILTER_CHANGED:
            return {
                ...state,
                chargingStatusFilterValue: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;
