import {
    aggregatedAverageRecuperationDaily,
    getAverageRecuperationSummaryValue,
    aggregatedAverageRecuperationMonthly,
} from './AverageRecuperationStatisticsHelper';
import StatisticsBuilder from './StatisticsBuilder';
import { AggregationPeriodType, AvgRecuperationChartData, StatisticsData } from './types';

class AverageRecuperationStatisticsBuilder extends StatisticsBuilder {
    build(): StatisticsData {
        let data: AvgRecuperationChartData[];
        if (this.isDailyAggregated()) {
            data = aggregatedAverageRecuperationDaily(this._vehicleStatistics, this._startDate, this._endDate);
            return {
                aggregationPeriodType: AggregationPeriodType.DAILY,
                data,
                summaryValue: getAverageRecuperationSummaryValue(data),
            };
        } else {
            data = aggregatedAverageRecuperationMonthly(this._vehicleStatistics, this._startDate, this._endDate);
            return {
                aggregationPeriodType: AggregationPeriodType.MONTHLY,
                data,
                summaryValue: getAverageRecuperationSummaryValue(data),
            };
        }
    }
}

export default AverageRecuperationStatisticsBuilder;
