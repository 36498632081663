import { WeekdayButtons } from './WeekdayButtons';
import TimePicker from '@rio-cloud/rio-uikit/TimePicker';
import Configuration from '../../Configuration';
import NextOccurrences from '../NextOccurrences';
import { useIntl } from 'react-intl';
import React, { useCallback, useEffect } from 'react';
import { TimerConfig } from './TimerSetupForm';

interface TimerSettingsProps {
    timerConfig: TimerConfig;
    setTimerConfig: (value: React.SetStateAction<TimerConfig>) => void;
    hasWeekdaysSelected: boolean;
}

const TimerSettings = ({ timerConfig, setTimerConfig, hasWeekdaysSelected }: TimerSettingsProps) => {
    const { formatMessage } = useIntl();

    const handleChange = useCallback(
        <T extends keyof TimerConfig>(key: T) =>
            (value: TimerConfig[T]) => {
                setTimerConfig((prevConfig: TimerConfig) => ({ ...prevConfig, [key]: value }));
            },
        [setTimerConfig]
    );

    // Clears the selected weekdays if weeklyRepeat is off and more than one weekday is selected
    useEffect(() => {
        if (!timerConfig.weeklyRepeat && timerConfig.weekdays.length > 1) {
            setTimerConfig(prev => ({ ...prev, weekdays: [] }));
        }
    }, [timerConfig.weeklyRepeat, timerConfig.weekdays, setTimerConfig]);

    return (
        <>
            <div className="text-medium">{formatMessage({ id: 'e4c.vehicle.details.weekDays' })}</div>
            <div className="display-flex justify-content-between margin-top-10 margin-bottom-20" data-testid="weekdays">
                <WeekdayButtons
                    weekdays={timerConfig.weekdays}
                    setWeekdays={handleChange('weekdays')}
                    isRepeatTimer={timerConfig.weeklyRepeat}
                />
                <TimePicker
                    value={timerConfig.departureTime}
                    onChange={handleChange('departureTime')}
                    showIcon
                    className="margin-left-5 width-30pct"
                />
            </div>
            <Configuration
                title={formatMessage({ id: 'e4c.vehicle.details.repeatTimer.title' })}
                description={
                    timerConfig.weeklyRepeat
                        ? formatMessage({ id: 'e4c.vehicle.details.repeatTimer.description.on' })
                        : formatMessage({ id: 'e4c.vehicle.details.repeatTimer.description.off' })
                }
                toggle={{
                    value: timerConfig.weeklyRepeat,
                    setValue: handleChange('weeklyRepeat'),
                }}
            />
            {hasWeekdaysSelected && (
                <NextOccurrences
                    weekdays={timerConfig.weekdays}
                    departureTime={timerConfig.departureTime}
                    repeatTimer={timerConfig.weeklyRepeat}
                />
            )}
            {!timerConfig.comfortTimer && (
                <>
                    <hr />
                    <Configuration
                        title={formatMessage({ id: 'e4c.vehicle.details.chargeUntil.title' })}
                        description={formatMessage({ id: 'e4c.vehicle.details.chargeUntil.description' })}
                        slider={{
                            value: timerConfig.chargeUntil || 100,
                            minValue: 20,
                            maxValue: 100,
                            step: 1,
                            unit: '%',
                            setValue: handleChange('chargeUntil'),
                        }}
                        dataTestId="timer-charge-until"
                    />
                </>
            )}
            <hr />
            <Configuration
                title={formatMessage({ id: 'e4c.vehicle.details.climateControl.title' })}
                description={formatMessage({ id: 'e4c.vehicle.details.climateControl.description.timer' })}
                toggle={{
                    value: timerConfig.climateMode,
                    setValue: handleChange('climateMode'),
                    isDisabled: timerConfig.comfortTimer,
                }}
                dataTestId="timer-climate-control"
            />
            <hr />
            <Configuration
                title={formatMessage({ id: 'e4c.vehicle.details.readyToDrive.title' })}
                description={formatMessage({ id: 'e4c.vehicle.details.readyToDrive.description' })}
                slider={{
                    value: timerConfig.readyToDriveDuration,
                    minValue: 0,
                    maxValue: 180,
                    step: 5,
                    unit: formatMessage({ id: 'e4c.global.minutes.unit' }),
                    setValue: handleChange('readyToDriveDuration'),
                }}
            />
        </>
    );
};

export default TimerSettings;
