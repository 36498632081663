import { TrackingAction } from '../../utils/googleTagManager';
import { CHARTS } from '../../common/constants/common';
import { KpiEnum, MultiselectOptionsType } from '../types';

export const processCorrectGAEventKpi = (kpi: KpiEnum, chartPosition: string): TrackingAction => {
    if (chartPosition === CHARTS.left) {
        switch (kpi) {
            case KpiEnum.AV_DRIVING_CONSUMPTION:
                return TrackingAction.CHANGE_CHARTS_FILTER_SELECTION_ONE_CONSUMPTION;
            case KpiEnum.AV_RECUPERATION:
                return TrackingAction.CHANGE_CHARTS_FILTER_SELECTION_ONE_RECUPERATION;
            case KpiEnum.AV_MILEAGE:
                return TrackingAction.CHANGE_CHARTS_FILTER_SELECTION_ONE_MILEAGE;
        }
    }
    if (chartPosition === CHARTS.right) {
        switch (kpi) {
            case KpiEnum.AV_DRIVING_CONSUMPTION:
                return TrackingAction.CHANGE_CHARTS_FILTER_SELECTION_TWO_CONSUMPTION;
            case KpiEnum.AV_RECUPERATION:
                return TrackingAction.CHANGE_CHARTS_FILTER_SELECTION_TWO_RECUPERATION;
            case KpiEnum.AV_MILEAGE:
                return TrackingAction.CHANGE_CHARTS_FILTER_SELECTION_TWO_MILEAGE;
        }
    }
    return TrackingAction.CHANGE_CHARTS_FILTER_SELECTION;
};

export const processCorrectGAEventTimeframe = (chartPosition: string): TrackingAction =>
    chartPosition === CHARTS.left
        ? TrackingAction.CHANGE_CHART_ONE_TIMEFRAME
        : TrackingAction.CHANGE_CHART_TWO_TIMEFRAME;

export const processCorrectGAEventVehicles = (chartPosition: string): TrackingAction =>
    chartPosition === CHARTS.left ? TrackingAction.CHANGE_CHART_ONE_VEHICLE : TrackingAction.CHANGE_CHART_TWO_VEHICLE;

export const getProcessedSelection = (
    selectedVehicleIds: string[] | MultiselectOptionsType[],
    newSelection: string[],
    vehiclesCount: number
): string[] | MultiselectOptionsType[] => {
    if (newSelection.length < 1) {
        return [MultiselectOptionsType.ALL_OPTIONS];
    }

    const newSelectedVehiclesAmount =
        newSelection.length - (newSelection.includes(MultiselectOptionsType.ALL_OPTIONS) ? 1 : 0);

    if (newSelectedVehiclesAmount === vehiclesCount) {
        return [MultiselectOptionsType.ALL_OPTIONS];
    }

    if (selectedVehicleIds.includes(MultiselectOptionsType.ALL_OPTIONS)) {
        return newSelection.filter(v => v !== MultiselectOptionsType.ALL_OPTIONS);
    } else {
        const justAddedAll = newSelection?.[newSelection.length - 1] === MultiselectOptionsType.ALL_OPTIONS;
        return justAddedAll ? [MultiselectOptionsType.ALL_OPTIONS] : newSelection;
    }
};
