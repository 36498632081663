import React, { ReactElement } from 'react';

export interface HorizontalButtonsProps {
    buttons: HorizontalButton[];
}

export interface HorizontalButton {
    className: string;
    text: string | ReactElement;
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    disabled?: boolean;
}

const HorizontalButtons = ({ buttons }: HorizontalButtonsProps): JSX.Element => {
    return (
        <div>
            {buttons.map((button, index) => {
                const margin = buttons.length - 1 > index ? ' margin-right-10' : '';
                const className = `btn ${button.className}${button?.disabled ? ' disabled' : ''}${margin}`;
                return (
                    <div
                        role={'button'}
                        key={`horizontal-button-${index}`}
                        className={className}
                        onClick={button.onClick}
                        data-testid={`horizontal-button-${index}`}
                    >
                        {button.text}
                    </div>
                );
            })}
        </div>
    );
};

export default HorizontalButtons;
