import { State } from '../../../../../types';
import { TimerConfiguration, UserAction, ChargingConfiguration } from '../../components/details/vehicleDetails/types';
import { Vehicle } from '../../types';
import { ErrorCodeEnum } from '../../../common/enums/ErrorCode';

export const getFetchingDetails = (state: State): boolean => {
    return state.vehicleDetails.fetchingDetails;
};

export const getTimerConfiguration = (state: State): TimerConfiguration | undefined => {
    return state.vehicleDetails.timerConfiguration;
};

export const getSelectedVehicle = (state: State): Vehicle | null => {
    return state.vehicleDetails.selectedVehicle;
};

export const getSendingChargingConfigSucceeded = (state: State): boolean => {
    return state.vehicleDetails.sendingChargingConfigSucceeded;
};

export const getSendingChargingConfigFailed = (state: State): boolean => {
    return state.vehicleDetails.sendingChargingConfigFailed;
};

export const getShowConfigurationChangeConfirmDialog = (state: State): boolean => {
    return state.vehicleDetails.showConfigurationChangeConfirmDialog;
};

export const getIsConfigurationChangeDialogConfirmed = (state: State): boolean => {
    return state.vehicleDetails.isConfigurationChangeDialogConfirmed;
};

export const getSelectedVehicleBeforeChanges = (state: State): Vehicle | null => {
    return state.vehicleDetails.selectedVehicleBeforeChanges;
};

export const getShowUnsavedChangesConfirmDialog = (state: State): boolean => {
    return state.vehicleDetails.showUnsavedChangesConfirmDialog;
};

export const getShowSwitchToImmediateChargingDialog = (state: State): boolean => {
    return state.vehicleDetails.showSwitchToImmediateChargingDialog;
};

export const getIsSwitchToImmediateChargingConfirmed = (state: State): boolean => {
    return state.vehicleDetails.isSwitchToImmediateChargingConfirmed;
};

export const getTimerConfigurationBeforeChanges = (state: State): TimerConfiguration | undefined => {
    return state.vehicleDetails.timerConfigurationBeforeChanges;
};

export const getUserAction = (state: State): UserAction | null => {
    return state.vehicleDetails.userAction;
};

export const getChargingConfigurations = (state: State): ChargingConfiguration[] => {
    return state.vehicleDetails.chargingConfigurations;
};

export const getVehicleDetailsErrorCode = (state: State): ErrorCodeEnum | null => {
    return state.vehicleDetails.errorCode;
};
