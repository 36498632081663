import ReactStars from 'react-rating-stars-component';
import './Feedback.css';
import { useState } from 'react';
import RadioButton from '@rio-cloud/rio-uikit/RadioButton';
import { FormattedMessage } from 'react-intl';
import { useSendFeedback } from './hooks/useSendFeedback';
import InformativeState from '../../states/InformativeState';
import { getLocale } from '../../../../../../configuration/lang/selectors';
import { store } from '../../../../../../configuration/setup/store';
import { config } from '../../../../../../config';

type FeedbackState = {
    rating: number | null;
    text: string;
    contactable: boolean | null;
};

const TOTAL_WORD_COUNT = 3000;
const TEXT_AREA_ROWS = 4;
const STARS_COUNT = 5;
const STARS_WIDTH = 60;

const FeedbackItem = (): JSX.Element => {
    const [feedbackState, setFeedbackState] = useState<FeedbackState>({ rating: null, text: '', contactable: null });
    const [wordCount, setWordCount] = useState<number>(0);
    const [showError, setShowError] = useState<boolean>(false);
    const [showFeedbackReceived, setShowFeedbackReceived] = useState<boolean>(false);
    const { isSending, isError, sendFeedback } = useSendFeedback(() => {
        setShowFeedbackReceived(true);
    });

    const handleRating = (rating: number) =>
        setFeedbackState(prevFeedbackState => ({
            ...prevFeedbackState,
            rating,
        }));

    const handleTextChange = (event: { target: { value: string } }) => {
        const value = event.target.value;
        const currentWordCount = value.length;
        setFeedbackState(prevFeedbackState => ({
            ...prevFeedbackState,
            text: value,
        }));
        setWordCount(currentWordCount);
    };

    const handleContact = (contactable: boolean) => {
        setFeedbackState(prevFeedbackState => ({
            ...prevFeedbackState,
            contactable,
        }));
    };

    const handleFormSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault();

        if (isSending) {
            return;
        }
        if (feedbackState.rating === null || feedbackState.contactable === null || isError) {
            setShowError(true);
            return;
        }

        sendFeedback({
            rating: feedbackState.rating,
            text: feedbackState.text,
            contactable: feedbackState.contactable,
        });
    };

    const getQuestionnaireLink = () => {
        const language = getLocale(store.getState());
        return `${config.feedbackQuestionnaire}${language}`;
    };

    if (showFeedbackReceived) {
        return (
            <InformativeState
                type="success"
                headline={<FormattedMessage id={'e4c.feedbackForm.thankYouTitle'} />}
                message={<FormattedMessage id={'e4c.feedbackForm.thankYouDetails'} />}
            />
        );
    }

    return (
        <>
            <div className={`${isSending ? 'opacity-50' : ''}`}>
                <p className="text-size-h2 text-thin">
                    <FormattedMessage id="e4c.feedbackForm.title" />
                </p>
                <FormattedMessage
                    id="e4c.feedbackForm.details.appreciate"
                    values={{
                        linebreak: <br />,
                        moduleName: (
                            <span className="text-bold">
                                <FormattedMessage id="e4c.feedbackForm.modalTitle" />
                            </span>
                        ),
                    }}
                />
                <form onSubmit={handleFormSubmit} id="feedbackForm">
                    <ol className="padding-15">
                        <li>
                            <FormattedMessage
                                id="e4c.feedbackForm.details.overallSatisfaction"
                                values={{
                                    moduleName: (
                                        <span className="text-bold">
                                            <FormattedMessage id="e4c.feedbackForm.modalTitle" />
                                        </span>
                                    ),
                                }}
                            />
                            {showError && feedbackState.rating === null && (
                                <i className="text-color-danger">
                                    <span className="margin-right-5 margin-left-5 rioglyph rioglyph-warning-sign" />
                                    <FormattedMessage id="e4c.feedbackForm.details.mandatoryField" />
                                </i>
                            )}
                            <div className="margin-bottom-15">
                                <i>
                                    <FormattedMessage id="e4c.feedbackForm.details.note" />
                                </i>
                            </div>
                            <ReactStars
                                count={STARS_COUNT}
                                onChange={handleRating}
                                size={STARS_WIDTH}
                                activeColor="#fccc7c"
                                color="#ffffff"
                            />
                        </li>
                        <li className="margin-bottom-10 form-group has-feedback">
                            <FormattedMessage
                                id={'e4c.feedbackForm.details.otherFeedback'}
                                values={{
                                    moduleName: (
                                        <span className="text-bold">
                                            <FormattedMessage id="e4c.feedbackForm.modalTitle" />
                                        </span>
                                    ),
                                }}
                            />{' '}
                            <i>
                                (<FormattedMessage id="e4c.feedbackForm.details.optional" />)
                            </i>
                            <textarea
                                className="form-control max-height-200 margin-top-10"
                                value={feedbackState.text}
                                rows={TEXT_AREA_ROWS}
                                maxLength={TOTAL_WORD_COUNT}
                                onChange={handleTextChange.bind(null)}
                            />
                            <span className={`help-block ${wordCount === TOTAL_WORD_COUNT ? 'text-color-danger' : ''}`}>
                                {wordCount}/{TOTAL_WORD_COUNT}
                            </span>
                        </li>
                        <li className="margin-top-20 margin-bottom-10">
                            <FormattedMessage id="e4c.feedbackForm.details.contact" />
                            {showError && feedbackState.contactable === null && (
                                <i className="text-color-danger">
                                    <span className="margin-right-5 margin-left-5 rioglyph rioglyph-warning-sign" />
                                    <FormattedMessage id="e4c.feedbackForm.details.mandatoryField" />
                                </i>
                            )}
                        </li>
                        <RadioButton
                            name="ContactRadios"
                            onClick={handleContact.bind(null, true)}
                            inline={true}
                            label={<FormattedMessage id="e4c.feedbackForm.details.contactYes" />}
                            defaultChecked={!!feedbackState.contactable}
                        />
                        <RadioButton
                            name="ContactRadios"
                            onClick={handleContact.bind(null, false)}
                            inline={true}
                            label={<FormattedMessage id="e4c.feedbackForm.details.contactNo" />}
                            defaultChecked={feedbackState.contactable === false}
                        />
                    </ol>
                    <p>
                        <FormattedMessage id="e4c.feedbackForm.details.productSatisfactionQuestionnaire.description" />{' '}
                        <a href={getQuestionnaireLink()} target="_blank" rel="noreferrer noopener">
                            <FormattedMessage id="e4c.feedbackForm.details.productSatisfactionQuestionnaire.link" />
                        </a>
                        .
                    </p>
                    <button
                        className={`btn btn-primary float-right ${isSending ? 'btn-loading-overlay' : ''}`}
                        form="feedbackForm"
                        type="submit"
                    >
                        <FormattedMessage id="e4c.feedbackForm.sendFeedback.button" />
                    </button>
                </form>
            </div>
        </>
    );
};

export default FeedbackItem;
