import { useConfigCatClient, useFeatureFlag, User } from 'configcat-react';
import { useSelector } from 'react-redux';
import { State } from '../../../../types';
import { getUserAccount, getUsername } from '../../../../configuration';
import { FeatureToggles } from '../../../../configuration/featureToggle/featureToggles';
import { useEffect, useState } from 'react';

export type FeatureTogglesT = (typeof FeatureToggles)[keyof typeof FeatureToggles];

export const useFeatureToggle = (featureToggleId: FeatureTogglesT, defaultValue: string | boolean | number = false) => {
    const accountId = useSelector((state: State) => getUserAccount(state));
    const userObject = new User(accountId ?? 'NO_IDENTIFIER');
    const client = useConfigCatClient();
    const result = useFeatureFlag(featureToggleId, defaultValue, userObject);

    if ((window.Cypress || import.meta.env.DEV) && featureToggleId === FeatureToggles.EARLY_FLEET) {
        client.setOffline();
        return { value: false, loading: false };
    } else if (import.meta.env.DEV) {
        client.setOffline();
        return { value: true, loading: false };
    } else {
        return result;
    }
};

export const useFullFeatureToggleByUsername = (
    featureToggleId: FeatureTogglesT,
    defaultValue: string | boolean | number = false
) => {
    const [toggle, setToggle] = useState(<any>{
        value: undefined,
        variationId: undefined,
    });
    const username = useSelector((state: State) => getUsername(state));
    const client = useConfigCatClient();

    useEffect(() => {
        if (import.meta.env.PROD && username) {
            const userObject = new User(username);
            client.getValueDetailsAsync(featureToggleId, defaultValue, userObject).then(res => setToggle(res));
        }
    }, [client, defaultValue, featureToggleId, username]);

    if (import.meta.env.DEV) {
        client.setOffline();
        return { value: true, loading: false };
    } else {
        return { value: toggle.value, variationId: toggle.variationId, loading: toggle.value === undefined };
    }
};
