import { ReactElement } from 'react';
import moment from 'moment';
import Bar from '@rio-cloud/rio-uikit/Bar';
import BarChart from '@rio-cloud/rio-uikit/BarChart';
import ChartTooltip from '@rio-cloud/rio-uikit/ChartTooltip';
import Legend, { LegendPayload } from '@rio-cloud/rio-uikit/Legend';
import { AggregatedData, AggregationPeriodType } from '../statistics/types';

interface KpiBarChartProps {
    aggregationPeriodType: AggregationPeriodType;
    data: AggregatedData[];
    barSettings: KpiBarChartBarSettings[];
    legends?: LegendPayload[];
    onAxisLeftFormat: (value: any) => any | string;
    tooltip: ({ active, payload }: { active?: boolean; payload?: any }) => ReactElement;
}

interface KpiBarChartBarSettings {
    dataKey: string;
    color: string;
}

const getInterval = (data: any[], aggregation: AggregationPeriodType): number => {
    const length = data.length;
    if (aggregation === AggregationPeriodType.MONTHLY || length < 14) {
        return 0;
    }
    if (length < 30) {
        return 2;
    } else {
        return 4;
    }
};

const KpiBarChart = (props: KpiBarChartProps): JSX.Element => (
    <BarChart
        data={props.data}
        dataKey="date"
        legend={
            props.legends && (
                <Legend layout="horizontal" verticalAlign="bottom" align="center" payload={props.legends} />
            )
        }
        showGrid
        gridOptions={{ vertical: false }}
        xAxisOptions={{
            dataKey: 'date',
            interval: getInterval(props.data, props.aggregationPeriodType),
            tickFormatter: (date: moment.MomentInput) =>
                props.aggregationPeriodType === AggregationPeriodType.MONTHLY
                    ? moment(date).format('MMM')
                    : moment(date).format('DD.'),
        }}
        yAxisOptions={{
            tickCount: 6,
            tickFormatter: props.onAxisLeftFormat,
        }}
        bars={props.barSettings.map(bar => (
            <Bar key={bar.dataKey} dataKey={bar.dataKey} stackId="a" barSize={26} color={bar.color} />
        ))}
        tooltip={<ChartTooltip content={props.tooltip} cursor={{ fill: '#ebf9fa80' }} />}
    />
);

export default KpiBarChart;
