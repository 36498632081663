import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import ImmediateCharging from './ImmediateCharging';
import TimerCharging from './TimerCharging/TimerCharging';

export interface ChargingTab {
    id: string;
    label: ReactElement;
    content: ReactElement;
}

export const createChargingTabs = (assetId: string): ChargingTab[] => [
    {
        id: 'immediateTab',
        label: <FormattedMessage id="e4c.vehicle.details.tab.immediateCharging" />,
        content: <ImmediateCharging />,
    },
    {
        id: 'timerTab',
        label: <FormattedMessage id="e4c.vehicle.details.tab.timerCharging" />,
        content: <TimerCharging assetId={assetId} />,
    },
];
