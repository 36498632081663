import { ChargingCycleType, Cycle } from './types';
import { TimeTableCell } from './columns/TimeTableCell';
import BatteryTemperatureCell from './columns/BatteryTemperatureCell';
import { EnergyAndRangeCell } from './columns/EnergyAndRangeCell';
import { ConsumptionCell } from './columns/ConsumptionCell';
import { SocCell } from './columns/SocCell';

const ChargeRow = ({ cycle }: { cycle: Cycle }) => {
    const textColor = 'text-color-success';
    return (
        <tr className="bg-lightest">
            <TimeTableCell
                startTime={cycle.startTime}
                endTime={cycle.endTime}
                duration={cycle.duration}
                labelId="e4c.history.vehicle.table.chargeCycle"
                textColor={textColor}
            />
            <td>
                <EnergyAndRangeCell value={cycle.energyCharged} unit="kWh" textColor={textColor} />
            </td>
            <td>
                <EnergyAndRangeCell value={cycle.rangeAdded} unit="km" textColor={textColor} />
            </td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <CommonCells cycle={cycle} />
        </tr>
    );
};

const DischargeRow = ({ cycle }: { cycle: Cycle }) => {
    const textColor = 'text-color-black';
    return (
        <tr className="bg-lightest">
            <TimeTableCell
                startTime={cycle.startTime}
                endTime={cycle.endTime}
                duration={cycle.duration}
                labelId="e4c.history.vehicle.table.dischargeCycle"
                textColor={textColor}
            />
            <td>-</td>
            <td>-</td>
            <td>
                <EnergyAndRangeCell value={cycle.energyConsumed} unit="kWh" textColor={textColor} />
            </td>
            <td>
                <EnergyAndRangeCell value={cycle.rangeDriven} unit="km" textColor={textColor} />
            </td>
            <td>
                <ConsumptionCell value={cycle.consumption} />
            </td>
            <CommonCells cycle={cycle} />
        </tr>
    );
};

const CommonCells = ({ cycle }: { cycle: Cycle }) => {
    return (
        <>
            <td>
                <SocCell value={cycle.socAtStart} />
            </td>
            <td>
                <SocCell value={cycle.socAtEnd} />
            </td>
            <td>
                <BatteryTemperatureCell
                    maxBatteryTemperature={cycle.batteryTemperature.maxBatteryTemperature}
                    minBatteryTemperature={cycle.batteryTemperature.minBatteryTemperature}
                />
            </td>
        </>
    );
};

const CycleDataBody = ({ cycles }: { cycles: Cycle[] }) => {
    return (
        <tbody data-testid="table-body">
            {cycles.map((cycle, index) => {
                return cycle.type === ChargingCycleType.CHARGING ? (
                    <ChargeRow cycle={cycle} key={index} />
                ) : (
                    <DischargeRow cycle={cycle} key={index} />
                );
            })}
        </tbody>
    );
};

export default CycleDataBody;
