import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { getAccessToken, getIdToken, getLanguageData, getLocale, isUserSessionExpired } from '../../../configuration';
import { State } from '../../../types';
import App from '../components/App';
import { hideSessionExpiredDialog } from '../actions/App.actions';
import { getSessionExpiredAcknowledged } from '../selectors/App.selector';
import { AppPropertiesFromDispatch, AppPropertiesFromState } from './types';
import { AppActions } from '../actions/types';

const mapDispatchToProps = (dispatch: Dispatch): AppPropertiesFromDispatch => {
    return {
        hideSessionDialog: (): AppActions => dispatch(hideSessionExpiredDialog()),
    };
};

const mapStateToProps = (state: State): AppPropertiesFromState => {
    return {
        accessToken: getAccessToken(state) as string,
        idToken: getIdToken(state),
        languageData: getLanguageData(state),
        showSessionExpired: isUserSessionExpired(state) && !getSessionExpiredAcknowledged(state),
        userLocale: getLocale(state),
    };
};

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppContainer;
