import AbstractElectricVehiclesSorter from './AbstractElectricVehiclesSorter';
import SortDirection from '../../common/utilities/sort/SortDirection';
import orderBy from 'lodash/orderBy';
import { SORT_KEY_STATE_OF_CHARGE } from './keys';
import { VehicleChargingData } from '../models/VehicleChargingData';

class StateOfChargeSorter extends AbstractElectricVehiclesSorter {
    constructor() {
        super(SORT_KEY_STATE_OF_CHARGE);
    }

    sort = (array: VehicleChargingData[], direction: SortDirection): VehicleChargingData[] =>
        orderBy<VehicleChargingData>(array, e => e.measurements.stateOfCharge.value, direction);
}

export default StateOfChargeSorter;
