enum WeekdayIntl {
    MONDAY = 'e4c.vehicles.details.timerSettings.monday',
    TUESDAY = 'e4c.vehicles.details.timerSettings.tuesday',
    WEDNESDAY = 'e4c.vehicles.details.timerSettings.wednesday',
    THURSDAY = 'e4c.vehicles.details.timerSettings.thursday',
    FRIDAY = 'e4c.vehicles.details.timerSettings.friday',
    SATURDAY = 'e4c.vehicles.details.timerSettings.saturday',
    SUNDAY = 'e4c.vehicles.details.timerSettings.sunday',
}

export default WeekdayIntl;
