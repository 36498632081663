import classnames from 'classnames';

interface ResetFilterButtonProps {
    onClick: () => void;
}

const ResetFilterButton = (props: ResetFilterButtonProps): JSX.Element | null => {
    const classes = classnames(
        'bg-light hover-bg-highlight',
        'display-flex justify-content-center align-items-center',
        'padding-2',
        'rounded-circle',
        'position-absolute top-15 right-15',
        'display-block',
        'height-20 width-20',
        'cursor-pointer'
    );

    return (
        <span className={classes} onClick={props.onClick}>
            <span className={'rioglyph rioglyph-revert text-color-white text-size-12'} />
        </span>
    );
};

export default ResetFilterButton;
