import { ChargingConfigurationStatusEnum, ChargingModeEnum } from '../../../../types';

export interface VehicleChargingConfiguration {
    assetId: string;
    chargingMode: ChargingModeEnum;
    immediateConfiguration: ImmediateConfiguration;
    timers: Timer[];
    userAction?: UserAction;
}

export interface ImmediateConfiguration {
    climateMode: boolean;
    chargeUntil: number;
}

export enum Weekday {
    SUNDAY = 'SUNDAY',
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
}

export interface Timer {
    id: string;
    weekdays: Weekday[];
    departureTimeHour: number;
    departureTimeMinute: number;
    zoneId: string;
    climateMode: boolean;
    chargeUntil?: number;
    readyToDriveDuration: number;
    weeklyRepeat: boolean;
    enabled: boolean;
    nextDeparturesTimeStatus: {
        status: ChargingConfigurationStatusEnum;
        departureTime: Date;
    }[];
    comfortTimer: boolean;
}

interface UserAction {
    postImmediateConfigurations?: ActionLink;
    postTimerConfigurations?: ActionLink;
}

interface ActionLink {
    href: string;
}
