import { Vehicle } from '../../../types';
import ChargingConfiguration from '../vehicleDetails/ChargingConfiguration';
import { VehicleDetailsProps } from '../vehicleDetails/types';
import VehicleDetailsConfiguration from './VehicleDetailsConfiguration';

class E4CVehicleDetailsConfiguration extends VehicleDetailsConfiguration {
    private readonly _selectedVehicle: Vehicle;
    private readonly _vehicleDetailsProps: VehicleDetailsProps;
    private readonly _isReadOnlyUser: boolean;
    private readonly _toggleUnsavedVehicleChanges: (unsavedChanges: boolean) => void;

    constructor(
        toggleUnsavedVehicleChanges: (unsavedChanges: boolean) => void,
        selectedVehicle: Vehicle,
        vehicleDetailsProps: VehicleDetailsProps,
        isReadOnlyUser: boolean
    ) {
        super();
        this._toggleUnsavedVehicleChanges = toggleUnsavedVehicleChanges;
        this._selectedVehicle = selectedVehicle;
        this._vehicleDetailsProps = vehicleDetailsProps;
        this._isReadOnlyUser = isReadOnlyUser;
    }

    renderConfiguration(): JSX.Element {
        return (
            <ChargingConfiguration
                timerConfiguration={this._vehicleDetailsProps.timerConfiguration}
                chargingConfigurations={this._vehicleDetailsProps.chargingConfigurations}
                accessToken={this._vehicleDetailsProps.accessToken}
                chargingMode={this._selectedVehicle.chargingConfiguration.chargingMode}
                locale={this._vehicleDetailsProps.locale}
                onChargingModeChange={this._vehicleDetailsProps.onChargingModeChange}
                onAddTimer={this._vehicleDetailsProps.onAddTimer}
                onRemoveTimer={this._vehicleDetailsProps.onRemoveTimer}
                onAddDayToTimer={this._vehicleDetailsProps.onAddDayToTimer}
                onRemoveDayFromTimer={this._vehicleDetailsProps.onRemoveDayFromTimer}
                onDepartureTimeChange={this._vehicleDetailsProps.onDepartureTimeChange}
                onTimerEnabledChange={this._vehicleDetailsProps.onTimerEnabledChange}
                climateMode={this._selectedVehicle.chargingConfiguration.climateMode}
                onClimateModeChange={this._vehicleDetailsProps.onClimateModeChange}
                targetStateOfCharge={this._selectedVehicle.chargingConfiguration.targetStateOfCharge}
                onTargetStateOfChargeChange={this._vehicleDetailsProps.onTargetStateOfChargeChange}
                isReadOnlyUser={this._isReadOnlyUser}
                onReadyToDriveDurationChange={this._vehicleDetailsProps.onReadyToDriveDurationChange}
                toggleUnsavedVehicleChanges={this._toggleUnsavedVehicleChanges}
            />
        );
    }
}

export default E4CVehicleDetailsConfiguration;
