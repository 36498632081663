import ContentLoader from '@rio-cloud/rio-uikit/ContentLoader';
import { ReactElement } from 'react';
import { NO_DATA } from '../../../vehicleCharging/components/types';
import TooltipWrapper from '../Tooltip/TooltipWrapper';

const LabelledText = ({
    label,
    text,
    isLoading = false,
    contentLoaderWidth,
}: {
    text?: string;
    label: string;
    isLoading?: boolean;
    contentLoaderWidth?: string;
}): ReactElement => {
    const isNoData = text === NO_DATA;
    return (
        <>
            <label className="margin-right-10 margin-bottom-0 max-width-150" id={`${label}-presentation`}>
                <TooltipWrapper tooltipContent={label} dataTestId={`${label}-presentation-tooltip`}>
                    <>{label}</>
                </TooltipWrapper>
            </label>
            {isLoading ? (
                <ContentLoader width={contentLoaderWidth} />
            ) : (
                <span
                    role="presentation"
                    className={`${isNoData ? 'text-color-light' : ''} white-space-nowrap`}
                    aria-labelledby={`${label}-presentation`}
                    data-testid={`${label}-presentation`}
                >
                    {text}
                </span>
            )}
        </>
    );
};
export default LabelledText;
