import { FormattedMessage } from 'react-intl';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';

const UnsavedChangesDialog = ({
    showDialog,
    onConfirm,
    onCancel,
}: {
    showDialog: boolean;
    onConfirm: () => void;
    onCancel: () => void;
}) => {
    return (
        <ConfirmationDialog
            show={showDialog}
            title={<FormattedMessage id="e4c.vehicle.details.unsavedChangesConfirmationDialog.title" />}
            content={<FormattedMessage id="e4c.vehicles.details.unsavedChangesText" />}
            onClickConfirm={onConfirm}
            onClickCancel={onCancel}
            cancelButtonText={<FormattedMessage id="e4c.global.keepEditing" />}
            confirmButtonText={<FormattedMessage id="e4c.global.discardChanges" />}
            disableConfirm={false}
            useOverflow={false}
            bsSize="sm"
        />
    );
};

export default UnsavedChangesDialog;
