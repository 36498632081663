import { FormattedMessage } from 'react-intl';
import ChargingModeColumnIntl from './ChargingModeColumnIntl';

const SendingConfiguration = (): JSX.Element => {
    return (
        <div className="display-flex text-color-gray">
            <span className="rioglyph rioglyph-wifi text-size-18 padding-right-5" />
            <div className="ellipsis-1">
                <FormattedMessage id={ChargingModeColumnIntl.SENDING_CONFIGURATION} />
            </div>
        </div>
    );
};

export default SendingConfiguration;
